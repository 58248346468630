// Styles
import styles from './style.module.css';
import card from '../../../../components/Styles/card.module.css';

// Components
import React, { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { Button, Card, CardBody, CardHeader, Col, Container, Row, UncontrolledTooltip } from "reactstrap"
import { Link } from 'react-router-dom';
import Select from "react-select";
import CreatableSelect from 'react-select/creatable';
import { Redirect, useHistory } from 'react-router-dom';

// Icons
import matrix_icon from '../../../../assets/icons/matrix_icon.svg';
import controls_icon from '../../../../assets/icons/controls_icon.svg';
import settings_icon from '../../../../assets/icons/settings_icon.svg';
import { selectDevice } from '../../../../redux/actions';

const RiskManagement = (props) => {
    const dispatch = useDispatch();
    const configID = window.sessionStorage.getItem("configID")
    const navigate = useHistory()
    useEffect(() => {
        const configID = window.sessionStorage.getItem("configID")
        console.log("RiskControl PROPS", props)
        if (!props.device || (Object.keys(props.device).length === 0 && !props.selectingDevice)) {
            const sessionStorageDevice = JSON.parse(localStorage.getItem('device'))
            if (sessionStorageDevice && sessionStorageDevice !== undefined) {
                dispatch(selectDevice(sessionStorageDevice._id, sessionStorageDevice._id, configID));
            }
            else if (props.location.state) {
                dispatch(selectDevice(props.location.state.id, props.location.state.idDevice, configID));
            } else {
                navigate.push("/home")
            }
        }
        setTimeout(() => {
            if (!props.device || (Object.keys(props.device).length === 0 && !props.selectingDevice)) {
                if (props.location.state) {
                    dispatch(selectDevice(props.location.state.id, props.location.state.idDevice, configID))
                } else {
                    // console.log("return 2")

                    return <Redirect to="/home" />
                }
            }
        }, 1500);
    }, [])

    const [currentRiskCat, setCurrentRiskCat] = useState({ value: "all", label: "All Categories" });
    var riskCats = [];
    if (props && props.riskCategories) {
        riskCats = [{ label: "All Groups", value: "all" }, ...props.riskCategories.map((grp) => {
            return { label: grp._source.name, value: grp._id };
        })]

    }
    const searchRiskMatrix = (riskCat) => {

        //This isn't the most efficient method, but was needed to get my head around the data structures
        //TODO: refactor to work more efficiently
        var finalStatus = { complete: [], started: [], toDo: [] };
        if (riskCat === '' || !props.riskDetails) {
            return { complete: [], started: [], toDo: [] };
        }
        var catRisks
        if (riskCat.value !== "all") {
            catRisks = props.riskDetails.filter((rsk) => rsk._source.category === riskCat.value)//.map((rsk) => rsk._id);
        }
        else {
            catRisks = props.riskDetails//.map((rsk) => rsk._id);
        }
        catRisks.forEach((rsk) => {
            var isDone = true
            var empty = true
            Object.keys(rsk._source).filter(k => !["ref", "edit", "configID", "idDevice"].includes(k)).map(cat => {
                if (rsk._source[cat] === "") { isDone = false } else { empty = false }
            })
            if (isDone || rsk._source.benefit !== "") { finalStatus.complete.push(rsk._id); }
            else if (!empty) { finalStatus.started.push(rsk._id); }
            else { finalStatus.toDo.push(rsk._id); }
        });



        // 
        return finalStatus;

    };
    var riskCatsVal = searchRiskMatrix(currentRiskCat)

    var matrixResultsHTML = <div className={styles.numberStatus} style={{ fontSize: '0.8rem', alignItems: 'center' }}>
        {riskCatsVal.complete.length}:
        <span className={card.greenCounter}>
            COMPLETE
        </span>
        {riskCatsVal.started.length}:
        <span className={card.blueCounter}>
            STARTED
        </span>
        {riskCatsVal.toDo.length}:
        <span className={card.greyCounter}>
            TO DO
        </span>
    </div>


    const searchReqs = (reqGroup) => {
        //This isn't the most efficient method, but was needed to get my head around the data structures
        //TODO: refactor to work more efficiently

        if (reqGroup === '' || !props.reqs) {
            return { verified: [], notMet: [], notTested: [] };
        }
        var grpReqs
        if (reqGroup.value !== "all") {
            grpReqs = props.reqs.filter((req) => req._source.group === reqGroup.value && req._source.riskReq).map((req) => req._id);
        }
        else {
            grpReqs = props.reqs.filter((req) => req._source.riskReq).map((req) => req._id);
        }
        var records = props.records.filter((r) => r._source.idTiles.length > 0);

        var outputVal = {};

        var filterRecs = records.filter(
            (rec) => grpReqs.filter((value) => rec._source.idTiles.includes(value)).length > 0
        );
        var reqsWithRecs = {};
        filterRecs.forEach((fRec) => {
            fRec._source.idTiles.forEach((idTile) => {
                if (reqsWithRecs[idTile]) {
                    reqsWithRecs[idTile] = [...reqsWithRecs[idTile], fRec];
                } else {
                    reqsWithRecs[idTile] = [fRec];
                }
            });
        });
        var reqsWithRuns = {};

        Object.keys(reqsWithRecs).forEach((reqId) => {
            var reqRecords = [...reqsWithRecs[reqId]];
            var reqRecordsRuns = []
            reqRecords.forEach((reqRec) => {
                reqRecordsRuns = [...reqRecordsRuns, ...props.runs.filter((run) => run._source.idTest === reqRec._id)]
            })
            reqsWithRuns[reqId] = reqRecordsRuns
        }
        )


        var finalRequirementStatus = { verified: [], notMet: [], notTested: [] };

        grpReqs.forEach((reqID) => {

            if (!Object.keys(reqsWithRuns).includes(reqID)) {
                finalRequirementStatus.notTested.push(reqID);
            } else {
                var runResults = []
                reqsWithRuns[reqID].forEach(run => runResults = [...runResults, ...run._source.results])

                var reqAnoms = []
                runResults.forEach(res => typeof res.idAnom === "string" ? reqAnoms = [...reqAnoms, ...props.anoms.filter(anm => anm._id === res.idAnom)] : res.idAnom.forEach(anomID => reqAnoms = [...reqAnoms, ...props.anoms.filter(anm => anm._id === anomID)]))


                if (reqAnoms.some(anm => [2, 3].includes(anm._source.type))) {
                    finalRequirementStatus.notMet.push(reqID)
                } else if (reqAnoms.some(anm => [4].includes(anm._source.type)) || runResults.some(res => res.result === 1)) {
                    finalRequirementStatus.verified.push(reqID);
                } else {
                    finalRequirementStatus.notTested.push(reqID);
                }
            }
        });

        // 
        return finalRequirementStatus;

    };

    const [reqResults, setReqResults] = useState(searchReqs({ value: "all", label: "All Groups" }));
    const [currentReqGroup, setCurrentReqGroup] = useState({ value: "all", label: "All Groups" });

    var reqResultsVal = searchReqs(currentReqGroup)
    var reqResultsHTML = <div className={styles.numberStatus} style={{ fontSize: '0.8rem', alignItems: 'center' }}>
        {reqResultsVal.verified.length}:
        <span className={card.greenCounter}>
            VERIFIED
        </span>
        {reqResultsVal.notMet.length}:
        <span className={card.redCounter}>
            NOT MET
        </span>
        {reqResultsVal.notTested.length}:
        <span className={card.greyCounter}>
            TO TEST
        </span>
    </div>
    var groupOptions = [];
    var riskGroupsIDs = []



    if (!props.device || (Object.keys(props.device).length === 0 && !props.selectingDevice)) {
        if (props.location.state) {
            dispatch(selectDevice(props.location.state.id, props.location.state.idDevice, configID))
        } else {
            // console.log("return 3")

            return <Redirect to="/home" />
        }
    }

    if (props && props.reqGroupsRaw) {
        riskGroupsIDs = props.reqGroupsRaw.filter(g => g._source.risk).map(r => r._id)
        props.reqGroupsRaw.filter(g => !g._source.risk).forEach(r => {
            if (props.reqs.filter(rq => rq._source.group === r._id).some(rq => rq._source.riskReq)) { riskGroupsIDs.push(r._id) }
        })
        groupOptions = [{ label: "All Groups", value: "all" }, ...props.reqGroupsRaw.filter(r => riskGroupsIDs.includes(r._id)).map((grp) => {
            return { label: grp._source.title, value: grp._id };
        })]


    }
    return (
        <React.Fragment>
            {props.device && props.device._source && props.riskDetails && <div style={{ "minHeight": "100vh" }}>
                <Container style={{
                    marginTop: "30px",
                    float: "left",
                    maxWidth: "1920px",
                }}>
                    <h3 className={styles.blocksHeading}>Risk Management</h3>
                    <Row className="blocks-row">
                        <div className={styles.cardContainer}>
                            {/* Risk Matrix Card */}
                            <Card className={card.card + ' ' + styles.deviceCard}>
                                <CardHeader id="riskMatrixTooltip" className={card.cardHeader}>
                                    <img src={matrix_icon} alt="riskmatrix" style={{ marginRight: "20px" }} />
                                    Risk Matrix
                                    <UncontrolledTooltip target="riskMatrixTooltip" placement="right">
                                        View and modify the risk matrix configuration for your device.
                                    </UncontrolledTooltip>
                                    <Link to={{ pathname: "/riskMatrix", state: { id: props.device._id, idDevice: props.device._id, device: props.device } }}>
                                        <Button className={styles.blueButton}><i className="mdi mdi-greater-than"></i></Button>
                                    </Link>
                                </CardHeader>
                                <CardBody className={styles.blocksBody + " " + card.cardBody} style={{ textAlign: 'left' }}>
                                    <span>
                                        Risk Categories:
                                        {/* <i className="uil uil-info-circle" id="StatusTooltip" ></i>
									<UncontrolledTooltip
																				target="StatusTooltip"
										placement="right"
									>
										The Stage in the development process the device is in
									</UncontrolledTooltip> */}
                                    </span>
                                    <Select
                                        onChange={(event, target) => {
                                            setCurrentRiskCat(event);
                                            // setReqResults(searchReqs(event));
                                        }}
                                        value={currentRiskCat}
                                        options={riskCats}
                                    />
                                    <span style={{ fontSize: '0.8rem' }}>
                                        {"Total Risks: "}
                                        {currentRiskCat.value === "all" ? (

                                            props.riskDetails.length
                                        ) : (
                                            [
                                                ...props.riskDetails.filter(
                                                    (req) => req._source.category === currentRiskCat.value
                                                ),
                                            ].length
                                        )

                                        }
                                    </span>

                                    {matrixResultsHTML}
                                </CardBody>
                            </Card>

                            {/* Risk Controls Card */}
                            <Card className={card.card + ' ' + styles.deviceCard}>
                                <CardHeader id="riskControlsTooltip" className={card.cardHeader}>
                                    <img src={controls_icon} alt="riskcontrols" style={{ marginRight: "20px" }} />
                                    Risk Controls
                                    <UncontrolledTooltip target="riskControlsTooltip" placement="right">
                                        Modify the parameters used in the risk matrix for your device.
                                    </UncontrolledTooltip>
                                    <Link to={{ pathname: "/riskControls", state: { id: props.device._id, idDevice: props.device._id, device: props.device } }}>
                                        <Button className={styles.blueButton}><i className="mdi mdi-greater-than"></i></Button>
                                    </Link>
                                </CardHeader>
                                <CardBody className={styles.blocksBody + " " + card.cardBody} style={{ textAlign: 'left' }}>
                                    <span>
                                        Control Groups:
                                        {/* <i className="uil uil-info-circle" id="StatusTooltip" ></i>
									<UncontrolledTooltip
																				target="StatusTooltip"
										placement="right"
									>
										The Stage in the development process the device is in
									</UncontrolledTooltip> */}
                                    </span>
                                    <Select
                                        onChange={(event, target) => {
                                            setCurrentReqGroup(event);
                                            setReqResults(searchReqs(event));
                                        }}
                                        value={currentReqGroup}
                                        options={groupOptions}
                                    />
                                    <span style={{ fontSize: '0.8rem' }}>
                                        {"Total Requirements: "}
                                        {console.log("TOTAL REQS =", props.reqs.filter(
                                            req => req._source.riskReq
                                        ))}
                                        {currentReqGroup.value === "all" ? (

                                            props.reqs.filter(
                                                req => req._source.riskReq
                                            )
                                                .length
                                        ) : (
                                            [
                                                ...props.reqs.filter(
                                                    (req) => req._source.group === currentReqGroup.value && req._source.riskReq
                                                ),
                                            ].length
                                        )

                                        }
                                    </span>

                                    {reqResultsHTML}
                                </CardBody>
                            </Card>

                            {/* Risk Configuration Card */}
                            <Card className={card.card + ' ' + styles.deviceCard}>
                                <CardHeader id="riskConfigTooltip" className={card.cardHeader}>
                                    <img src={settings_icon} alt="riskconfig" style={{ marginRight: "20px" }} />
                                    Risk Configuration
                                    <UncontrolledTooltip target="riskConfigTooltip" placement="right">
                                        Edit and View the configuration for risk management, and link risks to the issue tracker.
                                    </UncontrolledTooltip>
                                    <Link to={{ pathname: "/riskConfig", state: { id: props.device._id, idDevice: props.device._id, device: props.device } }}>
                                        <Button className={styles.blueButton}><i className="mdi mdi-greater-than"></i></Button>
                                    </Link>
                                </CardHeader>
                                <CardBody data-tut="reactour__standardsprops" className={styles.blocksBody + ' ' + card.cardBody}>
                                    <Row>
                                        <Col xs="9">
                                            <span>Step 1: Define P and S</span>

                                        </Col>
                                        <Col xs={3}>
                                            {props.riskProbability &&props.riskProbability.length > 0 &&
                                                props.riskSeverity &&props.riskSeverity.length > 0 &&
                                                !(props.riskProbability.some(rM => rM._source.description === "")) &&
                                                !(props.riskSeverity.some(rM => rM._source.description === "")) ?

                                                <span className={card.greenCounter}>DONE</span> : <span className={card.blueCounter}>ACTIVE</span>
                                            }


                                        </Col>
                                    </Row>
                                    <Row style={{ marginTop: "20px" }}>
                                        <Col xs="9" style={{ marginLeft: "0px" }}>
                                            <div className={styles.wordWrap}> <span>Step 2: Define Risk acceptance</span></div>
                                        </Col>
                                        <Col xs={3}>
                                            {props.riskMatrix &&
                                                props.riskMatrix.length > 0
                                                &&

                                                !(props.riskMatrix.some(rM => rM._source.text === "")) ?

                                                <span className={card.greenCounter}>DONE</span> : <span className={card.blueCounter}>ACTIVE</span>
                                            }


                                        </Col>
                                    </Row>

                                </CardBody>
                            </Card>
                        </div>
                    </Row>
                </Container>
            </div>}
        </React.Fragment>
    )
}

//   export default managementCards;
const mapStateToProps = (state, ownProps) => {
    const { TestPlan } = state;
    return { ...TestPlan };
}
export default connect(mapStateToProps)(RiskManagement);