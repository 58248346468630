import * as constants from './constants';

type DataAction = { type: string, payload: {}, expectServerPayload: boolean, seqNo: number };

var nextSeqNo = 1;

export const getPropertiesMaster = (): DataAction => ({
    type: constants.GET_PROPERTIES_MASTER,
    payload: {},
    expectServerPayload: false,
    seqNo: -1,
});

export const getGroups = (_id: string): DataAction => ({
    type: "GET_GROUPS",
    payload: { _id },
    expectServerPayload: false,
    seqNo: -1,
});
export const removeTeam = (id: string, displayName: string): DataAction => ({
    type: constants.REMOVE_TEAM,
    payload: { id, displayName },

});

export const joinUserRoom = (userID: string): DataAction => ({
    type: constants.JOIN_USER_ROOM,
    payload: { userID },
    expectServerPayload: false,
    seqNo: -1,
});
//projects

export const selectProject = (_id: string): DataAction => ({
    type: constants.SELECT_PROJECT,
    payload: { _id },
    expectServerPayload: false,
    seqNo: -1,
});

export const createProject = (_source: {}): DataAction => ({
    type: constants.CREATE_PROJECT,
    payload: { objectType: "project", _source: _source },
    expectServerPayload: true,
    seqNo: nextSeqNo++,
});


export const updateProject = (_id: string, _source: {}): DataAction => ({
    type: constants.UPDATE_PROJECT,
    payload: { objectType: "project", _id, _source },
    expectServerPayload: !constants.APPLY_DOCUMENT_CHANGES_LOCALLY,
    seqNo: nextSeqNo++,
});

export const deleteProject = (_id: string, _source: {}): DataAction => ({
    type: constants.DELETE_PROJECT,
    payload: { objectType: "project", _id, _source },
    expectServerPayload: !constants.APPLY_DOCUMENT_CHANGES_LOCALLY,
    seqNo: nextSeqNo++,
});

//BOARDS

export const selectBoard = (_id: string, _oldId: string, _deviceId: string): DataAction => ({
    type: constants.SELECT_BOARD,
    payload: { _id, _oldId, _deviceId },
    expectServerPayload: true,
    seqNo: nextSeqNo++,
});

export const createBoard = (_source: {}): DataAction => ({
    type: constants.CREATE_BOARD,
    payload: { objectType: "board", _source: _source },
    expectServerPayload: true,
    seqNo: nextSeqNo++,
});


export const updateBoard = (_id: string, _source: {}): DataAction => ({
    type: constants.UPDATE_BOARD,
    payload: { objectType: "board", _id, _source },
    expectServerPayload: !constants.APPLY_DOCUMENT_CHANGES_LOCALLY,
    seqNo: nextSeqNo++,
});

export const deleteBoard = (_id: string, _source: {}): DataAction => ({
    type: constants.DELETE_BOARD,
    payload: { objectType: "board", _id, _source },
    expectServerPayload: !constants.APPLY_DOCUMENT_CHANGES_LOCALLY,
    seqNo: nextSeqNo++,
});

export const getBoards = (_id: string, userGroups = []): DataAction => ({
    type: constants.GET_BOARDS,
    payload: { objectType: "user", _id, userGroups },
    expectServerPayload: true,
    seqNo: nextSeqNo++,
});

export const getDeviceCSVData = (_id: string, time: Date): DataAction => ({
    type: constants.CSV_DOWLOAD,
    payload: { objectType: "device", _id, time },
    expectServerPayload: true,
    seqNo: nextSeqNo++,
});

export const getDeviceGroups = (_id: string): DataAction => ({
    type: constants.GET_DEVICE_GROUPS,
    payload: { objectType: "user", _id },
    expectServerPayload: true,
    seqNo: nextSeqNo++,
});
// Comments
export const leaveComment = (_id: string, _source: string, _topLevelComment: any): DataAction => ({
    type: constants.LEAVE_COMMENT,
    payload: { _id, _source, _topLevelComment },
    expectServerPayload: true,
    seqNo: nextSeqNo++,
});
export const getComments = (_id: string, _source: string): DataAction => ({
    type: constants.GET_COMMENTS,
    payload: { _id },
    expectServerPayload: true,
    seqNo: nextSeqNo++,
});

export const getTeamDevices = (_id: string): DataAction => ({
    type: constants.GET_TEAM_DEVICES,
    payload: { _id },
    expectServerPayload: true,
    seqNo: nextSeqNo++,
});
export const getUsersTeamsDevices = (_id: {}, userGroups = []): DataAction => ({
    type: constants.GET_USER_TEAM_DEVICES,
    payload: { objectType: "device", _id: _id, userGroups },
    expectServerPayload: true,
    seqNo: nextSeqNo++,
});
export const getNotifs = (_id: string, _source: string): DataAction => ({
    type: constants.GET_NOTIFS,
    payload: { _id },
    expectServerPayload: true,
    seqNo: nextSeqNo++,
});
export const getTour = (_id: string): DataAction => ({
    type: constants.GET_TOUR,
    payload: { _id },
    expectServerPayload: true,
    seqNo: nextSeqNo++,
});
export const putTour = (_id: string, _source: string): DataAction => ({
    type: constants.PUT_TOUR,
    payload: { _id, _source },
    expectServerPayload: true,
    seqNo: nextSeqNo++,
});
export const clearNotifs = (id: string, notifs: string): DataAction => ({
    type: constants.CLEAR_NOTIFS,
    payload: { id, notifs },
    expectServerPayload: true,
    seqNo: nextSeqNo++,
});

export const updateToTeam = (team: any): DataAction => ({
    type: constants.UPDATE_TO_TEAM,
    payload: { team },
    expectServerPayload: true,
    seqNo: nextSeqNo++,
});


export const getNews = (): DataAction => ({
    type: constants.GET_NEWS,
    payload: {},
    expectServerPayload: true,
    seqNo: nextSeqNo++,
});
//Devices

export const selectDevice = (_id: string, _oldId: string, configID: String): DataAction => ({
    type: constants.SELECT_DEVICE,
    payload: { _id, _oldId, configID },
    expectServerPayload: true,
    seqNo: nextSeqNo++,
});
export const selectConfig = (idDevice: string, configID: string): DataAction => ({
    type: constants.SELECT_CONFIG,
    payload: { objectType: "config", idDevice, configID },
    expectServerPayload: true,
    seqNo: nextSeqNo++,
});
export const configFound = ( ): DataAction => ({
    type: constants.CONFIG_FOUND,
    payload: { objectType: "config",   },
    expectServerPayload: true,
    seqNo: nextSeqNo++,
});

export const createDevice = (_source: {}, userGroups = []): DataAction => ({
    type: constants.CREATE_DEVICE,
    payload: { objectType: "device", _source: _source, userGroups },
    expectServerPayload: true,
    seqNo: nextSeqNo++,
});
export const createConfig = (idDevice: {}, configSource: {}, configID: String,copyFrom:String,copyOpts:{}): DataAction => ({
    type: constants.CREATE_CONFIG,
    payload: { objectType: "config", idDevice, configSource, configID,copyFrom,copyOpts },
    expectServerPayload: true,
    seqNo: nextSeqNo++,
});
export const deleteConfig = ( configID :String): DataAction => ({
    type: constants.DELETE_CONFIG,
    payload: { objectType: "config", configID },
    expectServerPayload: true,
    seqNo: nextSeqNo++,
});
export const updateConfig = (_id: string, _source: {}, userGroups: [], userID: string,): DataAction => ({
    type: constants.UPDATE_CONFIG,
    payload: { objectType: "config", _id, _source, userGroups, userID },
    expectServerPayload: true,
    seqNo: nextSeqNo++,
});
export const getSection = (_id: string, sectionType: String, configID: String): DataAction => ({
    type: constants.GET_SECTION,
    payload: { objectType: "device", sectionType, _id, configID },
    expectServerPayload: !constants.APPLY_DOCUMENT_CHANGES_LOCALLY,
    seqNo: nextSeqNo++,
});

export const updateSection = (_id: string, sectionType: String, value: {}, user: any, configID: String): DataAction => ({
    type: constants.UPDATE_SECTION,
    payload: { objectType: "device", sectionType, _id, value, user, configID },
    expectServerPayload: !constants.APPLY_DOCUMENT_CHANGES_LOCALLY,
    seqNo: nextSeqNo++,
});

export const populateSection = (_id: string, sectionType: String, _source: {}, configID: String): DataAction => ({
    type: constants.POPULATE_SECTION,
    payload: { objectType: "device", sectionType, _id, _source, configID },
    expectServerPayload: !constants.APPLY_DOCUMENT_CHANGES_LOCALLY,
    seqNo: nextSeqNo++,
});

export const createEvidenceSection = (_source: String, user: any): DataAction => ({
    type: constants.CREATE_EVIDENCE_SECTION,
    payload: { objectType: "device", _source, user },
    expectServerPayload: !constants.APPLY_DOCUMENT_CHANGES_LOCALLY,
    seqNo: nextSeqNo++,
});
export const deleteEvidenceSection = (_id: string, _source: String, user: any, configID: String): DataAction => ({
    type: constants.DELETE_EVIDENCE_SECTION,
    payload: { objectType: "device", _id, _source, user, configID },
    expectServerPayload: !constants.APPLY_DOCUMENT_CHANGES_LOCALLY,
    seqNo: nextSeqNo++,
});
export const getAnalysisOptions = (): DataAction => ({
    type: constants.GET_ANALYSIS_OPTIONS,
    payload: { objectType: "device" },
    expectServerPayload: !constants.APPLY_DOCUMENT_CHANGES_LOCALLY,
    seqNo: nextSeqNo++,
});
export const getCurrentSection = (_id: string, configID: String): DataAction => ({
    type: constants.GET_CURRENT_SECTION,
    payload: { objectType: "device", _id, configID },
    expectServerPayload: !constants.APPLY_DOCUMENT_CHANGES_LOCALLY,
    seqNo: nextSeqNo++,
});
export const updateCurrentSection = (_id: string, value: string, configID: String): DataAction => ({
    type: constants.UPDATE_CURRENT_SECTION,
    payload: { objectType: "device", _id, value, configID },
    expectServerPayload: !constants.APPLY_DOCUMENT_CHANGES_LOCALLY,
    seqNo: nextSeqNo++,
});

export const updateDevice = (_id: string, _source: {}, userGroups: [], userID: string,): DataAction => ({
    type: constants.UPDATE_DEVICE,
    payload: { objectType: "device", _id, _source, userGroups, userID },
    expectServerPayload: true,
    seqNo: nextSeqNo++,
});
export const deviceImpactingUserRemoval = (devices: [], users: []): DataAction => ({
    type: constants.DEVICE_IMPACTING_USER_REMOVAL,
    payload: { objectType: "device", devices, users },
    expectServerPayload: true,
    seqNo: nextSeqNo++,
});

export const createGroup = (_id: string, _source: {}, new_id: string): DataAction => ({
    type: constants.CREATE_GROUP,
    payload: { objectType: "group", _id, _source, new_id },
    expectServerPayload: true,
    seqNo: nextSeqNo++,
});


export const createReport = (_id: string, _source: {}, new_id: string): DataAction => ({
    type: constants.CREATE_REPORT,
    payload: { objectType: "report", _id, _source, new_id },
    expectServerPayload: true,
    seqNo: nextSeqNo++,
});
export const updateReport = (_id: string, _source: {}): DataAction => ({
    type: constants.UPDATE_REPORT,
    payload: { objectType: "report", _id, _source},
    expectServerPayload: true,
    seqNo: nextSeqNo++,
});
export const deleteReport = (_id: string, _source: {}): DataAction => ({
    type: constants.DELETE_REPORT,
    payload: { objectType: "report", _id, _source },
    expectServerPayload: !constants.APPLY_DOCUMENT_CHANGES_LOCALLY,
    seqNo: nextSeqNo++,
});
export const updateGroup = (_id: string, _source: {}): DataAction => ({
    type: constants.UPDATE_GROUP,
    payload: { objectType: "group", _id, _source },
    expectServerPayload: true,
    seqNo: nextSeqNo++,
});
export const analyseForProperties = (_id: string, _source: {}): DataAction => ({
    type: constants.POPULATE_PROPERTIES,
    payload: { objectType: "device", _id, _source },
    expectServerPayload: !constants.APPLY_DOCUMENT_CHANGES_LOCALLY,
    seqNo: nextSeqNo++,
});

export const updateProperties = (_id: string, _source: {}): DataAction => ({
    type: constants.UPDATE_PROPERTIES,
    payload: { objectType: "device", _id, _source },
    expectServerPayload: !constants.APPLY_DOCUMENT_CHANGES_LOCALLY,
    seqNo: nextSeqNo++,
});
export const deleteDevice = (_id: string, _source: {}): DataAction => ({
    type: constants.DELETE_DEVICE,
    payload: { objectType: "device", _id, _source },
    expectServerPayload: !constants.APPLY_DOCUMENT_CHANGES_LOCALLY,
    seqNo: nextSeqNo++,
});

export const getDevices = (_id: string): DataAction => ({
    type: constants.GET_DEVICES,
    payload: { objectType: "user", _id },
    expectServerPayload: true,
    seqNo: nextSeqNo++,
});
export const getProperties = (_id: string, _source: {}): DataAction => ({
    type: constants.GET_PROPERTIES,
    payload: { objectType: "device", _id, _source },
    expectServerPayload: !constants.APPLY_DOCUMENT_CHANGES_LOCALLY,
    seqNo: nextSeqNo++,
});

export const getAnalysis = (_id: string, _source: {}, _properties: {}): DataAction => ({
    type: constants.GET_ANALYSIS,
    payload: { objectType: "device", _id, _source, _properties },
    expectServerPayload: !constants.APPLY_DOCUMENT_CHANGES_LOCALLY,
    seqNo: nextSeqNo++,
});
export const searchSnomed = (term: string, country: string): DataAction => ({
    type: constants.SEARCH_SNOMED,
    payload: { objectType: "device", term, country },
    expectServerPayload: !constants.APPLY_DOCUMENT_CHANGES_LOCALLY,
    seqNo: nextSeqNo++,
});
export const getEvidence = (_id: string, _source: {}): DataAction => ({
    type: constants.GET_EVIDENCE,
    payload: { objectType: "evidence", _id, _source },
    expectServerPayload: !constants.APPLY_DOCUMENT_CHANGES_LOCALLY,
    seqNo: nextSeqNo++,
});

// TILES

export const selectTile = (_id: string): DataAction => ({
    type: constants.SELECT_TILE,
    payload: { _id },
    expectServerPayload: false,
    seqNo: -1,
});

export const deleteTile = (_id: string, _source: {}): DataAction => ({
    type: constants.DELETE_TILE,
    payload: { objectType: "tile", _id, _source },
    expectServerPayload: !constants.APPLY_DOCUMENT_CHANGES_LOCALLY,
    seqNo: nextSeqNo++,
});

export const updateTile = (_id: string, _source: {}): DataAction => ({
    type: constants.UPDATE_TILE,
    payload: { objectType: "tile", _id, _source },
    expectServerPayload: !constants.APPLY_DOCUMENT_CHANGES_LOCALLY,
    seqNo: nextSeqNo++,
});

export const createTile = (_source: {},new_id:""): DataAction => ({
    type: constants.CREATE_TILE,
    payload: { objectType: "tile", _source: _source,new_id},
    expectServerPayload: true,
    seqNo: nextSeqNo++,
});

// REQS

export const selectReq = (_id: string): DataAction => ({
    type: constants.SELECT_REQ,
    payload: { _id },
    expectServerPayload: false,
    seqNo: -1,
});

export const deleteReq = (_id: string, _source: {}): DataAction => ({
    type: constants.DELETE_REQ,
    payload: { objectType: "req", _id, _source },
    expectServerPayload: !constants.APPLY_DOCUMENT_CHANGES_LOCALLY,
    seqNo: nextSeqNo++,
});

export const updateReq = (_id: string, _source: {}): DataAction => ({
    type: constants.UPDATE_REQ,
    payload: { objectType: "req", _id, _source },
    expectServerPayload: true,
    seqNo: nextSeqNo++,
});
export const reorderReqs = (_id: string, _sources: {}): DataAction => ({
    type: constants.REORDER_REQS,
    payload: { objectType: "req", _id, _sources },
    expectServerPayload: true,
    seqNo: nextSeqNo++,
});

export const reorderReqsGrps = (_id: string, _sources: {}): DataAction => ({
    type: constants.REORDER_REQ_GRPS,
    payload: { objectType: "board", _id, _sources },
    expectServerPayload: true,
    seqNo: nextSeqNo++,
});
export const updateTiles = (_id: string, _sources: {}): DataAction => ({
    type: constants.UPDATE_TILES,
    payload: { objectType: "req", _id, _sources },
    expectServerPayload: true,
    seqNo: nextSeqNo++,
});
export const createReq = (_source: {}, new_id: string): DataAction => ({
    type: constants.CREATE_REQ,
    payload: { objectType: "req", _source: _source, new_id: new_id },
    expectServerPayload: true,
    seqNo: nextSeqNo++,
});

//TESTLOGS


export const selectTestLog = (_id: string): DataAction => ({
    type: constants.SELECT_TESTLOG,
    payload: { _id },
    expectServerPayload: false,
    seqNo: -1,
});

export const createTestLog = (_source: {},new_id:string): DataAction => ({
    type: constants.CREATE_TESTLOG,
    payload: { objectType: "testlog", _source: _source, new_id},
    expectServerPayload: true,
    seqNo: nextSeqNo++,
});

export const updateTestLog = (_id: string, _source: {}): DataAction => ({
    type: constants.UPDATE_TESTLOG,
    payload: { objectType: "testlog", _id, _source },
    expectServerPayload: !constants.APPLY_DOCUMENT_CHANGES_LOCALLY,
    seqNo: nextSeqNo++,
});

export const deleteTestLog = (_id: string, _source: {}): DataAction => ({
    type: constants.DELETE_TESTLOG,
    payload: { objectType: "testlog", _id, _source },
    expectServerPayload: !constants.APPLY_DOCUMENT_CHANGES_LOCALLY,
    seqNo: nextSeqNo++,
});

//RECORDS


export const selectRecord = (_id: string): DataAction => ({
    type: constants.SELECT_RECORD,
    payload: { _id },
    expectServerPayload: false,
    seqNo: -1,
});

export const createRecord = (_source: {},new_id:string): DataAction => ({
    type: constants.CREATE_RECORD,
    payload: { objectType: "record", _source: _source ,new_id},
    expectServerPayload: true,
    seqNo: nextSeqNo++,
});

export const updateRecord = (_id: string, _source: {}): DataAction => ({
    type: constants.UPDATE_RECORD,
    payload: { objectType: "record", _id, _source },
    expectServerPayload: !constants.APPLY_DOCUMENT_CHANGES_LOCALLY,
    seqNo: nextSeqNo++,
});
export const updateTask = (_id: string, _source: {}): DataAction => ({
    type: constants.UPDATE_TASK,
    payload: { objectType: "task", _id, _source },
    expectServerPayload: !constants.APPLY_DOCUMENT_CHANGES_LOCALLY,
    seqNo: nextSeqNo++,
});
export const deleteRecord = (_id: string, _source: {}): DataAction => ({
    type: constants.DELETE_RECORD,
    payload: { objectType: "record", _id, _source },
    expectServerPayload: !constants.APPLY_DOCUMENT_CHANGES_LOCALLY,
    seqNo: nextSeqNo++,
});

//ANOMS

export const createAnom = (_source: {}, idProtocol, idRun,new_id): DataAction => ({
    type: constants.CREATE_ANOM,
    payload: { objectType: "anom", _source: _source, idProtocol, idRun,new_id },
    expectServerPayload: true,
    seqNo: nextSeqNo++,
});

export const deleteAnom = (_id: string, _source: {}): DataAction => ({
    type: constants.DELETE_ANOM,
    payload: { objectType: "anom", _id, _source },
    expectServerPayload: !constants.APPLY_DOCUMENT_CHANGES_LOCALLY,
    seqNo: nextSeqNo++,
});

export const updateAnom = (_id: string, _source: {}): DataAction => ({
    type: constants.UPDATE_ANOM,
    payload: { objectType: "anom", _id, _source },
    expectServerPayload: !constants.APPLY_DOCUMENT_CHANGES_LOCALLY,
    seqNo: nextSeqNo++,
});

//RUNS

export const createRun = (_source: {}): DataAction => ({
    type: constants.CREATE_RUN,
    payload: { objectType: "run", _source: _source },
    expectServerPayload: true,
    seqNo: nextSeqNo++,
});

export const deleteRun = (_id: string, _source: {}): DataAction => ({
    type: constants.DELETE_RUN,
    payload: { objectType: "run", _id, _source },
    expectServerPayload: !constants.APPLY_DOCUMENT_CHANGES_LOCALLY,
    seqNo: nextSeqNo++,
});

export const updateRun = (_id: string, _source: {}): DataAction => ({
    type: constants.UPDATE_RUN,
    payload: { objectType: "run", _id, _source },
    expectServerPayload: !constants.APPLY_DOCUMENT_CHANGES_LOCALLY,
    seqNo: nextSeqNo++,
});

//PROTOCOLS

export const updateProtocol = (_id: string, _source: {}): DataAction => ({
    type: constants.UPDATE_PROTOCOL,
    payload: { objectType: "protocol", _id, _source },
    expectServerPayload: !constants.APPLY_DOCUMENT_CHANGES_LOCALLY,
    seqNo: nextSeqNo++,
});

export const createProtocol = (_source: {}): DataAction => ({
    type: constants.CREATE_PROTOCOL,
    payload: { objectType: "protocol", _source: _source },
    expectServerPayload: true,
    seqNo: nextSeqNo++,
});

export const createProtocolCopy= (_source: {},new_id): DataAction => ({
    type: constants.CREATE_PROTOCOL_COPY,
    payload: { objectType:"protocol", _source: _source ,new_id},
    expectServerPayload: true,
    seqNo: nextSeqNo++,
});

export const deleteProtocol = (_id: string, _source: {}): DataAction => ({
    type: constants.DELETE_PROTOCOL,
    payload: { objectType: "protocol", _id, _source },
    expectServerPayload: !constants.APPLY_DOCUMENT_CHANGES_LOCALLY,
    seqNo: nextSeqNo++,
});


//ANOMS

export const createTag = (_source: {}): DataAction => ({
    type: constants.CREATE_TAG,
    payload: { objectType: "tag", _source: _source },
    expectServerPayload: true,
    seqNo: nextSeqNo++,
});

export const deleteTag = (_id: string, _source: {}): DataAction => ({
    type: constants.DELETE_TAG,
    payload: { objectType: "tag", _id, _source },
    expectServerPayload: !constants.APPLY_DOCUMENT_CHANGES_LOCALLY,
    seqNo: nextSeqNo++,
});

export const updateTag = (_id: string, _source: {}): DataAction => ({
    type: constants.UPDATE_TAG,
    payload: { objectType: "tag", _id, _source },
    expectServerPayload: !constants.APPLY_DOCUMENT_CHANGES_LOCALLY,
    seqNo: nextSeqNo++,
});

//EVIDENCE

export const updateEvidence = (_id: string, _source: {}, user: any, key: any): DataAction => ({
    type: constants.UPDATE_EVIDENCE,
    payload: { objectType: "evidence", _id, _source, "user": user, key },
    expectServerPayload: !constants.APPLY_DOCUMENT_CHANGES_LOCALLY,
    seqNo: nextSeqNo++,
});
export const deviceChange = (change: {}): DataAction => ({
    type: constants.DEVICE_CHANGE,
    payload: { objectType: "device", change },
    expectServerPayload: !constants.APPLY_DOCUMENT_CHANGES_LOCALLY,
    seqNo: nextSeqNo++,
});

// RISKS

export const createRiskCategory= (_source: {}): DataAction => ({
    type: constants.CREATE_RISK_CATEGORY,
    payload: { objectType:"riskCategory", _source: _source },
    expectServerPayload: !constants.APPLY_DOCUMENT_CHANGES_LOCALLY,
    seqNo: nextSeqNo++,
});

export const updateRiskCategory = (_id: string, _source: {} ): DataAction => ({
    type: constants.UPDATE_RISK_CATEGORY,
    payload: { objectType:"riskCategory",_id, _source },
    expectServerPayload: !constants.APPLY_DOCUMENT_CHANGES_LOCALLY,
    seqNo: nextSeqNo++,
});

export const deleteRiskCategory = (_id: string, _source: {}): DataAction => ({
    type: constants.DELETE_RISK_CATEGORY,
    payload: { objectType:"riskCategory",_id,_source },
    expectServerPayload: !constants.APPLY_DOCUMENT_CHANGES_LOCALLY,
    seqNo: nextSeqNo++,
});
export const createRiskDetail= (_source: {}): DataAction => ({
    type: constants.CREATE_RISK_DETAILS,
    payload: { objectType:"riskDetails", _source: _source },
    expectServerPayload: !constants.APPLY_DOCUMENT_CHANGES_LOCALLY,
    seqNo: nextSeqNo++,
});

export const updateRiskDetail = (_id: string, _source: {} ): DataAction => ({
    type: constants.UPDATE_RISK_DETAILS,
    payload: { objectType:"riskDetails",_id, _source },
    expectServerPayload: !constants.APPLY_DOCUMENT_CHANGES_LOCALLY,
    seqNo: nextSeqNo++,
});

export const deleteRiskDetail = (_id: string, _source: {}): DataAction => ({
    type: constants.DELETE_RISK_DETAILS,
    payload: { objectType:"riskDetails",_id,_source },
    expectServerPayload: !constants.APPLY_DOCUMENT_CHANGES_LOCALLY,
    seqNo: nextSeqNo++,
});

export const getRiskDetails = (_id: string,): DataAction => ({
    type: constants.GET_RISK_DETAILS,
    payload: { _id: _id },
    expectServerPayload: false,
    seqNo: -1,
});


export const createRiskSeverity = (_source: {},severity:[],probability:[]): DataAction => ({
    type: constants.CREATE_RISK_SEVERITY,
    payload: { objectType: "riskSeverity", _source: _source,severity,probability },
    expectServerPayload: !constants.APPLY_DOCUMENT_CHANGES_LOCALLY,
    seqNo: nextSeqNo++,
});

export const updateRiskSeverity = (_id: string, _source: {} ): DataAction => ({
    type: constants.UPDATE_RISK_SEVERITY,
    payload: { objectType:"riskSeverity",_id, _source },
    expectServerPayload: !constants.APPLY_DOCUMENT_CHANGES_LOCALLY,
    seqNo: nextSeqNo++,
});

export const deleteRiskSeverity = (_id: string, _source: {}): DataAction => ({
    type: constants.DELETE_RISK_SEVERITY,
    payload: { objectType:"riskSeverity",_id,_source },
    expectServerPayload: !constants.APPLY_DOCUMENT_CHANGES_LOCALLY,
    seqNo: nextSeqNo++,
});


export const createRiskProbability = (_source: {},severity:[],probability:[]): DataAction => ({
    type: constants.CREATE_RISK_PROBABILITY,
    payload: { objectType: "riskProbability", _source: _source,severity,probability },
    expectServerPayload: !constants.APPLY_DOCUMENT_CHANGES_LOCALLY,
    seqNo: nextSeqNo++,
});

export const updateRiskProbability = (_id: string, _source: {} ): DataAction => ({
    type: constants.UPDATE_RISK_PROBABILITY,
    payload: { objectType:"riskProbability",_id, _source },
    expectServerPayload: !constants.APPLY_DOCUMENT_CHANGES_LOCALLY,
    seqNo: nextSeqNo++,
});

export const deleteRiskProbability = (_id: string, _source: {}): DataAction => ({
    type: constants.DELETE_RISK_PROBABILITY,
    payload: { objectType:"riskProbability",_id,_source },
    expectServerPayload: !constants.APPLY_DOCUMENT_CHANGES_LOCALLY,
    seqNo: nextSeqNo++,
});


export const createRiskMatrix = (_source: {}): DataAction => ({
    type: constants.CREATE_RISK_MATRIX,
    payload: { objectType: "riskMatrix", _source: _source },
    expectServerPayload: !constants.APPLY_DOCUMENT_CHANGES_LOCALLY,
    seqNo: nextSeqNo++,
});

export const updateRiskMatrix = (_id: string, _source: {} ): DataAction => ({
    type: constants.UPDATE_RISK_MATRIX,
    payload: { objectType:"riskMatrix",_id, _source },
    expectServerPayload: !constants.APPLY_DOCUMENT_CHANGES_LOCALLY,
    seqNo: nextSeqNo++,
});

export const deleteRiskMatrix = (idDevice,data: []): DataAction => ({
    type: constants.DELETE_RISK_MATRIX,
    payload: { objectType:"riskMatrix",idDevice,data },
    expectServerPayload: !constants.APPLY_DOCUMENT_CHANGES_LOCALLY,
    seqNo: nextSeqNo++,
});

export const createProbabilityMatrixCells = (idDevice,data: []): DataAction => ({
    type: constants.CREATE_PROBABILITY_MATRIX_CELLS,
    payload: { objectType: "probMatrix", idDevice,data },
    expectServerPayload: !constants.APPLY_DOCUMENT_CHANGES_LOCALLY,
    seqNo: nextSeqNo++,
});
export const createProbabilityMatrix = (_source: {}): DataAction => ({
    type: constants.CREATE_PROBABILITY_MATRIX,
    payload: { objectType: "probMatrix", _source: _source },
    expectServerPayload: !constants.APPLY_DOCUMENT_CHANGES_LOCALLY,
    seqNo: nextSeqNo++,
});

export const updateProbabilityMatrix = (_id: string, _source: {} ): DataAction => ({
    type: constants.UPDATE_PROBABILITY_MATRIX,
    payload: { objectType:"probMatrix",_id, _source },
    expectServerPayload: !constants.APPLY_DOCUMENT_CHANGES_LOCALLY,
    seqNo: nextSeqNo++,
});

export const deleteProbabilityMatrix = (idDevice,data: []): DataAction => ({
    type: constants.DELETE_PROBABILITY_MATRIX,
    payload: { objectType:"probMatrix",idDevice,data },
    expectServerPayload: !constants.APPLY_DOCUMENT_CHANGES_LOCALLY,
    seqNo: nextSeqNo++,
});

//OTHERS

export const clearBoards = (): DataAction => ({
    type: constants.CLEAR_BOARDS,
    payload: {},
});
export const aiMenu = (isAIOpen: Boolean): DataAction => ({
    type: "AI_MENU",
    payload: { isAIOpen },
});
// OTHER


export const setTestPlanObject = (_id: string, _source: {}): DataAction => ({
    type: constants.SET_OBJECT,
    payload: { _id, _source },
    expectServerPayload: false,
    seqNo: nextSeqNo++,
});

export const serverUpdate = (payload) => ({
    type: constants.SERVER_UPDATE,
    payload: payload.serverPayload,
    expectServerPayload: false,
    seqNo: payload.seqNo,
});



export const getAISteps = (_id: string): DataAction => ({
    type: constants.GET_AI_STEPS,
    payload: { _id },
    expectServerPayload: false,
    seqNo: -1,
});
