import { useState } from 'react';
import { Button, Collapse } from 'reactstrap';
import styles from './style.module.css';

const FaqQuestion = (props) => {
    const [isOpen, setIsOpen] = useState(false);

    const openQuestion = () => setIsOpen(!isOpen);

    return (
        <div className={styles.faqSegment}>
            <p className={styles.faqButton}
                onClick={openQuestion}
            >
                {props.question}
                <i className={"mdi " + (isOpen ? "mdi-chevron-up" : "mdi-chevron-down")}></i>
            </p>
            <Collapse className={styles.faqAnswer} isOpen={isOpen}>
                {props.answer}
            </Collapse>
        </div>
    )
}

export default FaqQuestion