// @flow
import React, { Component } from 'react';
import Routes from './routes/Routes';

// setup fake backend

// Themes
import './assets/scss/Saas.scss';
import { PublicClientApplication, EventType } from "@azure/msal-browser";
import { msalConfig,loginRequest } from "./authConfig";
import { useMsal, MsalProvider ,useAccount} from "@azure/msal-react";
import 'bootstrap/dist/css/bootstrap.min.css';
import './style.css'

const App  = ({ instance }) => {
const pca = new PublicClientApplication(msalConfig);
// For Dark import Saas-Dark.scss
// import './assets/scss/Saas-Dark.scss';

// For Modern import Modern.scss
// import './assets/scss/Modern.scss';
// For modern dakr import Modern-Dark.scss
// import './assets/scss/Modern-Dark.scss';

// For Creative demo import Modern.scss
// import './assets/scss/Creative.scss';
// For Creative dark demo import Modern.scss
// import './assets/scss/Creative-Dark.scss';

// configure fake backend
// configureFakeBackend();

// type AppProps = {};

/**
 * Main app component
 */


// Default to using the first account if no account is active on page load
if (!pca.getActiveAccount() && pca.getAllAccounts().length > 0) {
    // Account selection logic is app dependent. Adjust as needed for different use cases.
    pca.setActiveAccount(pca.getAllAccounts()[0]);
  }
  
  // Optional - This will update account state if a user signs in from another tab or window
  pca.enableAccountStorageEvents();
  
  pca.addEventCallback((event) => {
    if (event.eventType === EventType.LOGIN_SUCCESS && event.payload.account) {
      const account = event.payload.account;
      pca.setActiveAccount(account);
    }
  });

  pca.handleRedirectPromise().then(async (authResult)=>{
    // Check if user signed in 
    const account = pca.getActiveAccount();
    const signInRequest ={...loginRequest,redirectUri:window.location.href}
    // const signInRequest ={...loginRequest,redirectUri:'/account/login'}

    if(!account){

      // redirect anonymous user to login page 
      await pca.loginRedirect(signInRequest);
    }
  }).catch(err=>{
    // TODO: Handle errors
    console.error("account:",err);
  });

  if (process.env.REACT_APP_STAGE === "production") {
    console.log = () => { };
}
        return(

        <MsalProvider instance={pca}>
            <Routes></Routes>
        </MsalProvider> 

        )
        
    
}

export default App;

// https://tentoreactdev.z33.web.core.windows.net/account/login
// https://tentoreactdev.z33.web.core.windows.net/account/login