import React, {
  Component, useState, useEffect, MouseEvent,
  ReactElement,
  useCallback,
  useMemo,
  useRef,
} from 'react';
import CommentSection from './CommentSection'
import {
  Card,
  CardBody,
  CardTitle,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  Button,
  Collapse,
  DropdownItem,
  Modal, ModalHeader, ModalBody, UncontrolledCollapse,
  Col,
  Container, Table,
  TabContent, TabPane, Nav, NavItem, NavLink, CardText, Row, CardHeader, Input
} from 'reactstrap';
import { Route, Link, Routes, useLocation } from 'react-router-dom';
import styles from './style.module.css';
import comment_icon from '../../assets/icons/comment_icon.svg'
import faq_icon from '../../assets/icons/faq_icon.svg'
import FaqQuestion from './FaqQuestion';

const faq = {
  overview: [
    ["What does this page show me?",
      "Answer: This page gives you an overview of the status of device and provides links to other sections of the application. The different blocks represent the different key parts of your regulatory journey. Standards planner allows you to find applicable standards, and generate a list of documents you need to comply. The document checklist tracks your progress to completing those documents. The requirements block allows you to create, edit and manage your product requirements. The traceability block allows you to link high level requirements of your product to lower level requirements, as well as map the requirements onwards to source files. The testing block allows you to create test plans, and monitor your testing results. "],
    ["What does \"number of documents left to complete\" mean?",
      "Answer: This statistic shows the number of documents that are still pending completion. This can be set within the document checklist itself. You can assign other team members to documents, set deadlines, and keep track of the remaining work that needs to be done."],
    ["How is the \"number of requirements verified\" calculated?",
      "Answer: This statistic shows the number of requirements that have been successfully verified through a test record. You can link requirements to particular tests in the testing section. Once you have completed a successful test run, the requirement will be verified. Test runs that have failed will be counted as NOT MET and those yet to be linked to a test run will be “Yet to Run”. These statistics can help you assess progress and identify requirements that have not been met or not tested. "],
    ["What does the \"total of test records\" refer to?",
      "Answer: This statistic shows the total number of test records that have been executed as part of your verification of your device. It can help you understand how extensively your system has been tested, see how many tests are left to run, and which ones have failed. "],
    ["How do I interpret the \"Total Test Anomalies\" statistic?",
      "Answer: This statistic shows the number of test records that have either passed or failed during testing. It can help you identify any defects or issues that need to be resolved before your project can be considered complete."],
    ["Can I export or download the statistics displayed on the dashboard?",
      "Answer: Yes, you can export an overview status report, using the export block. "],
    ["Can I customize the statistics shown on the dashboard?",
      "Answer: We are working on this as a feature coming soon. "],
    ["How frequently are the statistics updated?",
      "Answer: The statistics are current and updated every time a change is made. "],
    ["How do I use the report builder feature to download reports from the dashboard?",
      "Answer: Simply select from a range of pre-defined reports and press download.  We are working on making this feature more customisable.  "],
  ],
  properties: [
    ["What is the purpose of the device properties selection page?",
      "Answer: The device properties selection page is used to collect information about you device, such as who is going to use it, where it is going to be used, what is it primary medical function, what type of technology it contains and other relevant details. This information will be used later to plan which regulation are applicable to your device. "],
    ["What kind of information do I need to provide on the device properties selection page?",
      "Answer: Using the dropdown menus under each headings, you can provide details of users, age of user, sex, user training and experience, location of use, tech type, purpose of use etc. "],
    ["Can I update my device properties selection at a later time?",
      "Answer: Yes, your changes will be saved, and you can always return later to add or edit them. "],
    ["How is the device properties information used in the regulation planning process?",
      "Answer: The device properties information that you provide is used to find which standards are most likely to be applicable to your device. From here, you can review the selected standards and lock your selection. This then forms a list of all the standards you need to adhere to. "],
    ["Do I need to provide all of the information requested on the device properties selection page?",
      "Answer: No, if you don’t know you can simply leave it blank. However, this may mean we miss a key standard from your standards list. Don’t worry though, you can always add other standards in later as well. "],
    ["Will my personal information be collected as part of the device properties selection process?",
      "Answer: No, no personal information is collected during this process. "],
    ["Can I view or modify my device properties selection at any time?",
      "Answer: Yes, your changes will be saved, and you can always return later to add or edit them."],
  ],
  analysis: [
    ["What is the purpose of this page?",
      "Answer: The medical regulation selection page is used to help you determine which regulatory requirements apply to your medical device, based on the properties previously selected. "],
    ["How do I use the medical regulation selection page?",
      "Answer: You can select the applicable regulations from a list of options provided (most relevant ones are listed first), and you can also add in any additional regulations that you are aware of. Once you are happy with this, you can then lock the selection so that it cannot be changed until it is unlocked."],
    ["What happens when I lock my selection?",
      "Answer: This feature is designed to help ensure that your regulatory requirements are accurately identified and tracked. You can unlock and edit the selection at any time. Once locked, you will be asked whether to populate your document checklist. Re populating a previously confirmed selection will overwrite your document tracker. "],
    ["Can I unlock my medical regulation selection at a later time?",
      "Answer: Yes, you can unlock your medical regulation selection at a later time if you need to make changes. "],
    ["Why is it asking me to populate a checklist?",
      "Answer: The document checklist feature is designed to help you ensure that you are meeting all of the standard requirements applicable to your medical device. Based on the selected standards, the application can generate a list of documents that are typically required for compliance."],
    ["How is the document checklist generated?",
      "Answer: The document checklist is generated based on the standards requirements that have been selected and locked by you. The application will typically use a predefined set of criteria to determine which documents are required for each standard."],
  ],
  documentation: [
    ["What is the purpose of the document checklist?",
      "Answer: The document checklist is designed to help you ensure that you are meeting all of the standard requirements applicable to their medical device. It provides a list of documents that are typically required for compliance based on the selected and locked standards."],
    ["How is the document checklist generated?",
      "Answer: The document checklist can be either generated manually by adding in new document sections and documents, or generated based on the regulatory requirements that you have selected and locked in the standards planners. The application will use a predefined set of criteria to determine which documents are required for each standard."],
    ["Can I change the status of a document on the checklist?",
      "Answer: Yes, you can change the status of a document on the checklist to reflect its current state. The application may provide options for marking documents as \"in progress,\" \"completed,\" \"not started\”. "],
    ["Can I assign documents to other team members?",
      "Answer: Yes! "],
    ["Can I add deadlines to documents on the checklist?",
      "Answer: Yes, you can add deadlines to documents on the checklist to help keep track of progress and ensure that tasks are completed in a timely manner. "],
    ["How are the documents grouped on the checklist?",
      "Answer: The documents on the checklist are grouped according to their type or category, such as design documents, testing documents, or quality assurance documents. Grouping the documents in this way can help you stay organized and focused on specific areas of compliance."],
    ["Can I mark a whole section as complete?",
      "Answer: Yes, you may be able to mark a whole section of the document checklist as complete once all of the documents in that section have been reviewed and approved. This feature can help you quickly assess the overall status of your compliance efforts."],
    ["Can I upload documents? ",
      "This is a feature we are working on. Watch this space. "],
  ],
  requirements: [
    ["What is the purpose of the requirements page?",
      "Answer: The requirements page is designed to help you define the functional and performance requirements of your medical device. It provides a structured approach to capturing and organizing these requirements in a way that is easy to manage and track."],
    ["How do I create a new requirement?",
      "Answer: To create a new requirement, simply click on the \"Add Requirement\" button, enter the details. You can provide a further description, specify whether the requirement is mandatory or optional, and add any notes. "],
    ["Can I group my requirements together?",
      "Answer: Yes, you can group your requirements together based on their related functionality or performance criteria. Grouping requirements can help you organize your requirements and ensure that they are all properly accounted for. Simply create a new group and start adding in requirements. You can move requirements from a group to another group. "],
    ["Can I edit or delete a requirement once it has been created?",
      "Answer: Yes. "],
    ["What information should I include in a requirement?",
      "Answer: Enter the requirement into the title, and then add more details in the description or note if needed. When creating a requirement, be sure to include a clear and concise description of the functionality or performance criteria that it represents as well. "],
    ["How do I know if a requirement is mandatory or optional?",
      "Answer: Typically, mandatory requirements are those that are critical to the safe and effective operation of the device, while optional requirements are “nice to haves” and those that enhance the device's functionality or performance."],
    ["Can I link specific tests to requirements on the testing page?",
      "Answer: Yes, you can link specific tests to requirements on the testing page to ensure that all necessary testing activities are completed for each requirement. This helps to ensure that your testing activities are aligned with your development goals and that all requirements are properly tested."],
    ["Can I see which requirements are linked to specific tests on the testing page?",
      "Answer: Yes, you can see which requirements are linked to specific tests (you can link them together from the testing page). This helps you to ensure that all requirements are properly tested and that all testing activities are aligned with your development goals."],
    ["Can I use the testing results to update the status of specific requirements on the requirement page?",
      "Answer: Yes, this is done automatically. For example, if a test fails, the requirement will be counted as not met which can help you to track the status of each requirement and ensure that all requirements are properly tested and validated."],
    ["Can I use the requirement page to ensure that all necessary tests are performed on the testing page?",
      "Answer: Yes, you can use the requirement page to ensure that all necessary tests are performed on the testing page. By linking each requirement to specific tests, you can ensure that all testing activities are aligned with your development goals and that all requirements are properly tested and validated."],
    ["How does the requirement page and testing page integration help me to ensure regulatory compliance?",
      "Answer: The requirement page and testing page integration helps you to ensure regulatory compliance by providing a clear and transparent record of all testing activities and by demonstrating that all requirements have been properly tested and validated. This can help you to streamline your regulatory submission process and ensure that your medical device meets all necessary regulatory requirements."],
    ["Can I export my requirements to a document or spreadsheet?",
      "Answer: Yes. You can export a list of all requirements using the export builder on the device overview page. "],
  ],
  trace: [
    ["What is the purpose of the requirements traceability page?",
      "Answer: The requirements traceability page allows you to cross-reference high-level requirements to lower-level system or component requirements. By doing so, it provides a way to track the traceability of requirements throughout the development process."],
    ["What is the benefit of cross-referencing requirements?",
      "Answer: Cross-referencing requirements allows you to see how high-level requirements are linked to lower-level requirements, and how changes to one requirement can impact others. It also helps ensure that all requirements are properly accounted for and that there are no gaps in coverage."],
    ["Can I set a hierarchy of requirement groups?",
      "Answer: Yes, you first want to set the hierarchy of requirement groups based on the functional or performance criteria they represent. This helps to organize the requirements and provides a clear picture of how they are related to each other. Product level requirements should contain high level requirement groups such as use requirements or commercial requirements. System level requirements should contain mid-level requirements such as security or user interface specifications. Component level requirement should contain detailed requirements on how a component of the system will work.  "],
    ["How do I create a cross-reference between requirements?",
      "Answer: After you have set your hierarchy, and assigned your requirement groups to a level, you can start linking the requirements together. To create a cross-reference between requirements, you can use the “highlight” traceability feature of the application. Simply select a requirement and then select the related requirements. Typically, you would start with the high-level requirement and then link it to the lower-level requirements that support it."],
    ["Can I cross-reference component requirements down to software source?",
      "Answer: Yes, you can cross-reference component requirements down to software source code to ensure that all components are properly accounted for and that there are no gaps in coverage."],
    ["What are functions and units in the source code traceability? ",
      "Answer: You map requirements to software functions that are performed by specific software units. This helps to ensure that each requirement is properly implemented in the software code, and that there is a clear traceability link between the requirements and the software code. By defining software functions and linking them to specific requirements, you can easily identify which parts of the code need to be modified if a requirement changes, or if there is a defect in the code that needs to be addressed. This level of traceability is essential for ensuring the safety and effectiveness of your medical device and can help you to streamline your development process by identifying potential issues earlier in the process."],
    ["Can I export the cross-referenced requirements to a document or spreadsheet?",
      "Answer: Yes. You can export a list of all requirements using the export builder on the device overview page."],
  ],
  traceSource: [
    ["What is meant by mapping requirements to source files?",
      "Answer: Mapping requirements to source files involves connecting each requirement defined for a component to the specific source files (can be software ore component schematic or mechanical design drawings) that implement that requirement. This helps ensure that each requirement is met by the component as intended"],
    ["How is mapping requirements to source files typically accomplished?",
      "Answer: Mapping requirements to source files is typically accomplished by identifying the functions that are performed by specific units, and then linking those functions to the requirements that they satisfy. This process helps ensure that each requirement is addressed and is fully traceable to the specific part of the component that meets the requirements."],
    ["What are units and functions?",
      "Answer: Units are individual testable blocks of components of a system; each responsible for a specific set of tasks. Functions are the specific actions that each unit performs to accomplish its tasks."],
    ["Why is mapping requirements to source files through units and functions important?",
      "Answer: Mapping requirements to source files through units and functions is important because it helps ensure that the system meets its intended goals and functions correctly. By linking each requirement to the specific source that implements it, you can more easily identify and correct issues that may arise during the development process or after deployment."],
    ["How does mapping requirements to source files relate to testing?",
      "Answer: Mapping requirements to source files is important for testing because it helps ensure that all requirements are tested and that the corresponding source is functioning as intended. By establishing traceability between requirements, source, and testing results, you can more easily identify and fix issues that arise during testing."],
    ["Can I export my source traceability?",
      "Answer: Yes. You can export a matrix using the export builder on the device overview page."],
  ],
  traceReq: [
    ["What does it mean to map high level requirements down to component level requirements?",
      "Answer: Mapping high level requirements down to component level requirements involves breaking down larger, more complex requirements into smaller, more specific requirements that can be implemented by individual components of a system."],
    ["Why is it important to map high level requirements down to component level requirements?",
      "Answer: Mapping high level requirements down to component level requirements helps ensure that all necessary functionality is accounted for in the design and development of a system. It also helps with identifying potential design issues or gaps in requirements early on in the development process."],
    ["What types of things should be mapped when breaking down high level requirements to component level requirements?",
      "Answer: Component level requirements should be specific and actionable and should relate directly to the higher-level requirements they support. This could include things like specific functionality or features, performance requirements, user interface design, or technical specifications. It is important to ensure that each component level requirement is testable and measurable in order to ensure that it can be properly validated and verified during the development process."],
    ["How do I create a cross-reference between requirements?",
      "Answer: After you have set your hierarchy, and assigned your requirement groups to a level, you can start linking the requirements together. To create a cross-reference between requirements, you can use the “highlight” traceability feature of the application. Simply select a requirement and then select the related requirements. Typically, you would start with the high-level requirement and then link it to the lower-level requirements that support it."],
    ["Can I export the cross-referenced requirements to a document or spreadsheet?",
      "Answer: Yes. You can export a list of all requirements and the traceability between them using the export builder on the device overview page."],
  ],
  traceHeir: [
    ["Why do I need to set the structure of requirements?",
      "Answer: You first want to set the hierarchy of requirement groups based on the functional or performance criteria they represent. This helps to organize the requirements and provides a clear picture of how they are related to each other. Product level requirements should contain high level requirement groups such as use requirements or commercial requirements. System level requirements should contain mid-level requirements such as security or user interface specifications. Component level requirement should contain detailed requirements on how a component of the system will work."],
    ["How do I set the hierarchy?",
      "Answer: Simply select from the dropdown list which requirement groups you want in which column. Once complete, hit lock so that the structure is locked in. "],
  ],
  reqOverview: [
    ["What is the purpose of the requirements traceability page?",
      "Answer: The requirements traceability page allows you to cross-reference high-level requirements to lower-level system or component requirements. By doing so, it provides a way to track the traceability of requirements throughout the development process."],
    ["What is the benefit of cross-referencing requirements?",
      "Answer: Cross-referencing requirements allows you to see how high-level requirements are linked to lower-level requirements, and how changes to one requirement can impact others. It also helps ensure that all requirements are properly accounted for and that there are no gaps in coverage."],
    ["Can I set a hierarchy of requirement groups?",
      "Answer: Yes, you first want to set the hierarchy of requirement groups based on the functional or performance criteria they represent. This helps to organize the requirements and provides a clear picture of how they are related to each other. Product level requirements should contain high level requirement groups such as use requirements or commercial requirements. System level requirements should contain mid-level requirements such as security or user interface specifications. Component level requirement should contain detailed requirements on how a component of the system will work."],
    ["How do I create a cross-reference between requirements?",
      "Answer: After you have set your hierarchy, and assigned your requirement groups to a level, you can start linking the requirements together. To create a cross-reference between requirements, you can use the “highlight” traceability feature of the application. Simply select a requirement and then select the related requirements. Typically, you would start with the high-level requirement and then link it to the lower-level requirements that support it."],
    ["Can I cross-reference component requirements down to software source?",
      "Answer: Yes, you can cross-reference component requirements down to software source code to ensure that all components are properly accounted for and that there are no gaps in coverage."],
    ["What are functions and units in the source code traceability?",
      "Answer: You map requirements to software functions that are performed by specific software units. This helps to ensure that each requirement is properly implemented in the software code, and that there is a clear traceability link between the requirements and the software code. By defining software functions and linking them to specific requirements, you can easily identify which parts of the code need to be modified if a requirement changes, or if there is a defect in the code that needs to be addressed. This level of traceability is essential for ensuring the safety and effectiveness of your medical device, and can help you to streamline your development process by identifying potential issues earlier in the process."],
    ["Can I export the cross-referenced requirements to a document or spreadsheet?",
      "Answer: Yes. You can export a list of all requirements using the export builder on the device overview page."],
  ],
  testing: [
    ["What information is available on the testing page?",
      "Answer: The testing page provides you with a list of test logs and anomalies, which allows you to track the progress of your testing activities. Within each test log, you can see a set of test records that contain test scripts, along with their associated pass/fail status. This allows you to see at a glance which tests have been performed, and whether they have passed or failed."],
    ["Can I perform a test run from the testing page?",
      "Answer: Yes, you can perform a test run from the testing page by selecting a specific test record and stepping through the test script, marking each step as pass or fail as appropriate. This allows you to verify that each test is functioning as expected, and to identify any issues or defects that may need to be addressed."],
    ["What happens if I encounter a failure or observation during a test run?",
      "Answer: If you encounter a failure or observation during a test run, this information is recorded in the anomaly log. You can monitor the status of your anomalies from the testing page, and work to address them as needed. Once an anomaly has been resolved, you can close it to indicate that it has been fixed."],
    ["How does the testing page help me to ensure the safety and effectiveness of my medical device?",
      "Answer: The testing page provides you with a comprehensive view of your testing activities, allowing you to identify potential issues or defects early in the development process. By tracking your test results and anomalies, you can ensure that your medical device meets the necessary safety and effectiveness standards, and that any issues are addressed in a timely manner. This can help you to minimize risk and ensure that your medical device is safe and effective for use by patients."],
    ["Can I assign tests or anomalies to specific team members?",
      "Answer: Yes, you can assign tests or anomalies to specific team members to help ensure that they are addressed in a timely manner. This can help you to streamline your testing process by ensuring that each team member knows exactly what they need to work on, and by providing a clear accountability structure for testing activities."],
    ["How does the testing page integrate with other pages or features in the application?",
      "Answer: The testing page is designed to integrate seamlessly with the requirements. For example, you can link specific tests to requirements to ensure that all necessary testing activities are completed and use the testing results to update the status of specific requirements. This helps to ensure that all aspects of your medical device development process are integrated and coordinated, and that your testing activities are aligned with your overall development goals."],
    ["Can I export my test logs or anomalies? ",
      "Yes. You can export a list of all test logs, and a list of anomalies using the export builder on the device overview page."],
  ],
  anomalies: [
    ["What is an anomaly?",
      "Answer: An anomaly is a deviation from expected behaviour or results in the software testing process. Anomalies can be bugs, defects, errors, or other unexpected behaviours that were not accounted for in the original design or requirements."],
    ["How are anomalies tracked and managed?",
      "Answer: Anomalies are tracked and managed through the anomalies page, which lists all current anomalies and allows you to filter by severity, and status. Anomalies are linked to test runs and will affect the results of your test runs. You can add notes and update the status of each anomaly, and eventually mark them as closed when they have been resolved."],
    ["How are anomalies prioritized?",
      "Answer: Anomalies may be prioritized based on their severity, impact on the system, or other factors determined by the project team. Typically, high-priority anomalies that affect critical functionality or safety will be addressed first, followed by lower-priority issues that may have less impact on the system."],
    ["Who can view and manage anomalies?",
      "Answer: All team members can access the anomalies page can view and manage anomalies."],
    ["Can I export or print the list of anomalies?",
      "Answer: Yes, you can export a list of anomalies from the report builder on the device overview page. "],
  ]

}

/*
Why do I need to set the structure of requirements?

Answer: You first want to set the hierarchy of requirement groups based on the functional or performance criteria they represent. This helps to organize the requirements and provides a clear picture of how they are related to each other. Product level requirements should contain high level requirement groups such as use requirements or commercial requirement. System level requirements should contain mid-level requirements such as security or user interface specifications. Component level requirement should contain detailed requirements on how a component of the system will work.

Q:How do I set the hierarchy?

A: Simply select from the dropdown list which requirement groups you want in which column. Once complete, hit lock so that the structure is locked in. 
*/

const CommentsAndHistory = (props) => {
  const [commentsisOpen, setCommentsIsOpen] = useState(false);
  const [faqIsOpen, setFaqIsOpen] = useState(false);
  const toggleComments = () => setCommentsIsOpen(!commentsisOpen);
  const toggleFaq = () => { setFaqIsOpen(!faqIsOpen); }
  const location = useLocation();
  return (
    <div className={styles.comments + ' comments'}>
      {(props.device._id !== "js1wBogB1L2gw3Qicv2Q" && props.hasSub) && <Row>
        <Button id="toggler"
          style={{
            backgroundColor: "white", border: "none", width: commentsisOpen ? '350px' : "60px",
            transition: "width 0.2s", transitionDelay: commentsisOpen ? "0s" : "0.4s",
            float: "right",
            marginBottom: '10px', marginRight: "0", top: "75px",
            position: "fixed",
            right: "0", fontSize: "25px",
            // top:"15vh"
          }}
          onClick={toggleComments}
        >
          <img alt ={"Comments"} src={comment_icon}></img>
        </Button>
        <div>
          <Collapse isOpen={commentsisOpen}
            style={{
              width: "350px", float: "right", transitionDelay: commentsisOpen ? "0.2s" : "0s", float: "right",
              marginBottom: '10px', marginRight: "0",
              marginTop: "25px",
              position: "fixed",
              right: "0",
            }}
          >
            <div style={{ backgroundColor: 'white', width: "100%", border: " 1px solid gray", borderColor: "lightgray", borderRadius: "5px", borderWidth: "2px" }}>
              <CommentSection tentoUser={props.tentoUser} account={props.account} instance={props.instance} device={props.device} changes={props.changes}
                comments={props.comments} userOptionMentionObjs={props.userOptionMentionObjs} leaveComment={props.leaveComment} dispatch={props.dispatch}>
              </CommentSection>
            </div>
          </Collapse>
        </div>
      </Row>
      }
      <Row>
        <Button
          style={{
            backgroundColor: "white", border: "none", width: faqIsOpen ? '425px' : "60px",
            transition: "all 0.2s", transitionDelay: faqIsOpen ? "0s" : commentsisOpen ? "0s" : "0.4s",
            float: "right",
            marginBottom: '10px', right: commentsisOpen ? "355px" : "65px", top: "75px",
            position: "fixed",
            fontSize: "25px",
            // top:"15vh"
          }}
          onClick={toggleFaq}
        >
          <img src={faq_icon}></img>
        </Button>
        <div>
          <Collapse isOpen={faqIsOpen}
            style={{
              width: "425px", float: "right", transitionDelay: faqIsOpen ? "0.2s" : "0s", float: "right",
              marginBottom: '10px',
              marginTop: "25px",
              position: "fixed",
              right: commentsisOpen ? "355px" : "65px",
              backgroundColor: "white",
              border: "2px solid lightgray",
              maxHeight: "70vh",
              overflowY: "scroll",
              padding: "10px"
            }}
          >
            {props.device && props.device._id === "js1wBogB1L2gw3Qicv2Q" && [
              "/home",
              "/designOverview",
              "/designRequirements",
              "/designTraceabilityRequirementXref",
              "/designTraceabilitySourceXref",
              "/designTesting",
              "/designAnomalies",
              "/deviceDocumentation",
            ].includes(location.pathname) && (
                <> <Button color="primary" onClick={() => {
                  props.setCurStep(0)
                  if (props.settourCompleted) {
                    props.settourCompleted(false)
                  }
                  props.setIsTourOpen(true)
                  setFaqIsOpen(false)


                }}>Revisit the guided tutorial</Button> <br /><br />
                  Or review our FAQs for help.
                </>)}
            {(faq[props.page]) && (
              <div style={{ marginTop: "10px" }}>
                {faq[props.page].map(e => (
                  <FaqQuestion
                    question={e[0]}
                    answer={e[1]}
                  />
                ))}
              </div>
            )}
          </Collapse>
        </div>
      </Row>
      <div />
    </div>
  )
}

export default CommentsAndHistory