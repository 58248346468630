import React, {
    MouseEvent,
    ReactElement,
    useCallback,
    useMemo,
    useRef,
    useState,useEffect
  } from 'react';
  import { EditorState } from 'draft-js';
  import Editor from '@draft-js-plugins/editor';
  import createMentionPlugin, {
    defaultSuggestionsFilter,
    MentionData,
    MentionPluginTheme,
  } from '@draft-js-plugins/mention';
  import editorStyles from './editorStyles.module.css';
  import mentionsStyles from './MentionsStyles.module.css';
import {getDefaultKeyBinding, KeyBindingUtil} from 'draft-js';
const {hasCommandModifier} = KeyBindingUtil;
  // import mentions from './Mentions';
  
  export interface EntryComponentProps {
    className?: string;
    onMouseDown(event: MouseEvent): void;
    onMouseUp(event: MouseEvent): void;
    onMouseEnter(event: MouseEvent): void;
    role: string;
    id: string;
    'aria-selected'?: boolean | 'false' | 'true';
    theme?: MentionPluginTheme;
    mention: MentionData;
    isFocused: boolean;
    searchValue?: string;
  }
  
  function Entry(props: any): any {
    const {
      mention,
      theme,
      searchValue, // eslint-disable-line @typescript-eslint/no-unused-vars
      isFocused, // eslint-disable-line @typescript-eslint/no-unused-vars
      ...parentProps
    } = props;
    
    // console.log("ENTRY:",props)
    return (
      <div {...parentProps}>
        <div className={theme?.mentionSuggestionsEntryContainer}>
          <div className={theme?.mentionSuggestionsEntryContainerLeft}>
            <img
              src={mention.avatar}
              className={theme?.mentionSuggestionsEntryAvatar}
              role="presentation"
            />
          </div>
  
          <div className={theme?.mentionSuggestionsEntryContainerRight}>
            <div className={theme?.mentionSuggestionsEntryText}>
              {mention.name}
            </div>
  
            <div className={theme?.mentionSuggestionsEntryTitle}>
              {mention.title}
            </div>
          </div>
        </div>
      </div>
    );
  }
  
  export default function CustomMentionEditor(props:any): ReactElement {
    const editorref = useRef<Editor>(null);
    const {editorState, setEditorState} = props
    // const [editorState, setEditorState] = useState(() =>
    //   EditorState.createEmpty()
    // );

    const AUTHAPIURL = process.env.REACT_APP_STAGE == 'stage'
    ? "https://tentodevauth.azurewebsites.net/"
    : process.env.REACT_APP_STAGE == 'production'?"https://tentoauth.azurewebsites.net/":"http://localhost:5001/"; 
    
    var deviceUsers:any = []
      
    const [open, setOpen] = useState(false);
    const {mentions,account,instance,suggestions} = props
    
  function Entry(props: EntryComponentProps): ReactElement {
    const {
      mention,
      theme,
      searchValue, // eslint-disable-line @typescript-eslint/no-unused-vars
      isFocused, // eslint-disable-line @typescript-eslint/no-unused-vars
      ...parentProps
    } = props;
  
    return (
      <div {...parentProps}>
        <div className={theme?.mentionSuggestionsEntryContainer}>
          {/* <div className={theme?.mentionSuggestionsEntryContainerLeft}>
            <img
              src={mention.avatar}
              className={theme?.mentionSuggestionsEntryAvatar}
              role="presentation"
            />
          </div> */}
  
          <div className={theme?.mentionSuggestionsEntryContainerRight}>
            <div className={theme?.mentionSuggestionsEntryText}>
              {mention.name}
            </div>
  
            <div className={theme?.mentionSuggestionsEntryTitle}>
              {mention.title}
            </div>
          </div>
        </div>
      </div>
    );
  }
    // console.log("SUGGEST 2:",mentions,suggestions)
    

    const { MentionSuggestions, plugins } = useMemo(() => {
      const mentionPlugin = createMentionPlugin({
        entityMutability: 'IMMUTABLE',
        theme: mentionsStyles,
        mentionPrefix: '@',
        supportWhitespace: true,
      });
      // eslint-disable-next-line no-shadow
      const { MentionSuggestions } = mentionPlugin;
      // eslint-disable-next-line no-shadow
      const plugins = [mentionPlugin];
      return { plugins, MentionSuggestions };
    }, []);
  
    const onChange = useCallback((_editorState: EditorState) => {
      setEditorState(_editorState);
    }, []);
    const onOpenChange = useCallback((_open: boolean) => {
      // console.log("KEY OPEN:",_open)
      setOpen(_open);
      if (_open){
        removeBinding()
      }else{
        applyBinding()
      }
      
    }, []);
    const onSearchChange = useCallback(({ value }: { value: string }) => {
      // console.log("SEARCH:::",value,mentions)
      // setSuggestions( defaultSuggestionsFilter(value,mentions) );
      props.fetchUsersInSharedGroups(account.localAccountId,value)
    }, []);
    
    // function keyBinding(e: SyntheticKeyboardEvent): string | null {
    
    //   if (e.keyCode === 13 && !hasCommandModifier(e) && !open/* `Enter` key  without the command key pressed, but mentions not opened*/ ) {
    //     return 'myeditor-save';
    //   }
    //   return getDefaultKeyBinding(e);
    // }
    const keyBinding = () => (e: React.KeyboardEvent): string | null => {
      // console.log("KEY PRESS:",e.key ,e.nativeEvent.ctrlKey, e.key === "Enter" && !e.nativeEvent.ctrlKey)
      if (e.key === "Enter" /* `Enter` key  without the command key pressed, but mentions not opened*/ ) {

        
        if (e.nativeEvent.ctrlKey) {
          return "split-block";
        } else {
          return "myeditor-save";
        }
      }
      else{return getDefaultKeyBinding(e);}
      
    };

   function handleKeyCommand(command: string): any {
    // console.log("KEY COMMAND:",command)
      if (command === 'myeditor-save') {
        // Perform a request to save your contents, set
        // console.log("YOU'VE HIT Enter!")
        props.handleSendComment()
        return 'handled';
      }
      return 'not-handled';
    }
    const [stateKeyBinding, setStateKeyBinding] = useState<any>();

    const removeBinding = () => {
      setStateKeyBinding(null);
    };
  
    const applyBinding = () => {
      setStateKeyBinding(keyBinding);
    };

    return (
      <div
        className={editorStyles.editor}
        onClick={() => {
          editorref.current!.focus();
        }}
      >
        <Editor
          placeholder='Add comment.....'
          editorKey={'editor'}
          editorState={editorState}
          onChange={onChange}
           handleKeyCommand={handleKeyCommand}
           keyBindingFn={stateKeyBinding}
          // keyBindingFn={myKeyBindingFn}
          plugins={plugins}
          ref={editorref}
        />
         <MentionSuggestions
        open={open}
        // onOpen={removeBinding}
        // onClose={applyBinding}
        onOpenChange={onOpenChange}
        suggestions={suggestions}
        onSearchChange={onSearchChange}
        onAddMention={() => {
          // get the mention object selected
        }}
        entryComponent={Entry}
        popoverContainer={({ children }) => <div>{children}</div>}
      />
      
      </div>
    );
  }
  