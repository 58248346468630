import { useAccount, useMsal } from "@azure/msal-react";
import React, { useEffect, useRef, useState } from "react";
import { connect, useDispatch } from "react-redux";
import Switch from "react-switch";
import { Container } from "reactstrap";
import { createProbabilityMatrix, createProbabilityMatrixCells, createRiskMatrix, createRiskProbability, createRiskSeverity, deleteProbabilityMatrix, deleteRiskMatrix, deleteRiskProbability, deleteRiskSeverity, getTour, putTour, selectDevice, updateDevice, updateProbabilityMatrix, updateRiskDetail, updateRiskMatrix, updateRiskProbability, updateRiskSeverity } from "../../../../redux/actions";
import Definition from "./Definition";
import Matrix from "./Matrix";
import { Redirect, useHistory } from 'react-router-dom';

import Tour from 'reactour'

import styles from './style.module.css';

const RiskConfig = (props) => {
    const { instance, accounts, inProgress } = useMsal();
    const hasRiskSub = props.userModules.some( module => module.name.includes("Risk"))
    const [sevCols, setSevCols] = useState([
        { dataField: "severity", text: "Severity", editable: false, sort: true, style: { width: "20%", "max-width": "33%" } },
        { dataField: "description", text: "Description", editable: hasRiskSub, sort: false, style: { width: "40%", "max-width": "40%" } },
        { dataField: "notes", text: "Notes", editable: hasRiskSub, sort: false, style: { width: "40%", "max-width": "40%" } },
    ])
    const [probCols, setProbCols] = useState([
        { dataField: "probability", text: "Probability", editable: false, sort: true, style: { width: "20%", "max-width": "20%" } },
        { dataField: "description", text: "Description", editable: hasRiskSub, sort: false, style: { width: "40%", "max-width": "40%" } },
        { dataField: "notes", text: "Notes", editable: hasRiskSub, sort: false, style: { width: "40%", "max-width": "40%" } },
    ])
    const [tierTwoData, setTierTwoData] = useState([])
    const [tierTwoCols1, setTierTwoCols1] = useState([])
    const [tierTwoCols2, setTierTwoCols2] = useState([])
    const [curStep, setCurStep] = useState(0)
    const [isTourOpen, setIsTourOpen] = useState(false)
    const [matrixH1, setMatrixH1] = useState([])
    const [matrixH2, setMatrixH2] = useState([])
    const [matrixData, setMatrixData] = useState([])
    const [probH1, setProbH1] = useState([])
    const [probData, setProbData] = useState([])
    const [displayT2, setDisplayT2] = useState(true)
    const dispatch = useDispatch();
    const account = useAccount(accounts[0] || {});
    useEffect(() => {
        // 
        //
       
        if (!props.tour) {
            dispatch(getTour(account.localAccountId))
        } else if ((!props.tour.visited || !props.tour.visited.includes("riskConfig")) && window.innerWidth > 768) {
            const newVisitedArray = props.tour.visited ? [...props.tour.visited, "riskConfig"] : ["riskConfig"]
            dispatch(putTour(account.localAccountId, { visited: newVisitedArray }))
            setIsTourOpen(true)
        }
    },[props.tour])

    const steps = [
        {
            selector: '[data-tut="reactour__"]',
            content: () => (<div>In order to complete your risk analysis, you'll need to specify the scoring parameters. This is required by the medical device standards to be specific to each device. <br /><br /> This includes defining, what we call <b>probability, severity and risk accpetance</b> matrices.</div>),
            position: [1000, 30]
        },
        {
            selector: '[data-tut="reactour__severity"]',
            content: () => (<div>Severity is a scale which shows the impact of harm. In other words, how bad would it be. <br /><br /> You can define as many points on the scale as you like, but must choose 3 or 5 point scales. <br /><br /> Don't forget to also add a description to each so that you know what classifies as that on the scale.</div>),
        },
        {
            selector: '[data-tut="reactour__probability"]',
            content: () => (<div>Probability is a scale which shows the rate of occurence of the harm. In other words, how likely is it to happen. <br /><br /> You can define as many points on the scale as you like, but must choose 3 or 5 point scales. <br /><br /> Don't forget to also add a description to each so that you know what classifies as that on the scale.</div>),
        },
        {
            selector: '[data-tut="reactour__twoteir"]',
            content: () => (<div>Sometimes it is easier to have two levels of probability. One for the forseeable event sequence leading up to the harm, and one for hazardous situation resulting in harm. <br /><br /> Tento+ allows you to configure your risk analysis for both, 1 or two teir systems. You'll just need to define an extra matrix detailing how an overal probability is made from two probabilities.</div>),
        },
        {
            selector: '[data-tut="reactour__riskaccept"]',
            content: () => (<div>Finally, the risk acceptance table will allow you to define when a risk is acceptable, needs review or is unacceptable based on the severity and probability scores. <br /><br /><b>Exit the tutorial and try adding some items to these tables to get your risk configuration set-up.</b></div>),
        }
        // ...
    ]

    const severityDataForTable = (newProps) => newProps.sort(function (a, b) {
        return a._source.severity - b._source.severity
    }).map(a => {
        var row = {
            id: a._id,
            severity: a._source.severity,
            description: a._source.description,
            notes: a._source.notes,
            idDevice: a._source.idDevice,
            configID: a._source.configID,
            edit: { a, props },
        }

        return row;
    })

    const setDisplayT2Prop = (val) => {
        setDisplayT2(val);
        props.device._source.isT2 = val;

        dispatch(updateDevice(props.device._id, props.device._source, props.userTeams, account.localAccountId))
    }

    const probabilityDataForTable = (newProps = []) => newProps.sort(function (a, b) {
        return a._source.probability - b._source.probability
    }).map(a => {
        var row = {
            id: a._id,
            probability: a._source.probability,
            description: a._source.description,
            notes: a._source.notes,
            idDevice: a._source.idDevice,
            configID: a._source.configID,
            edit: { a, props },
        }

        return row;
    })

    const createMatrixCells = (toCreate) => {
        const configID = window.sessionStorage.getItem("configID")

        for (let i = 0; i < toCreate.length; i++) {
            const newCell = {
                severity: toCreate[i].severity,
                probability: toCreate[i].probability,
                text: "",
                idDevice: props.device._id, configID
            }

            dispatch(createRiskMatrix(newCell));
        }
    }

    const getProbMatrixDropdownOptions = () => {
        const newOptions = []
        for (let i = 0; i < props.riskProbability.length; i++) {
            newOptions.push({
                label: props.riskProbability[i]._source.probability,
                value: props.riskProbability[i]._id
            })
        }

        return newOptions;
    }

    const createProbMatrixCells = (toCreate) => {
        const configID = window.sessionStorage.getItem("configID")

        for (let i = 0; i < toCreate.length; i++) {
            const newCell = {
                probability1: toCreate[i].probability1,
                probability2: toCreate[i].probability2,
                text: "",
                idDevice: props.device._id, configID
            }

            dispatch(createProbabilityMatrix(newCell));
        }
    }

    const deleteProbMatrixCells = (toDelete) => {
        dispatch(deleteProbabilityMatrix(props.device._id, toDelete));
    }

    const deleteMatrixCells = (toDelete) => {
        console.log("deleteMatrixCells",toDelete)
        dispatch(deleteRiskMatrix(props.device._id, toDelete));
    }

    const updateMatrixCell = (newData) => {

        const currentCell = props.riskMatrix.find(e => e._source.severity === newData[1]  && e._source.probability === newData[2])
        console.log("FOUND", props.riskMatrix, currentCell, newData)

        currentCell._source.text = newData[0]
        dispatch(updateRiskMatrix(currentCell._id, currentCell._source));
    }

    const updateProbMatrixCell = (newData) => {
        const currentCell = props.probMatrix.find(e => e._source.probability1 === newData[1] && e._source.probability2 === newData[2] )
        console.log("FOUND", props.probMatrix, currentCell, newData)

        if (currentCell === undefined) { return; }

        currentCell._source.text = newData[0]
        dispatch(updateProbabilityMatrix(currentCell._id, currentCell._source));
    }

    const matrixHeadersForTable = () => {
        const headers1 = []
        const headers2 = []

        for (let p = 0; p < props.riskProbability.length; p++) {
            headers1.push({ text: props.riskProbability[p]._source.probability, id: props.riskProbability[p]._id });
        }

        for (let s = 0; s < props.riskSeverity.length; s++) {
            headers2.push({ text: props.riskSeverity[s]._source.severity, id: props.riskSeverity[s]._id });
        }

        return { headers1, headers2 }
    }

    const probMatrixHeadersForTable = () => {
        const headers = []

        for (let p = 0; p < props.riskProbability.length; p++) {
            headers.push({text:props.riskProbability[p]._source.probability,id:props.riskProbability[p]._id});
        }

        return headers
    }

    const probMatrixForTable = (newProps = []) => {
        const headers = probMatrixHeadersForTable();
        console.log("probMatrixForTable", headers)
        const newTable = [];
        for (let s = 0; s < headers.length; s++) {
            const newRow = []
            for (let p = 0; p < headers.length; p++) {
                const matrixDoc = props.probMatrix.find( mat => mat._source.probability1 === headers[p].id  &&mat._source.probability2 === headers[s].id )
                newRow.push({
                    probability1: headers[p],
                    probability2: headers[s],
                    text: matrixDoc?matrixDoc._source.text:"",
                    matrixID : matrixDoc?matrixDoc._id:undefined,
                    exists: false,
                })
            }
            newTable.push(newRow)
        }

        const toCreate = [];
        // const toDelete = [];
        // props.probMatrix.forEach((r) => {
        //     let risk = r._source;
        //     let row = newTable[risk.probability1 - 1]
        //     if (row !== undefined) {
        //         let cell = row[risk.probability2 - 1]
        //         if (cell !== undefined) {
        //             cell.text = risk.text
        //             cell.exists = true
        //             newTable[risk.probability1 - 1][risk.probability2 - 1] = cell
        //         } else {
        //             toDelete.push({ _id: r._id, _source: risk });
        //         }
        //     } else {
        //         toDelete.push({ _id: r._id, _source: risk });
        //     }
        // })

        // for (let s = 0; s < headers.length; s++) {
        //     for (let p = 0; p < headers.length; p++) {
        //         if (newTable[s][p].exists === false) {
        //             toCreate.push({ probability1: headers[p], probability2: headers[s] })
        //         }
        //     }
        // }

    
        // // Generate required cells
        // if (props.probMatrix.length !== headers.length * headers.length) {
        //     if (toCreate.length > 0) {
        //         createProbMatrixCells(toCreate); 
        //     }

        // // Delete unused rows/columns
        // if (toDelete.length > 0) {
        //     deleteProbMatrixCells(toDelete);
        // }
        // }
        return [headers, newTable];
    }

    const matrixDataForTable = (newProps = []) => {
        // Get Headers
        const { headers1, headers2 } = matrixHeadersForTable();

        // Generate Table
        const newTable = [];
        for (let s = 0; s < headers2.length; s++) {
            const newRow = []
            for (let p = 0; p < headers1.length; p++) {
                const text = props.riskMatrix.find( tableCell => tableCell._source.severity===headers2[s].id&&tableCell._source.probability===headers1[p].id)
                newRow.push({
                    probability: headers1[p],
                    severity: headers2[s],
                    text: text?text._source.text:"",
                    exists: false,
                })
            }
            newTable.push(newRow)
        }

        // Populate Table
        console.log("matrixDataForTable",newTable)
        const toCreate = [];
        const toDelete = [];
        // props.riskMatrix.forEach((r) => {
        //     let risk = r._source;
        //     console.log("Matrix cekk",risk ,newTable.find( tableCell => tableCell.severity.id===risk.severity&&tableCell.probability.id===risk.probability))
            
            // let row = newTable[risk.severity - 1]

            // if (row !== undefined) {
            //     let cell = row[risk.probability - 1]
            //     if (cell !== undefined) {
            //         cell.text = risk.text
            //         cell.exists = true
            //         newTable[risk.severity - 1][risk.probability - 1] = cell
            //     } else {
            //         toDelete.push({ _id: r._id, _source: risk });
            //     }
            // } else {
            //     toDelete.push({ _id: r._id, _source: risk });
            // }
        // })

        // for (let s = 0; s < headers2.length; s++) {
        //     for (let p = 0; p < headers1.length; p++) {
        //         if (newTable[s][p].exists === false) {
        //             toCreate.push({ probability: headers1[p], severity: headers2[s] })
        //         }
        //     }
        // }

        // Generate required cells
        // if (toCreate.length > 0) {
        //     createMatrixCells(toCreate);
        // }


        return [headers1, headers2, newTable];
    }
    const usePrevious = (value, initialValue) => {
        const ref = useRef(initialValue);
        useEffect(() => {
            ref.current = value;
        });
        return ref.current;
    };

    const navigate = useHistory()

    useEffect(() => {
        const configID = window.sessionStorage.getItem("configID")

        if (!props.device || (Object.keys(props.device).length === 0 && !props.selectingDevice)) {
            const sessionStorageDevice = JSON.parse(localStorage.getItem('device'))
            if (sessionStorageDevice && sessionStorageDevice !== undefined) {
                dispatch(selectDevice(sessionStorageDevice._id, sessionStorageDevice._id, configID));
            }
            else if (props.location.state) {
                dispatch(selectDevice(props.location.state.id, props.location.state.idDevice, configID));
            } else {
                navigate.push("/home")
            }
        }
        setTimeout(() => {
            if (!props.device || (Object.keys(props.device).length === 0 && !props.selectingDevice)) {
                if (props.location.state) {
                    dispatch(selectDevice(props.location.state.id, props.location.state.idDevice, configID))
                } else {
                    // console.log("return 2")

                    return <Redirect to="/home" />
                }
            }
        }, 1500);
    }, [])
    useEffect(() => {
        console.log("REDUCER USE EFFECT ---------", props.riskSeverity, props.riskProbability,props.riskMatrix.map(m => m._source.text))

        const newData = props.riskSeverity && props.riskProbability ? matrixDataForTable() : [[], [], []];
        setMatrixH1(newData[0])
        setMatrixH2(newData[1])
        setMatrixData(newData[2])

        const newProb = props.riskSeverity && props.riskProbability ? probMatrixForTable() : [[], [], []];;
        setProbH1(newProb[0])
        setProbData(newProb[1])



    }, [props.riskSeverity, props.riskProbability,props.riskMatrix])

    const handleSeverityChange = (newVal, row) => {
        console.log("SEVERITY UPDATE", row);
        const newData = props.riskSeverity.find((c) => c._id == row.id)
        const newSource = {}

        Object.keys(newData._source).forEach((key) => {
            newSource[key] = row[key];
        })

        console.log("SENDING", newSource);

        dispatch(updateRiskSeverity(row.id, newSource));
    }

    const addSevRow = () => {
        const configID = window.sessionStorage.getItem("configID")

        const newRow = {
            severity: (props.riskSeverity.length + 1).toString(),
            description: "",
            notes: "",
            idDevice: props.device._id,
            configID
        };
        dispatch(createRiskSeverity(newRow, props.riskSeverity, props.riskProbability));

        console.log("NEW ROW CREATED", props);
        
    }

    const handleProbabilityChange = (newVal, row) => {
        console.log("PROBABILITY UPDATE", row);
        const newData = props.riskProbability.find((c) => c._id == row.id)
        const newSource = {}

        Object.keys(newData._source).forEach((key) => {
            newSource[key] = row[key];
        })

        console.log("SENDING", newSource);

        dispatch(updateRiskProbability(row.id, newSource));
    }

    const addProbRow = () => {
        const configID = window.sessionStorage.getItem("configID")

        const newProb = probMatrixForTable();
        setProbH1(newProb[0])
        setProbData(newProb[1])


        const newRow = {
            probability: (props.riskProbability.length + 1).toString(),
            description: "",
            notes: "",
            configID,
            idDevice: props.device._id,
        };

        dispatch(createRiskProbability(newRow, props.riskSeverity, props.riskProbability));

        // const newProb = probMatrixForTable();
        var toCreate = []
        const headers = []

        for (let p = 0; p < props.riskProbability.length; p++) {
            headers.push(props.riskProbability[p]._source.probability);
        }
        headers.push(newRow.probability);

        for (let s = 0; s < headers.length; s++) {
            for (let p = 0; p < headers.length; p++) {
                if (!probData[s] || !probData[s][p]) {
                    toCreate.push({ probability1: headers[p], probability2: headers[s] })
                }
            }
        }

        console.log("NEW PROBS MATRIX", toCreate);
        // createProbMatrixCells(toCreate)

        // dispatch(createProbabilityMatrixCells(props.device._id, toCreate.map(cell => {
        //     return {
        //         probability1: cell.probability1,
        //         probability2: cell.probability2,
        //         text: "",
        //         idDevice: props.device._id, configID
        //     }
        // })));

        // createProbMatrixCells(toCreate)
        // setProbH1(newProb[0])
        // setProbData(newProb[1])

        console.log("NEW ROW CREATED", props);
    }

    const handleSeverityDelete = () => {
        console.log("DELETING", props.riskSeverity.sort((a, b) => { return a._source.severity - b._source.severity }));
        const newData = props.riskSeverity.sort((a, b) => { return a._source.severity - b._source.severity })[props.riskSeverity.length - 1];
        const newSource = {...newData._source}


        console.log("SOURCE", newData,props.riskMatrix)


        dispatch(deleteRiskSeverity(newData._id, newSource));
        // deleteMatrixCells(props.riskMatrix.filter( rMatrix=> rMatrix._source.severity===newData._id))
        // .forEach()
    }

    const handleProbabilityDelete = () => {
        const newData = props.riskProbability.sort((a, b) => { return a._source.probability - b._source.probability })[props.riskProbability.length - 1];
        const newSource = {}

        Object.keys(newData._source).forEach((key) => {
            newSource[key] = newData._source[key];
        })
        console.log("SOURCE", newData,props.riskMatrix.map( p => p._source))


        dispatch(deleteRiskProbability(newData._id, newSource));


        // deleteMatrixCells(props.riskMatrix.filter( rMatrix=> rMatrix._source.probability===newData._id))
    }

    return (
        <React.Fragment>
            {<Tour
                steps={steps}
                showNavigation={false}
                isOpen={isTourOpen && window.innerWidth > 768}
                scrollSmooth
                goToStep={curStep}
                nextStep={() => { setCurStep(curStep + 1) }}
                prevStep={() => { setCurStep(curStep - 1) }}
                onRequestClose={() => { setIsTourOpen(false) }} />
            }
            {props.device && props.device._source && props.riskProbability && <div style={{ "minHeight": "100vh" }}>
                <Container style={{
                    marginTop: "30px",
                    float: "left",
                    maxWidth: "1920px",
                }}>
                    <div className={styles.matrixContainer}>
                        <div className={styles.matrix}>
                            <h3 className={styles.matrixTitle}>Severity Definition</h3>
                            <div data-tut="reactour__severity" className={styles.tableContainer}>
                                <Definition
                                    keyField={"severity"}
                                    columns={sevCols}
                                    data={severityDataForTable(props.riskSeverity)}
                                    addRow={addSevRow}
                                    handleRowChange={handleSeverityChange}
                                    handleRowDelete={handleSeverityDelete}
                                    hasRiskSub={hasRiskSub}
                                    userModules={props.userModules}
                                />
                            </div>
                        </div>
                        <div className={styles.matrix}>
                            <h3 className={styles.matrixTitle}>Probability Definition</h3>
                            <div data-tut="reactour__probability" className={styles.tableContainer}>
                                <Definition
                                    keyField={"probability"}
                                    columns={probCols}
                                    data={probabilityDataForTable(props.riskProbability)}
                                    addRow={addProbRow}
                                    handleRowChange={handleProbabilityChange}
                                    handleRowDelete={handleProbabilityDelete}
                                    hasRiskSub={hasRiskSub}
                                />
                            </div>
                        </div>
                    </div>
                    <div data-tut="reactour__twoteir" className={styles.matrix}>
                        <h3 className={styles.matrixTitle} style={{ display: "grid", gridTemplateColumns: "150px auto", alignItems: "center" }}>
                            Two Tier Probability
                            <Switch
                            disabled={!hasRiskSub}
                                checked={props.device._source.isT2}
                                onChange={hasRiskSub ? setDisplayT2Prop : () => { console.log("NO SUB") }}
                            />
                        </h3>
                        {console.log("probData", probData)}
                        {props.device._source.isT2 &&
                            <div className={styles.tableContainer}>
                                <Matrix
                                    title1={"Probability"}
                                    type={"select"}
                                    columns1={probH1}
                                    columns2={probH1}
                                    data={probData}
                                    onChange={updateProbMatrixCell}
                                    hasRiskSub ={hasRiskSub}
                                    userModules={props.userModules}
                                    options={getProbMatrixDropdownOptions()}
                                    riskProbability={props.riskProbability}
                                />
                            </div>
                        }
                    </div>
                    <div className={styles.matrix}>
                        <h3 className={styles.matrixTitle}>Risk Matrix</h3>
                        <div data-tut="reactour__riskaccept" className={styles.tableContainer}>
                            <Matrix
                                type={"text"}
                                title1={"Probability"}
                                title2={"Severity"}
                                columns1={matrixH1}
                                columns2={matrixH2}
                                data={matrixData}
                                riskProbability={props.riskProbability}
                                // handleRowChange={updateMatrixCell}
                                onChange={updateMatrixCell}
                                hasRiskSub ={hasRiskSub}
                                />
                        </div>
                    </div>
                </Container>
            </div>}
        </React.Fragment>
    )
}

const mapStateToProps = (state, ownProps) => {
    const { TestPlan } = state;
    return { ...TestPlan };
}
export default connect(mapStateToProps)(RiskConfig);