import React, { useState, useEffect, useCallback } from 'react';
import { Link } from 'react-router-dom';
import {

    Card,
    CardBody, CardHeader, Button,
    UncontrolledDropdown,
    Row,
    Input,
    UncontrolledTooltip,

} from 'reactstrap';
import * as FileSaver from 'file-saver'
import CreatableSelect from 'react-select/creatable';
import classNames from 'classnames';
import Select from 'react-select';
import { connect, useSelector, useDispatch } from 'react-redux';
import { createAnom, createGroup, createProtocol, createRecord, createReq, createTestLog, createTile, updateReq } from '../redux/actions';
import styles from './overviewStyles.module.css'
import import_icon from '../assets/icons/import_icon.svg';
import {
    InteractionRequiredAuthError,
    InteractionStatus,
} from "@azure/msal-browser";
import { read, utils, writeFileXLSX, writeFile, readFile } from 'xlsx-js-style';
import card from './Styles/card.module.css';
import LoaderWidget from './Loader';
import TentoModal from './TentoModal';

const createMarkup = text => {
    return { __html: text };
};

const ImportButton = (props) => {
    // const [exportType, setExportType] = useState({ label: "Design Traceability Matrix", value: "Design Traceability Matrix" });
    const [file, setFile] = useState()
    const [readyToImport, setReadyToImport] = useState(false)
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState("")
    const dispatch = useDispatch();


    const importRequirements = useCallback((props, wb, isRisk,requirementGroupSelected) => {
        //    alert(JSON.stringify(wb.SheetNames))
        try {
            const configID = window.sessionStorage.getItem("configID")
            if (requirementGroupSelected === null)
            { 
            wb.SheetNames.forEach((sheetname) => {
                const ws = wb.Sheets[sheetname]
                const group_name = ws["B1"].v
                const group_ref = ws["B2"].v
                const group_level = { "Product": "top", "System": "mid", "Component": "low" }[ws["B3"].v]
                // alert(JSON.stringify([group_name, group_ref, group_level]))

                const group_creation_time = Date.now()

                const grpID = configID + group_creation_time
                dispatch(createGroup(grpID, {
                    idDevice: props.device._id,
                    configID,
                    level: group_level,
                    title: group_name,
                    ref: group_ref,
                    risk:isRisk
                }, grpID))
                var row = 5
                var descriptionCell = ws[utils.encode_cell({ c: 1, r: row })]
                while (descriptionCell && descriptionCell.t !== "z" && descriptionCell.v !== "") {


                    const newOrder = props.reqs.filter(t => t._source.level === group_level).length !== 0 ? Math.max(...props.reqs.map(t => t._source.order)) + 1 : 0;

                    const req_name_raw = ws[utils.encode_cell({ c: 1, r: row })]
                    const req_ref_raw = ws[utils.encode_cell({ c: 0, r: row })]
                    const req_measure_raw = ws[utils.encode_cell({ c: 2, r: row })]
                    const req_notes_raw = ws[utils.encode_cell({ c: 3, r: row })]
                    const req_priority_raw = ws[utils.encode_cell({ c: 4, r: row })]
                    console.log("CELL", req_name_raw, req_ref_raw, req_measure_raw, req_priority_raw)
                    const req_name = req_name_raw && req_name_raw.t !== "z" && `${req_name_raw.v}`.replaceAll(" ", "").length !== 0 ? req_name_raw.v + "" : ""
                    const req_ref = req_ref_raw && req_ref_raw.t !== "z" && `${req_ref_raw.v}`.replaceAll(" ", "").length !== 0 ? req_ref_raw.v + "" : ""
                    const req_measure = req_measure_raw && req_measure_raw.t !== "z" && `${req_measure_raw.v}`.replaceAll(" ", "").length !== 0 ? req_measure_raw.v + "" : ""
                    const req_notes = req_notes_raw && req_notes_raw.t !== "z" && `${req_notes_raw.v}`.replaceAll(" ", "").length !== 0 ? req_notes_raw.v + "" : ""
                    const req_priority = req_priority_raw && req_priority_raw.t !== "z" && `${req_priority_raw.v}`.replaceAll(" ", "").length !== 0 ? req_priority_raw.v + "" : ""


                    dispatch(createReq(
                        // console.log(newReqID, "SOURCE",
                        {
                            idDevice: props.device._id,
                            configID,

                            title: req_name,
                            ref: req_ref,
                            required: req_priority,
                            desc: req_measure,

                            created: new Date(),
                            createdBy: props.account.localAccountId,
                            status: 1,
                            level: group_level,
                            group: grpID,
                            type: 1,
                            child: [],
                            parent: [],
                            reqchild: [],
                            reqparent: [],
                            notes: req_notes,
                            order: newOrder,
                            riskReq: isRisk
                        }
                    )
                    )
                    row += 1
                    descriptionCell = ws[utils.encode_cell({ c: 1, r: row })]
                }
                if (group_level === "low") {
                    var row = 5
                    var descriptionCell = ws[utils.encode_cell({ c: 7, r: row })]
                    while (descriptionCell && descriptionCell.t !== "z" && descriptionCell.v !== "") {


                        // const newOrder = props.reqs.filter(t => t._source.level === group_level).length !== 0 ? Math.max(...props.reqs.map(t => t._source.order)) + 1 : 0;

                        const tile_ref_raw = ws[utils.encode_cell({ c: 6, r: row })]
                        const tile_name_raw = ws[utils.encode_cell({ c: 7, r: row })]
                        const tile_description_raw = ws[utils.encode_cell({ c: 8, r: row })]
                        const tile_notes_raw = ws[utils.encode_cell({ c: 9, r: row })]
                        const tile_type_raw = ws[utils.encode_cell({ c: 10, r: row })]


                        const tile_name = tile_name_raw && tile_name_raw.t !== "z" && `${tile_name_raw.v}`.replaceAll(" ", "").length !== 0 ? tile_name_raw.v + "" : ""
                        const tile_ref = tile_ref_raw && tile_ref_raw.t !== "z" && `${tile_ref_raw.v}`.replaceAll(" ", "").length !== 0 ? tile_ref_raw.v + "" : ""
                        const tile_description = tile_description_raw && tile_description_raw.t !== "z" && `${tile_description_raw.v}`.replaceAll(" ", "").length !== 0 ? tile_description_raw.v + "" : ""
                        const tile_notes = tile_notes_raw && tile_notes_raw.t !== "z" && `${tile_notes_raw.v}`.replaceAll(" ", "").length !== 0 ? tile_notes_raw.v + "" : ""
                        const tile_type = tile_type_raw && tile_type_raw.t !== "z" && `${tile_type_raw.v}`.replaceAll(" ", "").length !== 0 ? tile_type_raw.v + "" : ""

                        console.log("REDUCER CREATE TILE:=====", tile_type, tile_type.toLocaleLowerCase(), { "function": 2, "unit": 3, "source": 4 }[tile_type.toLocaleLowerCase()])
                        const newTileObj = {
                            idDevice: props.device._id,
                            configID,
                            created: new Date(),
                            title: tile_name,
                            ref: tile_ref,
                            desc: tile_description,
                            createdBy: props.account.localAccountId,
                            status: 1,
                            idBoard: "",
                            group: grpID,
                            notes: tile_notes,
                            type: { "function": 2, "unit": 3, "source": 4 }[tile_type.toLocaleLowerCase()],
                            child: [], parent: [], reqchild: [], reqparent: [], order: Math.max(...props.tiles?props.tiles.map(t => t._source.order):[]) + row
                        };
                        dispatch(createTile(newTileObj))

                        row += 1
                        descriptionCell = ws[utils.encode_cell({ c: 7, r: row })]
                    }

                }
            
            })
            }        
            if (requirementGroupSelected != null)
            { 
                const sheetname = wb.SheetNames[0]
                const ws = wb.Sheets[sheetname]
                const group_name = ws["B1"].v
                const group_ref = ws["B2"].v
                const group_level = requirementGroupSelected.value._source.level 
                const group_id = requirementGroupSelected.value._id
                // alert(JSON.stringify([group_name, group_ref, group_level]))

                var row = 5
                var descriptionCell = ws[utils.encode_cell({ c: 1, r: row })]
                while (descriptionCell && descriptionCell.t !== "z" && descriptionCell.v !== "") {


                    const newOrder = props.reqs.filter(t => t._source.level === group_level).length !== 0 ? Math.max(...props.reqs.map(t => t._source.order)) + 1 : 0;

                    const req_name_raw = ws[utils.encode_cell({ c: 1, r: row })]
                    const req_ref_raw = ws[utils.encode_cell({ c: 0, r: row })]
                    const req_measure_raw = ws[utils.encode_cell({ c: 2, r: row })]
                    const req_notes_raw = ws[utils.encode_cell({ c: 3, r: row })]
                    const req_priority_raw = ws[utils.encode_cell({ c: 4, r: row })]
                    console.log("CELL", req_name_raw, req_ref_raw, req_measure_raw, req_priority_raw)
                    const req_name = req_name_raw && req_name_raw.t !== "z" && `${req_name_raw.v}`.replaceAll(" ", "").length !== 0 ? req_name_raw.v + "" : ""
                    const req_ref = req_ref_raw && req_ref_raw.t !== "z" && `${req_ref_raw.v}`.replaceAll(" ", "").length !== 0 ? req_ref_raw.v + "" : ""
                    const req_measure = req_measure_raw && req_measure_raw.t !== "z" && `${req_measure_raw.v}`.replaceAll(" ", "").length !== 0 ? req_measure_raw.v + "" : ""
                    const req_notes = req_notes_raw && req_notes_raw.t !== "z" && `${req_notes_raw.v}`.replaceAll(" ", "").length !== 0 ? req_notes_raw.v + "" : ""
                    const req_priority = req_priority_raw && req_priority_raw.t !== "z" && `${req_priority_raw.v}`.replaceAll(" ", "").length !== 0 ? req_priority_raw.v + "" : ""


                    dispatch(createReq(
                        // console.log(newReqID, "SOURCE",
                        {
                            idDevice: props.device._id,
                            configID,

                            title: req_name,
                            ref: req_ref,
                            required: req_priority,
                            desc: req_measure,

                            created: new Date(),
                            createdBy: props.account.localAccountId,
                            status: 1,
                            level: group_level,
                            group: group_id,
                            type: 1,
                            child: [],
                            parent: [],
                            reqchild: [],
                            reqparent: [],
                            notes: req_notes,
                            order: newOrder,
                            riskReq: isRisk
                        }
                    )
                    )
                    row += 1
                    descriptionCell = ws[utils.encode_cell({ c: 1, r: row })]
                }
                if (group_level === "low") {
                    var row = 5
                    var descriptionCell = ws[utils.encode_cell({ c: 7, r: row })]
                    while (descriptionCell && descriptionCell.t !== "z" && descriptionCell.v !== "") {


                        // const newOrder = props.reqs.filter(t => t._source.level === group_level).length !== 0 ? Math.max(...props.reqs.map(t => t._source.order)) + 1 : 0;

                        const tile_ref_raw = ws[utils.encode_cell({ c: 6, r: row })]
                        const tile_name_raw = ws[utils.encode_cell({ c: 7, r: row })]
                        const tile_description_raw = ws[utils.encode_cell({ c: 8, r: row })]
                        const tile_notes_raw = ws[utils.encode_cell({ c: 9, r: row })]
                        const tile_type_raw = ws[utils.encode_cell({ c: 10, r: row })]


                        const tile_name = tile_name_raw && tile_name_raw.t !== "z" && `${tile_name_raw.v}`.replaceAll(" ", "").length !== 0 ? tile_name_raw.v + "" : ""
                        const tile_ref = tile_ref_raw && tile_ref_raw.t !== "z" && `${tile_ref_raw.v}`.replaceAll(" ", "").length !== 0 ? tile_ref_raw.v + "" : ""
                        const tile_description = tile_description_raw && tile_description_raw.t !== "z" && `${tile_description_raw.v}`.replaceAll(" ", "").length !== 0 ? tile_description_raw.v + "" : ""
                        const tile_notes = tile_notes_raw && tile_notes_raw.t !== "z" && `${tile_notes_raw.v}`.replaceAll(" ", "").length !== 0 ? tile_notes_raw.v + "" : ""
                        const tile_type = tile_type_raw && tile_type_raw.t !== "z" && `${tile_type_raw.v}`.replaceAll(" ", "").length !== 0 ? tile_type_raw.v + "" : ""

                        console.log("REDUCER CREATE TILE:=====", tile_type, tile_type.toLocaleLowerCase(), { "function": 2, "unit": 3, "source": 4 }[tile_type.toLocaleLowerCase()])
                        const newTileObj = {
                            idDevice: props.device._id,
                            configID,
                            created: new Date(),
                            title: tile_name,
                            ref: tile_ref,
                            desc: tile_description,
                            createdBy: props.account.localAccountId,
                            status: 1,
                            idBoard: "",
                            group: group_id,
                            notes: tile_notes,
                            type: { "function": 2, "unit": 3, "source": 4 }[tile_type.toLocaleLowerCase()],
                            child: [], parent: [], reqchild: [], reqparent: [], order: Math.max(...props.tiles?props.tiles.map(t => t._source.order):[]) + row
                        };
                        dispatch(createTile(newTileObj))

                        row += 1
                        descriptionCell = ws[utils.encode_cell({ c: 7, r: row })]
                    }

                }
            

            }
            setTimeout(() => { setLoading(false); setModalOpen(false) }, 1000)
        } catch (error) {
            setLoading(false)
            console.log(error)

            setError("Something went wrong with the upload. Check that you've filled in the template correctly")
        }
    }, []);

    const importTestLog = useCallback((props, wb) => {
        try {
            //    alert(JSON.stringify(wb.SheetNames))
            const configID = window.sessionStorage.getItem("configID")

            const testLogSheet = wb.Sheets["Test Log Info"]
            const testLogNameRaw = testLogSheet["B1"]
            const testLogRefRaw = testLogSheet["B2"]

            const test_log_name = testLogNameRaw && testLogNameRaw.t !== "z" && `${testLogNameRaw.v}`.replaceAll(" ", "").length !== 0 ? testLogNameRaw.v + "" : ""
            const test_log_raw = testLogRefRaw && testLogRefRaw.t !== "z" && `${testLogRefRaw.v}`.replaceAll(" ", "").length !== 0 ? testLogRefRaw.v + "" : ""
            const log_creation_time = Date.now()
            const logID = configID + log_creation_time

            dispatch(createTestLog(
                {
                    idDevice: props.device._id,
                    configID,
                    created: new Date(),
                    title: test_log_name,
                    ref: test_log_raw,// ""+(props.reqs.filter( r =>r._source.group ===resultItem.reqGroupID).length+1) ,
                    createdBy: props.account.localAccountId,
                    status: 1,
                    desc: "resultItem.description",
                    idBoard: "",
                    notes: "",
                    result: "",
                    testitems: "",


                }, logID))
            wb.SheetNames.filter(s => s !== "Test Log Info").forEach((sheetname) => {
                const ws = wb.Sheets[sheetname]
                const rec_name_raw = ws[utils.encode_cell({ c: 1, r: 0 })]
                const rec_ref_raw = ws[utils.encode_cell({ c: 1, r: 1 })]
                const rec_description_raw = ws[utils.encode_cell({ c: 1, r: 2 })]
                const rec_tools_raw = ws[utils.encode_cell({ c: 1, r: 3 })]
                const rec_inputs_raw = ws[utils.encode_cell({ c: 1, r: 4 })]
                const rec_notes_raw = ws[utils.encode_cell({ c: 1, r: 5 })]

                const rec_name = rec_name_raw && rec_name_raw.t !== "z" && `${rec_name_raw.v}`.replaceAll(" ", "").length !== 0 ? rec_name_raw.v + "" : ""
                const rec_ref = rec_ref_raw && rec_ref_raw.t !== "z" && `${rec_ref_raw.v}`.replaceAll(" ", "").length !== 0 ? rec_ref_raw.v + "" : ""
                const rec_description = rec_description_raw && rec_description_raw.t !== "z" && `${rec_description_raw.v}`.replaceAll(" ", "").length !== 0 ? rec_description_raw.v + "" : ""
                const rec_tools = rec_tools_raw && rec_tools_raw.t !== "z" && `${rec_tools_raw.v}`.replaceAll(" ", "").length !== 0 ? rec_tools_raw.v + "" : ""
                const rec_inputs = rec_inputs_raw && rec_inputs_raw.t !== "z" && `${rec_inputs_raw.v}`.replaceAll(" ", "").length !== 0 ? rec_inputs_raw.v + "" : ""
                const rec_notes = rec_notes_raw && rec_notes_raw.t !== "z" && `${rec_notes_raw.v}`.replaceAll(" ", "").length !== 0 ? rec_notes_raw.v + "" : ""

                const rec_creation_time = Date.now()
                const recID = configID + rec_creation_time

                dispatch(createRecord({
                    title: rec_name,
                    ref: rec_ref.toString(),
                    desc: rec_description,
                    configID,
                    status: 1,
                    idBoard: props.idBoard,
                    created: new Date(),
                    idDevice: props.device._id,
                    createdBy: props.account.localAccountId,
                    idTiles: [],
                    result: 0,
                    idTestLog: logID,

                    "tools": rec_tools,
                    "inputs": rec_inputs,
                    "notes": rec_notes,
                    "version": "",

                }, recID))
                // dispatch(createGroup(grpID, {
                //     idDevice: props.device._id,
                //     configID,
                //     level: group_level,
                //     title: group_name,
                //     ref: group_ref
                // }, grpID))
                var row = 8
                var descriptionCell = ws[utils.encode_cell({ c: 1, r: row })]
                while (descriptionCell && descriptionCell.t !== "z" && descriptionCell.v !== "") {


                    //     const newOrder = props.reqs.filter(t => t._source.level === group_level).length !== 0 ? Math.max(...props.reqs.map(t => t._source.order)) + 1 : 0;

                    const proto_step_raw = ws[utils.encode_cell({ c: 0, r: row })]
                    const proto_description_raw = ws[utils.encode_cell({ c: 1, r: row })]
                    const proto_outcome_raw = ws[utils.encode_cell({ c: 2, r: row })]

                    //     const req_priority_raw = ws[utils.encode_cell({ c: 3, r: row })]
                    //     
                    const proto_step = proto_step_raw && proto_step_raw.t !== "z" && `${proto_step_raw.v}`.replaceAll(" ", "").length !== 0 ? proto_step_raw.v + "" : ""
                    const proto_description = proto_description_raw && proto_description_raw.t !== "z" && `${proto_description_raw.v}`.replaceAll(" ", "").length !== 0 ? proto_description_raw.v + "" : ""
                    const proto_outcome = proto_outcome_raw && proto_outcome_raw.t !== "z" && `${proto_outcome_raw.v}`.replaceAll(" ", "").length !== 0 ? proto_outcome_raw.v + "" : ""
                    //     const req_priority = req_priority_raw && req_priority_raw.t !== "z" && `${req_priority_raw.v}`.replaceAll(" ", "").length !== 0 ? req_priority_raw.v + "" : ""
                    console.log("CELL", proto_step, proto_description, proto_outcome)
                    const newProtocol = {
                        status: 1,
                        idDevice: props.device._id,
                        idBoard: props.idBoard,
                        created: new Date(),
                        createdBy: props.account.localAccountId,

                        idTest: recID,
                        order: parseInt(proto_step),
                        outcome: proto_outcome,
                        desc: proto_description,
                        notes: "",
                        configID
                    };
                    dispatch(createProtocol(newProtocol))
                    //     // dispatch(createReq(
                    //     //     // console.log(newReqID, "SOURCE",
                    //     //     {
                    //     //         idDevice: props.device._id,
                    //     //         configID,

                    //     //         title: req_name,
                    //     //         ref: req_ref,
                    //     //         required: req_priority,
                    //     //         desc: req_measure,

                    //     //         created: new Date(),
                    //     //         createdBy: props.account.localAccountId,
                    //     //         status: 1,
                    //     //         level: group_level,
                    //     //         group: grpID,
                    //     //         type: 1,
                    //     //         child: [],
                    //     //         parent: [],
                    //     //         reqchild: [],
                    //     //         reqparent: [],
                    //     //         order: newOrder
                    //     //     }
                    //     // )
                    //     // )
                    row += 1
                    descriptionCell = ws[utils.encode_cell({ c: 1, r: row })]
                }


            })
            setTimeout(() => { setLoading(false); setModalOpen(false) }, 1000)
        } catch (error) {
            console.log(error)

            setLoading(false)
            setError("Something went wrong with the upload. Check that you've filled in the template correctly")
        }

    }, []);

    const importAnoms = useCallback((props, wb) => {
        //    alert(JSON.stringify(wb.SheetNames))
        try {
            const configID = window.sessionStorage.getItem("configID")



            const ws = wb.Sheets[wb.SheetNames[0]]

            var row = 2
            var descriptionCell = ws[utils.encode_cell({ c: 1, r: row })]
            while (descriptionCell && descriptionCell.t !== "z" && descriptionCell.v !== "") {


                //     const newOrder = props.reqs.filter(t => t._source.level === group_level).length !== 0 ? Math.max(...props.reqs.map(t => t._source.order)) + 1 : 0;

                const anom_ref_raw = ws[utils.encode_cell({ c: 0, r: row })]
                const anom_description_raw = ws[utils.encode_cell({ c: 1, r: row })]
                const anom_type_raw = ws[utils.encode_cell({ c: 2, r: row })]
                const anom_raised_raw = ws[utils.encode_cell({ c: 3, r: row })]
                const anom_status_raw = ws[utils.encode_cell({ c: 4, r: row })]
                const anom_notes_raw = ws[utils.encode_cell({ c: 5, r: row })]
                const anom_date_raw = ws[utils.encode_cell({ c: 6, r: row })]
                const anom_signed_raw = ws[utils.encode_cell({ c: 7, r: row })]

                const anom_ref = anom_ref_raw && anom_ref_raw.t !== "z" && `${anom_ref_raw.v}`.replaceAll(" ", "").length !== 0 ? anom_ref_raw.v + "" : ""
                const anom_description = anom_description_raw && anom_description_raw.t !== "z" && `${anom_description_raw.v}`.replaceAll(" ", "").length !== 0 ? anom_description_raw.v + "" : ""
                const anom_type = anom_type_raw && anom_type_raw.t !== "z" && `${anom_type_raw.v}`.replaceAll(" ", "").length !== 0 ? anom_type_raw.v + "" : ""
                const anom_raised = anom_raised_raw && anom_raised_raw.t !== "z" && `${anom_raised_raw.v}`.replaceAll(" ", "").length !== 0 ? anom_raised_raw.w : new Date()
                const anom_status = anom_status_raw && anom_status_raw.t !== "z" && `${anom_status_raw.v}`.replaceAll(" ", "").length !== 0 ? anom_status_raw.v + "" : ""
                const anom_notes = anom_notes_raw && anom_notes_raw.t !== "z" && `${anom_notes_raw.v}`.replaceAll(" ", "").length !== 0 ? anom_notes_raw.v + "" : ""
                const anom_date = anom_date_raw && anom_date_raw.t !== "z" && `${anom_date_raw.v}`.replaceAll(" ", "").length !== 0 ? anom_date_raw.w : new Date()
                const anom_signed = anom_signed_raw && anom_signed_raw.t !== "z" && `${anom_signed_raw.v}`.replaceAll(" ", "").length !== 0 ? anom_signed_raw.v + "" : ""


                const newAnom = {
                    status: ["None", 'OPEN', 'CLOSED', 'NO ACTION', 'REVIEW'].findIndex((element) => element === anom_status),
                    idBoard: "",
                    idDevice: props.device._id,
                    created: new Date(Date.parse(anom_raised)),
                    idProtocol: "",
                    idTest: "",
                    createdBy: props.account.localAccountId,
                    result: ["None", 'OPEN', 'CLOSED', 'NO ACTION', 'REVIEW'].findIndex((element) => element === anom_status),
                    desc: anom_description,
                    ref: anom_ref,
                    type: ["NONE", "PASS", "MAJOR", "MINOR", "OBSERVATION"].findIndex((element) => element === anom_type),
                    notes: anom_notes,
                    latestDate: anom_date,
                    sign: anom_signed,
                    version: "",
                    serial: "",
                    module: "",
                    configID
                };
                console.log("ANOM", new Date(Date.parse(anom_raised)), anom_raised, anom_raised_raw)
                dispatch(createAnom(newAnom))


                //     // dispatch(createReq(
                //     //     // console.log(newReqID, "SOURCE",
                //     //     {
                //     //         idDevice: props.device._id,
                //     //         configID,

                //     //         title: req_name,
                //     //         ref: req_ref,
                //     //         required: req_priority,
                //     //         desc: req_measure,

                //     //         created: new Date(),
                //     //         createdBy: props.account.localAccountId,
                //     //         status: 1,
                //     //         level: group_level,
                //     //         group: grpID,
                //     //         type: 1,
                //     //         child: [],
                //     //         parent: [],
                //     //         reqchild: [],
                //     //         reqparent: [],
                //     //         order: newOrder
                //     //     }
                //     // )
                //     // )
                row += 1
                descriptionCell = ws[utils.encode_cell({ c: 1, r: row })]
            }



            setTimeout(() => { setLoading(false); setModalOpen(false) }, 1000)
        } catch (error) {
            console.log(error)

            setLoading(false)
            setError("Something went wrong with the upload. Check that you've filled in the template correctly")
        }

    }, []);

    useEffect(() => {


    }, []);

    const templateURLS = {
        "Requirements": "https://tentoimporttemplates.blob.core.windows.net/templates/Requirement Template.xlsx",
        "Design Outputs": "https://tentoimporttemplates.blob.core.windows.net/templates/Requirement Template.xlsx",
        "Test Log": "https://tentoimporttemplates.blob.core.windows.net/templates/Test Log Template.xlsx",
        "Anomalies": "https://tentoimporttemplates.blob.core.windows.net/templates/Anomalies Template.xlsx",
        "Risk":"https://tentoimporttemplates.blob.core.windows.net/templates/Risk Controls Template.xlsx"
    }
    const [modalOpen, setModalOpen] = useState(false)
    const [requirementGroupSelected, setSequirementGroupSelected] = useState("")
    return (
        <React.Fragment>
            <Button onClick={() => {setModalOpen(true);setSequirementGroupSelected(null)}} id='import_button'
            disabled={!props.hasRelevantSub}
             style={{marginLeft:"10px"}} 
            > Import {props.dataType}</Button>

            <UncontrolledTooltip target="import_button" placement="right" >Import {props.dataType} from a file. </UncontrolledTooltip>
            {modalOpen && (
                <TentoModal
                    title={`Import ${props.dataType}`}
                    isOpen={modalOpen}

                    toggle={() => { setModalOpen(!modalOpen) }}
                    size="lg"

                    body={
                        // <AvForm className="p-2" onValidSubmit={handleEditRecord}>
                        <div>
                            {loading && <LoaderWidget></LoaderWidget>}
                            <Row style={{ minHeight: "300px" }}>

                                <Card className={card.card + ' ' + styles.deviceCard}>

                                    <CardBody className={styles.blocksBody + ' ' + card.cardBody} style={{ textAlign: "left", }}>


                                        <Row style={{ marginBottom: "15px", }}>
                                                 <span style={{ fontSize: "1rem", textAlign: "left", }}>Step 1: Download the template file</span>
                                            {/* <Link to={templateURLS[importType.value]} target="_blank" download> */}
                                            <Button className={styles.downloadButton} style={{ width: "50%", margin: "auto", marginTop: "10px", height: "2.5rem" }} onClick={
                                                async () => {
                                                    FileSaver.saveAs(templateURLS[props.dataType], `${props.dataType} template.xlsx`)
                                                }
                                            }>
                                                Download template
                                            </Button>



                                        </Row>
                                        <Row style={{ marginBottom: "15px", }}>
                                            <span style={{ fontSize: "1rem", textAlign: "left", }}>Step 2: Fill out the template file and upload it here:</span>
                                            <Input type="file" on onChange={e => {
                                                setFile(e.target.files[0])
                                                setReadyToImport(true)
                                            }} style={{ width: "90%", margin: "auto", marginTop: "10px" }}></Input>
                                        </Row>
                                        <Row style={{ marginBottom: "15px", }}>
                                        {props.dataType === 'Requirements' && (
                                            <div>
                                            <span style={{ fontSize: "1rem", textAlign: "left", }}>Step 3: Select if you want to add to an exisiting group. </span>
                                                <Select 
                                                style={{ width: "90%", margin: "auto", marginTop: "10px" }}
                                                placeholder = {"Otherwise, leave this blank and the import will create new requirement groups for each tab in the spreadsheet."}
                                                isClearable = {true}
                                                isSearchable = {true}
                                                options={props.reqGroupsRaw.map(g => { return { label: g._source.title, value: g } })}
                                                        onChange={(v)=>{setSequirementGroupSelected(v)}} />
                                                          {requirementGroupSelected != null && (<span style={{ fontSize: "1rem", textAlign: "left", }}>  As you are adding to a existing requirement group, the import will only include the first tab in your spreadsheet, and add that content to the group. Other tabs will be ignored. </span>)}
                                                
                                            </div>
                                            )}
                                            { props.dataType === 'Design Outputs' && (
                                            <div>
                                            <span style={{ fontSize: "1rem", textAlign: "left", }}>Step 3: Select if you want to add to an exisiting requirement group. </span>
                                                <Select  style={{ width: "90%", margin: "auto", marginTop: "10px" }}
                                                placeholder = {"Otherwise, leave this blank and the import will create new requirement groups for each tab in the spreadsheet."}
                                                isClearable = {true}
                                                isSearchable = {true}
                                                options={props.reqGroupsRaw.map(g => { return { label: g._source.title, value: g } })}
                                                        onChange={(v)=>{setSequirementGroupSelected(v)}} />
                                                          {requirementGroupSelected != null && (<span style={{ fontSize: "1rem", textAlign: "left", }}>  As you are adding to a existing requirement group, the import will only include the first tab in your spreadsheet, and add that content to the group. Other tabs will be ignored. </span>)}
                                                
                                            </div>
                                            )
                                                }        
                                        </Row>
                                        <Row>

                                            <span style={{ fontSize: "1rem", textAlign: "left", }}>Step {props.dataType==="Requirements"?"4: ": props.dataType==="Design Outputs" ? "4: ":"3: "} Click "Import" to start the upload</span>
                                            <Button style={{ width: "50%", margin: "auto", marginTop: "10px", height: "2.5rem" }}
                                                className={styles.downloadButton} disabled={!readyToImport} onClick={async () => {

                                                    setError("")
                                                    try {
                                                        var reader = new FileReader();
                                                        reader.onload = function (e) {
                                                            setLoading(true)

                                                            var workbook = read(e.target.result);
                                                            switch (props.dataType) {
                                                                case "Design Outputs": 
                                                                    importRequirements(props, workbook, false,requirementGroupSelected)
                                                                    return
                                                                case "Requirements":
                                                                    importRequirements(props, workbook, false,requirementGroupSelected)
                                                                    return
                                                                case "Test Log":
                                                                    importTestLog(props, workbook)
                                                                    return
                                                                case "Anomalies":
                                                                    importAnoms(props, workbook)
                                                                    return
                                                                case "Risk":
                                                                    importRequirements(props, workbook, true)
                                                                    return
                                                                default:
                                                                    return
                                                            }

                                                        };
                                                        reader.readAsArrayBuffer(file);

                                                    } catch (error) {
                                                        console.log(error)

                                                        setLoading(false)
                                                        setError("Something went wrong with the upload. Check that you've filled in the template correctly")
                                                    }
                                                }
                                                }>Import</Button>
                                        </Row>
                                        <Row>
                                            <span style={{ color: "red", marginTop: "10px", textAlign: "center" }}>{error}</span>
                                        </Row>



                                    </CardBody>
                                </Card>


                            </Row>



                        </div>
                        // </AvForm>
                    }
                ></TentoModal>)}


        </React.Fragment>
    );
};

export default ImportButton;
