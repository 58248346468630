
import * as constants from './constants'

import { serverUpdate } from './actions'

import { eventChannel } from 'redux-saga';

import io from "socket.io-client"; // this creates a socket client ready to transmit data - NT
import { Redirect } from "react-router";

// wrapping functions for socket events (connect, disconnect, reconnect)

import { store } from '../store'
import { all, take, takeEvery, call, put, fork } from 'redux-saga/effects';

//const socketServerURL = "http://192.168.1.203:5000/"
const socketServerURL = process.env.REACT_APP_STAGE == 'stage'
  ? "https://tentodevsockets.azurewebsites.net/"
  : process.env.REACT_APP_STAGE == 'production' ? "https://tentosockets.azurewebsites.net/" : "http://localhost:5000/";

//const socketServerURL = "https://tentoplusfrontendserver.azurewebsites.net/"
export const socket = io(socketServerURL, {
  transports: ["websocket", "polling"]
}); // starts the client to listen this address - NT


export const connection = () => {

  // console.log('connecting to server');
  return new Promise((resolve, reject) => {
    socket.on('connect', () => {
      resolve(socket);
      const payload = { server: "ok" };
      store.dispatch({ type: "SERVER_CONNECTION_UPDATE", payload })
    });
    socket.on('connect_error', rsp => {
      const payload = { server: "CONNECTION_SERVER_ERROR" };
      store.dispatch({ type: "SERVER_CONNECTION_UPDATE", payload })
      return <Redirect to={{ pathname: '/error' }} />
    });
    socket.on('pong', function (data) {
      //console.log('Received Pong: ', data);
    });

    socket.on('ping', () => {
      //   console.log("sending ping")
    });

  });

};

export const disconnect = (socket) => {

  return new Promise((resolve) => {
    socket.on('disconnect', () => {
      resolve(socket);
    });
  });
};



export const reconnect = (socket) => {

  return new Promise((resolve) => {
    socket.on('reconnect', () => {
      resolve(socket);
    });
  });
};



function* read(socket, room) {
  // This will call the subscribe function to a channel.
  const channel = yield call(subscribe, socket, room);
  while (true) {
    let action = yield take(channel);
    yield put(action);
    //yield call(startConnection);
  }
}

export function* subscribe(socket, room) {
  return new eventChannel(emit => {
    socket.on(room, rsp => {
      emit(serverUpdate(rsp));
    })
    return () => { }
  })
}



/* 
------------------
WATCHERS
------------------

*/
function* watcherForUpdateToTeam(socket) {
  yield takeEvery(constants.UPDATE_TO_TEAM, (action) => { socket.send(action) });
}
function* watcherForJoinUserRoom(socket) {
  yield takeEvery(constants.JOIN_USER_ROOM, (action) => { socket.send(action) });
}
function* watcherForGetGroups(socket) {
  yield takeEvery("GET_GROUPS", (action) => { socket.send(action) });
}
//PROJECTS

function* watcherForProjectCreates(socket) {
  yield takeEvery(constants.CREATE_PROJECT, (action) => { socket.send(action) });
}

function* watcherForProjectDelete(socket) {
  yield takeEvery(constants.DELETE_PROJECT, (action) => { socket.send(action) });
}


function* watcherForProjectUpdate(socket) {
  yield takeEvery(constants.UPDATE_PROJECT, (action) => { socket.send(action) });
}

//BOARDS

function* watcherForBoardCreates(socket) {
  yield takeEvery(constants.CREATE_BOARD, (action) => { socket.send(action) });
}

function* watcherForBoardDelete(socket) {
  yield takeEvery(constants.DELETE_BOARD, (action) => { socket.send(action) });
}


function* watcherForBoardUpdate(socket) {
  yield takeEvery(constants.UPDATE_BOARD, (action) => { socket.send(action) });
}

function* watcherForCSVDownload(socket) {
  yield takeEvery(constants.CSV_DOWLOAD, (action) => { socket.send(action) });
}
//EVIDENCE

function* watcherForEvidenceUpdate(socket) {
  yield takeEvery(constants.UPDATE_EVIDENCE, (action) => { socket.send(action) });
}
function* watcherForCreateEvidenceSection(socket) {
  yield takeEvery(constants.CREATE_EVIDENCE_SECTION, (action) => { socket.send(action) });
}
function* watcherForDeleteEvidenceSection(socket) {
  yield takeEvery(constants.DELETE_EVIDENCE_SECTION, (action) => { socket.send(action) });
}
function* watcherForGetEvidence(socket) {
  yield takeEvery(constants.GET_EVIDENCE, (action) => { socket.emit('section', action) });
}
// COMMENTS
function* watcherForLeaveComment(socket) {
  yield takeEvery(constants.LEAVE_COMMENT, (action) => { socket.send(action) });
}
function* watcherForGetComments(socket) {
  yield takeEvery(constants.GET_COMMENTS, (action) => { socket.send(action) });
}
function* watcherForGetNotifs(socket) {
  yield takeEvery(constants.GET_NOTIFS, (action) => { socket.send(action) });
}
function* watcherForGetTour(socket) {
  yield takeEvery(constants.GET_TOUR, (action) => {
    // console.log("GET TOUR")

    socket.send(action)
  });
}

function* watcherForPutTour(socket) {
  yield takeEvery(constants.PUT_TOUR, (action) => {
    // console.log("PUT TOUR",action)

    socket.send(action)
  });
}
function* watcherForClearNotifs(socket) {
  yield takeEvery(constants.CLEAR_NOTIFS, (action) => { socket.send(action) });
}
function* watcherForGetNews(socket) {
  yield takeEvery(constants.GET_NEWS, (action) => { socket.send(action) });
}
//DEVICES
function* watcherForDeviceImpactingUserRemoval(socket) {
  yield takeEvery(constants.DEVICE_IMPACTING_USER_REMOVAL, (action) => { socket.send(action) });
}
function* watcherForGetTeamDevices(socket) {
  yield takeEvery(constants.GET_TEAM_DEVICES, (action) => { socket.send(action) });
}
function* watcherForDeviceChange(socket) {
  yield takeEvery(constants.DEVICE_CHANGE, (action) => { socket.send(action) });
}
function* watcherForRemoveTeam(socket) {
  yield takeEvery(constants.REMOVE_TEAM, (action) => { socket.send(action) });
}
function* watcherForGetUserTeamDevices(socket) {
  yield takeEvery(constants.GET_USER_TEAM_DEVICES, (action) => { socket.send(action) });
}
function* watcherForDeviceCreates(socket) {
  yield takeEvery(constants.CREATE_DEVICE, (action) => { socket.send(action) });
}

function* watcherForDeviceDelete(socket) {
  yield takeEvery(constants.DELETE_DEVICE, (action) => { socket.send(action) });
}
function* watcherForCreateGroup(socket) {
  yield takeEvery(constants.CREATE_GROUP, (action) => { socket.send(action) });
}
function* watcherForCreateReport(socket) {
  yield takeEvery(constants.CREATE_REPORT, (action) => { socket.send(action) });
}
function* watcherForUpdateReport(socket) {
  yield takeEvery(constants.UPDATE_REPORT, (action) => { socket.send(action) });
}
function* watcherForDeleteReport(socket) {
  yield takeEvery(constants.DELETE_REPORT, (action) => { socket.send(action) });
}
function* watcherForUpdateGroup(socket) {
  yield takeEvery(constants.UPDATE_GROUP, (action) => { socket.send(action) });
}
function* watcherForGetDeviceGroups(socket) {
  yield takeEvery(constants.GET_DEVICE_GROUPS, (action) => { socket.send(action) });
}
function* watcherForDeviceUpdate(socket) {
  yield takeEvery(constants.UPDATE_DEVICE, (action) => { socket.send(action) });
}
function* watcherForAnalyseForProperties(socket) {
  yield takeEvery(constants.POPULATE_PROPERTIES, (action) => { socket.emit('section', action) });
}
function* watcherForUpdateProperties(socket) {
  yield takeEvery(constants.UPDATE_PROPERTIES, (action) => { socket.emit('section', action) });
}

function* watcherForGetAnalysis(socket) {
  yield takeEvery(constants.GET_ANALYSIS, (action) => { socket.emit('section', action) });
}
function* watcherForGetAnalysisOptions(socket) {
  yield takeEvery(constants.GET_ANALYSIS_OPTIONS, (action) => { socket.send(action) });

} function* watcherForGetPropertiesMaster(socket) {
  yield takeEvery(constants.GET_PROPERTIES_MASTER, (action) => { socket.emit('section', action) });
}
function* watcherForGetProperties(socket) {
  yield takeEvery(constants.GET_PROPERTIES, (action) => { socket.emit('section', action) });
}
function* watcherForSearchSnomed(socket) {
  yield takeEvery(constants.SEARCH_SNOMED, (action) => { socket.emit('section', action) });
}

function* watcherForGetSection(socket) {
  yield takeEvery(constants.GET_SECTION, (action) => { socket.emit('section', action) });
}
function* watcherForUpdateSection(socket) {
  yield takeEvery(constants.UPDATE_SECTION, (action) => { socket.emit('section', action) });
}

function* watcherForPopulateSection(socket) {
  yield takeEvery(constants.POPULATE_SECTION, (action) => { socket.emit('section', action) });
}

function* watcherForGetCurrentSection(socket) {
  yield takeEvery(constants.GET_CURRENT_SECTION, (action) => { socket.emit('section', action) });
}

function* watcherForUpdateCurrentSection(socket) {
  yield takeEvery(constants.UPDATE_CURRENT_SECTION, (action) => { socket.emit('section', action) });
}
// TILES

function* watcherForCreates(socket) {
  yield takeEvery(constants.CREATE_TILE, (action) => { socket.send(action) });
}

function* watcherForDeletes(socket) {
  yield takeEvery(constants.DELETE_TILE, (action) => { socket.send(action) });
}

function* watcherForUpdate(socket) {
  yield takeEvery(constants.UPDATE_TILE, (action) => { socket.send(action) });
}
// TILES

function* watcherForCreateReq(socket) {
  yield takeEvery(constants.CREATE_REQ, (action) => { socket.send(action) });
}

function* watcherForDeleteReq(socket) {
  yield takeEvery(constants.DELETE_REQ, (action) => { socket.send(action) });
}
function* watcherForReorderReqs(socket) {
  yield takeEvery(constants.REORDER_REQS, (action) => { socket.send(action) });
}

function* watcherForReorderReqsGrps(socket) {
  yield takeEvery(constants.REORDER_REQ_GRPS, (action) => { socket.send(action) });
}
function* watcherForUpdateTiles(socket) {
  yield takeEvery(constants.UPDATE_TILES, (action) => { socket.send(action) });
}
function* watcherForUpdateReq(socket) {
  yield takeEvery(constants.UPDATE_REQ, (action) => { socket.send(action) });
}
// TESTLOGS

function* watcherForTestlogCreates(socket) {
  yield takeEvery(constants.CREATE_TESTLOG, (action) => { socket.send(action) });
}

function* watcherForTestlogDeletes(socket) {
  yield takeEvery(constants.DELETE_TESTLOG, (action) => { socket.send(action) });
}

function* watcherForTestlogUpdate(socket) {
  yield takeEvery(constants.UPDATE_TESTLOG, (action) => { socket.send(action) });
}

// RECORDS

function* watcherForRecordCreates(socket) {
  yield takeEvery(constants.CREATE_RECORD, (action) => { socket.send(action) });
}

function* watcherForRecordDeletes(socket) {
  yield takeEvery(constants.DELETE_RECORD, (action) => { socket.send(action) });
}

function* watcherForRecordUpdate(socket) {
  yield takeEvery(constants.UPDATE_RECORD, (action) => { socket.send(action) });
}
function* watcherForTaskUpdate(socket) {
  yield takeEvery(constants.UPDATE_TASK, (action) => { socket.send(action) });
}

// PROTOCOLS

function* watcherForProtocolCreates(socket) {
  yield takeEvery(constants.CREATE_PROTOCOL, (action) => { socket.send(action) });
}

function* watcherForProtocolCreateCopy(socket) {
  yield takeEvery(constants.CREATE_PROTOCOL_COPY, (action) => { socket.send(action) });
}

function* watcherForProtocolUpdate(socket) {
  yield takeEvery(constants.UPDATE_PROTOCOL, (action) => { socket.send(action) });
}

function* watcherForProtocolDelete(socket) {
  yield takeEvery(constants.DELETE_PROTOCOL, (action) => { socket.send(action) });
}

//RUNS

function* watcherForRunCreates(socket) {
  yield takeEvery(constants.CREATE_RUN, (action) => { socket.send(action) });
}

function* watcherForRunUpdate(socket) {
  yield takeEvery(constants.UPDATE_RUN, (action) => { socket.send(action) });
}

function* watcherForRunDelete(socket) {
  yield takeEvery(constants.DELETE_RUN, (action) => { socket.send(action) });
}

//ANOMS

function* watcherForAnomCreates(socket) {
  yield takeEvery(constants.CREATE_ANOM, (action) => { socket.send(action) });
}

function* watcherForAnomUpdate(socket) {
  yield takeEvery(constants.UPDATE_ANOM, (action) => { socket.send(action) });
}

function* watcherForAnomDelete(socket) {
  yield takeEvery(constants.DELETE_ANOM, (action) => { socket.send(action) });
}

//TAGS

function* watcherForTagCreates(socket) {
  yield takeEvery(constants.CREATE_TAG, (action) => { socket.send(action) });
}

function* watcherForTagUpdate(socket) {
  yield takeEvery(constants.UPDATE_TAG, (action) => { socket.send(action) });
}

function* watcherForTagDelete(socket) {
  yield takeEvery(constants.DELETE_TAG, (action) => { socket.send(action) });
}

//BOARDS

function* watcherForSelectBoard(socket) {
  yield takeEvery(constants.SELECT_BOARD, (action) => { socket.emit('join', action) });
}

function* watcherForSelectDevice(socket) {
  yield takeEvery(constants.SELECT_DEVICE, (action) => { socket.emit('join', action) });
}

function* watcherForCreateConfig(socket) {
  try {
    yield takeEvery(constants.CREATE_CONFIG, (action) => {

      socket.send(action)
      // saga-body

    });
  } catch (e) {
    console.log("watcherForCreateConfig", e);
  }
}

function* watcherForDeleteConfig(socket) {
  try {
    yield takeEvery(constants.DELETE_CONFIG, (action) => {

      socket.send(action)
      // saga-body

    });
  } catch (e) {
    console.log("watcherForDeleteConfig", e);
  }
}
function* watcherForUpdateConfig(socket) {
  try {
    yield takeEvery(constants.UPDATE_CONFIG, (action) => {

      socket.send(action)
      // saga-body

    });
  } catch (e) {
    console.log("watcherForUpdateConfig", e);
  }
}
function* watcherForGetBoard(socket) {
  yield takeEvery(constants.GET_BOARDS, (action) => { socket.emit('initial', action) });
}

function* watcherForGetAISteps(socket) {
  yield takeEvery(constants.GET_AI_STEPS, (action) => { socket.send(action) });
}

// RISKS

function* watcherForRiskCategoryCreates(socket) {
  yield takeEvery(constants.CREATE_RISK_CATEGORY, (action) => { socket.send(action) });
}

function* watcherForRiskCategoryUpdate(socket) {
  yield takeEvery(constants.UPDATE_RISK_CATEGORY, (action) => { socket.send(action) });
}

function* watcherForRiskCategoryDelete(socket) {
  yield takeEvery(constants.DELETE_RISK_CATEGORY, (action) => { socket.send(action) });
}
function* watcherForRiskDetailsCreates(socket) {
  yield takeEvery(constants.CREATE_RISK_DETAILS, (action) => { socket.send(action) });
}

function* watcherForRiskDetailUpdate(socket) {
  yield takeEvery(constants.UPDATE_RISK_DETAILS, (action) => { socket.send(action) });
}

function* watcherForRiskDetailDelete(socket) {
  yield takeEvery(constants.DELETE_RISK_DETAILS, (action) => { socket.send(action) });
}

function* watcherForRiskDetailGet(socket) {
  yield takeEvery(constants.GET_RISK_DETAILS, (action) => { socket.send(action) });
}


function* watcherForRiskSeverityCreates(socket) {
  yield takeEvery(constants.CREATE_RISK_SEVERITY, (action) => { socket.send(action) });
}

function* watcherForRiskSeverityUpdate(socket) {
  yield takeEvery(constants.UPDATE_RISK_SEVERITY, (action) => { socket.send(action) });
}

function* watcherForRiskSeverityDelete(socket) {
  yield takeEvery(constants.DELETE_RISK_SEVERITY, (action) => { socket.send(action) });
}


function* watcherForRiskProbabilityCreates(socket) {
  yield takeEvery(constants.CREATE_RISK_PROBABILITY, (action) => { socket.send(action) });
}

function* watcherForRiskProbabilityUpdate(socket) {
  yield takeEvery(constants.UPDATE_RISK_PROBABILITY, (action) => { socket.send(action) });
}

function* watcherForRiskProbabilityDelete(socket) {
  yield takeEvery(constants.DELETE_RISK_PROBABILITY, (action) => { socket.send(action) });
}


function* watcherForRiskMatrixCreates(socket) {
  yield takeEvery(constants.CREATE_RISK_MATRIX, (action) => { socket.send(action) });
}

function* watcherForRiskMatrixUpdate(socket) {
  yield takeEvery(constants.UPDATE_RISK_MATRIX, (action) => { socket.send(action) });
}

function* watcherForRiskMatrixDelete(socket) {
  yield takeEvery(constants.DELETE_RISK_MATRIX, (action) => { socket.send(action) });
}


function* watcherForProbabilityMatrixCreates(socket) {
  yield takeEvery(constants.CREATE_PROBABILITY_MATRIX, (action) => { socket.send(action) });
}
function* watcherForProbabilityMatrixCreatesCells(socket) {
  yield takeEvery(constants.CREATE_PROBABILITY_MATRIX_CELLS, (action) => { socket.send(action) });
}

function* watcherForProbabilityMatrixUpdate(socket) {
  yield takeEvery(constants.UPDATE_PROBABILITY_MATRIX, (action) => { socket.send(action) });
}

function* watcherForProbabilityMatrixDelete(socket) {
  yield takeEvery(constants.DELETE_PROBABILITY_MATRIX, (action) => { socket.send(action) });
}

function* apiSaga() {
  // yield take(GET_TODOS)
  const socket = yield call(connection);
  yield all([
    fork(read, socket, 'testPlan'),
    watcherForCreateConfig(socket),
    watcherForDeleteConfig(socket),
    watcherForUpdateConfig(socket),
    watcherForGetPropertiesMaster(socket),
    watcherForCreateGroup(socket),
    watcherForCreateReport(socket),
    watcherForUpdateReport(socket),
    watcherForDeleteReport(socket),
    watcherForUpdateToTeam(socket),
    watcherForGetDeviceGroups(socket),
    watcherForUpdateGroup(socket),
    watcherForGetTeamDevices(socket),
    watcherForGetUserTeamDevices(socket),
    watcherForRemoveTeam(socket),
    //REQS
    watcherForDeleteReq(socket),
    watcherForCreateReq(socket),
    watcherForUpdateReq(socket),
    watcherForReorderReqs(socket),
    //TILES
    watcherForDeletes(socket),
    watcherForCreates(socket),
    watcherForUpdate(socket),
    watcherForUpdateTiles(socket),
    //RUNS
    watcherForRunUpdate(socket),
    watcherForRunCreates(socket),
    watcherForRunDelete(socket),
    //RECORDS
    watcherForTestlogCreates(socket),
    watcherForTestlogUpdate(socket),
    watcherForTestlogDeletes(socket),
    //RECORDS
    watcherForRecordCreates(socket),
    watcherForRecordUpdate(socket),
    watcherForRecordDeletes(socket),
    watcherForTaskUpdate(socket),
    //PROTOCOL
    watcherForProtocolCreates(socket),
    watcherForProtocolCreateCopy(socket),
    watcherForProtocolDelete(socket),
    watcherForProtocolUpdate(socket),
    //BOARDS
    watcherForBoardCreates(socket),
    watcherForBoardDelete(socket),
    watcherForBoardUpdate(socket),
    watcherForSelectBoard(socket),
    watcherForGetBoard(socket),
    //ANOMS
    watcherForAnomDelete(socket),
    watcherForAnomUpdate(socket),
    watcherForAnomCreates(socket),
    //ANOMS
    watcherForTagDelete(socket),
    watcherForTagUpdate(socket),
    watcherForTagCreates(socket),
    watcherForReorderReqsGrps(socket),
    //PROJECTS
    watcherForProjectCreates(socket),
    watcherForProjectDelete(socket),
    watcherForProjectUpdate(socket),
    //DEVICES
    watcherForDeviceCreates(socket),
    watcherForDeviceDelete(socket),
    watcherForDeviceUpdate(socket),
    watcherForSelectDevice(socket),
    watcherForAnalyseForProperties(socket),
    watcherForGetProperties(socket),
    watcherForUpdateProperties(socket),
    watcherForDeviceChange(socket),
    watcherForGetAnalysis(socket),
    watcherForCSVDownload(socket),
    //EVIDENCE
    watcherForEvidenceUpdate(socket),
    watcherForCreateEvidenceSection(socket),
    watcherForDeleteEvidenceSection(socket),
    watcherForGetEvidence(socket),
    watcherForSearchSnomed(socket),
    watcherForGetSection(socket),
    watcherForUpdateSection(socket),
    watcherForPopulateSection(socket),
    watcherForGetCurrentSection(socket),
    watcherForGetAnalysisOptions(socket),
    watcherForUpdateCurrentSection(socket),
    watcherForGetGroups(socket),
    // comments
    watcherForLeaveComment(socket),
    watcherForGetComments(socket),
    watcherForGetNotifs(socket),
    watcherForClearNotifs(socket),
    watcherForGetNews(socket),
    watcherForJoinUserRoom(socket),
    //tour
    watcherForGetTour(socket),
    watcherForPutTour(socket),
    watcherForDeviceImpactingUserRemoval(socket),
    watcherForGetAISteps(socket),
    //Risks
    watcherForRiskCategoryCreates(socket),
    watcherForRiskCategoryUpdate(socket),
    watcherForRiskCategoryDelete(socket),
    watcherForRiskDetailsCreates(socket),
    watcherForRiskDetailUpdate(socket),
    watcherForRiskDetailDelete(socket),
    watcherForRiskDetailGet(socket),
    watcherForRiskSeverityCreates(socket),
    watcherForRiskSeverityUpdate(socket),
    watcherForRiskSeverityDelete(socket),
    watcherForRiskProbabilityCreates(socket),
    watcherForRiskProbabilityUpdate(socket),
    watcherForRiskProbabilityDelete(socket),
    watcherForRiskMatrixCreates(socket),
    watcherForRiskMatrixUpdate(socket),
    watcherForRiskMatrixDelete(socket),
    watcherForProbabilityMatrixCreates(socket),
    watcherForProbabilityMatrixCreatesCells(socket),
    watcherForProbabilityMatrixUpdate(socket),
    watcherForProbabilityMatrixDelete(socket),
  ])
  //yield fork(write, socket)
}



export default apiSaga;


