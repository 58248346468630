export const JOIN_USER_ROOM = 'JOIN_USER_ROOM';

export const SELECT_BOARD = 'SELECT_BOARD';
export const CREATE_BOARD = 'CREATE_BOARD';
export const UPDATE_BOARD ='UPDATE_BOARD';
export const DELETE_BOARD ='DELETE_BOARD';
export const GET_BOARDS ='GET_BOARDS';
export const SELECT_DEVICE = 'SELECT_DEVICE';
export const SELECT_CONFIG = 'SELECT_CONFIG';
export const CONFIG_FOUND = 'CONFIG_FOUND';
export const CREATE_DEVICE = 'CREATE_DEVICE';
export const UPDATE_DEVICE ='UPDATE_DEVICE';

export const CREATE_CONFIG = 'CREATE_CONFIG';
export const DELETE_CONFIG = 'DELETE_CONFIG';

export const UPDATE_CONFIG ='UPDATE_CONFIG';

export const DEVICE_IMPACTING_USER_REMOVAL ="DEVICE_IMPACTING_USER_REMOVAL"
export const DELETE_DEVICE ='DELETE_DEVICE';
export const GET_DEVICES ='GET_DEVICES';
export const GET_USER_TEAM_DEVICES ='GET_USER_TEAM_DEVICES';

export const DELETE_EVIDENCE_SECTION ='DELETE_EVIDENCE_SECTION';
export const CREATE_EVIDENCE_SECTION ='CREATE_EVIDENCE_SECTION';

export const UPDATE_TO_TEAM ='UPDATE_TO_TEAM';

export const GET_TOUR ='GET_TOUR';
export const PUT_TOUR ='PUT_TOUR';

export const GET_DEVICE_GROUPS ='GET_DEVICE_GROUPS';
export const GET_TEAM_DEVICES ='GET_TEAM_DEVICES';
export const REMOVE_TEAM ='REMOVE_TEAM';
export const LEAVE_COMMENT ='LEAVE_COMMENT';
export const GET_COMMENTS ='GET_COMMENTS';
export const GET_NOTIFS ='GET_NOTIFS';
export const CLEAR_NOTIFS ='CLEAR_NOTIFS';
export const GET_NEWS ='GET_NEWS';
export const CREATE_GROUP ='CREATE_GROUP';
export const CREATE_REPORT ='CREATE_REPORT';
export const UPDATE_REPORT ='UPDATE_REPORT';
export const DELETE_REPORT='DELETE_REPORT';
export const UPDATE_GROUP ='UPDATE_GROUP';
export const GET_SECTION ='GET_SECTION';
export const UPDATE_SECTION ='UPDATE_SECTION';
export const POPULATE_SECTION ='POPULATE_SECTION';
export const GET_CURRENT_SECTION ='GET_CURRENT_SECTION'
export const UPDATE_CURRENT_SECTION ='UPDATE_CURRENT_SECTION'
export const DEVICE_CHANGE ='DEVICE_CHANGE'
export const GET_PROPERTIES  ='GET_PROPERTIES';
export const POPULATE_PROPERTIES = 'POPULATE_PROPERTIES';
export const UPDATE_PROPERTIES = 'UPDATE_PROPERTIES';
export const GET_PROPERTIES_MASTER  ='GET_PROPERTIES_MASTER';
export const GET_ANALYSIS  ='GET_ANALYSIS';
export const GET_ANALYSIS_OPTIONS  ='GET_ANALYSIS_OPTIONS';
export const POPULATE_ANALYSIS = 'POPULATE_ANALYSIS';
export const UPDATE_ANALYSIS = 'UPDATE_ANALYSIS';
export const SEARCH_SNOMED = 'SEARCH_SNOMED';

export const GET_EVIDENCE ='GET_EVIDENCE';
export const UPDATE_EVIDENCE = 'UPDATE_EVIDENCE';
export const POPULATE_EVIDENCE = 'POPULATE_EVIDENCE';
export const GET_AI_STEPS  ='GET_AI_STEPS';
export const SELECT_PROJECT = 'SELECT_PROJECT';

export const CREATE_PROJECT = 'CREATE_PROJECT';
export const UPDATE_PROJECT ='UPDATE_PROJECT';
export const DELETE_PROJECT ='DELETE_PROJECT';

export const SELECT_REQ = 'SELECT_REQ';
export const DELETE_REQ = 'DELETE_REQ';
export const CREATE_REQ = 'CREATE_REQ';
export const UPDATE_REQ = 'UPDATE_REQ';
export const REORDER_REQ_GRPS = 'REORDER_REQ_GRPS';
export const REORDER_REQS = 'REORDER_REQS';
export const UPDATE_TILES = 'UPDATE_TILES';

export const SELECT_TILE = 'SELECT_TILE';
export const DELETE_TILE = 'DELETE_TILE';
export const CREATE_TILE = 'CREATE_TILE';
export const UPDATE_TILE = 'UPDATE_TILE';
export const CLEAR_BOARDS = 'CLEAR_BOARDS';
export const SELECT_RUN = 'SELECT_RUN';
export const DELETE_RUN = 'DELETE_RUN';
export const CREATE_RUN = 'CREATE_RUN';
export const UPDATE_RUN = 'UPDATE_RUN';
export const CSV_DOWLOAD = 'CSV_DOWLOAD'
export const SELECT_RECORD = 'SELECT_RECORD';
export const DELETE_RECORD = 'DELETE_RECORD';
export const CREATE_RECORD = 'CREATE_RECORD';
export const UPDATE_RECORD = 'UPDATE_RECORD';
export const UPDATE_TASK = 'UPDATE_TASK';

export const CREATE_RISK_CATEGORY = 'CREATE_RISK_CATEGORY'
export const UPDATE_RISK_CATEGORY = 'UPDATE_RISK_CATEGORY'
export const DELETE_RISK_CATEGORY = 'DELETE_RISK_CATEGORY'
export const CREATE_RISK_DETAILS = 'CREATE_RISK_DETAILS'
export const UPDATE_RISK_DETAILS = 'UPDATE_RISK_DETAILS'
export const DELETE_RISK_DETAILS = 'DELETE_RISK_DETAILS'
export const GET_RISK_DETAILS = 'GET_RISK_DETAILS'
export const CREATE_RISK_SEVERITY = 'CREATE_RISK_SEVERITY'
export const UPDATE_RISK_SEVERITY = 'UPDATE_RISK_SEVERITY'
export const DELETE_RISK_SEVERITY = 'DELETE_RISK_SEVERITY'
export const CREATE_RISK_PROBABILITY = 'CREATE_RISK_PROBABILITY'
export const UPDATE_RISK_PROBABILITY = 'UPDATE_RISK_PROBABILITY'
export const DELETE_RISK_PROBABILITY = 'DELETE_RISK_PROBABILITY'
export const CREATE_RISK_MATRIX = 'CREATE_RISK_MATRIX'
export const UPDATE_RISK_MATRIX = 'UPDATE_RISK_MATRIX'
export const DELETE_RISK_MATRIX = 'DELETE_RISK_MATRIX'
export const CREATE_PROBABILITY_MATRIX = 'CREATE_PROBABILITY_MATRIX'
export const CREATE_PROBABILITY_MATRIX_CELLS = 'CREATE_PROBABILITY_MATRIX_CELLS'
export const UPDATE_PROBABILITY_MATRIX = 'UPDATE_PROBABILITY_MATRIX'
export const DELETE_PROBABILITY_MATRIX = 'DELETE_PROBABILITY_MATRIX'

export const SELECT_TESTLOG = 'SELECT_TESTLOG';
export const DELETE_TESTLOG = 'DELETE_TESTLOG';
export const CREATE_TESTLOG = 'CREATE_TESTLOG';
export const UPDATE_TESTLOG = 'UPDATE_TESTLOG';

export const SELECT_PROTOCOL = 'SELECT_PROTOCOL';
export const DELETE_PROTOCOL = 'DELETE_PROTOCOL';
export const CREATE_PROTOCOL = 'CREATE_PROTOCOL';
export const CREATE_PROTOCOL_COPY = 'CREATE_PROTOCOL_COPY';

export const UPDATE_PROTOCOL = 'UPDATE_PROTOCOL';

export const SELECT_ANOM = 'SELECT_ANOM';
export const DELETE_ANOM = 'DELETE_ANOM';
export const CREATE_ANOM = 'CREATE_ANOM';
export const UPDATE_ANOM = 'UPDATE_ANOM';

export const DELETE_TAG = 'DELETE_TAG';
export const CREATE_TAG = 'CREATE_TAG';
export const UPDATE_TAG = 'UPDATE_TAG';

export const CHANGE_BOARD = 'CHANGE_BOARD';

export const REORDER_TILES = 'REORDER_TILES';

export const SET_OBJECT = 'SET_OBJECT';

export const SERVER_UPDATE = 'SERVER_UPDATE';
export const SERVER_CONNECTION_UPDATE = 'SERVER_CONNECTION_UPDATE';

export const APPLY_DOCUMENT_CHANGES_LOCALLY = true;
