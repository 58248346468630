import React, { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { Button, Col, Container, Form, FormGroup, Input, Modal, ModalBody, ModalHeader, Row, Table, UncontrolledTooltip } from "reactstrap";
import BootstrapTable from 'react-bootstrap-table-next';
import cellEditFactory, { Type } from 'react-bootstrap-table2-editor';
import CreatableSelect from 'react-select/creatable';
import Select from 'react-select';
import TentoModal from "../../../../components/TentoModal";
import { Redirect, useHistory } from 'react-router-dom';

import Tour from 'reactour'




import styles from './style.module.css'
import tables from '../../../../components/Styles/table.module.css';
import modals from '../../../../components/Styles/modal.module.css';
import { createRiskDetail, createRiskCategory, deleteRiskDetail, updateDevice, updateRecord, updateReq, updateRiskDetail, updateRiskCategory, deleteRiskCategory, selectDevice, getTour, putTour } from "../../../../redux/actions";
import { useAccount, useMsal } from "@azure/msal-react";
import { deleteModal } from "../../../../components/Modals";
function HeaderTooltip(props) {
    const id = props.name + "Tooltip"
    console.log("ID", props.name, id)
    return (
        <span>{props.name}
            <i className="uil uil-info-circle" id={id} ></i>
            <UncontrolledTooltip style={{ zIndex: 100 }}
                target={id}
            >
                {props.text}
            </UncontrolledTooltip></span>
    )
}
const editFormatter = (value) => {
    const { a, props, setToDelete,toggleWarningModal,hasRiskSub } = value;
    console.log("EDIT FORMATTER:", value)
    return (
        <div>


        { <i style={{ fontSize: "20px" }} onClick={() => {  if(hasRiskSub){setToDelete(a);toggleWarningModal()} }} className="mdi mdi-delete-forever"></i>}
        </div>
    )
}

const categorySelectFormatter = (value) => {
    const { r, props, setSelected } = value;
    console.log("Select FORMATTER:", value)
    return (


        <Input type="checkbox" onClick={() => { setSelected(r._id) }} />


    )
}
const categoryEditFormatter = (value) => {
    const { r, props } = value;
    console.log("EDIT FORMATTER:", value)
    return (
        <i className="mdi mdi-delete-forever mr-1" style={{ fontSize: "20px" }} onClick={() => { props.dispatch(deleteRiskCategory(r._id, r._source)) }}></i>
        // <Button  color="danger" className="btn-rounded mb-3">Delete</Button>

    )
}
const TableSelect = (props) => {
    const [value, setValue] = useState()

    console.log("SELECT", props)

    const update = (val) => {
        console.log("update", value)
        props.onUpdate(value || props.defaultValue);
    }

    return (
        <select
            autoFocus
            defaultValue={props.defaultValue || ""}
            onBlur={update}
            onChange={e => { console.log("ON CHANGE", e.target.value); setValue(e.target.value) }}
            className={styles.matrixDropdown}
        >
            <option style={{ display: "none" }}></option>
            {props.options.map((option) => (
                <option value={option.value}>{option.label}</option>
            ))}
        </select>
    )
}

const TableCreateHazard = (props) => {
    const [value, setValue] = useState()
    const [isOpen, setIsOpen] = useState(false)
    const [isEditing, setIsEditing] = useState(false)
    const [toEdit, setToEdit] = useState([])
    console.log("TABLE", props);

    const getValue = () => {
        console.log("UPDATING TABLE", value);
        return value
    }

    const onUpdated = (val) => {
        console.log("TABLE CHANGE", val);
        // setValue(val.value)

        if (!props.device._source.hazards) {
            props.device._source.hazards = []
        }

        if (val.__isNew__) {
            props.device._source.hazards.push({
                label: val.label,
                value: val.value
            })
        }

        props.updateOptions(props.refreshOptions())
        props.onUpdate(val.value)
    }

    console.log("OUTPUT OPTIONS", props.options)

    return (
        <div>
            <div style={{ display: "grid", gridTemplateColumns: "auto 20px" }}>
                <p>{props.defaultValue}</p>
                <i
                    className="mdi mdi-pencil"
                    style={{ fontSize: "16px", cursor: "pointer" }}
                    onClick={() => {
                        setIsOpen(true)
                    }}
                />
            </div>
            <TentoModal
                bodyClassname={modals.modalBody_noScroll}
                isOpen={isOpen}
                toggle={() => {
                    setIsOpen(false)
                }}
                title="Select Hazard"
                size="md"
                body={
                    !isEditing ? (
                        <CreatableSelect
                            options={props.options}
                            onChange={onUpdated}
                            defaultValue={{ value: props.defaultValue, label: props.defaultValue }}
                        />
                    ) : (
                        <Table className={tables.table}>
                            <thead className={tables.tableHead}>
                                <tr>
                                    <th>Name</th>
                                    <th>Edit</th>
                                </tr>
                            </thead>
                            <tbody>
                                {props.options.map((option, index) => (
                                    <tr>
                                        <td className={tables.tableTD}>
                                            <Input
                                                defaultValue={option.label}
                                                value={option.label}
                                                type="text"
                                                onChange={(val) => {
                                                    props.device._source.hazards[index] = { label: val.target.value, value: val.target.value }
                                                    props.updateOptions(props.refreshOptions())
                                                }}
                                            />
                                        </td>
                                        <td className={tables.tableTD}>
                                            <Button
                                                color="danger"
                                                onClick={() => {
                                                    props.device._source.hazards.splice(index, 1)
                                                    props.updateOptions(props.refreshOptions())
                                                }}
                                            >
                                                Delete
                                            </Button>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                    )
                }
                seperateButtons={[
                    {
                        text: isEditing ? "Exit" : "Edit", className: modals.modalButtonSmall, onClick: () => {
                            setIsEditing(!isEditing);
                        }
                    },

                ]}
            />
        </div>
    )
}
const TableCreateCategory = (props) => {
    const [value, setValue] = useState()
    const [isOpen, setIsOpen] = useState(false)
    const [isEditing, setIsEditing] = useState(false)
    const [toEdit, setToEdit] = useState([])
    console.log("TABLE", props);

    const getValue = () => {
        console.log("UPDATING TABLE", value);
        return value
    }

    const onUpdated = (val) => {
        console.log("TABLE CHANGE", val);
        setValue(val.value)

        if (!props.device._source.riskCategories) {
            props.device._source.riskCategories = []
        }

        if (val.__isNew__) {
            props.device._source.riskCategories.push({
                label: val.label,
                value: val.value
            })
        }

        props.updateOptions(props.refreshOptions())
        props.onUpdate(val.value)
    }
    const [selected, setSelected] = useState(props.row.rawCategory)
    useEffect(() => {
        setTableData(props.riskCategories.map(r => { return { ...r._source, id: r._id, ref: r._source.ref, name: r._source.name, select: { r, props, selected, setSelected }, edit: { r, props }, } }));

        // for (let i = 0; i < tableData.length; i++) {
        //     handleRowChange("", "", tableData[i], "");
        // }
    }, [props.riskCategories])

    console.log("OUTPUT OPTIONS", selected)
    const [tableData, setTableData] = useState(props.riskCategories.map(r => { return { ...r._source, id: r._id, ref: r._source.ref, name: r._source.name, select: { r, props, selected, setSelected }, edit: { r, props }, } }))

    const [searchValue, setSearchValue] = useState("")
    return (
        <div>
            <div style={{ display: "grid", gridTemplateColumns: "auto 20px" }}>
                <p>{props.defaultValue}</p>
                <i
                    className="mdi mdi-pencil"
                    style={{ fontSize: "16px", cursor: "pointer" }}
                    onClick={() => {
                        console.log("CATEGORY PROPS", props)
                        setIsOpen(true)
                    }}
                />
            </div>
            <TentoModal
                bodyClassname={modals.modalBody_noScroll}
                isOpen={isOpen}
                toggle={() => {
                    setSearchValue("")
                    setIsOpen(false)
                }}
                title="Select Category"
                size="xl"
                body={
                    <div>
                        <div className={styles.tableControls}>
                            <Button onClick={() => {
                                const configID = window.sessionStorage.getItem("configID")

                                let newCategory = {
                                    // id: props.device._id + (data.length).toString(),
                                    ref: "RCAT" + props.riskCategories.length,
                                    name: "",
                                    idDevice: props.device._id,
                                    configID,
                                    edit: {},
                                };
                                props.dispatch(createRiskCategory(newCategory))
                            }} color="primary" className="btn-rounded mb-3">Add</Button>
                            <Input

                                value={searchValue}
                                onChange={(event) => { setSearchValue(event.target.value) }}
                                style={{ marginBottom: "20px", borderRadius: "100px", display: "inline" }} placeholder="Search..." />
                        </div>




                        <BootstrapTable
                            bootstrap4
                            classes={tables.table}
                            headerWrapperClasses={tables.tableHead}
                            bodyClasses={tables.tableTD}
                            keyField="id"
                            key={props.riskCategories}
                            data={!tableData ? [] : tableData.filter(r => r.ref.toLocaleLowerCase().includes(searchValue) || r.name.toLocaleLowerCase().includes(searchValue))}
                            selectRow={{
                                mode: 'radio',
                                clickToSelect: true,
                                clickToEdit: true,
                                bgColor: '#d4d4d4',
                                selectColumnStyle: { width: "40px", "min-width": "40px", },
                                selected: [selected],
                                onSelect: (row, isSelect, rowIndex, e) => {
                                    console.log("SELECTED", row, isSelect, rowIndex, e)
                                    setSearchValue("")

                                    setSelected(row.id)
                                    // props.onUpdate(row.id)
                                    // ...
                                }
                            }}
                            columns={
                                [
                                    //     {
                                    //     dataField: 'select',
                                    //     text: 'Select',
                                    //     sort: false,
                                    //     style: { width: "60px", "min-width": "60px" },
                                    //     formatter: categorySelectFormatter,
                                    //     editable: false,
                                    // },
                                    {
                                        dataField: "ref",
                                        text: "Ref",
                                        headerStyle: { width: "20%", "min-width": "60px", "max-width": "450px" },
                                        editable: props.hasRiskSub                                        ,
                                        sort: true,
                                    },
                                    {
                                        dataField: "name",
                                        text: "Name",
                                        style: { "min-width": "60px" },
                                        editable: props.hasRiskSub,
                                        sort: true,
                                    },
                                    {
                                        dataField: 'edit',
                                        text: 'Delete',
                                        sort: false,
                                        style: { width: "30px", "max-width": "30px", "minWidth": "30px" },
                                        formatter: categoryEditFormatter,
                                        editable: false,
                                    },
                                ]
                            }
                            cellEdit={cellEditFactory({
                                mode: 'click',
                                afterSaveCell: (oldVal, newVal, row, column) => {
                                    const newData = props.riskCategories.find((c) => c._id == row.id)
                                    const vals = ["edit", "id", "select"]
                                    const newRow = { ...newData._source, ...row, [column.dataField]: newVal, edit: {} }
                                    vals.forEach((e) => {
                                        delete newRow[e]

                                    })

                                    props.dispatch(updateRiskCategory(row.id, newRow));
                                    // setTableData( [...tableData.filter(t => t.id !== row.id),newRow])
                                    // handleRiskCategoryChange(oldVal, newVal, row, column) 
                                },
                                blurToSave: true,
                                autoSelectText: false,
                            })}
                            defaultSorted={[{
                                dataField: 'ref',
                                order: 'asc'
                            }]}
                        />

                    </div>
                }
                seperateButtons={[
                    {
                        text: "Save", className: modals.modalButtonSmall, color: "primary", onClick: () => {
                            props.onUpdate(selected)
                        }
                    },

                ]}
            />
        </div>
    )
}
const TableCreateHarm = (props) => {
    const [value, setValue] = useState()
    const [isOpen, setIsOpen] = useState(false)
    const [isEditing, setIsEditing] = useState(false)
    const [toEdit, setToEdit] = useState([])
    console.log("TABLE", props);

    const getValue = () => {
        console.log("UPDATING TABLE", value);
        return value
    }

    const onUpdated = (val) => {
        console.log("TABLE CHANGE", val);
        setValue(val.value)

        if (!props.device._source.harms) {
            props.device._source.harms = []
        }

        if (val.__isNew__) {
            props.device._source.harms.push({
                label: val.label,
                value: val.value
            })
        }

        props.updateOptions(props.refreshOptions())
        props.onUpdate(val.value)
    }

    console.log("OPTIONS", props.options)

    return (
        <div>
            <div style={{ display: "grid", gridTemplateColumns: "auto 20px" }}>
                <p>{props.defaultValue}</p>
                <i
                    className="mdi mdi-pencil"
                    style={{ fontSize: "16px", cursor: "pointer" }}
                    onClick={() => {
                        setIsOpen(true)
                    }}
                />
            </div>
            <TentoModal
                bodyClassname={modals.modalBody_noScroll}
                isOpen={isOpen}
                toggle={() => {
                    setIsOpen(false)
                }}
                title="Select Harm"
                size="md"
                body={
                    !isEditing ? (
                        <CreatableSelect
                            options={props.options}
                            onChange={onUpdated}
                            defaultValue={{ value: props.defaultValue, label: props.defaultValue }}
                        />
                    ) : (
                        <Table className={tables.table}>
                            <thead className={tables.tableHead}>
                                <tr>
                                    <th>Name</th>
                                    <th>Edit</th>
                                </tr>
                            </thead>
                            <tbody>
                                {props.options.map((option, index) => (
                                    <tr>
                                        <td className={tables.tableTD}>
                                            <Input
                                                defaultValue={option.label}
                                                value={option.label}
                                                type="text"
                                                onChange={(val) => {
                                                    props.device._source.harms[index] = { label: val.target.value, value: val.target.value }
                                                    props.updateOptions(props.refreshOptions())
                                                }}
                                            />
                                        </td>
                                        <td className={tables.tableTD}>

                                            <i onClick={() => {
                                                props.device._source.harms.splice(index, 1)
                                                props.updateOptions(props.refreshOptions())
                                            }} className="mdi mdi-delete-forever"></i>

                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                    )
                }
                seperateButtons={[
                    {
                        text: isEditing ? "Save Edit" : "Edit", className: modals.modalButtonSmall, onClick: () => {
                            setIsEditing(!isEditing);
                        }
                    },

                ]}
            />
        </div>
    )
}

const TableRiskControl = (props) => {
    const [openState, setOpenState] = useState(false);
    const [warningOpen, setWarningOpen] = useState(false);
    const [toChange, setToChange] = useState([]);
    const [value, setValue] = useState({})
    // console.log("clicked here");
    // console.log(props);

    const onUpdated = (val) => {
        let res = []
        setValue(val);

        const reqsToSwitch = []
        if (val) {
            for (let i = 0; i < val.length; i++) {
                const req = props.reqs.find(e => e._id == val[i].value)
                if (req && req._source && !req._source.riskReq) {
                    console.log("NEW RISK CONTROL", req);
                    reqsToSwitch.push(req);
                }
            }


            console.log("TO SWITCH", reqsToSwitch)

            if (reqsToSwitch.length === 0) {
                for (let i = 0; i < val.length; i++) {
                    // res += val[i].label + " "
                    res.push(val[i].label)
                }

                props.onUpdate(res);
            } else {
                setToChange(reqsToSwitch)
                setWarningOpen(true);
            }
        }
    }

    return (
        <div>
            <div style={{ display: "grid", gridTemplateColumns: "auto 20px" }}>
                <div>
                    {Array.isArray(props.defaultValue) ? props.defaultValue.map((label, index) => <p>{label.substring(0, 20) + (label.length > 20 ? "..." : "")}</p>) : props.defaultValue}
                </div>
                <i
                    className="mdi mdi-pencil"
                    style={{ fontSize: "16px", cursor: "pointer", alignSelf: "center" }}
                    onClick={() => {
                        if (props.getTime() > 1) {
                            setOpenState(true) // get opened here
                        }
                    }}
                />
            </div>

            <RiskControlModal
                getTime={props.getTime}
                setTime={props.setTime}
                openState={openState}
                setOpenState={setOpenState}
                options={props.options}
                filter={props.filter}
                setFilter={props.setFilter}
                onUpdate={onUpdated}
                defaultValue={props.defaultValue}
                reqs={props.reqs}
            />

            <TentoModal
                isOpen={warningOpen}
                toggle={() => {
                    setWarningOpen(false)
                }}
                size="sm"
                title={"Choose Risk Control"}
                body={
                    <div>
                        <p>Some of the selected Requirements are not Risk Controls.</p>
                        <p>By clicking Confirm, you will be setting those Requirements to also be Risk Controls.</p>
                    </div>
                }
                seperateButtons={[
                    {
                        text: "Confirm", color: "danger", onClick: () => {

                            for (let i = 0; i < toChange.length; i++) {
                                toChange[i]._source.riskReq = true;

                                props.dispatch(updateReq(toChange[i]._id, toChange[i]._source))
                            }

                            let res = "";

                            for (let i = 0; i < value.length; i++) {
                                res += value[i].label + " "
                            }

                            props.onUpdate(res);

                            setWarningOpen(false)
                            setToChange([]);
                        }
                    },
                    {
                        text: "Cancel", onClick: () => {
                            setWarningOpen(false)
                            setOpenState(true)
                        }
                    }
                ]}
            />
        </div>
    )
}

const RiskMatrix = (props) => {
    const dispatch = useDispatch();
    const navigate = useHistory()
    const hasRiskSub = props.userModules.some( module => module.name.includes("Risk"))

    const steps = [
        {
            selector: '[data-tut="reactour__"]',
            content: () => (<div>Medical device standards require you to assess risks of using a device. This is done through what we call a <b>risk analysis matrix</b>. <br /><br />This table allows you to create risk items, attribute hazards and harms, score and evalute the risk, as well as define risk mitigiation. </div>),
            position: [1000, 30]
        },
        {
            selector: '[data-tut="reactour__table"]',
            content: () => (<div>Every risk will be listed here in the table. Just work your way across the columns to define, evaluate and mitigate that risk. <br /><br /></div>),
        },
        {
            selector: '[data-tut="reactour__filter"]',
            content: () => (<div>Tento helps you to manage risks by easily filtering risk based on cateogries you define. </div>),
        },
        {
            selector: '[data-tut="reactour__new"]',
            content: () => (<div>Now it's over to you. <br /><br /> <b>Exit the tutorial and add your first risk.</b> </div>),
        }
        // ...
    ]

    const [toDelete, setToDelete] = useState({});
    const [riskContOpen, setRiskContOpen] = useState(false);
    const [isTourOpen, setIsTourOpen] = useState(false)
    const [curStep, setCurStep] = useState(0)
    const [filter, setFilter] = useState(true);
    const { instance, accounts, inProgress } = useMsal();
    const account = useAccount(accounts[0] || {});
    let timer = 0;
    const getTime = () => { return timer; }
    const setTime = (val) => { timer = val; }
    const configID = window.sessionStorage.getItem("configID")


    setInterval(() => {
        timer++;
    }, 10);

    const getProbOptions = (editorProps) => {
        console.log("editorProps", editorProps)
        const newArr = []

        for (let i = 0; i < props.riskProbability.length; i++) {
            newArr.push({
                label: props.riskProbability[i]._source.probability,
                value: props.riskProbability[i]._id
            })
        }

        return newArr
    }

    const getSevOptions = () => {
        const newArr = []

        for (let i = 0; i < props.riskSeverity.length; i++) {
            newArr.push({
                label: props.riskSeverity[i]._source.severity,
                value: props.riskSeverity[i]._id
            })
        }

        return newArr
    }

    const riskControlOptions = (tofilter) => {
        const newArr = []
        const filtered = tofilter ? props.reqs.filter(e => e._source.riskReq) : props.reqs;

        for (let i = 0; i < filtered.length; i++) {
            const group = props.reqGroupsRaw.find(e => e._id === filtered[i]._source.group)
            newArr.push({
                label: `${group ? group._source.ref : ""} - ${filtered[i]._source.ref} - ${filtered[i]._source.title}`,
                value: filtered[i]._id
            })
        }

        return newArr
    }

    const dataForTable = (newProps) => newProps ? newProps.sort(function (a, b) {
        return Date.parse(a._source.created) - Date.parse(b._source.created)
    }).map(a => {

        var catValue = props.riskCategories.find(cat => cat._id === a._source.category)
        const resRiskEvalVal = props.riskMatrix.find(r => r._id === a._source.resRiskEval)
        const riskEvalVal = props.riskMatrix.find(r => r._id === a._source.riskEval)
        const severity = props.riskSeverity.find(r => r._id === a._source.severity)
        console.log("DATA FOR TABLE", a._source, riskEvalVal)
        var row = {
            category: catValue ? catValue._source.ref : "",
            id: a._id,
            ref: a._source.ref,

            rawCategory: a._source.category,
            input: a._source.input,
            hazard: a._source.hazard || "",
            forseeableSequence: a._source.forseeableSequence,
            prob1: a._source.prob1 || "",
            hazardSit: a._source.hazardSit,
            prob2: a._source.prob2 || "",
            harm: a._source.harm,
            probabilityOccurrance: a._source.probabilityOccurrance,

            severity: severity ? severity._id : "",
            riskEval: riskEvalVal ? riskEvalVal._source.text : "",
            riskControl: a._source.riskControl,
            resProb1: a._source.resProb1 || "",
            resProb2: a._source.resProb2 || "",
            residualProbOcc: a._source.residualProbOcc,
            residualSev: a._source.residualSev,
            resRiskEval: resRiskEvalVal ? resRiskEvalVal._source.text : "",
            benefit: a._source.benefit,
            idDevice: a._source.idDevice,
            edit: { a, props, setToDelete,toggleWarningModal,hasRiskSub },
        }

        return row;
    }) : []

    const createProbOptions = () => {
        const newOptions = []
        for (let i = 0; i < props.riskProbability.length; i++) {
            newOptions.push({
                label: props.riskProbability[i]._source.probability,
                value: props.riskProbability[i]._id,
            })
        }

        return newOptions
    }

    const createHazardOptions = () => {
        const newOptions = []

        if (props.device && props.device._source && props.device._source.hazards) {
            console.log("UPDATING HAZARDS", props.device._source.hazards);
            for (let i = 0; i < props.device._source.hazards.length; i++) {
                newOptions.push({
                    label: props.device._source.hazards[i].label,
                    value: props.device._source.hazards[i].value,
                })
            }
        }

        return newOptions
    }
    const createCategoryOptions = () => {
        const newOptions = []

        if (props.device && props.device._source && props.device._source.riskCategories) {
            console.log("UPDATING riskCategories", props.device._source.riskCategories);
            for (let i = 0; i < props.device._source.riskCategories.length; i++) {
                newOptions.push({
                    label: props.device._source.riskCategories[i].label,
                    value: props.device._source.riskCategories[i].value,
                })
            }
        }

        return newOptions
    }
    const createHarmsOptions = () => {
        const newOptions = []
        if (props.device && props.device._source && props.device._source.harms) {
            console.log("UPDATING HARMS", props.device._source.harms);
            for (let i = 0; i < props.device._source.harms.length; i++) {
                newOptions.push({
                    label: props.device._source.harms[i].label,
                    value: props.device._source.harms[i].value,
                })
            }
        }

        return newOptions
    }
    const [riskCategoryOptions, setRiskCategoryOptions] = useState(createCategoryOptions())
    const [hazardOptions, setHazardOptions] = useState(createHazardOptions())
    // const [hazardOptions, setHazardOptions] = useState(createHazardOptions())

    const [harmOptions, setHarmOptions] = useState(createHarmsOptions())
    const [collapseHeadings, setCollapseHeadings] = useState(
        {
            details: false,
            definition: false,
            evaluation: false,
            mitigation: false,
            result: false,
        }
    )
    const [columnHeaderWidths, setColumnHeaderWidths] = useState({
        "ref": 90,
        "input": 100,
        "category": 100,
        "hazard": 120,
        "forseeableSequence": 380,
        "prob1": props.device && props.device._source && props.device._source.isT2 ? 100 : 0,
        "hazardSit": 300,
        "prob2": props.device && props.device._source && props.device._source.isT2 ? 100 : 0,
        "harm": 120,
        "probabilityOccurrance": 100,
        "severity": 80,
        "riskEval": 100,
        "riskControl": 240,
        "resProb1": props.device && props.device._source && props.device._source.isT2 ? 100 : 0,
        "resProb2": props.device && props.device._source && props.device._source.isT2 ? 100 : 0,
        "residualProbOcc": 100,
        "residualSev": 80,
        "resRiskEval": 100,
        "benefit": 300,
        "edit": 100,


    })
    const columns = [
        {
            dataField: "category",
            text: <HeaderTooltip name="Category" text="Group similar risks into categories."></HeaderTooltip>,
            headerStyle: { width: columnHeaderWidths["category"], "min-width": columnHeaderWidths["category"] },


            style: { width: columnHeaderWidths["category"], "min-width": columnHeaderWidths["category"] },
            editable: hasRiskSub,
            sort: true,
            editorRenderer: (editorProps, value, row) => (
                <TableCreateCategory
                    {...editorProps}

                    dispatch={dispatch}
                    hasRiskSub={hasRiskSub}
                    riskCategories={props.riskCategories}
                    device={props.device}
                    options={riskCategoryOptions}
                    updateOptions={setRiskCategoryOptions}
                    refreshOptions={createCategoryOptions}
                    value={value}
                    row={row}
                />
            )
        },
        {
            dataField: "ref",
            text: <HeaderTooltip name="Ref" text="Unique Reference for this risk."></HeaderTooltip>,

            headerClasses: styles.stickyHeader + " " + styles.stickyColumn,
            classes: "" + styles.stickyColumn,
            style: { width: columnHeaderWidths["ref"] + "px", "min-width": columnHeaderWidths["ref"] + "px" },
            headerStyle: { width: columnHeaderWidths["ref"] + "px", "min-width": columnHeaderWidths["ref"] + "px" },
            editable: hasRiskSub,
            sort: true,
        },
        {
            dataField: "input",
            text: <HeaderTooltip name="Input" text="Where this risk has come from (e.g FMEA, risk analysis)."></HeaderTooltip>,

            headerStyle: { width: columnHeaderWidths["input"], "min-width": columnHeaderWidths["input"] },

            style: { width: columnHeaderWidths["input"], "min-width": columnHeaderWidths["input"] },
            editable: hasRiskSub,
            sort: false,
        },
        {
            dataField: "hazard",
            text: "Hazard",
            style: { width: columnHeaderWidths["hazard"], "min-width": columnHeaderWidths["hazard"] },

            headerStyle: { width: columnHeaderWidths["hazard"], "min-width": columnHeaderWidths["hazard"] },
            editable: hasRiskSub,
            sort: false,
            editorRenderer: (editorProps) => (
                <TableCreateHazard
                    {...editorProps}
                    device={props.device}
                    options={hazardOptions}
                    updateOptions={setHazardOptions}
                    refreshOptions={createHazardOptions}
                />
            )

        }, {
            text: '',
            sort: false,
            hidden: !collapseHeadings["definition"],
            events: {
                onClick: () => {
                    setCollapseHeadings({ ...collapseHeadings, definition: !collapseHeadings["definition"] })

                }
            },
            headerEvents: {
                onClick: () => {
                    setCollapseHeadings({ ...collapseHeadings, definition: !collapseHeadings["definition"] })

                }
            },
            headerStyle: { backgroundColor: '#7ab4f550' },
            style: { width: "100px", maxWidth: "100px", "min-width": "100px", backgroundColor: "#7ab4f550" },

            editable: false,
        },
        {
            dataField: "forseeableSequence",
            text: "Forseeable Event Sequence",
            headerStyle: { width: columnHeaderWidths["forseeableSequence"], maxWidth: columnHeaderWidths["forseeableSequence"], "minWidth": columnHeaderWidths["forseeableSequence"] },

            style: { width: columnHeaderWidths["forseeableSequence"], maxWidth: columnHeaderWidths["forseeableSequence"], "minWidth": columnHeaderWidths["forseeableSequence"] },
            editable: hasRiskSub,
            sort: false,
            hidden: collapseHeadings["definition"],
            editor: {
                type: Type.TEXTAREA,
            }
        },
        {
            dataField: "prob1",
            text: "Probability 1",
            headerStyle: { width: columnHeaderWidths["prob1"], maxWidth: columnHeaderWidths["prob1"], "min-width": columnHeaderWidths["prob1"] },

            style: { width: columnHeaderWidths["prob1"], maxWidth: columnHeaderWidths["prob1"], "min-width": columnHeaderWidths["prob1"] },
            editable: hasRiskSub,
            sort: false,
            formatExtraData: props.riskProbability,
            formatter: (cell, row, rowIndex, formatExtraData) => {
                console.log("formatter", formatExtraData)
                const document = formatExtraData.find(r => r._id === cell)
                return document ? document._source.probability : ""
            },
            hidden: (props.device && props.device._source && !props.device._source.isT2) || collapseHeadings["definition"],
            editorRenderer: (editorProps) => (
                <TableSelect
                    {...editorProps}
                    options={getProbOptions()}
                />
            )
        },
        {
            dataField: "hazardSit",
            text: "Hazardous Situation",
            headerStyle: { width: columnHeaderWidths["hazardSit"], maxWidth: columnHeaderWidths["hazardSit"], "min-width": columnHeaderWidths["hazardSit"] },

            style: { width: columnHeaderWidths["hazardSit"], maxWidth: columnHeaderWidths["hazardSit"], "min-width": columnHeaderWidths["hazardSit"] },
            editable: hasRiskSub,
            hidden: collapseHeadings["definition"],

            sort: false,
            editor: {
                type: Type.TEXTAREA,
            }
        },
        {
            dataField: "prob2",
            text: "Probability 2",
            style: { width: columnHeaderWidths["prob2"], maxWidth: columnHeaderWidths["prob2"], "min-width": columnHeaderWidths["prob2"] },
            headerStyle: { width: columnHeaderWidths["prob2"], maxWidth: columnHeaderWidths["prob2"], "min-width": columnHeaderWidths["prob2"] },

            editable: hasRiskSub,
            formatExtraData: props.riskProbability,
            formatter: (cell, row, rowIndex, formatExtraData) => {
                console.log("formatter", formatExtraData)
                const document = formatExtraData.find(r => r._id === cell)
                return document ? document._source.probability : ""
            },
            sort: false,
            hidden: (props.device && props.device._source && !props.device._source.isT2) || collapseHeadings["definition"],
            editorRenderer: (editorProps) => (
                <TableSelect
                    {...editorProps}
                    options={getProbOptions()}
                />
            )
        },

        {
            dataField: "harm",
            text: "Harm",
            headerStyle: { width: columnHeaderWidths["harm"], maxWidth: columnHeaderWidths["harm"], "min-width": columnHeaderWidths["harm"] },

            style: { width: columnHeaderWidths["harm"], maxWidth: columnHeaderWidths["harm"], "min-width": columnHeaderWidths["harm"] },
            editable: hasRiskSub,
            hidden: collapseHeadings["definition"],

            sort: false,
            editorRenderer: (editorProps) => (
                <TableCreateHarm
                    {...editorProps}
                    device={props.device}
                    options={harmOptions}
                    updateOptions={setHarmOptions}
                    refreshOptions={createHarmsOptions}
                />
            )
        },
        {
            text: '',
            sort: false,
            hidden: !collapseHeadings["evaluation"],
            events: {
                onClick: () => {
                    setCollapseHeadings({ ...collapseHeadings, evaluation: !collapseHeadings["evaluation"] })

                }
            },
            headerEvents: {
                onClick: () => {
                    setCollapseHeadings({ ...collapseHeadings, evaluation: !collapseHeadings["evaluation"] })

                }
            },
            headerStyle: { backgroundColor: '#ffb06190' },
            style: { width: "100px", maxWidth: "100px", "min-width": "100px", backgroundColor: "#ffb06190" },

            editable: false,
        },
        {
            dataField: "probabilityOccurrance",
            text: "Probability of Occurrence",
            formatExtraData: { prob: props.riskProbability, twoTier: props.probMatrix },
            formatter: (cell, row, rowIndex, formatExtraData) => {

                let document
                document = formatExtraData.prob.find(r => r._id === cell)
                document = document ? document._source.probability : ""
                console.log("probabilityOccurrance formatter", cell, document, formatExtraData)

                return document
            },
            headerStyle: { width: columnHeaderWidths["probabilityOccurrance"], maxWidth: columnHeaderWidths["probabilityOccurrance"], "min-width": columnHeaderWidths["probabilityOccurrance"] },

            style: { width: columnHeaderWidths["probabilityOccurrance"], maxWidth: columnHeaderWidths["probabilityOccurrance"], "min-width": columnHeaderWidths["probabilityOccurrance"] },
            editable: (props.device && props.device._source
                && !props.device._source.isT2
            ) && hasRiskSub,
            sort: false,

            hidden: collapseHeadings["evaluation"],
            editorRenderer: (editorProps) => (
                <TableSelect
                    {...editorProps}
                    options={getProbOptions()}
                />
            ),
        },
        {
            dataField: "severity",
            text: "Severity",
            headerStyle: { width: columnHeaderWidths["severity"], maxWidth: columnHeaderWidths["severity"], "min-width": columnHeaderWidths["severity"] },

            style: { width: columnHeaderWidths["severity"], maxWidth: columnHeaderWidths["severity"], "min-width": columnHeaderWidths["severity"] },
            editable: hasRiskSub,
            sort: false,
            formatExtraData: props.riskSeverity,
            formatter: (cell, row, rowIndex, formatExtraData) => {
                console.log("formatter", formatExtraData)
                const document = formatExtraData.find(r => r._id === cell)
                return document ? document._source.severity : ""
            },
            hidden: collapseHeadings["evaluation"],
            editorRenderer: (editorProps) => (
                <TableSelect
                    {...editorProps}
                    options={getSevOptions()}
                />
            )
        },
        {
            dataField: "riskEval",
            headerStyle: { width: columnHeaderWidths["riskEval"], maxWidth: columnHeaderWidths["riskEval"], "min-width": columnHeaderWidths["riskEval"] },

            style: { width: columnHeaderWidths["riskEval"], maxWidth: columnHeaderWidths["riskEval"], "min-width": columnHeaderWidths["riskEval"] },
            text: "Risk Evaluation",
            editable: false,
            sort: false,
            hidden: collapseHeadings["evaluation"],
        }, {
            text: '',
            sort: false,
            hidden: !collapseHeadings["mitigation"],
            events: {
                onClick: () => {
                    setCollapseHeadings({ ...collapseHeadings, mitigation: !collapseHeadings["mitigation"] })

                }
            },
            headerEvents: {
                onClick: () => {
                    setCollapseHeadings({ ...collapseHeadings, mitigation: !collapseHeadings["mitigation"] })

                }
            },
            headerStyle: { backgroundColor: '#ffe26190' },
            style: { width: "100px", maxWidth: "100px", "min-width": "100px", backgroundColor: "#ffe26190" },

            editable: false,
        },
        {
            dataField: "riskControl",
            hidden: collapseHeadings["mitigation"],
            text: "Risk Control",
            headerStyle: { width: columnHeaderWidths["riskControl"], maxWidth: columnHeaderWidths["riskControl"], "min-width": columnHeaderWidths["riskControl"] },

            style: { width: columnHeaderWidths["riskControl"], maxWidth: columnHeaderWidths["riskControl"], "min-width": columnHeaderWidths["riskControl"] },
            editable: hasRiskSub,
            sort: false,
            formatter: (cell) => {
                return (<>{Array.isArray(cell) ? cell.map(label => <p>{label.substring(0, 20) + (label.length > 20 ? "..." : "")}</p>) : cell}</>)
            },
            editorRenderer: (editorProps) => (
                <TableRiskControl
                    {...editorProps}
                    getTime={getTime}
                    openState={riskContOpen}
                    setOpenState={setRiskContOpen}

                    setTime={setTime}
                    options={riskControlOptions(filter)}
                    filter={filter}
                    setFilter={setFilter}
                    reqs={props.reqs}
                    dispatch={dispatch}
                />
            )
        },
        {
            dataField: "resProb1",
            text: "Residual Probability 1",
            headerStyle: { width: columnHeaderWidths["resProb1"], maxWidth: columnHeaderWidths["resProb1"], "min-width": columnHeaderWidths["resProb1"] },

            style: { width: columnHeaderWidths["resProb1"], maxWidth: columnHeaderWidths["resProb1"], "min-width": columnHeaderWidths["resProb1"] },
            editable: hasRiskSub,
            sort: false,
            formatExtraData: props.riskProbability,
            formatter: (cell, row, rowIndex, formatExtraData) => {
                console.log("formatter", formatExtraData)
                const document = formatExtraData.find(r => r._id === cell)
                return document ? document._source.probability : ""
            },
            hidden: (props.device && props.device._source && !props.device._source.isT2) || collapseHeadings["mitigation"],
            editorRenderer: (editorProps) => (
                <TableSelect
                    {...editorProps}
                    options={getProbOptions()}
                />
            )
        },
        {
            dataField: "resProb2",
            text: "Residual Probability 2",
            headerStyle: { width: columnHeaderWidths["resProb2"], maxWidth: columnHeaderWidths["resProb2"], "min-width": columnHeaderWidths["resProb2"] },

            style: { width: columnHeaderWidths["resProb2"], maxWidth: columnHeaderWidths["resProb2"], "min-width": columnHeaderWidths["resProb2"] },
            editable: hasRiskSub,
            sort: false,
            hidden: (props.device && props.device._source && !props.device._source.isT2) || collapseHeadings["mitigation"],
            formatExtraData: props.riskProbability,
            formatter: (cell, row, rowIndex, formatExtraData) => {
                console.log("formatter", formatExtraData)
                const document = formatExtraData.find(r => r._id === cell)
                return document ? document._source.probability : ""
            },
            editorRenderer: (editorProps) => (
                <TableSelect
                    {...editorProps}
                    options={getProbOptions()}
                />
            )
        },
        {
            dataField: "residualProbOcc",
            text: "Residual Probability of Occurrence",
            headerStyle: { width: columnHeaderWidths["residualProbOcc"], maxWidth: columnHeaderWidths["residualProbOcc"], "min-width": columnHeaderWidths["residualProbOcc"] },

            style: { width: columnHeaderWidths["residualProbOcc"], maxWidth: columnHeaderWidths["residualProbOcc"], "min-width": columnHeaderWidths["residualProbOcc"] },
            editable: (props.device && props.device._source
                && !props.device._source.isT2
            ) && hasRiskSub,
            sort: false,
            hidden: collapseHeadings["mitigation"],
            formatExtraData: { prob: props.riskProbability, twoTier: props.probMatrix },
            formatter: (cell, row, rowIndex, formatExtraData) => {
                console.log("residualProbOcc formatter", cell, formatExtraData)
                let document


                document = formatExtraData.prob.find(r => r._id === cell)
                document = document ? document._source.probability : ""


                return document
            },
            editorRenderer: (editorProps) => (props.device && props.device._source && !props.device._source.isT2 &&
                <TableSelect
                    {...editorProps}
                    options={createProbOptions()}
                />
            )
        },
        {
            dataField: "residualSev",
            text: "Residual Severity",
            headerStyle: { width: columnHeaderWidths["residualSev"], maxWidth: columnHeaderWidths["residualSev"], "min-width": columnHeaderWidths["residualSev"] },

            style: { width: columnHeaderWidths["residualSev"], maxWidth: columnHeaderWidths["residualSev"], "min-width": columnHeaderWidths["residualSev"] },
            editable: hasRiskSub,
            sort: false,
            hidden: collapseHeadings["mitigation"],
            formatExtraData: props.riskSeverity,
            formatter: (cell, row, rowIndex, formatExtraData) => {
                console.log("formatter", formatExtraData)
                const document = formatExtraData.find(r => r._id === cell)
                return document ? document._source.severity : ""
            },
            editorRenderer: (editorProps) => (
                <TableSelect
                    {...editorProps}
                    options={getSevOptions()}
                />
            )
        },
        {
            text: '',
            sort: false,
            hidden: !collapseHeadings["result"],
            events: {
                onClick: () => {
                    setCollapseHeadings({ ...collapseHeadings, result: !collapseHeadings["result"] })

                }
            },
            headerEvents: {
                onClick: () => {
                    setCollapseHeadings({ ...collapseHeadings, result: !collapseHeadings["result"] })

                }
            },
            headerStyle: { backgroundColor: 'lightgreen' },
            style: { width: "100px", maxWidth: "100px", "min-width": "100px", backgroundColor: "lightgreen" },

            editable: false,
        },
        {
            dataField: "resRiskEval",
            text: "Residual Risk Evaluation",
            headerStyle: { width: columnHeaderWidths["resRiskEval"], maxWidth: columnHeaderWidths["resRiskEval"], "min-width": columnHeaderWidths["resRiskEval"] },

            style: { width: columnHeaderWidths["resRiskEval"], maxWidth: columnHeaderWidths["resRiskEval"], "min-width": columnHeaderWidths["resRiskEval"] },
            hidden: collapseHeadings["result"],
            editable: false,
            sort: false,
        },
        {
            dataField: "benefit",
            headerStyle: { width: columnHeaderWidths["benefit"], maxWidth: columnHeaderWidths["benefit"], "min-width": columnHeaderWidths["benefit"] },

            style: { width: columnHeaderWidths["benefit"], maxWidth: columnHeaderWidths["benefit"], "min-width": columnHeaderWidths["benefit"] },
            text: "Benefit vs Risk",
            hidden: collapseHeadings["result"],
            editable: hasRiskSub,
            sort: false,
        },
        {
            dataField: 'edit',
            text: 'Edit',
            sort: false,
            formatExtraData: {hasRiskSub:hasRiskSub},
            formatter: editFormatter,
            editable: false,
        },
    ]
    const [tableSearchValue, settableSearchValue] = useState("")
    const [tableData, setTableData] = useState([])
    const [showWarningModal, setshowWarningModal] = useState(false)
    const handleDeleteWarning = () => {
        deleteRow(toDelete)
        setToDelete()
        setshowWarningModal(false)
    }

    const toggleWarningModal = () => {

        setshowWarningModal(!showWarningModal)
        // setparametersForDetail(parameters)

    };

    const actionsForDeleteModal = { toggleWarningModal, handleDeleteWarning }

    useEffect(() => {
        const configID = window.sessionStorage.getItem("configID")
        if (!props.tour) {
            dispatch(getTour(account.localAccountId))
          } else if ((!props.tour.visited || !props.tour.visited.includes("riskMatrix")) && window.innerWidth > 768) {
            const newVisitedArray = props.tour.visited ? [...props.tour.visited, "riskMatrix"] : ["riskMatrix"]
            dispatch(putTour(account.localAccountId, { visited: newVisitedArray }))
            setIsTourOpen(true)
          }

        if (!props.device || (Object.keys(props.device).length === 0 && !props.selectingDevice)) {
            const sessionStorageDevice = JSON.parse(localStorage.getItem('device'))
            if (sessionStorageDevice && sessionStorageDevice !== undefined) {
                dispatch(selectDevice(sessionStorageDevice._id, sessionStorageDevice._id, configID));
            }
            else if (props.location.state) {
                dispatch(selectDevice(props.location.state.id, props.location.state.idDevice, configID));
            } else {
                navigate.push("/home")
            }
        }
        // setTimeout(() => {
        //     if (!props.device || (Object.keys(props.device).length === 0 && !props.selectingDevice)) {
        //         if (props.location.state) {
        //             dispatch(selectDevice(props.location.state.id, props.location.state.idDevice, configID))
        //         } else {
        //             // console.log("return 2")

        //             return <Redirect to="/home" />
        //         }
        //     }
        // }, 1500);
    }, [])
    useEffect(() => {
        // if (toDelete !== {}) {
        //     toggleWarningModal()
        //     // deleteRow(toDelete);
        // }
        // if(props.device && props.device._id){
        //     dispatch(getRiskDetails(props.device._id))
        // }
    }, [toDelete])


    useEffect(() => {
        setTableData(dataForTable(props.riskDetails));

        // for (let i = 0; i < tableData.length; i++) {
        //     handleRowChange("", "", tableData[i], "");
        // }
    }, [props.riskDetails])
    const updateRow = (oldVal, newVal, row, column) => {

    }

    const handleRowChange = (oldVal, newVal, row, column) => {
        console.log("HANDLE ROW CHANGE")
        const newData = props.riskDetails.find((c) => c._id === row.id)
        if (!newData) { return; }

        setRiskContOpen(false);

        newData._source[column.dataField] = newVal;

        const riskProb1 = newData._source.prob1;
        const riskProb2 = newData._source.prob2;
        const riskNew = props.probMatrix.find(p => p._source.probability1 === riskProb1 && p._source.probability2 === riskProb2)
        // TODO: what is this meant to be storing? 
        if (props.device && props.device._source && props.device._source.isT2 && riskNew) {

            newData._source.probabilityOccurrance = riskNew._source.text

            console.log("NEW RISK", riskNew._id, riskNew._source)
        }

        const sev = newData._source.severity;
        const evalNew = props.riskMatrix.find(p => p._source.probability === newData._source.probabilityOccurrance && p._source.severity === sev)
        if (evalNew) {
            newData._source.riskEval = evalNew._id
        }

        const resProb1 = newData._source.resProb1;
        const resProb2 = newData._source.resProb2;
        const riskNew2 = props.probMatrix.find(p => p._source.probability1 == resProb1 && p._source.probability2 == resProb2)
        if (props.device && props.device._source && props.device._source.isT2 && riskNew2) {
            newData._source.residualProbOcc = riskNew2._source.text
        }

        const resSev = newData._source.residualSev;
        const resEvalNew = props.riskMatrix.find(p => p._source.probability == row.residualProbOcc && p._source.severity == resSev)
        if (resEvalNew) {
            newData._source.resRiskEval = resEvalNew._id
        }

        const rowToUpdate = props.riskDetails.findIndex(d => d._id == row.id)
        props.riskDetails[rowToUpdate] = newData;

        setTableData(dataForTable(props.riskDetails));


        dispatch(updateRiskDetail(row.id, newData._source));
        // dispatch(updateDevice(props.device._id, props.device._source, props.userTeams, account.localAccountId))
    }

    const deleteRow = (row) => {
        const newData = props.riskDetails && props.riskDetails.find((c) => c._id == row._id)
        const newSource = {}

        if (!newData) { return }

        Object.keys(newData._source).forEach((key) => {
            newSource[key] = newData._source[key];
        })

        dispatch(deleteRiskDetail(row._id, newSource));
    }

    const createNewRow = () => {
        const configID = window.sessionStorage.getItem("configID")
        let newRow = {
            // id: props.device._id + (data.length).toString(),
            ref: "RC" + (props.riskDetails ? props.riskDetails.length + 1 : 1),
            input: "",
            category: "",
            hazard: "",
            forseeableSequence: "",
            prob1: "",
            hazardSit: "",
            prob2: "",
            harm: "",
            probabilityOccurrance: "",
            severity: "",
            riskEval: "",
            riskControl: "",
            resProb1: "",
            resProb2: "",
            residualProbOcc: "",
            residualSev: "",
            resRiskEval: "",
            benefit: "",
            idDevice: props.device._id,
            configID,
            edit: {},
        };

        dispatch(createRiskDetail(newRow))
        // dispatch(getRiskDetail(newRow))
        // setTableData([...tableData, newRow]);
    }
    if (!props.device || (Object.keys(props.device).length === 0 && !props.selectingDevice)) {
        if (props.location.state) {
            dispatch(selectDevice(props.location.state.id, props.location.state.idDevice, configID))
        } else {
            // console.log("return 3")

            return <Redirect to="/home" />
        }
    }

    return (
        <React.Fragment>

            {<Tour
                steps={steps}
                showNavigation={false}
                isOpen={isTourOpen && window.innerWidth > 768}
                scrollSmooth
                goToStep={curStep}
                nextStep={() => { setCurStep(curStep + 1) }}
                prevStep={() => { setCurStep(curStep - 1) }}
                onRequestClose={() => { setIsTourOpen(false) }} />
            }

            {props.device && props.device._source && <div style={{ "minHeight": "10vh" }}>
                <Container style={{
                    marginTop: "30px",
                    float: "left",
                    maxWidth: "100%",
                }}>
                    <Row  >
                        <Col sm={1}>
                            <Button disabled={!hasRiskSub} data-tut="reactour__new" onClick={() => { createNewRow() }} color="primary" >Create risk</Button>
                        </Col>
                        <Col data-tut="reactour__filter" sm={2} style={{
                                zIndex: "1000",
                               
                            }}>
                            <Select id="selecttt"
                            
                                defaultValue={{ value: "all", label: "All Categories" }}
                            //onChange={(event, target) => {
                            //props.setLevelToInclude(event.value === "all" ? undefined : event.value);
                            //}}
                            //options={[{ value: "all", label: "All levels" }, { value: "top", label: "User needs and product level" }, { value: "mid", label: "System level" }, { value: "low", label: "Component level" }]}
                            /></Col>
                        <Col sm={2}>
                            <Input

                                value={tableSearchValue}
                                onChange={(event) => { settableSearchValue(event.target.value) }}
                                style={{ marginBottom: "20px", borderRadius: "100px", display: "inline" }} placeholder="Search..." />
                        </Col>
                    </Row>
                    <div data-tut="reactour__table" className={styles.tableContainer} style={{ height: "75vh", paddingTop: "0px" }}>

                        <div style={{ width: "100%" ,marginTop:"10px"}} className={styles.stickyBar}>
                            <span style={{
                                border: "1px solid #dee2e6", display: "inline-block", backgroundColor: "white",
                                width: 1 + columnHeaderWidths["ref"] + columnHeaderWidths["input"] + columnHeaderWidths["category"] + columnHeaderWidths["hazard"] + "px",
                                minWidth: 1 + columnHeaderWidths["ref"] + columnHeaderWidths["input"] + columnHeaderWidths["category"] + columnHeaderWidths["hazard"] + "px",
                                maxWidth: 1 + columnHeaderWidths["ref"] + columnHeaderWidths["input"] + columnHeaderWidths["category"] + columnHeaderWidths["hazard"] + "px"

                            }}>
                                Details</span>

                            <span onClick={() => {
                                setCollapseHeadings({ ...collapseHeadings, definition: !collapseHeadings["definition"] })
                            }} style={{
                                border: "1px solid #dee2e6",
                                display: "inline-block", backgroundColor: "#7ab4f550",
                                width: collapseHeadings["definition"] ? "100px" : (columnHeaderWidths["forseeableSequence"] + columnHeaderWidths["prob1"] + columnHeaderWidths["hazardSit"] + columnHeaderWidths["harm"] + columnHeaderWidths["prob2"]) + "px",
                                minWidth: collapseHeadings["definition"] ? "100px" : (columnHeaderWidths["forseeableSequence"] + columnHeaderWidths["prob1"] + columnHeaderWidths["hazardSit"] + columnHeaderWidths["harm"] + columnHeaderWidths["prob2"]) + "px",
                                maxWidth: collapseHeadings["definition"] ? "100px" : (columnHeaderWidths["forseeableSequence"] + columnHeaderWidths["prob1"] + columnHeaderWidths["hazardSit"] + columnHeaderWidths["harm"] + columnHeaderWidths["prob2"]) + "px"


                            }}> Definition <i className={collapseHeadings["definition"] ? "mdi mdi-chevron-right font-16 accordion-arrow" : "mdi mdi-chevron-left font-16 accordion-arrow"} ></i></span>

                            <span onClick={() => {
                                setCollapseHeadings({ ...collapseHeadings, evaluation: !collapseHeadings["evaluation"] })
                            }} style={{
                                border: "1px solid #dee2e6", display: "inline-block", backgroundColor: "#ffb06190",
                                width: collapseHeadings["evaluation"] ? "100px" : columnHeaderWidths["probabilityOccurrance"] + columnHeaderWidths["severity"] + columnHeaderWidths["riskEval"] + "px",
                                minWidth: collapseHeadings["evaluation"] ? "100px" : columnHeaderWidths["probabilityOccurrance"] + columnHeaderWidths["severity"] + columnHeaderWidths["riskEval"] + "px",
                                maxWidth: collapseHeadings["evaluation"] ? "100px" : columnHeaderWidths["probabilityOccurrance"] + columnHeaderWidths["severity"] + columnHeaderWidths["riskEval"] + "px"
                            }}>
                                Evaluation <i className={collapseHeadings["evaluation"] ? "mdi mdi-chevron-right font-16 accordion-arrow" : "mdi mdi-chevron-left font-16 accordion-arrow"} ></i></span>

                            <span onClick={() => {
                                setCollapseHeadings({ ...collapseHeadings, mitigation: !collapseHeadings["mitigation"] })
                            }} style={{
                                border: "1px solid #dee2e6", display: "inline-block", backgroundColor: "#ffe26190",
                                width: collapseHeadings["mitigation"] ? "100px" : columnHeaderWidths["riskControl"] + columnHeaderWidths["resProb1"] + columnHeaderWidths["resProb2"] + columnHeaderWidths["residualProbOcc"] + columnHeaderWidths["residualSev"] + "px",
                                maxWidth: collapseHeadings["mitigation"] ? "100px" : columnHeaderWidths["riskControl"] + columnHeaderWidths["resProb1"] + columnHeaderWidths["resProb2"] + columnHeaderWidths["residualProbOcc"] + columnHeaderWidths["residualSev"] + "px",
                                minWidth: collapseHeadings["mitigation"] ? "100px" : columnHeaderWidths["riskControl"] + columnHeaderWidths["resProb1"] + columnHeaderWidths["resProb2"] + columnHeaderWidths["residualProbOcc"] + columnHeaderWidths["residualSev"] + "px"
                            }}>
                                Mitigation <i className={collapseHeadings["mitigation"] ? "mdi mdi-chevron-right font-16 accordion-arrow" : "mdi mdi-chevron-left font-16 accordion-arrow"} ></i></span>
                            <span onClick={() => {
                                setCollapseHeadings({ ...collapseHeadings, result: !collapseHeadings["result"] })
                            }} style={{
                                border: "1px solid #dee2e6", display: "inline-block", backgroundColor: "lightgreen",
                                width: collapseHeadings["result"] ? "100px" : columnHeaderWidths["resRiskEval"] + columnHeaderWidths["benefit"] + "px",
                                minWidth: collapseHeadings["result"] ? "100px" : columnHeaderWidths["resRiskEval"] + columnHeaderWidths["benefit"] + "px",
                                maxWidth: collapseHeadings["result"] ? "100px" : columnHeaderWidths["resRiskEval"] + columnHeaderWidths["benefit"] + "px"
                            }}>
                                Result<i className={collapseHeadings["result"] ? "mdi mdi-chevron-right font-16 accordion-arrow" : "mdi mdi-chevron-left font-16 accordion-arrow"} ></i></span>
                        </div>


                        {console.log("TABLE DATA", tableData)}

                        {
                            // (!props.riskDetails || props.riskDetails.length === 0  ) ? " " :
                            <BootstrapTable
                                bootstrap4
                                classes={tables.table + " " + styles.noCollapse}
                                headerWrapperClasses={tables.tableHead + " " + styles.stickyHeader}
                                bodyClasses={tables.tableTD}
                                keyField="idDevice"
                                key={props.riskDetails}
                                data={tableData.filter(r => r.ref.toLocaleLowerCase().includes(tableSearchValue) || r.category.toLocaleLowerCase().includes(tableSearchValue))}
                                columns={columns}
                                cellEdit={cellEditFactory({
                                    mode: 'click',
                                    afterSaveCell: (oldVal, newVal, row, column) => { handleRowChange(oldVal, newVal, row, column) },
                                    blurToSave: true,
                                    autoSelectText: false,
                                })}
                                defaultSorted={[{
                                    dataField: 'ref',
                                    order: 'asc'
                                }]}
                            />
                        }
                    </div>
                    {showWarningModal && (<div> {deleteModal(showWarningModal, actionsForDeleteModal, "Risk")} </div>)}
                </Container>
            </div>}

        </React.Fragment>
    )
}

const RiskControlModal = (props) => {
    const [value, setValue] = useState()
    return (
        <TentoModal
            bodyClassname={modals.modalBody_noScroll}
            isOpen={props.openState}
            toggle={() => {
                console.log("TOGGLE")
                props.setOpenState(false)
                props.setTime(0)
            }}
            size="lg"
            title={"Choose Risk Control"}
            body={
                <div>
                    <Select
                        options={props.options}
                        onChange={setValue}
                        defaultValue={Array.isArray(props.defaultValue) ? props.defaultValue.map((label) => {
                            const value = props.reqs.find(e => e._source.ref + "-" + e._source.title === label)
                            return { label: label, value: value ? value._id : "" }
                        }) : props.defaultValue
                        }
                        isMulti
                    />
                    <p>Some requirements are filtered as they aren't ticked as Risk Controls.<br />
                        <span
                            style={{ color: "#35A8E0", textDecoration: "underline", cursor: "pointer" }}
                            onClick={() => {
                                props.setFilter(!props.filter)
                            }}
                        >
                            Click here to {props.filter ? "un" : ""}filter them
                        </span>
                    </p>
                </div>
            }
            seperateButtons={[
                {
                    text: "Save", color: "primary", className: modals.modalButtonSmall, onClick: () => {
                        // if (props.filter) {
                        props.setOpenState(false)
                        props.setTime(0)

                        props.onUpdate(value)
                        // }
                    }
                }
            ]}
        >
        </TentoModal>
    )
}

const mapStateToProps = (state, ownProps) => {
    const { TestPlan } = state;
    return { ...TestPlan };
}
export default connect(mapStateToProps)(RiskMatrix);