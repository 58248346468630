import React, { memo, useEffect, useState } from 'react';
import ai_solid_icon from "../assets/icons/ai_solid_icon.svg"
import styles from './styles.module.css'
import Switch from "react-switch";
import ai_icon from "../assets/icons/ai_icon.svg"


import {
  Row, Col, Offcanvas, OffcanvasHeader, OffcanvasBody, NavLink, Table, Button, Form, FormGroup, Input, Spinner, UncontrolledTooltip
} from 'reactstrap';

import {
  InteractionRequiredAuthError,
  InteractionStatus,
} from "@azure/msal-browser";

import {
  createReq, createGroup, updateDevice, aiMenu, createTestLog, createTile, selectBoard, createRecord
} from '../redux/actions';

const AIAPIURL = process.env.REACT_APP_STAGE == 'stage'
  ? "https://gemastag.azurewebsites.net/"
  : process.env.REACT_APP_STAGE == 'production' ? "https://gemaprod.azurewebsites.net/" : "http://localhost:1980/";

const TextBoxWithCount = (props) => {
  const [inputText, setInputText] = useState("")
  const [showMinTextLengthError, setShowMinTextLengthError] = useState(true)
  const limit = 500
  const min = 50
  return (
    <FormGroup>
      {
        ["description"].map(
          v => <Input
            id={v}
            name={v}
            onChange={(event, value) => {
              setInputText(event.target.value.slice(0, limit))
              setShowMinTextLengthError(event.target.value.length < min)

            }}
            value={inputText}
            type="textarea"
          />
        )
      }
      <span style={{ color: "grey", cursor: "auto" }}>{inputText.length}/{limit} characters</span>
      <p>      {showMinTextLengthError && <span style={{ color: "red", }}>Description must be at least {min} characters long so that I have something to analyse.</span>}
      </p>
      <Button type="submit" disabled={showMinTextLengthError}>
        Submit
      </Button>
    </FormGroup>
  )
}
const ReqTextBoxWithCount = (props) => {
  const [inputText, setInputText] = useState("")
  const [showMinTextLengthError, setShowMinTextLengthError] = useState(true)
  const limit = 500
  const min = 10
  return (
    <FormGroup>
      {
        ["reqExample"].map(
          v => <Input
            id={v}
            name={v}
            onChange={(event, value) => {
              setInputText(event.target.value.slice(0, limit))
              setShowMinTextLengthError(event.target.value.length < min)

            }}
            value={inputText}
            type="textarea"
          />
        )
      }
      {/* <span style={{ color: "grey", cursor: "auto" }}>{inputText.length}/{limit} characters</span>
      <p>      {showMinTextLengthError && <span style={{ color: "red", }}>Example must be at least {min} characters long so that I have something to analyse.</span>}
      </p> */}
      <Button type="submit"
      // disabled={showMinTextLengthError}
      >
        Next
      </Button>
    </FormGroup>
  )
}
const AIMenuButton = (props) => {
  const {
    aiSteps,
    dispatch,
    instance,
    account,
    aiQuestions,
    setAiQuestions,
    aiChat,
    setAiChat,
    aiResult,
    setAiResult,
    added, setAdded
  } = props
  const [localTokens, setLocalTokens] = useState({
    month: 0,
    tokens: 0
  })
  function cleanup() {
    console.log("CLEANUP Table View")
    dispatch(aiMenu(false))
    // props.setIsAIOpen(false)
  }
  useEffect(() => {
    console.log("AI RERENDER", aiChat.length === 0 && aiQuestions.length === 0)
    if (aiChat.length === 0 && aiQuestions.length === 0) {
      return cleanup;
    }
    
  }, []);

  // useEffect(() => {

  //   // props.setIsAIOpen(false)
  //   return cleanup;
  // }, [props.aiSteps]);
  // -------------------------------
  // Send to AI ChatGPT 
  // --------------------------------
  async function aiSubmitText(endpoint, data, dataType, nextStep) {
    const limit = 500000


    if (props.device._source.tokens && (props.device._source.tokens.tokens > limit || localTokens.tokens > limit)) {
      setTimeout(() => {
        handleAIError("monthlyTokenLimitExceeded")

      }, 1000);

    } else {
      try {
        if (Object.keys(data).includes("description") && data.description !== props.device._source.description) {
          dispatch(updateDevice(props.device._id, { ...props.device._source, description: data["description"] }, props.userTeams, account.localAccountId))

        }
        console.log("OBJECT NAME", props.reqGroups)
        var testAreas
        var reqExample
        if (endpoint === "testRecords") {
          if (data.objectName.includes("Unit Test")) {
            const reqGroupName = data.objectName.replaceAll(" Unit Test", "")
            const reqGroupToSend = props.reqGroupsRaw.find(r => r._source.title === reqGroupName)
            if (reqGroupToSend) {
              testAreas = props.reqs.filter(r => r._source.group === reqGroupToSend._id).map(r => r._source.title)
            }
          } else if (data.objectName.includes("Integration Testing")) {
            const components = props.reqGroups.low
            testAreas = props.reqs.filter(r => components.some(g => g._id === r._source.group)).map(r => r._source.title)
          }
        }

        const tokenResp = await instance.acquireTokenSilent({
          account: account
        }).then((response) => {
          // if(response) {
          return response         //   }
        }).catch((error) => {
          if (error instanceof InteractionRequiredAuthError) {
            instance.acquireTokenRedirect({
              account: account,
            });
          }
        }).then(async (tokenResp) => {

          return await fetch(
            AIAPIURL + "suggest",
            {
              method: "POST",
              body: JSON.stringify({ ...data, endpoint, testAreas }),
              // mode: 'cors',
              headers: new Headers({
                Accept: "application/json",
                'Content-Type': 'application/json',
                Authorization: `Bearer ${tokenResp.idToken}`
              }
              )

            }
          )
        }).then(res => { return res.json() }).then(result => {
          if (result.error) {
            setAiChat(["Looks like we've encountered an error analysing that for you. Please try again later."])
            setAiQuestions([])
          } else {

            const curTokens = props.device._source.tokens ? props.device._source.tokens : 0
            var newTokens = {}
            console.log("USAGE", result.tokens, curTokens, localTokens)
            const curMonth = new Date().getMonth()
            if (!curTokens || (curTokens.month !== curMonth)) {
              newTokens = {
                month: curMonth,
                tokens: result.tokens
              }

            } else {

              newTokens = {
                month: curMonth,
                tokens: curTokens.tokens + result.tokens
              }

            }
            const deviceSource = window.sessionStorage.getItem("deviceSource")
            dispatch(updateDevice(props.device._id, { ...deviceSource, tokens: newTokens }, props.userTeams, account.localAccountId))
            setLocalTokens(newTokens)
            handleAiSteps(nextStep, { ...data, type: dataType, results: result.result })
          }
        })

      } catch (error) {
        console.log("ERROR", error)
        setAiChat(["Looks like we've encountered an error analysing that for you. Please try again later."])
        setAiQuestions([])
      }
    }
  }

  // -----------------------------
  // Handle the step stored in the database 
  // -----------------------------


  async function performImpactAssessment(data, dataType, nextStep) {

    try {
      switch (dataType) {
        case "Anomalies":
          // find which anomlies are open or to review
          var anomalies = []
          var opreAnomalies = props.anoms.filter(a => a._source.result === 1 || a._source.result === 4)
          var types = [2, 3, 4] //for each type MAJOR MIN OB

          types.map((t => {
            var resultsForType = []
            opreAnomalies.filter(anom => anom._source.type === t).map(anom => {
              var numberofRelatedResults = 0
              props.runs.map(run => {
                var runsResultLinkedToAnom = run._source.results.filter(result => result.idAnom.includes(anom._id)) // FInd related number of test runs.
                numberofRelatedResults = numberofRelatedResults + runsResultLinkedToAnom.length
              })
              resultsForType.push({ anomTitle: anom._source.desc, anomRef: anom._source.ref, type: anom._source.type, number: numberofRelatedResults })
            })
            resultsForType.sort((a, b) => b.number - a.number) // Now sort for highest number. 
            resultsForType.map(re =>
              // results.results.anomalies.push(re)
              anomalies.push(re)
            ) // Add each one to overal results. This preserves order of the type. 
          }))
          handleAiSteps(nextStep, { ...data, type: dataType, results: { anomalies: anomalies } })
          //handleAiSteps(nextStep, { ...data, type: dataType, results: results })
          return 0
        default:
          return 0
      }

    } catch (error) {
      setAiChat(["Looks like we've encountered an error analysing that for you. Please try again later."])
      setAiQuestions([])
    }
  }

  function handleAIError(type) {
    switch (type) {
      case "monthlyTokenLimitExceeded":
        setAiChat(["Looks like you've made too many requests this month. Please wait until next month, or email us at at support@tentoplus.com to ask about increasing your limit."])
        setAiQuestions([])
        break
      default:
        setAiChat(["Looks like we've encountered an error analysing that for you. Please try again later."])
        setAiQuestions([])
    }
  }
  // -----------------------------
  // Handle the step stored in the database 
  // -----------------------------
  function handleAiSteps(stepName, data) {
    const step = props.aiSteps._source[stepName]
    console.log("handleAiSteps", stepName, step, data)
    var chosenGroup = ""
    var chosenGroupReturned = ""
    if (data && Object.keys(data).includes("objectName")) {
      chosenGroup = `I will analyse using "${data.objectName}"`
      chosenGroupReturned = `I have analysed using "${data.objectName}"`
    }
    const deviceSource = window.sessionStorage.getItem("deviceSource")
    var text = ""
    const updatedProps = { ...props, device: { ...props.device, _source: JSON.parse(deviceSource) } }
    switch (step["type"]) {
      case "ifStatement":
        console.log("IFstatement", updatedProps, step["if"]["ifCheckParameter"].split('.').reduce((o, i) => o[i], updatedProps))
        if (
          [...step["if"]["ifCheckValue"], undefined].includes(step["if"]["ifCheckParameter"].split('.').reduce((o, i) => o[i], updatedProps)) ||
          [...step["if"]["ifCheckValue"], undefined].includes(step["if"]["ifCheckParameter"].split('.').reduce((o, i) => o[i], updatedProps).replaceAll(" ", ""))
        ) {

          handleAiSteps(step["if"]["options"]["true"], data)

        } else {

          handleAiSteps(step["if"]["options"]["false"], data)
        }
        return 0
      case "searchForIDFromData":
        console.log("searchForIDFromData", data, step["if"]["fieldToCheck"], data[step["if"]["idToCheck"]])
        const exampleRequirement = props[step["if"]["dataArrayToCheck"]].find(r => r._source[step["if"]["fieldToCheck"]] === data[step["if"]["idToCheck"]])
        console.log("searchForIDFromData", exampleRequirement)
        if (
          exampleRequirement
        ) {

          handleAiSteps(step["if"]["options"]["true"], { ...data, reqExample: exampleRequirement._source.title })

        } else {

          handleAiSteps(step["if"]["options"]["false"], data)
        }
        return 0
      case "useData":

        var dataVals = Object.keys(step["data"]).reduce((acc, v) => { return { ...acc, [v]: step["data"][v].split('.').reduce((o, i) => o[i], props) }; }, {})
        if (step["dataType"] === "Test Logs") {
          dataVals = { ...dataVals, requirements: props.reqs.filter(req => req._source.group === data.reqGroupID).map(r => { return { id: r._id, requirement: r._source.title } }) }
        }

        aiSubmitText(step["endpoint"], { ...data, ...dataVals }, step["dataType"], step["returnStep"])
        handleAiSteps(step["nextStep"])

        return 0

      case "assessData":
        var dataVals
        if (step["dataType"] === "Anomalies") {
          dataVals = { ...dataVals, props }
        }

        performImpactAssessment(dataVals, step["dataType"], step["returnStep"])
        //handleAiSteps(step["nextStep"])

        return 0
      case "textInput":
        setAiChat([chosenGroup, step["text"]])
        setAiQuestions([{
          text: <Form onSubmit={(event) => {
            event.preventDefault();
            aiSubmitText(step["endpoint"], { ...data, ...["description"].reduce((acc, v) => { return { ...acc, [v]: event.target[v].value }; }, {}) }, step["dataType"], step["returnStep"])
            handleAiSteps(step["nextStep"], data)
          }}>
            <TextBoxWithCount ></TextBoxWithCount>

          </Form>
        }])
        return 0
      case "reqTextInput":
        setAiChat([chosenGroup, step["text"]])
        setAiQuestions([{
          text: <Form onSubmit={(event) => {
            event.preventDefault();
            // aiSubmitText(step["endpoint"], { ...data, ...["reqExample"].reduce((acc, v) => { return { ...acc, [v]: event.target[v].value }; }, {}) }, step["dataType"], step["returnStep"])
            handleAiSteps(step["nextStep"], { ...data, ...["reqExample"].reduce((acc, v) => { return { ...acc, [v]: event.target[v].value }; }, {}) })
          }}>
            <ReqTextBoxWithCount ></ReqTextBoxWithCount>

          </Form>
        }])
        return 0
      case "userChoice":
        const aiChats = step["chats"].map(chat => {
          switch (chat.type) {
            case "data":
              return chat.value.split('.').reduce((o, i) => o[i], updatedProps)
            default:
              return chat.text
          }
        })
        setAiChat([chosenGroup, aiChats])
        setAiQuestions(
          step["questions"].map(
            q => { return { text: q["text"], action: (() => { handleAiSteps(q["nextStep"], data) }) } }
          )
        )
        return 0
      case "addItemsFromList":
        var listDisplayItems = [...step["itemList"]]
        switch (step["listType"]) {
          case "Test Logs":
            listDisplayItems = [...listDisplayItems, ...props.reqGroups.low.map(r => {
              // console.log("OPTION:",r._source.ref,r._source.title,r._source.title.replaceAll(" and", "").slice(0, 3))
              return {
                "title": r._source.title + " Unit Test",
                "ref": r._source.ref !== "" ? r._source.ref : r._source.title.replaceAll(" and", "").slice(0, 3),
                "description": `Unit Testing for ${r._source.title}`
              }
            })]
            break
          default:

        }
        setAiChat([step["text"]])
        setAiQuestions(step["questions"].map(
          q => { return { text: q["text"], action: (() => { handleAiSteps(q["nextStep"]) }) } }
        )
        )
        setAiResult({ type: step.listType + " List", "results": { results: listDisplayItems } })
        return 0
      case "objectChoice":

        setAiChat([step["text"]])
        // console.log("OBJ", step)
        var objVals = step["object"].split('.').reduce((o, i) => o[i], props) // props[]
        if (["DesignOutputCheckDescription", "TestPlanCheckDescription"].includes(step["nextStep"])) {
          objVals = objVals.filter(obj => props.reqs.some(r => r._source.group === obj._id))
        }
        // console.log(objVals)
        setAiQuestions(
          objVals.map(
            q => { return { text: q._source.title, action: (() => { handleAiSteps(step["nextStep"], { objectName: q._source.title, objectID: q._id, objectLevel: q._source.level, }) }) } }
          )
        )
        return 0
      case "waiting":
        setAiChat([step["text"]])
        setAiQuestions([{
          text: <Spinner>
            Loading...
          </Spinner>
        }]
        )
        return 0
      case "returned":
        setAiChat([chosenGroupReturned, step["chats"]])
        setAiQuestions(step["questions"].map(
          q => { return { text: q["text"], action: (() => { handleAiSteps(q["nextStep"]) }) } }
        )
        )
        setAiResult(data)
        return 0
      case "reset":
        var filteredOptions = Object.keys(props.aiSteps._source).reduce(function (r, e) {
          if (["topLevelQ"].includes(props.aiSteps._source[e].type)) r[e] = props.aiSteps._source[e]
          return r;
        }, {})
        setAiResult([])
        setAdded([])
        setAiChat(["Hi, I'm GeMA, your personalised generative MedTech AI-assistant. I'm here to help you generate content and assess impact of changes on your devices. "])
        setAiQuestions(

          Object.values(filteredOptions).map(v => { return { text: v["question"]["text"], action: (() => { handleAiSteps(v["question"]["nextStep"]) }) } })
        )
      default:
        // console.log()
        return
    }

  }

  const toggleAIMenu = () => {
    console.log("AI RERENDER TOGGLE", )
    var filteredOptions = Object.keys(aiSteps._source).reduce(function (r, e) {
      if (["topLevelQ"].includes(aiSteps._source[e].type)) r[e] = aiSteps._source[e]
      return r;
    }, {})

    // console.log("AIOPEN", props.isAIOpen)
    dispatch(aiMenu(!props.isAIOpen))
    // setIsOpen(!isOpen)
    setAdded([])
    setAiResult([])
    setAiChat(["Hi, I'm GeMA, your personalised generative MedTech AI-assistant. I'm here to help you generate content and assess impact of changes on your devices."])
    setAiQuestions(

      Object.values(filteredOptions).map(v => { return { text: v["question"]["text"], action: (() => { handleAiSteps(v["question"]["nextStep"]) }) } })
    )
  };

  // ---------------------------------------
  // Function for adding AI results from the menu. For each type, it will dispatch the correct action. 
  // ---------------------------------------
  function addAIResult(resultItem, resultObjectType, resultObjectID) {
    const configID = window.sessionStorage.getItem("configID")
    console.log("addAIResult", resultItem, resultObjectType, resultObjectID)

    switch (resultObjectType) {
      case "Requirement Groups":
        const level = { product: "top", "system": "mid", "component": "low" }[resultItem.level]


        dispatch(createGroup("", { idDevice: props.device._id, configID, level: level, title: resultItem.title, ref: resultItem.ref }))
        return 0
      case "Requirements":
        const newOrder = Math.max(...props.reqs.map(t => t._source.order)) + 1;

        const reqObj = {
          idDevice: props.device._id,
          created: new Date(),
          title: resultItem.title,
          ref: resultItem.ref,// ""+(props.reqs.filter( r =>r._source.group ===resultItem.reqGroupID).length+1) ,
          createdBy: account.localAccountId,
          status: 1,
          level: resultItem.level,
          group: resultItem.reqGroupID, configID,
          type: 1, child: [], parent: [], reqchild: [], required: "Mandatory", reqparent: [], order: newOrder
        };
        dispatch(createReq(reqObj))
        return 0
      case "Test Logs":
        const TestObj = {
          idDevice: props.device._id, configID,
          created: new Date(),
          title: resultItem.title,
          ref: resultItem.ref,// ""+(props.reqs.filter( r =>r._source.group ===resultItem.reqGroupID).length+1) ,
          createdBy: account.localAccountId,
          status: 1,
          desc: resultItem.description,
          idBoard: "",
          notes: "",
          result: "",
          testitems: "",


        };
        dispatch(createTestLog(TestObj))
        break
      case "Test Records":

        const newrefnum = props.records.filter(r => r._source.idTestLog === resultObjectID).length + 1

        const recordObj =
        {
          title: resultItem.title, desc: resultItem.description,
          configID, status: 1, idBoard: props.idBoard, created: new Date(), idDevice: props.device._id,
          createdBy: account.localAccountId, idTiles: [], result: 0, idTestLog: resultObjectID, ref: newrefnum.toString(),
          "tools": "",
          "inputs": "",
          "notes": "",
          "version": "",

        }
        dispatch(createRecord(recordObj))
        break
      case "Test Logs List":
        const TestLogObj = {
          idDevice: props.device._id,
          configID,
          created: new Date(),
          title: resultItem.title,
          ref: resultItem.ref,// ""+(props.reqs.filter( r =>r._source.group ===resultItem.reqGroupID).length+1) ,
          createdBy: account.localAccountId,
          status: 1,
          desc: resultItem.description,
          idBoard: "",
          notes: "",
          result: "",
          testitems: "",


        };
        dispatch(createTestLog(TestLogObj))
        break
      case "Design Outputs":
        const newDesignOrder = Math.max(...props.tiles.map(t => t._source.order)) + 1;

        const newTileObj = {
          idDevice: props.device._id,
          configID,
          created: new Date(),
          title: resultItem.title,
          ref: resultItem.ref,
          desc: "",
          createdBy: account.localAccountId,
          status: 1,
          idBoard: "",
          group: resultItem.group,
          type: { "functions": 2, "units": 3, "source": 4 }[resultItem.type],
          child: [], parent: [], reqchild: [], reqparent: [], order: newDesignOrder
        };
        dispatch(createTile(newTileObj))
        // console.log(resultItem,newTileObj)

        break
      default:
        break
    }
  }

  function handleResultDisplay(res, resGroup, index) {
    var title
    var button
    var ref
    console.log("HANDLEReulst", props.reqs, aiResult)
    const anomDescCuttoff = 60
    switch (aiResult.type) {
      case "Anomalies":
        title = `${res.anomRef} - ${["NONE", "PASS", "MAJOR", "MINOR", "OBSERVATION"][res.type]} - affects ${res.number} test runs: ${res.anomTitle.substring(0, anomDescCuttoff)}${res.anomTitle.length > anomDescCuttoff ? "..." : ""}`
        break
      case "Requirements":
        ref = "" + (Math.max(...[0, ...props.reqs.filter(r => r._source.group === aiResult.objectID).map(r => parseInt(r._source.ref))]) + 1)
        title = res
        button = { title: res, ref: ref, reqGroupID: aiResult.objectID, level: aiResult.objectLevel }
        break
      case "Requirement Groups":
        ref = res.slice(0, 3).toLocaleUpperCase()
        title = res
        button = { title: res, level: resGroup, ref: ref }
        break
      case "Test Logs List":
        ref = res.ref
        title = res.title
        button = { title: title, description: res["description"], ref: ref }
        break
      case "Test Records":
        ref = res["Test Title"].replaceAll(" and", "").split(" ").slice(0, -1).map(r => r.slice(0, 3).toLocaleUpperCase()).join("")
        title = res["Test Title"]
        button = { title: title, description: res["Description"], ref: ref }
        break
      case "Test Logs":
        ref = res["Test Title"].replaceAll(" and", "").split(" ").map(r => r.slice(0, 3).toLocaleUpperCase()).join("")
        title = res["Test Title"] + " - " + res["Description"]
        button = { title: res["Test Title"], description: res["Description"], ref: ref }
        break
      case "Design Outputs":
        ref = resGroup.slice(0, 1).toLocaleUpperCase() + index
        title = res + " (" + resGroup.slice(0, -1) + ")"
        button = { title: res, ref: ref, type: resGroup, group: aiResult.objectID }
        break
      default:
        return 0
    }
    return [title, button, ref]
  }
  // ---------------------------------------
  // Display OffCanvas
  // ---------------------------------------
  if (aiChat && aiQuestions) {
    return (
      <span>
        <Button onClick={() => toggleAIMenu()} style={{ background: "purple", margin: "0 20px" }} > <img id="ai_tt" src={ai_icon} alt="GeMa" /> </Button>

        <UncontrolledTooltip target="ai_tt" placement="right" >Use our AI-enabled assitant, GeMA to help! </UncontrolledTooltip>


        <Offcanvas
          className={styles.AIMenu}
          direction="end"
          zIndex={10000}
          isOpen={props.isAIOpen}
          toggle={toggleAIMenu}
          backdrop={false}
        >
          <OffcanvasHeader toggle={toggleAIMenu} >
            <img id="ai_tt" src={ai_solid_icon} alt="regulation" /> How can I help?
            <p className={styles.AIDisclaimer}>Any outputs given are only suggestions. Tento+ does not guarantee regulatory and safety compliance.</p>
          </OffcanvasHeader>
          <OffcanvasBody>
            {aiChat.map(sentence => {
              if (sentence !== "") {
                return (
                  <p className={styles.AIChat}>{sentence}</p>
                )
              }
            })}
            {aiQuestions.map(question => {
              return (
                <p onClick={question.action} className={styles.AIMenuButton}>{question.text}</p>
              )
            })}
            {aiResult.results && aiResult.type != "Anomalies" && <Row>
              <Col xs={3}>
                <p style={{ margin: "auto", cursor: "pointer" }}
                  onClick={() => {
                    var tmpAdded = [...added]
                    Object.keys(aiResult.results).map(resGroup => {
                      const max_ref = Math.max(...[0, ...props.reqs.filter(r => r._source.group === aiResult.objectID).map(r => parseInt(r._source.ref))])
                      const already_added_index = tmpAdded.length > 0 ? 0 : 1
                      aiResult.results[resGroup].forEach((res, index) => {
                        var [title, button, ref] = handleResultDisplay(res, resGroup, index)
                        if (aiResult.type === "Requirements") {
                          button.ref = "" + (max_ref + index + already_added_index)
                        }

                        if (!tmpAdded.includes("" + resGroup + index)) {
                          tmpAdded = [...tmpAdded, "" + resGroup + index]
                          addAIResult(button, aiResult.type, aiResult.objectID);
                        }
                      })
                    })
                    setAdded([...added, ...tmpAdded])
                  }}
                ><i className="mdi mdi-plus" style={{ cursor: "pointer", color: "purple", margin: "auto" }} /> Add all</p>
              </Col>
            </Row>}

            {aiResult.results && aiResult.type !== "Anomalies" && Object.keys(aiResult.results).map(resGroup => {
              console.log("aiResult", aiResult, aiResult.results, resGroup)
              // (<div>
              { aiResult.type === "Requirement Groups" && <span>{resGroup}</span> }
              return [
                aiResult.type === "Requirement Groups" && <span style={{ "textTransform": "capitalize" }}>{resGroup} Level Groups</span>,
                aiResult.results[resGroup].map((res, index) => {
                  var [title, button, ref] = handleResultDisplay(res, resGroup, index)

                  // console.log("handleResultDisplay", title, button, ref)
                  return <Row style={{ leftMargin: "5px" }}>
                    <Col xs={1}>
                      {!added.includes("" + resGroup + index) && <i onClick={() => {

                        addAIResult(button, aiResult.type, aiResult.objectID); setAdded([...added, "" + resGroup + index])
                      }} className="mdi mdi-plus" style={{ color: "purple", cursor: "pointer", margin: "auto" }}></i>}
                      {added.includes("" + resGroup + index) && <i className="mdi mdi-check-circle-outline" style={{ color: "purple", margin: "auto" }}></i>}
                    </Col>
                    <Col xs={10}>{title} </Col>
                    <br />

                  </Row>


                })]


              // </div>
              // )
            })
            }

            {aiResult.results && aiResult.type === "Anomalies" && Object.keys(aiResult.results).map(resGroup => {

              return aiResult.results[resGroup].map((res, index) => {
                if (index > 10) { return }
                var [title, button, ref] = handleResultDisplay(res, resGroup, index)
                return <Row style={{ leftMargin: "5px" }}>
                  <Col xs={1}></Col>
                  <Col xs={10}>{title} </Col>
                  <br />
                  <br />

                </Row>


              })


              // </div>
              // )
            })
            }

          </OffcanvasBody>
        </Offcanvas>
      </span>
    )
  }
}

export { AIMenuButton }