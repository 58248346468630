import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import {
    Row,
    Col,
    Card,
    CardBody,
    Button,
    UncontrolledDropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    Media,
    UncontrolledTooltip, Form, FormGroup, Label, Input
} from 'reactstrap';
import classNames from 'classnames';
import { connect, useSelector, useDispatch } from 'react-redux';
import { updateReq } from '../../../../redux/actions';
import {
    InteractionRequiredAuthError,
    InteractionStatus,
} from "@azure/msal-browser";
import styles from './styles.module.css';
import card from '../../../../components/Styles/card.module.css';
import tables from '../../../../components/Styles/table.module.css';
import { ResultLabels } from '../constants';
import Switch from "react-switch";

const createMarkup = text => {
    return { __html: text };
};

const TaskWindow = (props) => {
    const AUTHAPIURL = process.env.REACT_APP_STAGE == 'stage'
        ? "https://tentodevauth.azurewebsites.net/"
        : process.env.REACT_APP_STAGE == 'production' ? "https://tentoauth.azurewebsites.net/" : "http://localhost:5001/";
    const dispatch = useDispatch();
    const { task } = props
    const account = props.account
    const instance = props.instance
    const [completed, setCompleted] = useState(task.stage === 'Done');
    const markCompleted = e => setCompleted(e.target.checked);
    const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
    const [createdByName, setcreatedByName] = useState(task._source && props.userOptionObjs[task._source.createdBy] ? props.userOptionObjs[task._source.createdBy].displayName : "")
    const [description, setDescription] = useState();
    const [note, setNote] = useState();
    const [editDetails, setEditDetails] = useState({ ref: false, name: false });
    const [required, setRequired] = useState();
    const [group, setGroup] = useState();
    const [textareaSize, setTextareaSize] = useState(100);
    const [selectedTab, setSelectedTab] = useState("testing");
    // const [details, setDetails] = useState({title:"",ref:""});
    const [taskSource, setTaskSource] = useState();
    async function getUserForID() {
        //TODO: this will fetch the user name for the id for every task. 
        //  This is very ineficient- need to use an object fetched from all contributors,
        //  then only do this search if user is missing e.g they've been removed as contrib
        let tokenResp
        if (account) {
            tokenResp = await instance.acquireTokenSilent({
                account: account
            }).then((response) => {
                if (response) {
                    return response
                }
            }).catch((error) => {
                if (error instanceof InteractionRequiredAuthError) {
                    instance.acquireTokenRedirect({
                        account: account,
                    });
                    //  console.log(error)
                }
            }).then((response) => {
                if (response) {
                    return response
                }
            });
        }
        fetch(
            AUTHAPIURL + `getUserForID`,
            {
                method: "POST",
                body: JSON.stringify({ "id": props.task._source.createdBy }),
                // mode: 'cors',
                headers: new Headers({
                    Accept: "application/json",
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${tokenResp.idToken}`
                }
                )

            }
        )
            .then(res => res.json()).then(
                (result) => {
                    setcreatedByName(result.displayName)
                }
            )
            .catch();
    }

    function updateTask(key, value) {


        //dispatch(updateReq(task._id, {...taskSource}))
        // if(task._source[key]!==value || key=="group"|| key=="required"){
        //     console.log("UPDATE:", {...task._source,[key]:value})
        setTaskSource({ ...taskSource, [key]: value })
        dispatch(updateReq(task._id, { ...taskSource, [key]: value }))

        // }
    }

    function getRef(record) {
        const passLog = props.testlogs.filter(log => log._id === record._source.idTestLog)[0];
        return `${passLog._source.ref}-${record._source.ref}`
    }

    function getRelated(record) {
        const related = props.allTasks.filter(task => record._source.idTiles.includes(task._id));

        return related.sort((a, b) => {
            const newA = record._source.ref + "-" + a._source.ref
            const newB = record._source.ref + "-" + b._source.ref

            return newA.localeCompare(newB, 'en', { numeric: true })
        });
    }

    useEffect(() => {
        if (props.task._source) {
            getUserForID()
            setTaskSource(props.task._source)
            setDescription(props.task._source.desc ? props.task._source.desc : "")
            setNote(props.task._source.note ? props.task._source.note : "")
            setRequired(props.task._source.required ? props.task._source.required : "Mandatory")
            setGroup(props.task._source.group ? props.task._source.group : "")
            //setDetails(props.task._source?{title:task._source.title,ref:task._source.ref}:{title:"",ref:""})
        } else {
            setTaskSource(undefined)
        }

        // getUserForID()


    }, [props.task]);

    function setSize(e) {
        // this.style.height = "";this.style.height = this.scrollHeight + "px";
        e.target.style.height = "";
        e.target.style.height = e.target.scrollHeight + "px";
    }
    var taskStatus = props.getReqTestStatus(task._id)
    var taskStatusOutput = taskStatus.value
    if (taskStatus.value === "Not Met") {

        if (taskStatus.runs.includes(undefined)) {
            var recordIndex = taskStatus.runs.indexOf(undefined)
            taskStatusOutput = taskStatus.records[recordIndex]._source.ref + "-" + taskStatus.records[recordIndex]._source.title
        } else {
            //     var runAnomalyIDs = []
            //     taskStatus.runs.forEach(rn => {
            //         rn._source.results.forEach(res => {
            //             typeof res.idAnom === "string" ? runAnomalyIDs = [...runAnomalyIDs, res.idAnom] : runAnomalyIDs = [...runAnomalyIDs, ...res.idAnom]
            //         })
            //     });

            //     const runAnomalies = props.anoms.filter( anm => runAnomalyIDs.includes(anm._id))
            //    var failingRun = taskStatus.runs.filter(run =>{
            //     run._source.results.some( res => {
            //         var runAnoms = runAnomalies.filter( anm => res.idAnom.includes(anm._id))
            //         return runAnoms.some( anm => [2, 3].includes(anm._source.type) )
            //     })
            //    })
            //     // var failingRun = taskStatus.runs.filter(run =>{
            //     //     console.log("FAILING",run,props.anoms.filter(an => [2, 3].includes(an._source.type) && an._source.result === 1 && run._source.results.some( res => res.idAnom.includes(an._id))))
            //     //     return props.anoms.filter(an => [2, 3].includes(an._source.type) && an._source.result === 1 && run._source.results.some( res => res.idAnom.includes(an._id))).length>0
            //     // })[0]
            //     console.log("FAILING RUN:", taskStatus.runs, failingRun, taskStatus.records)
            var failingRecord = taskStatus.records.filter(rec => rec._id === taskStatus.runs[0]._source.idTest)[0]
            var failingLog = props.testlogs.filter(log => log._id === failingRecord._source.idTestLog)[0]
            taskStatusOutput = failingLog._source.ref + "-" + failingRecord._source.ref

        }
    }
    else if (taskStatus.value === "To Test") {
        taskStatusOutput = "TO TEST"
    }
    else if (taskStatus.value === "Verified") {

        var passingRun = taskStatus.runs[0]//.filter(run => run.result === 1)[0]
        var passingRecord = taskStatus.records.filter(rec => rec._id === passingRun._source.idTest)[0]
        var passLog = props.testlogs.filter(log => log._id === passingRecord._source.idTestLog)[0]
        taskStatusOutput = passLog._source.ref + "-" + passingRecord._source.ref
    }



    const testingRows = taskStatus.records.map((record, index) => {
        const relatedRuns = props.runs.filter(r => r._source.idTest === record._id);
        const starResult = relatedRuns.filter(r => r._source.star === true);
        var relatedAnomIds = []
        starResult.forEach(anm => {
            anm._source.results.forEach(res => {
                if (res.idAnom.length > 0) {
                    if (typeof res.idAnom === "string") {
                        relatedAnomIds.push(res.idAnom)
                    } else {
                        relatedAnomIds = [...relatedAnomIds, ...res.idAnom]
                    }
                }
            })

        });
        const relatedAnoms = props.anoms.filter(anom => relatedAnomIds.includes(anom._id))
        const relatedLog = props.testlogs.filter(l => l._id === record._source.idTestLog)[0];
        if (relatedLog) {
            return (
                <tr>
                    <td className={tables.tableTD} style={{ padding: "5px" }}>{getRef(record, index)}</td>
                    <td className={tables.tableTD} style={{ padding: "5px" }}>{record._source.title}</td>
                    <td className={tables.tableTD} style={{ padding: "5px" }}>
                        {/* {taskStatusOutput} */}
                        {relatedAnoms.some(res => res._source.type === 2) ? (<><span className={styles.cardHeader}></span> {" "}<span className={styles.redCounter}>{"MAJOR"}</span></>) :
                            relatedAnoms.some(res => res._source.type === 3) ? (<><span className={styles.cardHeader}></span> {" "}<span className={styles.redCounter}>{"MINOR"}</span></>) :
                                relatedAnoms.some(res => res._source.type === 4) ? (<><span className={styles.cardHeader}></span> {" "}<span className={styles.greenCounter}>{"OBSERVATION"}</span></>) :
                                    starResult.some(res => res._source.result === 1) ? (<><span className={styles.cardHeader}></span> {" "}<span className={styles.greenCounter}>{ResultLabels[starResult.filter(res => res._source.result === 1)[0]._source.result]}</span></>) :
                                        (<><span className={styles.cardHeader}></span> {" "}<span className={styles.greyCounter}>TO TEST</span></>)}
                    </td>
                </tr>
            )
        }
    })

    return (
        <React.Fragment>
            {taskSource &&
                <div data-tut="reactour__window">
                    <Card className={card.card} style={{ marginBottom: "0" }}>
                        <CardBody className={card.cardBody} style={{ padding: "0", maxHeight: "100vh" }}>


                            <Row>
                                <Col>

                                    <div className={styles.cardHeadTitle} style={{ padding: "10px 20px" }}>

                                        <div>
                                            <FormGroup>

                                                {"Requirement Description: "}
                                                <i className="uil uil-info-circle" id="desctooltip" ></i>
                                                <UncontrolledTooltip
                                                    target="desctooltip"
                                                    placement="right"
                                                >
                                                    Give a description of the requirement to specify what the device needs to be able to do/have.
                                                </UncontrolledTooltip>

                                                <Input value={taskSource.title} on onChange={(e) => setTaskSource({ ...taskSource, title: e.target.value })} type="textarea" disabled={(props.device._id === "js1wBogB1L2gw3Qicv2Q" || !props.hasRiskSub)}
                                                    onBlur={() => { setEditDetails({ ...editDetails, title: false }); updateTask('title', taskSource.title) }} style={{ display: "inline", height: "200px" }} />
                                                {/* <i onClick={()=> {setEditDetails({...editDetails,title:false});updateTask('title',taskSource.title)}}className="mdi mdi-check-circle-outline"></i> */}

                                            </FormGroup>
                                        </div>
                                    </div>
                                    <div style={{ padding: "10px 20px" }}>

                                        {/* } */}

                                        <Row>
                                            <Col sm={2}>

                                                {"Ref: "}
                                                <i className="uil uil-info-circle" id="reftooltip" ></i>
                                                <UncontrolledTooltip
                                                    target="reftooltip"
                                                    placement="right"
                                                >
                                                    Give the requirement a unique id so that it can be linked to test records and design outputs easily.
                                                </UncontrolledTooltip>
                                                {props.group._source.ref + "-"}
                                                <Input label={"hello"} value={taskSource.ref} onBlur={() => { setEditDetails({ ...editDetails, ref: false }); updateTask('ref', taskSource.ref) }} disabled={(props.device._id === "js1wBogB1L2gw3Qicv2Q" || !props.hasRiskSub)}
                                                    onChange={(e) => setTaskSource({ ...taskSource, ref: e.target.value })} style={{ width: "4vw", display: "inline" }} />

                                                {/* <i onClick={()=> {setEditDetails({...editDetails,ref:false});updateTask('ref',taskSource.ref)}}className="mdi mdi-check-circle-outline"></i> */}

                                            </Col>
                                            <Col sm={4}>

                                                {"Required? "}
                                                <i className="uil uil-info-circle" id="reqtooltip" ></i>
                                                <UncontrolledTooltip
                                                    target="reqtooltip"
                                                    placement="right"
                                                >
                                                    Choose whether this requirement is optional or mandatory.
                                                </UncontrolledTooltip>
                                                <Input
                                                    onChange={(event) => {
                                                        setTaskSource({ ...taskSource, required: event.target.value });
                                                        updateTask('required', event.target.value)
                                                    }}
                                                    id="mandatory"
                                                    name="mandatory"
                                                    type="select"
                                                    value={taskSource.required}
                                                    disabled={(props.device._id === "js1wBogB1L2gw3Qicv2Q" || !props.hasRiskSub)}
                                                >
                                                    <option
                                                    // onClick={(event) =>{console.log("required:",event.target.value);setTaskSource({...taskSource,required:event.target.value});
                                                    //      updateTask('required',event.target.value)
                                                    //  }}
                                                    >
                                                        Mandatory
                                                    </option>
                                                    <option
                                                    // onClick={(event) =>{console.log("GROUP:",event.target.value);updateTask('required',event.target.value)
                                                    //  }}
                                                    >
                                                        Optional
                                                    </option>

                                                </Input>

                                            </Col>
                                            <Col sm={4}>

                                                {"Group "}
                                                <i className="uil uil-info-circle" id="grouptooltip" ></i>
                                                <UncontrolledTooltip
                                                    target="grouptooltip"
                                                    placement="right"
                                                >
                                                    Change which group this requirement is a part of.
                                                </UncontrolledTooltip>

                                                <Input
                                                    onChange={(event) => {
                                                        // const e =JSON.parse(event.target.value)
                                                        updateTask('group', event.target.value)
                                                        setTaskSource({ ...taskSource, "group": event.target.value })
                                                        // setGroup(event.target.value);updateTask('group',event.target.value) 
                                                    }}
                                                    disabled={(props.device._id === "js1wBogB1L2gw3Qicv2Q" || !props.hasRiskSub)}
                                                    id="group"
                                                    name="group"
                                                    type="select"
                                                    value={taskSource.group
                                                        // props.reqGroups[taskSource.level].filter(e => e._id ===taskSource.group)[0]._source.title
                                                        // 
                                                    }
                                                // value ={props.task._source.required}
                                                >
                                                    {/* {console.log("REQGROUPS:",props.reqGroups[taskSource.level].filter(e => e._id ===taskSource.group)[0]._source.title)} */}


                                                    {props.reqGroupsRaw.map(e => {
                                                        return (
                                                            <option value={e._id}
                                                            //     onClick={(event) =>{console.log("GROUP:",e._id,e._source.title);updateTask('group',e._id);setTaskSource({...taskSource,group:e._id});
                                                            //  }}
                                                            >
                                                                {e._source.title}
                                                            </option>)
                                                    })}


                                                </Input>

                                            </Col>
                                            <Col sm={2}>
                                                {"Risk "}
                                                <i className="uil uil-info-circle" id="risktooltip"></i>
                                                <UncontrolledTooltip
                                                    target="risktooltip"
                                                    placement="right"
                                                >
                                                    Set whether this is a risk control requirement.
                                                </UncontrolledTooltip>
                                                <Switch
                                                    checked={taskSource.riskReq}
                                                    disabled={(props.device._id === "js1wBogB1L2gw3Qicv2Q" || !props.hasRiskSub)}

                                                    onChange={(val) => {
                                                        updateTask('riskReq', val)
                                                        setTaskSource({ ...taskSource, "riskReq": val })
                                                    }}
                                                />
                                            </Col>
                                        </Row>
                                        <br />
                                        <Row>
                                            <Col>




                                                {"Measurable  Details: "}
                                                <i className="uil uil-info-circle" id="measuretooltip" ></i>
                                                <UncontrolledTooltip target="measuretooltip" placement="right" >
                                                    Detail here extra measurable requirements to speficy further how the requirement should be met.
                                                </UncontrolledTooltip>

                                                <Input
                                                    value={description}
                                                    onBlur={() => updateTask('desc', description)}
                                                    onChange={(event) => setDescription(event.target.value)}
                                                    id="descText"
                                                    name="desc"
                                                    type="textarea"
                                                    disabled={(props.device._id === "js1wBogB1L2gw3Qicv2Q" || !props.hasRiskSub)}
                                                />

                                            </Col>
                                        </Row>
                                        <br />
                                        <Row>
                                            <Col>
                                                Notes

                                                <Input
                                                    value={note}
                                                    onBlur={() => updateTask('note', note)}
                                                    onChange={(event) => { console.log("NOTE:", note, event.target.value); setNote(event.target.value) }}
                                                    id="noteText"
                                                    name="note"
                                                    type="textarea"
                                                    disabled={(props.device._id === "js1wBogB1L2gw3Qicv2Q" || !props.hasRiskSub)}
                                                />
                                            </Col>
                                        </Row>
                                        <br />
                                        <Row>
                                            <div className={styles.requirementTableContainer}>
                                                <div className={styles.requirementTabs}>
                                                    <span
                                                        className={styles.requirementTab}
                                                        onClick={() => setSelectedTab("testing")}
                                                        style={{ color: selectedTab === "testing" ? "#35A8E0" : "" }}
                                                    >
                                                        Testing
                                                    </span>
                                                    {/*
                                                    <div className={styles.requirementDivider} />
                                                    <span
                                                        className={styles.requirementTab}
                                                        onClick={() => setSelectedTab("related")}
                                                        style={{ color: selectedTab === "related" ? "#35A8E0" : "" }}
                                                    >
                                                        Related
                                                    </span>
                                                    */}
                                                </div>

                                                <div className={styles.requirementContent}>
                                                    {selectedTab === "testing" &&
                                                        <table className={tables.table}>
                                                            <thead className={tables.tableHead}>
                                                                <tr>
                                                                    <th className={tables.tableTH} style={{ padding: "10px 5px", width: "10%" }}>Reference</th>
                                                                    <th className={tables.tableTH} style={{ padding: "10px 5px", width: "55%" }}>Name</th>
                                                                    <th className={tables.tableTH} style={{ padding: "10px 5px", width: "10%" }}>Status</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {
                                                                    testingRows
                                                                }
                                                            </tbody>

                                                        </table>
                                                    }
                                                    {props.hasRiskSub && <p onClick={() => props.editCoverage(task)} className={styles.reqCrossRef}>+ Add Test Record Cross Reference</p>
                                                    }
                                                    {/*selectedTab === "related" &&
                                                        <table className={tables.table}>
                                                            <thead className={tables.tableHead}>
                                                                <tr>
                                                                    <th className={tables.tableTH} style={{ padding: "10px 5px", width: "10%" }}>Reference</th>
                                                                    <th className={tables.tableTH} style={{ padding: "10px 5px", width: "65%" }}>Name</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {props.getReqTestStatus(task._id).records.map((record, index) => (
                                                                    <>
                                                                        {getRelated(record).map(tile => (
                                                                            <tr>
                                                                                <td className={tables.tableTD} style={{ padding: "5px" }}>{`${record._source.ref}-${tile._source.ref}`}</td>
                                                                                <td className={tables.tableTD} style={{ padding: "5px" }}>{tile._source.title}</td>
                                                                            </tr>
                                                                        ))}
                                                                    </>
                                                                ))}
                                                            </tbody>
                                                        </table>
                                                                        */}
                                                </div>
                                            </div>
                                        </Row>
                                        <br />
                                        <Row>

                                            <Col sm={9}>
                                            </Col>
                                            <Col sm={3}>
                                                <Button className='mdi mdi-delete' color="danger"
                                                    disabled={(props.device._id === "js1wBogB1L2gw3Qicv2Q" || !props.hasRiskSub)}
                                                    onClick={() => props.deleteWarning({ _id: task._id, _source: task._source, taskWindowSelectTask: props.selectTask }, "requirement")}
                                                    //onClick={()=>{
                                                    //setToDeleteDoc({id:evidenceSection._id,item:selectedSectionItem[evidenceSection._id]}); setshowWarningDocModal(true)

                                                    // removeEvidenceDoc(evidenceSection._id,selectedSectionItem[evidenceSection._id]);
                                                    // setSelectedSectionItem({...selectedSectionItem,[evidenceSection._id]:""})}}
                                                    style={{ float: "right" }}>Delete</Button>
                                                {/* <Button className='mdi mdi-copy' color="secondary"
                                              onClick={() => console.log("PROPS LIST", props)}
                                              >Copy</Button> */}
                                            </Col>
                                        </Row>

                                    </div>

                                    {/* {task.attachments.map((f, index) => {
                                const ext = f.filename.substr(f.filename.lastIndexOf('.') + 1);
                                return (
                                    <Card key={index} className="mb-2 shadow-none border">
                                        <div className="p-1">
                                            <Row className="align-items-center">
                                                <Col className="col-auto">
                                                    <div className="avatar-sm">
                                                        <span className="avatar-title rounded">.{ext}</span>
                                                    </div>
                                                </Col>
                                                <Col className="pl-0">
                                                    <Link to="#" className="text-muted font-weight-bold">
                                                        {f.filename}
                                                    </Link>
                                                    <p className="mb-0">{f.size}</p>
                                                </Col>
                                                <Col className="col-auto">
                                                    <Link
                                                        to="#"
                                                        id="btn-download"
                                                        className="btn btn-link text-muted btn-lg p-0 mr-1">
                                                        <i className="uil uil-cloud-download"></i>
                                                    </Link>
                                                    <UncontrolledTooltip placement="right" target="btn-download">
                                                        Download
                                                    </UncontrolledTooltip>

                                                    <Link
                                                        to="#"
                                                        id="btn-Delete"
                                                        className="btn btn-link text-danger btn-lg p-0">
                                                        <i className="uil uil-multiply"></i>
                                                    </Link>
                                                    <UncontrolledTooltip placement="right" target="btn-Delete">
                                                        Delete
                                                    </UncontrolledTooltip>
                                                </Col>
                                            </Row>
                                        </div>
                                    </Card>
                                );
                            })} */}

                                    {/* <div className="row mt-3">
                                <div className="col">
                                    <h5 className="mb-2 font-16">Comments</h5>

                                    {task.comments.map((comment, idx) => (
                                        <React.Fragment key={idx}>
                                            <Media key={idx} className="mt-3 p-1">
                                                <img
                                                    src={comment.author_avatar}
                                                    className="mr-2 rounded-circle"
                                                    height="36"
                                                    alt={comment.author}
                                                />
                                                <Media body>
                                                    <h5 className="mt-0 mb-0">
                                                        <span className="float-right text-muted font-12">
                                                            {comment.posted_on}
                                                        </span>
                                                        {comment.author}
                                                    </h5>
                                                    <p className="mt-1 mb-0 text-muted">{comment.text}</p>
                                                </Media>
                                            </Media>
                                            <hr />
                                        </React.Fragment>
                                    ))}
                                </div>
                            </div> */}

                                    {/* <Row className="mt-2">
                                <Col>
                                    <div className="border rounded">
                                        <form action="#" className="comment-area-box">
                                            <textarea
                                                rows="3"
                                                className="form-control border-0 resize-none"
                                                placeholder="Your comment..."></textarea>
                                            <div className="p-2 bg-light">
                                                <div className="float-right">
                                                    <button type="submit" className="btn btn-sm btn-success">
                                                        <i className="uil uil-message mr-1"></i>Submit
                                                    </button>
                                                </div>
                                                <div>
                                                    <Link to="#" className="btn btn-sm px-1 btn-light">
                                                        <i className="uil uil-cloud-upload"></i>
                                                    </Link>
                                                    <Link to="#" className="btn btn-sm px-1 btn-light">
                                                        <i className="uil uil-at"></i>
                                                    </Link>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </Col>
                            </Row> */}
                                </Col>
                            </Row>
                        </CardBody>
                    </Card>
                </div>
            }
        </React.Fragment>
    );
};

export default TaskWindow;