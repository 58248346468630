import React, { useState } from 'react';
import { Row, Col, Modal, ModalHeader, ModalBody, Button, ListGroup, ListGroupItem, Card, Form, FormGroup, Input } from 'reactstrap';
import CreatableSelect from 'react-select/creatable';

import BootstrapTable from 'react-bootstrap-table-next';
import cellEditFactory from 'react-bootstrap-table2-editor';
import modal from './Styles/modal.module.css';
import TentoModal from './TentoModal';

import { Link } from 'react-router-dom';



const deleteModal = (openState, actions, deleteType) => {

        const { handleDeleteWarning, toggleWarningModal } = actions
        return (
                <TentoModal
                        // style={{ minWidth: "80vw", marginLeft: "250px" }}
                        isOpen={openState} toggle={toggleWarningModal}
                        size="lg"
                        title={"Are you sure?"}
                        body={"Deleting will permanently remove this " + deleteType + ". This can NOT be undone. Are you sure?"}
                        fullWidthButtons={ //<Button className={modal.modalButton} color="danger" onClick={()=>{handleDeleteWarning()}}> Confirm </Button>
                                [
                                        {
                                                color: "danger",
                                                onClick: handleDeleteWarning,
                                                text: "Confirm"
                                        }
                                ]}
                >

                </TentoModal>

        )
}

const tagModal = (openState, actions, data) => {
        const { tags, idForModal } = data
        const { handleTagChange, handleTagCreate, handleTagsSave, toggleEditTagModal, toggleTagModal } = actions
        return (
                <div>
                        <TentoModal
                                // style={{ minWidth: "80vw", marginLeft: "250px" }}
                                isOpen={openState}
                                title={"Select tags to attach:"}
                                toggle={toggleTagModal}
                                size="lg"
                                body={<Form className="p-2" onValidSubmit={handleTagsSave}>
                                        <Row><Col>
                                                <span>Tags:</span>
                                                <CreatableSelect
                                                        isMulti
                                                        closeMenuOnSelect={false}
                                                        onCreateOption={handleTagCreate}
                                                        onChange={handleTagChange}
                                                        defaultValue={tags.filter(t => t._source.tentobits.includes(idForModal)).map((c) => { return { label: c._source.title, value: c } })}
                                                        options={tags.map((c) => { return { label: c._source.title, value: c } })}
                                                />
                                        </Col></Row>
                                        <p className={"text-muted"}> You can create a new tag by simply typing and pressing enter. </p>

                                        <div className="text-right">
                                                <Button
                                                        color="light"
                                                        type="button"
                                                        className="mr-1 float-left"
                                                        onClick={() => { toggleEditTagModal() }}
                                                >
                                                        Edit or Delete Tag
                                                </Button>

                                                <Button
                                                        color="light"
                                                        type="button"
                                                        className="mr-1"
                                                        onClick={toggleTagModal}>
                                                        Cancel
                                                </Button>
                                                <Button color="primary" type="submit">
                                                        Save
                                                </Button>
                                        </div>
                                </Form>}

                        >

                        </TentoModal>


                </div>
        )
}

const editFormatter = (value) => {
        const { t, toggleWarningModal } = value;
        return (
                <div>
                        <i className="mdi mdi-delete mr-1" onClick={() => toggleWarningModal({ _id: t._id, _source: t._source }, "tag")}></i>Delete
                </div>)
};



const editTagModal = (openState, actions, data) => {
        const { tags } = data
        const { toggleEditTagModal, toggleWarningModal, updateTag } = actions
        const tagTableData = tags.map((t) => { return { name: t._source.title, edit: { t, toggleWarningModal } } })
        const handleTagChange = (newValue, row) => {
                const newSource = { ...row.edit.t._source, title: row.name };
                updateTag(row.edit.t._id, newSource)
        }
        const columns = [{
                dataField: 'name',
                text: 'Tags for this board',
                sort: true,
        },
        {
                dataField: 'edit',
                text: '',
                sort: false,
                formatter: editFormatter,
                editable: false,
        }];

        return (
                <TentoModal isOpen={openState}
                        title={"Edit and Delete Tags"}
                        toggle={toggleEditTagModal}
                        size="lg"
                        body={<div>
                                <p className={"text-muted"}> You can double click to edit a tag and save by pressing enter. </p>
                                <BootstrapTable
                                        keyField="id"
                                        data={tagTableData}
                                        columns={columns}
                                        bordered={false}
                                        cellEdit={cellEditFactory({
                                                mode: 'dbclick',
                                                afterSaveCell: (oldValue, newValue, row, column) => { handleTagChange(newValue, row) },
                                                blurToSave: true,
                                                autoSelectText: false
                                        })}
                                /></div>}>

                </TentoModal>

        )
}


const CreateTeamModal = (props) => {

        const { teamModal, setTeamModal, loading, LoaderWidget, createGroup, validateGroupName } = props
        const [error, setError] = useState("")
        const [group_name, set_group_name] = useState("")
        const length = 20
        return (
                <TentoModal
                        // style={{ minWidth: "80vw", marginLeft: "250px" }}
                        isOpen={teamModal}
                        toggle={() => setTeamModal(!teamModal)}
                        size="lg"
                        title={"New Team"}
                        body={
                                (<div>
                                        {(loading && <LoaderWidget></LoaderWidget>)}
                                        <Form className="p-2" onSubmit={(event) => {
                                                event.preventDefault()
                                                console.log("FORM", group_name)

                                                var isValidName = true
                                                if (group_name === "") {
                                                        isValidName = false
                                                        setError("Please enter a name for your team")
                                                } else if (group_name.length > length) {
                                                        isValidName = false
                                                        setError(`Your Team name must be shorter than ${length} characters`)
                                                }
                                                if (isValidName) {
                                                        createGroup(event, { group_name })
                                                }


                                        }}>
                                                <Row>
                                                        <Col >
                                                                <Input name="group_name"
                                                                        label="Team Name"
                                                                        value={group_name}
                                                                        onChange={e => {
                                                                                set_group_name(e.target.value)
                                                                                setError("")
                                                                        }}
                                                                        type="text"
                                                                        invalid={error.length !== 0}

                                                                />
                                                                <div style={{
                                                                        "width": "100%",
                                                                        "marginTop": ".25rem",
                                                                        "fontSize": ".875em",
                                                                        "color": "#dc3545", "marginBottom": ".25rem",
                                                                }}>{error.length !== 0 && error}</div>
                                                                <Button className={modal.confirmationButton} color="primary" type="submit" style={{
                                                                        marginTop: "10px",

                                                                }}>
                                                                        Create Team
                                                                </Button>
                                                        </Col>

                                                </Row>



                                        </Form>
                                </div>
                                )}
                >

                </TentoModal>
        )
}
const SubscriptionModal = (props) => {

        const { showSubscriptionModal, toggleSubscriptionModal } = props
        return (
                <TentoModal
                        title={"Tento Premium Required"}
                        isOpen={showSubscriptionModal}

                        toggle={toggleSubscriptionModal}
                        size="lg"
                        body={<div>
                                <h5>The free version of Tento only allows you to create one Team with one other user </h5>

                                <div>Head <Link to='/prices'>Here</Link> to upgrade to Tento Premium and benefit from:</div>
                                <ul>
                                        <li>Unlimited Devices</li>
                                        <li>In depth analysis of your device, with Regulation and compliance area recommendations</li>
                                        <li>Full Evidence check list, tailored to your device</li>
                                        <li>An unlimited number of teams and members</li>
                                        <li>Commenting on devices and tag users to notify them</li>
                                        <li>Automatic tracking of changes made by users</li>
                                </ul>
                        </div>}
                >

                </TentoModal>
        )
}
export { deleteModal, tagModal, editTagModal, CreateTeamModal, SubscriptionModal }