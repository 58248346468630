import React, { Component, useEffect, useState, useCallback } from 'react';
import { BrowserRouter, Switch } from 'react-router-dom';
// import Loadable from 'react-loadable';
// import Loadable from 'react-loadable-hooks';
import loadable from '@loadable/component'
import { connect } from 'react-redux';
import { useMsal, MsalProvider, useAccount } from "@azure/msal-react";
import {
    InteractionRequiredAuthError,
    InteractionStatus,
} from "@azure/msal-browser";
import { useHistory } from "react-router";
import { loginRequest, b2cPolicies } from "../authConfig.js";
import { allFlattenRoutes as routes } from './index';
import { isUserAuthenticated } from '../helpers/authUtils';
import * as layoutConstants from '../constants/layout';
import AuthDataProvider from "./auth-provider";
import { PublicClientApplication } from "@azure/msal-browser";
import { msalConfig } from "../authConfig";
import LoaderWidget from '../components/Loader.js';
// const pca = new PublicClientApplication(msalConfig);
// Lazy loading and code splitting -
// Derieved idea from https://blog.logrocket.com/lazy-loading-components-in-react-16-6-6cea535c0b52
const loading = () => <div></div>;
const AUTHAPIURL = process.env.REACT_APP_STAGE == 'stage'
    ? "https://tentodevauth.azurewebsites.net/"
    : process.env.REACT_APP_STAGE == 'production' ? "https://tentoauth.azurewebsites.net/" : "http://localhost:5001/";
// All layouts/containers
// const AuthLayout = Loadable({
//     loader: () => import('../layouts/Auth'),
//     render(loaded, props) {
//         let Component = loaded.default;
//         return <Component {...props} />;
//     },
//     loading,
// });

// const VerticalLayout = Loadable({
//     loader: () => import('../layouts/Vertical'),
//     render(loaded, props) {
//         let Component = loaded.default;
//         return <Component {...props} />;
//     },
//     loading,
// });


// const AuthLayout = loadable({
//         loader: () => import('../layouts/Auth'),
//         render(loaded, props) {
//             let Component = loaded.default;
//             return <Component {...props} />;
//         },
//         loading,
//     });

const AuthLayout = loadable(() => import('../layouts/Auth'), {
    resolveComponent: (components, props) => components.default
    // {
    //     let Component = components.default;
    //     console.log("COMPONENT",Component)
    //     return <Component {...props} />
    // }
    ,
})
const VerticalLayout = loadable(() => import('../layouts/Vertical'), {
    resolveComponent: (components, props) => components.default
    // {
    //     let Component = components.default;
    //     console.log("COMPONENT",Component)
    //     return <Component {...props} />
    // }
    ,
})

// loadable({
//     loader: () => import('../layouts/Vertical'),
//     render(loaded, props) {
//         let Component = loaded.default;
//         return <Component {...props} />;
//     },
//     loading,
// });

// const HorizontalLayout = Loadable({
//     loader: () => import('../layouts/Horizontal'),
//     render(loaded, props) {
//         let Component = loaded.default;
//         return <Component {...props} />;
//     },
//     loading,
// });

// const DetachedLayout = Loadable({
//     loader: () => import('../layouts/Detached'),
//     render(loaded, props) {
//         let Component = loaded.default;
//         return <Component {...props} />;
//     },
//     loading,
// });
const STRIPEAPIURL = process.env.REACT_APP_STAGE == 'stage'
    ? "https://tentostripedev.azurewebsites.net/"
    : process.env.REACT_APP_STAGE == 'production' ? "https://tentostripe.azurewebsites.net/" : "http://localhost:4242/";

function shouldUpdate(prevProps, nextProps) {
    let oldLayout = { ...prevProps.layout };
    delete oldLayout.showRightSidebar;
    let newLayout = { ...nextProps.layout };
    delete newLayout.showRightSidebar;
    return (
        JSON.stringify(oldLayout) !== JSON.stringify(newLayout) ||
        JSON.stringify(prevProps.user) !== JSON.stringify(nextProps.user)
    );
}


const Routes = (props) => {
    const { instance, accounts, inProgress } = useMsal();
    const account = useAccount(accounts[0] || {});
    const [tentoUser, setTentoUser] = useState()
    const [userTeams, setUserTeams] = useState([0])
    const [fetchingUserTeams, setFetchingUserTeams] = useState(false)
    const [userTeamNames, setUserTeamNames] = useState([0])
    const [serverError, setServerError] = useState({ status: false, section: "" })
    // console.log("ROUTES PROPS",props)
    const history = useHistory();

    // async function fetchTeamNames(id, addUser = false) {
    const fetchTeamNames = useCallback(async (id, addUser = false) => {
        try {
            let tokenResp
            if (account) {
                return await instance.acquireTokenSilent({
                    account: account
                }).then((response) => {
                    // if(response) {
                    return response         //   }
                }).catch((error) => {
                    if (error instanceof InteractionRequiredAuthError) {
                        try {
                            instance.acquireTokenRedirect({

                                account: account,
                            });
                        } catch (error) {
                            throw (error)
                        }
                    }
                }).then(async (tknRsp) => {
                    if (tknRsp) {
                        // return response
                        await fetchWithTimeout(

                            AUTHAPIURL + `getTeamsForUser`,
                            {
                                timeout: 6000,
                                method: "POST",
                                body: JSON.stringify({ "id": id }),
                                // mode: 'cors',
                                headers: new Headers({
                                    Accept: "application/json",
                                    'Content-Type': 'application/json',
                                    Authorization: `Bearer ${tknRsp.idToken}`
                                }
                                )

                            }

                        ).then(response => {
                            if (response.ok || response.id) { return response.json() }
                            else { throw response }
                        }).then(
                            (result) => {
                                // console.log("getTeamsForUser",result)
                                let deletedTeams = window.sessionStorage.getItem("deletedTeams")
                                deletedTeams = [...JSON.parse(deletedTeams) || []]

                                setUserTeamNames(result.filter(r => !deletedTeams.includes(r.id)))


                                return result;
                            })
                    }
                });





            }
        } catch (error) {
            setServerError({ status: true, section: "getTeamsForUser", error: error })
            throw (error)
        }



    }, [account, instance])

    // async function useCallback(() => {fetchTeams(id, addUser = false) {
    const fetchTeams = useCallback(async (id, addUser) => {
        setFetchingUserTeams(true)
        try {
            let tokenResp
            if (account) {
                return await instance.acquireTokenSilent({
                    account: account
                }).then((response) => {
                    // if(response) {
                    return response         //   }
                }).catch((error) => {
                    if (error instanceof InteractionRequiredAuthError) {
                        try {
                            instance.acquireTokenRedirect({

                                account: account,
                            });
                        } catch (error) {
                            throw (error)
                        }
                    }
                }).then(async (tknRsp) => {
                    if (tknRsp) {
                        // return response
                        await fetchWithTimeout(

                            AUTHAPIURL + `getGroupsForUser`,
                            {
                                timeout: 10000,
                                method: "POST",
                                body: JSON.stringify({ "id": id }),
                                // mode: 'cors',
                                headers: new Headers({
                                    Accept: "application/json",
                                    'Content-Type': 'application/json',
                                    Authorization: `Bearer ${tknRsp.idToken}`
                                }
                                )

                            }

                        ).then(response => {
                            if (response.ok || response.id) { return response.json() }
                            else { throw response }
                        }).then(
                            (result) => {

                                setUserTeams(result)
                                setFetchingUserTeams(false)

                                return result;
                            })
                    }
                });





            }
        } catch (error) {
            setServerError({ status: true, section: "fetchTeams", error: error })
            throw (error);
        }
        setFetchingUserTeams(false)


    }, [account, instance])

    const getLayout = () => {
        // if (!isUserAuthenticated()) return AuthLayout;
        if (!account || account === null) return AuthLayout;

        let layoutCls = VerticalLayout;

        // switch (props.layout.layoutType) {
        //     case layoutConstants.LAYOUT_HORIZONTAL:
        //         layoutCls = HorizontalLayout;
        //         break;
        //     // case layoutConstants.LAYOUT_DETACHED:
        //     //     layoutCls = DetachedLayout;
        //     //     break;
        //     default:
        //         layoutCls = VerticalLayout;
        //         break;
        // }
        return layoutCls;
    };
    async function fetchWithTimeout(resource, options = {}) {
        // const { timeout = 5000 } = options;

        // const controller = new AbortController();
        // const id = setTimeout(() => controller.abort(), timeout);

        const response = await fetch(resource, {
            ...options,
            // signal: controller.signal
        });
        // clearTimeout(id);

        return response;
    }

    const [modules, setModules] = useState()

    const getModules = async () => {
        await fetch(

            STRIPEAPIURL + 'config',
            {
                method: "GET",
                // body: JSON.stringify({"id":"cus_LsN5soVRF7jFCz",}),
                // mode: 'cors',
                headers: new Headers({
                    Accept: "application/json",
                    'Content-Type': 'application/json',
                    // Authorization: `Bearer ${tokenResp.idToken}`
                }
                )

            }).then(res => res.json()).then(
                result => {
                    console.log("PRODUCTS", result)
                    setModules({ ...result, products: result.products.filter(prod => prod.name.includes("MODULE")) })
                })
    }


    const Layout = getLayout();
    const [stripeUser, setStripeUser] = useState()
    const [subs, setSubs] = useState()


    useEffect(() => {
        getModules()
        const work = async () => {
            // console.log("ERROR:",props.server)
            // console.log("EFFECT");

            async function signIn(href) {
                // console.log("ACCOUNT HREF", href)
                const signInRequest = { ...loginRequest, redirectUri: `${window.location.origin}/account/login` }
                // console.log("ACCOUNT HREF", signInRequest)
                const accounts = instance.getAllAccounts()

                if (accounts.length > 0) {
                    Promise.all(
                        accounts.map(
                            async (a) => {
                                await instance.logoutRedirect(
                                    {
                                        account: a,
                                        onRedirectNavigate: () => false //!BrowserUtils.isInIframe()
                                    }
                                )
                            }
                        )
                    ).then(async () => await instance.loginRedirect(signInRequest))

                } else {
                    await instance.loginRedirect(signInRequest)
                }
            }

            const getTentoUser = async () => {
                // console.log(event,action)
                // Object.keys(userGroups).map(key =>{console.log("GROUP:",userGroups[key])})
                try {
                    if (account) {
                        let response;
                        response = await instance.acquireTokenSilent({
                            account: account
                        })
                        // console.log("then 1", response)
                        if (response) {
                            // console.log("then 2", response)
                            await fetchWithTimeout(
                                AUTHAPIURL + `getUserForID`,
                                {
                                    timeout: 6000,
                                    method: "POST",
                                    body: JSON.stringify({ "id": account.localAccountId }),
                                    // mode: 'cors',
                                    headers: new Headers({
                                        Accept: "application/json",
                                        'Content-Type': 'application/json',
                                        Authorization: `Bearer ${response.idToken}`
                                    })
                                }
                            ).then(response => {
                                if (response.ok) { return response.json() }
                                else { throw response }
                            }).then(data => {
                                setTentoUser(data)
                                // console.log(instance.getAccountByLocalId(account.localAccountId))
                                // instance.loginRedirect()
                            })
                        }
                        return response
                    }
                } catch (error) {
                    console.log("catch Error", error)
                    if (error instanceof InteractionRequiredAuthError) {
                        // instance.acquireTokenRedirect({

                        //     account: account,
                        // });
                        await signIn(window.location.href)
                    } else {
                        await signIn(window.location.href)
                    }

                    throw (error)
                }
                // console.log("BUTTON",action,key)
                // catch (e) {
                //     if (e.name === "InteractionRequiredAuthError") {
                //         instance.loginRedirect()
                //     } else {
                //         setServerError({ status: true, section: "getTentoUser 2", error: e })

                //     }
                //     // 

                // }
            }

            const getStripeUser = async () => {
                try {
                    if (account) {
                        await instance.acquireTokenSilent({
                            account: account
                        }).then((response) => {
                            if (response) {
                                return response
                            }
                        }).catch((error) => {
                            if (error instanceof InteractionRequiredAuthError) {

                                signIn(window.location.href)
                            } else {
                                signIn(window.location.href)
                            }
                            throw (error)
                        }).then(async (idTokenResponse) => {
                            if (idTokenResponse) {
                                try {
                                    if (account) {
                                        var tempSubs = await fetchWithTimeout(

                                            STRIPEAPIURL + 'get-customer',
                                            {
                                                method: "POST",
                                                timeout: 8000,
                                                body: JSON.stringify({ "id": account.localAccountId, }),
                                                // mode: 'cors',
                                                headers: new Headers({
                                                    Accept: "application/json",
                                                    'Content-Type': 'application/json',
                                                    Authorization: `Bearer ${idTokenResponse.idToken}`
                                                }
                                                )

                                            }).then(response => {
                                                if (response.ok) { return response.json() }
                                                else { throw response }
                                            }).then(
                                                async (result) => {
                                                    if (result.data.length == 0) {
                                                        await fetchWithTimeout(

                                                            STRIPEAPIURL + 'create-customer',
                                                            {
                                                                method: "POST",
                                                                timeout: 8000,
                                                                body: JSON.stringify({ "id": account.localAccountId, "email": account.idTokenClaims.email, "name": account.idTokenClaims.given_name + " " + account.idTokenClaims.family_name }),
                                                                // mode: 'cors',
                                                                headers: new Headers({
                                                                    Accept: "application/json",
                                                                    'Content-Type': 'application/json',
                                                                    Authorization: `Bearer ${idTokenResponse.idToken}`
                                                                }
                                                                )

                                                            }).then(response => {

                                                                if (response.ok) { return response.json() }
                                                                else {
                                                                    throw response
                                                                }
                                                            }).then(async (result) => {
                                                                var userData
                                                                if (Object.keys(result).includes("data")) {
                                                                    userData = result['data'][0]
                                                                } else {
                                                                    userData = result
                                                                }


                                                                setStripeUser(userData)
                                                                await fetchWithTimeout(

                                                                    STRIPEAPIURL + 'check-subs',
                                                                    {
                                                                        timeout: 8000,
                                                                        method: "POST",
                                                                        body: JSON.stringify({ "id": userData.id, }),
                                                                        // mode: 'cors',
                                                                        headers: new Headers({
                                                                            Accept: "application/json",
                                                                            'Content-Type': 'application/json',
                                                                            Authorization: `Bearer ${idTokenResponse.idToken}`
                                                                        }
                                                                        )

                                                                    }).then(response => {

                                                                        if (response.ok) { return response.json() }
                                                                        else {
                                                                            throw response
                                                                        }
                                                                    }).then(
                                                                        result => {
                                                                            // console.log("SUBS:",result)
                                                                            setSubs(result)
                                                                        })

                                                            })
                                                    } else {
                                                        var userData
                                                        if (Object.keys(result).includes("data")) {
                                                            userData = result['data'][0]
                                                        } else {
                                                            userData = result
                                                        }
                                                        setStripeUser(userData)
                                                        await fetchWithTimeout(

                                                            STRIPEAPIURL + 'check-subs',
                                                            {
                                                                method: "POST",
                                                                body: JSON.stringify({ "id": userData.id, }),
                                                                // mode: 'cors',
                                                                headers: new Headers({
                                                                    Accept: "application/json",
                                                                    'Content-Type': 'application/json',
                                                                    Authorization: `Bearer ${idTokenResponse.idToken}`
                                                                }
                                                                )

                                                            }).then(res => {

                                                                if (res.ok) { return res.json() }
                                                                else {
                                                                    throw res
                                                                }
                                                            }).then(
                                                                result => {
                                                                    setSubs(result)
                                                                })

                                                    }

                                                })
                                    }
                                } catch (error) {
                                    setServerError({ status: true, section: "getStripeUser", error: error })

                                }
                            }
                        });
                    }
                    // console.log("BUTTON",action,key)

                }
                catch (e) {
                    if (e.name === "InteractionRequiredAuthError") {
                        await instance.loginRedirect()
                    } else {
                        setServerError({ status: true, section: "getTentoUser 2", error: e })

                    }
                    // 
                    throw (e)
                }
            }

            if (account) {
                try {
                    await getTentoUser()
                    await fetchTeams(account.localAccountId)
                    await fetchTeamNames(account.localAccountId)
                    // getSubs()
                    await getStripeUser()
                } catch (err) {
                    console.error(err);
                }

            } else {
                // instance.loginRedirect(loginRequest)
            }

        }
        work().finally(() => {

        })
    }, [account, fetchTeamNames, fetchTeams, instance, props.location]);
    // const { instance } = useMsal();
    const [hasSub, setHasSub] = useState(window.sessionStorage.getItem("hasSub") === "true")
    const [userModules, setUserModules] = useState([])
    
    useEffect(() => {

        if (subs && subs.subscriptions.length > 0) {
            var tempSub = false
            const userSubs = subs.subscriptions.filter(sub => sub.active && ["active", "trialing"].includes(sub.status)) 
            let subscribedList = modules ? [
                ...modules.products.filter(
                    c => c.metadata.ready === "true" && (c.metadata.free === "true" || userSubs.some(sub => sub.product === c.id))

                ).sort((a, b) => a.name.localeCompare(b.name, 'en', { numeric: true }))
            ] : []

            subs.subscriptions.forEach(el => {
                console.log("SET HASSUB false if else", el.active, el.status, el.product)
                if (el.active && ["active", "trialing"].includes(el.status) && ["prod_LsLTHdyq5x5Yy2", "prod_Lw2sxyg6duJ2zx"
                    , "prod_NsIfNCmeBfEJuT", "prod_NsIf2szynGis1L", "prod_NsIGaEaB6nEVcE"].includes(el.product)) {
                    tempSub = true
                    // setHasSub(true)
                    console.log("SET HASSUB TRUE")
                    // window.sessionStorage.setItem("hasSub",true)


                }
                else {

                    // tempSub = false
                    // setHasSub(false)
                    // window.sessionStorage.setItem("hasSub",false)
                }
            })
            console.log("SET HASSUB false if AFTER", tempSub)
            setHasSub(tempSub)
            setUserModules(subscribedList)
            window.sessionStorage.setItem("hasSub", tempSub)
        } else {
            console.log("SET HASSUB false else")
            // hasSub = false
            setHasSub(false)

            window.sessionStorage.setItem("hasSub", false)
        }
    }, [
        subs
        // props.reqGroups, props.reqGroupsRaw
    ])


    return (
        <BrowserRouter>
            <Layout {...props} modules={modules} tentoUser={tentoUser} hasSub={hasSub} userModules={userModules} fetchTeams={fetchTeams} userTeams={userTeams} fetchingUserTeams={fetchingUserTeams} setFetchingUserTeams={setFetchingUserTeams} fetchTeamNames={fetchTeamNames} userTeamNames={userTeamNames} subs={subs} stripeUser={stripeUser} setServerError={setServerError} serverError={serverError} fetchWithTimeout={fetchWithTimeout} >
                {props.configLoading && <LoaderWidget></LoaderWidget>}
                {
                    // stripeUser && subs &&
                    <Switch>
                        {routes.map((route, index) => {
                            return (
                                !route.children && (
                                    <route.route
                                        // onLeave ={() => {console.log("LEFT route",props)} }

                                        key={index}
                                        modules={modules}
                                        path={route.path}
                                        roles={route.roles}
                                        exact={route.exact}
                                        tentoUser={tentoUser}
                                        userTeams={userTeams}
                                        fetchingUserTeams={fetchingUserTeams}
                                        setFetchingUserTeams={setFetchingUserTeams}
                                        subs={subs}
                                        hasSub={hasSub}
                                        userModules={userModules}
                                        stripeUser={stripeUser}
                                        serverError={serverError}
                                        fetchTeams={fetchTeams}
                                        fetchTeamNames={fetchTeamNames}
                                        userTeamNames={userTeamNames}
                                        setServerError={setServerError}
                                        fetchWithTimeout={fetchWithTimeout}
                                        component={route.component}></route.route>
                                )
                            );
                        })}
                    </Switch>

                }
            </Layout>
        </BrowserRouter>
    );
}

// class RoutesOLD extends Component {
//     // returns the layout
//     getLayout = () => {
//         if (!isUserAuthenticated()) return AuthLayout;

//         let layoutCls = VerticalLayout;

//         switch (this.props.layout.layoutType) {
//             case layoutConstants.LAYOUT_HORIZONTAL:
//                 layoutCls = HorizontalLayout;
//                 break;
//             case layoutConstants.LAYOUT_DETACHED:
//                 layoutCls = DetachedLayout;
//                 break;
//             default:
//                 layoutCls = VerticalLayout;
//                 break;
//         }
//         return layoutCls;
//     };

//     shouldComponentUpdate(nextProps, nextState) {

//     }

//     render() {
//         const Layout = this.getLayout();
//         // rendering the router with layout
//         return (
//             <BrowserRouter>
//             <AuthDataProvider>
//                 <Layout {...this.props}>
//                     <Switch>
//                         {routes.map((route, index) => {
//                             return (
//                                 !route.children && (
//                                     <route.route
//                                         key={index}
//                                         path={route.path}
//                                         roles={route.roles}
//                                         exact={route.exact}
//                                         component={route.component}></route.route>
//                                 )
//                             );
//                         })}
//                     </Switch>
//                 </Layout>
//                 </AuthDataProvider>
//             </BrowserRouter>
//         );
//     }
// }

const mapStateToProps = state => {
    return {
        layout: state.Layout,
        user: state.Auth.user,
        configLoading: state.TestPlan.configLoading,
        runs: state.TestPlan.runs,
        reqs: state.TestPlan.reqs,
        reqGroupsRaw: state.TestPlan.reqGroupsRaw,
        testlogs: state.TestPlan.testlogs,
        records: state.TestPlan.records,
        protocols: state.TestPlan.protocols,
        anoms: state.TestPlan.anoms,
        JOINED: state.TestPlan.JOINED,
        "ignoreConfig-reqGroupsRaw": state.TestPlan["ignoreConfig-reqGroupsRaw"],
        "ignoreConfig-testlogs": state.TestPlan["ignoreConfig-testlogs"],
        "ignoreConfig-deviceEvidence": state.TestPlan["ignoreConfig-deviceEvidence"],
        // deviceData: state.TestPlan
        device: state.TestPlan.device
    };
};
export default connect(mapStateToProps)(Routes);
// export default connect(mapStateToProps)(React.memo(Routes, shouldUpdate));
// export default connect(
//     mapStateToProps,
//     null
// )(Routes);
