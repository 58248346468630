import { useState } from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import cellEditFactory, { Type } from 'react-bootstrap-table2-editor';
import { Button } from 'reactstrap';

import tables from '../../../../components/Styles/table.module.css';

const Definition = (props) => {
    return (
        <>
            <BootstrapTable
                bootstrap4
                classes={tables.table}
                headerWrapperClasses={tables.tableHead}
                bodyClasses={tables.tableTD}
                data={props.data}
                columns={props.columns}
                keyField={props.keyField}
                cellEdit={cellEditFactory({
                    mode: 'click',
                    afterSaveCell: (oldVal, newVal, row, column) => {props.handleRowChange(newVal, row)},
                    blurToSave: true,
                    autoSelectText: false,
                })}
                noDataIndication="Add a new row to specify scale."
            />
            <div className={tables.tableButtonContainer}>
                <Button
                disabled={!props.hasRiskSub}
                    onClick={props.addRow}
                >
                    Add 
                </Button>
                <Button 
                    color="danger"
                    disabled ={!props.hasRiskSub||props.data.length ===0}
                    onClick={props.handleRowDelete}
                >
                    Remove
                </Button>
            </div>
        </>
    )
}

export default Definition