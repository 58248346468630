import React, { Component, useState, useEffect, useMemo } from 'react';

import { connect, useSelector, useDispatch } from 'react-redux';
import LoaderWidget from '../../../../components/Loader';
import { Redirect, useHistory } from 'react-router-dom';
import { SortableContainer, SortableElement } from 'react-sortable-hoc';

import Switch from "react-switch";
import {
  Accordion, AccordionBody, AccordionHeader, Collapse, AccordionItem,
  Row, Col, Card, CardBody, UncontrolledPopover, Popover, PopoverBody, PopoverHeader,
  UncontrolledTooltip, Modal, ModalHeader, ModalBody, Button, ButtonGroup,
  DropdownToggle, UncontrolledDropdown, DropdownMenu, DropdownItem,
  UncontrolledCollapse, ListGroup, ListGroupItem, Container, CardHeader,
  FormGroup, Label, Input, InputGroup, Tooltip,
  Dropdown, Offcanvas, OffcanvasBody, OffcanvasHeader, Spinner, Form
} from 'reactstrap';
import Select from "react-select";

import TaskSection from './Section';
import { deleteModal } from '../../../../components/Modals';
import { AIMenuButton } from '../../../../components/AIMenuButton';

import 'bootstrap/dist/css/bootstrap.min.css';
import { MsalAuthenticationTemplate, useMsal, useAccount } from "@azure/msal-react";
import { Link } from 'react-router-dom';
import CsvDownload from 'react-json-to-csv'
import CreatableSelect from 'react-select/creatable';
import card from '../../../../components/Styles/card.module.css';
import modals from '../../../../components/Styles/modal.module.css';

import ai_icon from "../../../../assets/icons/ai_icon.svg"

import Tour from 'reactour'



import { traceCSV, tilesCSV, reqsCSV, testlogCSV, fullTestCSV, anomsCSV } from '../../../../helpers/csvdownload.js'

import CommentSection from '../../../../components/Comments/CommentSection';
import { arrayMove } from 'react-sortable-hoc';
import styles from './styles.module.css'
import {
  createTile, deleteBoard, createReq, getComments, updateGroup, getDeviceGroups, createGroup, createBoard, updateBoard, updateReq, getBoards, selectBoard, getDeviceCSVData, selectProject, createProtocol, createProject, updateProject, deleteProject, createTag, updateTag, deleteTag, createAnom, updateAnom,
  deleteAnom, deleteProtocol, leaveComment, deleteRun, updateProtocol, createRun, createRecord, updateRun, updateRecord, updateTile, deleteTile, deleteRecord, selectTile,
  selectRecord, createTestLog, updateTestLog, deleteTestLog, reorderReqs, selectTestLog, deleteReq, updateDevice, selectDevice, getTour, putTour, getUsersTeamsDevices, updateTask, getAISteps
} from '../../../../redux/actions';

import {
  InteractionRequiredAuthError,
  InteractionStatus,
} from "@azure/msal-browser";

import CommentsAndHistory from '../../../../components/Comments/CommentsAndHistory';
import TentoModal from '../../../../components/TentoModal';
import ImportButton from '../../../../components/ImportButton';
// import Toggle from 'react-toggle'
// import "react-toggle/style.css" 



const mapById = (arr) => arr.reduce((map, obj) => (map[obj._id] = obj, map), {});

const filterIdBoard = (obj, id) => { obj.filter(t => t._source.idBoard === id) };
const filterIdDevice = (obj, id) => { obj.filter(t => t._source.idDevice === id) };



const CopyReqGroupModal = (props) => {
  const { sourceForModal, getReqTestStatus, showCopyModal, toggleCopyModal, setsourceForModal, handleCopyReqGroup } = props

  var associatedGroupTasks = props.tasks.filter(e => e._id === "reqGroup-" + sourceForModal.groupID)

  var associatedRequirements = props.reqs.filter(e => e._source.group === sourceForModal.groupID)
  var associatedRecords = associatedRequirements.map(r => getReqTestStatus(r._id)).filter(test => test.records.length > 0)
  var associatedSpecificationTrace = associatedRequirements.filter(r => (r._source.reqchild.length > 0 || r._source.reqparent.length > 0))
  var associatedDesignTrace = associatedRequirements.filter(r => (r._source.child.length > 0 || r._source.parent.length > 0))

  const showAssociatedReqGroupTasks = associatedGroupTasks.length > 0
  const showAssociatedRequirements = associatedRequirements.length > 0

  if (((!showAssociatedReqGroupTasks && !showAssociatedRequirements))) { handleCopyReqGroup() }
  return (
    (showAssociatedRequirements || showAssociatedReqGroupTasks) && <TentoModal
      title={"Copy Requirement Group"}
      isOpen={showCopyModal}
      toggle={toggleCopyModal}
      size="lg"

      body={
        <div style={{ minHeight: + 400 + "px", position: "relative" }}>

          <Container>

           
            {((!showAssociatedReqGroupTasks && !showAssociatedRequirements)) &&
              <p>This requirement group is ready to copy with the button below:</p>
            }
            {(showAssociatedRequirements || showAssociatedReqGroupTasks) &&
              <p>Just a few things to check, then use the button below to copy this group:</p>
            }
            {showAssociatedReqGroupTasks &&
              <Row style={{ minHeight: "50px" }}>
                <Col sm={9}>
                  <Label>This group is assigned to team members. Do you want to assign the copied group to them as well? </Label>
                </Col>
                <Col sm={3}>
                  <Switch checked={sourceForModal.howToTreatAssociatedReqGroupTasks} onChange={(checked) => {
                    setsourceForModal({ ...sourceForModal, howToTreatAssociatedReqGroupTasks: checked })
                  }}
                  />
                </Col>


              </Row>}

            {showAssociatedRequirements && <Row style={{ minHeight: "50px" }}>
              <Col sm={9}>
                <Label>Do you want to copy requirements within this group? This group has {associatedRequirements.length} requirements.</Label>
              </Col><Col sm={3}>
                <Switch checked={sourceForModal.howToTreatAssociatedReqs} onChange={(checked) => {
                  setsourceForModal({ ...sourceForModal, howToTreatAssociatedReqs: checked })

                }}
                />
              </Col>

            </Row>}
            {sourceForModal.howToTreatAssociatedReqs &&
              associatedRecords.filter(test => test.records.length > 0).length > 0 &&
              <Row style={{ minHeight: "50px" }}>
                <Col sm={9}>
                  <Label> Do you want to associate these new requirements with the same test records? This group has {
                    associatedRecords.filter(test => test.records.length > 0).length
                  } requirement{associatedRecords.length > 1 ? "s" : ""} with an associated test record.</Label>              </Col><Col sm={3}>
                  <Switch checked={sourceForModal.howToTreatAssociatedReqRecs} onChange={(checked) => {
                    setsourceForModal({ ...sourceForModal, howToTreatAssociatedReqRecs: checked })

                  }}
                  />
                </Col>
              </Row>
            }


            {sourceForModal.howToTreatAssociatedReqs &&

              (associatedSpecificationTrace.length > 0) &&
              <Row style={{ minHeight: "50px" }}>
                <Col sm={9}>
                  <Label> Would you like to copy the associated specification traceability to these new requirements? </Label>
                </Col>
                <Col sm={3}>
                  <Switch checked={sourceForModal.howToTreatAssociatedSpecification} onChange={(checked) => {
                    setsourceForModal({ ...sourceForModal, howToTreatAssociatedSpecification: checked })

                  }}
                  />
                </Col>
              </Row>



            }




            {sourceForModal.howToTreatAssociatedReqs &&

              (associatedDesignTrace.length > 0) &&

              <Row style={{ minHeight: "50px" }}>
                <Col sm={9}>
                  <Label> Would you like to copy the associated design traceability to these new requirements?</Label>
                </Col>
                <Col sm={3}>
                  <Switch checked={sourceForModal.howToTreatAssociatedDesign} onChange={(checked) => {
                    setsourceForModal({ ...sourceForModal, howToTreatAssociatedDesign: checked })

                  }}
                  />
                </Col>
              </Row>


            }

          </Container>



          <div className="text-right" style={{ position: "absolute", bottom: 0, right: 0 }}>
            <Button
              color="light"
              type="button"
              className="mr-1"
              onClick={toggleCopyModal}>
              Cancel
            </Button>
            <Button color="primary" onClick={() => { handleCopyReqGroup() }}
            // disabled={!(status.reqs && status.records && status.specification && status.design)}
            >
              Copy
            </Button>
          </div>
        </div>
      }>
    </TentoModal>
  )
}
const CopyReqModal = (props) => {
  const { sourceForModal, getReqTestStatus, showCopyModal, toggleCopyModal, setsourceForModal, handleCopyReq } = props
  console.log("CopyReqModal", getReqTestStatus(sourceForModal._id))
  // var associatedGroupTasks = props.tasks.filter(e => e._id === "reqGroup-" + sourceForModal.groupID)

  // var associatedRequirements = props.reqs.filter(e => e._source.group === sourceForModal.groupID)
  var associatedRecords = getReqTestStatus(sourceForModal._id).records//.filter(test => test.records.length > 0)
  var hasAssociatedSpecificationTrace = sourceForModal._source.reqchild.length > 0 || sourceForModal._source.reqparent.length > 0
  var hasAssociatedDesignTrace = sourceForModal._source.child.length > 0 || sourceForModal._source.parent.length > 0

  const showAssociatedReqGroupTasks = true// associatedGroupTasks.length > 0
  const showAssociatedRequirements = true///associatedRequirements.length >= 1
  if (!(associatedRecords.length > 0 || hasAssociatedSpecificationTrace || hasAssociatedDesignTrace)) {
    handleCopyReq()
  }

  return (
    (associatedRecords.length > 0 || hasAssociatedSpecificationTrace || hasAssociatedDesignTrace) && <TentoModal
      title={"Copy Requirement"}
      isOpen={showCopyModal}
      toggle={toggleCopyModal}
      size="lg"
      body={<div style={{ minHeight: + 400 + "px", position: "relative" }}>

        <Container>
          {(associatedRecords.length > 0 || hasAssociatedSpecificationTrace || hasAssociatedDesignTrace) && <p>Just a few things to check, then use the button below to copy this requirement</p>}
          {!(associatedRecords.length > 0 || hasAssociatedSpecificationTrace || hasAssociatedDesignTrace) && <p>This requirement is ready to copy with the button below</p>}
          {
            associatedRecords.length > 0 &&
            <Row style={{ minHeight: "50px" }}>
              <Col sm={9}>
                <Label> Do you want to associate this new requirement with the same test records? This requirement has {
                  associatedRecords.length
                } requirement{associatedRecords.length > 1 ? "s" : ""} with an associated test record.</Label>              </Col><Col sm={3}>
                <Switch checked={sourceForModal.howToTreatAssociatedReqRecs} onChange={(checked) => {
                  setsourceForModal({ ...sourceForModal, howToTreatAssociatedReqRecs: checked })

                }}
                />
              </Col>
            </Row>
          }


          {
            hasAssociatedSpecificationTrace &&
            <Row style={{ minHeight: "50px" }}>
              <Col sm={9}>
                <Label> Would you like to copy the associated specification traceability to these new requirements? </Label>
              </Col>
              <Col sm={3}>
                <Switch checked={sourceForModal.howToTreatAssociatedSpecification} onChange={(checked) => {
                  setsourceForModal({ ...sourceForModal, howToTreatAssociatedSpecification: checked })

                }}
                />
              </Col>
            </Row>
          }
          {hasAssociatedDesignTrace &&

            <Row style={{ minHeight: "50px" }}>
              <Col sm={9}>
                <Label> Would you like to copy the associated design traceability to these new requirements?</Label>
              </Col>
              <Col sm={3}>
                <Switch checked={sourceForModal.howToTreatAssociatedDesign} onChange={(checked) => {
                  setsourceForModal({ ...sourceForModal, howToTreatAssociatedDesign: checked })

                }}
                />
              </Col>
            </Row>


          }

        </Container>



        <div className="text-right" style={{ position: "absolute", bottom: 0, right: 0 }}>
          <Button
            color="light"
            type="button"
            className="mr-1"
            onClick={toggleCopyModal}
          >
            Cancel
          </Button>
          <Button color="primary"
            onClick={() => { handleCopyReq() }}
          // disabled={!(status.reqs && status.records && status.specification && status.design)}
          >
            Copy
          </Button>
        </div></div>
      }
    ></TentoModal>
  )
}


const TableView = (props) => {

  useEffect(() => {

    if (props.isTourOpen) {
      document.documentElement.style.overflowX = 'inherit';
      document.documentElement.style.scrollBehavior = 'inherit';
    } else {
      document.documentElement.style.overflowX = 'hidden';
      document.documentElement.style.scrollBehavior = 'smooth';
    }
  }, [
    // props.reqGroups, props.reqGroupsRaw
  ])





  const steps = [
    {
      selector: '[data-tut="reactour__biglist"]',
      content: () => (<div>Medical device standard require you to capture risk control measures that will mitigate risks you have identified.  <br/><br/>Risk controls are sectioned into <b>requirement groups</b> to help organise your risk control measures. Each group contains risk control requirements that the device needs to meet.</div>),
      position: [1000, 30]
    },
    {
      selector: '[data-tut="reactour__dropdown"]',
      content: () => (<div>Tento+ also categorise each group into one of three levels of:<br /><br /> 1) User needs and high-level product requirements that detail generally how the device will be used. <br /><br /> 2) System level requirements that detail what the system will consist of, including labelling, instructions etc. <br /><br /> 3) Finally, low-level detailed requirements for each component for your system. <br/><br/> The reason for this is that you'll need to show how your high level requirements have informed that low level design.</div>),
    },
    {
      selector: '[data-tut="reactour__newgroup"]',
      content: () => (<div>Here you can add risk controls. <br/><br/> By adding risk controls, you will be able to select these from the risk analysis table to show which risks this control is mitigating.<br/><br/><b>Exit the tutorial and try adding your first risk control group.</b></div>),
    }
    // ...
  ]
  const [hideNonRiskGroups, setHideNonRiskGroups] = useState(true)
  var riskGroupsIDs = props.reqGroupsRaw.filter(g => g._source.risk).map( r => r._id)
  props.reqGroupsRaw.filter(g => !g._source.risk).forEach( r =>{
    if(props.reqs.filter( rq => rq._source.group === r._id).some( rq=>rq._source.riskReq)){riskGroupsIDs.push(r._id)}
  }) 
  const groups = hideNonRiskGroups ?
    props.reqGroupsRaw.filter(g => riskGroupsIDs.includes(g._id)) :
    props.reqGroupsRaw

  return (


    <div data-tut="reactour__biglist" className={styles.testContainer}>


      {<Tour
        steps={steps}
        showNavigation={false}
        isOpen={props.isTourOpen && window.innerWidth > 768}
        scrollSmooth
        goToStep={props.curStep}
        nextStep={() => { props.setCurStep(props.curStep + 1) }}
        prevStep={() => { props.setCurStep(props.curStep - 1) }}
        onRequestClose={() => { props.setIsTourOpen(false) }} />
      }
      {(props.device._id !== "js1wBogB1L2gw3Qicv2Q" && props.hasRiskSub) &&
        (<>
          <Row  >
            <Col  data-tut="reactour__dropdown" sm="2">
              <Select id="selecttt"
                defaultValue={{ value: "all", label: "All Categories" }}
                onChange={(event, target) => {
                  props.setLevelToInclude(event.value === "all" ? undefined : event.value);
                }}
                options={[{ value: "all", label: "All Categories" }, { value: "top", label: "User needs and product level" }, { value: "mid", label: "System level" }, { value: "low", label: "Component level" }]}
              />
              <UncontrolledTooltip
                target="selecttt"
                placement="right"
              >
                You can filter here the requirements to high-level user needs & product level, system level, or component level requirement groups.
              </UncontrolledTooltip>
              {<span className="text-muted">{groups.length + " of " + props.reqGroupsRaw.length}</span>}
            </Col>
            <Col data-tut="reactour__newgroup" 
              sm="4"
            >
              <Button  id="creategrouptooltip" color="primary" onClick={() => props.newGroup("")} style={{ marginRight:"15px" }}
              >
                Create group
              </Button>

              <UncontrolledTooltip target="creategrouptooltip"
                placement="right"
              >
                By creatings groups, your requirements are organised into manageable sections. They can be related to one component of the system, or can be high level user requirements, or regulatory requirements.
              </UncontrolledTooltip>
       

              <ImportButton hasRelevantSub={props.hasRiskSub} {...props} account={props.account} dataType={"Risk"} />
              {/* <span ><span style={{ fontSize: "1rem", verticalAlign: "middle", marginLeft: "30px" }}>Hide non Risk groups</span>
                <span style={{ display:"inline-block",  verticalAlign: "middle" ,marginLeft: "10px"}}>
                  <Switch
                    checked={hideNonRiskGroups}
                    onChange={(checked) => {
                      console.log("CHECKED",checked)
                      setHideNonRiskGroups(checked)
                    }}
                  ></Switch>
                </span>
              </span> */}
            </Col>
            <Col sm="4">


              <UncontrolledTooltip target="creategrouptooltip"
                placement="right"
              >
                By creatings groups, your requirements are organised into manageable sections. They can be related to one component of the system, or can be high level user requirements, or regulatory requirements.
              </UncontrolledTooltip>

            </Col>
          </Row>
        </>
        )}

      <Row style={{ padding: "10px 0", margin: "10px 20px", borderBottom: "1px solid rgba(0,0,0,.125)", borderBottomColor: "rgba(0, 0, 0, 0.125)", }}>
        {/* {recordsResults?recordsResults.pass:0}:  */}
        <Col xs={3}><span >Risk Control Group Title</span></Col>
        {/* {recordsResults?recordsResults.pass:0}:  */}
        <Col id="verftt" style={{ display: "grid", justifyContent: "center" }} xs={1}><span className={card.greenCounter} style={{ width: "100px" }}>VERIFIED</span></Col>
        <UncontrolledTooltip
          target="verftt"
          placement="right"
        >
          This column shows the number of requirements that have been verified by an assoicated test record with a pass status.
        </UncontrolledTooltip>
        {/* {recordsResults?recordsResults.fail:0}: */}
        <Col id="notmettt" style={{ display: "grid", justifyContent: "center" }} xs={1}><span className={card.redCounter} style={{ width: "100px" }}>NOT MET</span></Col>
        <UncontrolledTooltip
          target="notmettt"
          placement="right"
        >
          This column shows the number of requirements that have failed to be verified due to an assoicated test record with a fail status.
        </UncontrolledTooltip>
        {/* {recordsResults?recordsResults.test:0}: */}
        <Col id="todott" style={{ display: "grid", justifyContent: "center" }} xs={1}><span className={card.greyCounter} style={{ width: "100px" }}>TO TEST</span></Col>
        <UncontrolledTooltip
          target="todott"
          placement="right"
        >
          This column shows the number of requirements that have no test records with active test runs associated and therefore are awaiting testing.
        </UncontrolledTooltip>
        <Col></Col>
      </Row>



      {props.tasks && groups.sort(function (a, b) { return a._source.ref.localeCompare(b._source.ref) }).map((group, id) => {
        return (

          <div data-tut="reactour__section" onClick={() => {
            if (props.curStep === 1) {
              setTimeout(() => {
                props.setCurStep(props.curStep + 1)
              }, 1000);
            }
          }} key={id} className={styles.cardBorderB} style={{ padding: "0 20px" }}>
            <TaskSection
              // aiButton = {<AIMenuButton dispatch={props.dispatch} instance={props.instance} account={props.account} aiSteps={props.aiSteps} reqGroupsRaw={props.reqGroupsRaw} reqs={props.reqs} device={props.device} isAIOpen={props.isAIOpen}
              // setIsOpen={props.setIsAIOpen} added={props.added} setAdded={props.setAdded} {...props} ></AIMenuButton>}
              aiButtonProps={{
                dispatch: props.dispatch, instance: props.instance, account: props.account, aiSteps: props.aiSteps,
                reqGroupsRaw: props.reqGroupsRaw, reqs: props.reqs, device: props.device, isAIOpen: props.isAIOpen,
                setIsOpen: props.setIsAIOpen, added: props.added, setAdded: props.setAdded, ...props
              }}
              title={group._source.ref + ": " + group._source.title} // changed the title to be the title of the req group. - NT
              key={group._source.ref + ": " + group._source.title}
              toggleGroupWarningModal={props.toggleGroupWarningModal}
              editGroup={props.editGroup}
              copyGroup={props.copyGroup}
              copyReq={props.copyReq}
              cSelected={props.cSelected}
              setCSelected={props.setCSelected}
              newTile={props.newBlankReq}
              newGroup={props.newGroup}
              type={1}

              level={group._source.level}
              group={group}
              testlogs={props.testlogs}
              reqGroups={props.reqGroups}
              tasks={props.reqs.filter(e => e._source.group === group._id)} // changed the filter to make the group._id. - NT
              selectTask={props.selectTask}
              selectedTask={props.selectedTask.top ? props.selectedTask.top : {}}
              deleteWarning={props.toggleWarningModal}
              dispatch={props.dispatch}
              selectBoard={selectBoard}
              device={props.device}
              getReqTestStatus={props.getReqTestStatus}
              account={props.account}
              instance={props.instance}
              userOptionObjs={props.userOptionObjs}
              reqGroupsRaw={props.reqGroupsRaw}
              anoms={props.anoms}
              runs={props.runs}
              hasRiskSub={props.hasRiskSub}
              userModules={props.userModules}
              deviceUsers={props.deviceUsers}
              openAssignMember={props.openAssignMember}
              toDoTasks={props.tasks}
              editCoverage={props.editCoverage}
            ></TaskSection>
          </div>

        )
      })}

      {/* - comment this out to remove tiers of groups - NT 
        <div className={card.cardBorderB} style={{background:"white",marginTop:"10px",padding:"10px 20px"}}>
          <TaskSection
            title="Product Level Requirements"
            toggleGroupWarningModal={props.toggleGroupWarningModal}
            editGroup={props.editGroup}
            cSelected={props.cSelected}
            setCSelected = {props.setCSelected}
            newTile={props.newBlankReq}
            newGroup={props.newGroup}
            type={1}
            level ={"top"}
            reqGroups={props.reqGroups}
            tasks={props.reqs.filter(e =>  e._source.level ==="top")}
            selectTask={props.selectTask}
            selectedTask={props.selectedTask.top?props.selectedTask.top:{}}
            dispatch ={props.dispatch}
            selectBoard ={selectBoard}
            device={props.device}
            getReqTestStatus ={props.getReqTestStatus}
            account={props.account}
            instance={props.instance}
            userOptionObjs ={props.userOptionObjs}
            >

          </TaskSection>
        </div>

        <div className={card.cardBorderB} style={{background:"white",margin:"0",padding:"10px 20px"}}>
          <TaskSection
            title="System Level Requirements"
            toggleGroupWarningModal={props.toggleGroupWarningModal}
            cSelected={props.cSelected}
            setCSelected = {props.setCSelected}
            newTile={props.newBlankReq}
            newGroup={props.newGroup}
            type={2}
            level ={"mid"}
            reqGroups={props.reqGroups}
            tasks={props.reqs.filter(e =>  e._source.level ==="mid")}
            selectTask={props.selectTask}
            selectedTask={props.selectedTask.mid?props.selectedTask.mid:{}}
            getReqTestStatus ={props.getReqTestStatus}
            account={props.account}
            instance={props.instance}
            userOptionObjs ={props.userOptionObjs}
            >
          </TaskSection>
        </div>
        
        <div className={card.cardBorderB} style={{background:"white", padding:"10px 20px", marginBottom:"10px"}}>
          <TaskSection
            title="Component Level Requirements"
            toggleGroupWarningModal={props.toggleGroupWarningModal}
            cSelected={props.cSelected}
            setCSelected = {props.setCSelected}
            newTile={props.newBlankReq}
            newGroup={props.newGroup}
            type={3}
            reqGroups={props.reqGroups}
            level ={"low"}
            tasks={props.reqs.filter(e =>  e._source.level ==="low")}
            selectTask={props.selectTask}
            selectedTask={props.selectedTask.low?props.selectedTask.low:{}}
            getReqTestStatus ={props.getReqTestStatus}
            account={props.account}
            instance={props.instance}
            userOptionObjs ={props.userOptionObjs}
            >
          </TaskSection>
        </div>
        */
      }

      <Col>

        {/* <TaskWindow // {Object.keys(props.selectedTask)!==0?props.selectedTask:props.reqs.filter(e =>  e._source.level ==="top")[0]}
        
        task ={props.selectedTask}
        account={props.account} instance={props.instance}
         userOptionObjs ={props.userOptionObjs}
         dispatch ={props.dispatch}
         reqGroups={props.reqGroups}
         /> */}
      </Col>
    </div>
  )
}





const RiskControl = (props) => {
  const [cSelected, setCSelected] = useState({ top: [], mid: [], low: [] });
  const hasRiskSub = props.userModules.some( module => module.name.includes("Risk"))

  const dispatch = useDispatch();
  const [CSVTime, setCSVTime] = useState()

  const [showWarningModal, setshowWarningModal] = useState(false)
  const [showTagsModal, setshowTagsModal] = useState(false)
  const [showEditTagsModal, setshowEditTagsModal] = useState(false)
  const [showCopySelection, setshowCopySelection] = useState(false)
  const [parametersForDetail, setparametersForDetail] = useState({})
  const [tagsToBeUpdated, settagsToBeUpdated] = useState([])
  const [idForModal, setidForModal] = useState({})
  const [deleteType, setdeleteType] = useState({})
  const [itemsToCopy, setitemsToCopy] = useState([])
  const [copyType, setcopyType] = useState({})
  const [selectedTask, setselectedTask] = useState({})
  const [levelToInclude, setLevelToInclude] = useState()
  // getDefaultTableTask(props.reqs))
  const [userOptionIDs, setuserOptionIDs] = useState([])
  const [groupPopovers, setGroupPopovers] = useState({ top: false, mid: false, low: false })
  const [userOptionObjs, setuserOptionObjs] = useState([])
  const [showBoardModal, setshowBoardModal] = useState(false)
  const [showCopyModal, setshowCopyModal] = useState(false)
  const [showCopyReqModal, setshowCopyReqModal] = useState(false)
  const [boardModalMode, setboardModalMode] = useState(null)
  const [showGroupWarningModal, setShowGroupWarningModal] = useState(false)
  const [editParentChild, seteditParentChild] = useState(false)
  const [highlighting, sethighlighting] = useState(false)
  const [sourceForModal, setsourceForModal] = useState(null)
  const [showTileModal, setshowTileModal] = useState(false)
  const [showGroupModal, setshowGroupModal] = useState(false)
  const [tileModalMode, settileModalMode] = useState(null)
  const [groupModalMode, setgroupModalMode] = useState(null)
  const [userOptionMentionObjs, setuserOptionMentionObjs] = useState([])
  const [isSortDropdownOpen, setIsSortDropdownOpen] = useState(false)
  const [reqGroupsState, setReqGroupsState] = useState(props.reqGroupsRaw)
  const [showAIMenu, setShowAIMenu] = useState(false)

  const [aiQuestions, setAiQuestions] = useState([])
  const [aiChat, setAiChat] = useState([])
  const [aiResult, setAiResult] = useState([])
  const [isAIOpen, setIsAIOpen] = useState(false)
  const [added, setAdded] = useState([])

  const [spotlighting, setspotlighting] = useState(false)

  const [isTourOpen, setIsTourOpen] = useState(false)
  const [curStep, setCurStep] = useState(0)
  const [tourCompleted, settourCompleted] = useState(false)

  const [commentsisOpen, setCommentsIsOpen] = useState(false);
  const [moveOrDeleteOption, setMoveOrDeleteOption] = useState({ label: "Delete them", value: { type: "delete" } })
  const { instance, accounts, inProgress } = useMsal();
  const [deviceUsers, setDeviceUsers] = useState(undefined)
  async function getMembersForTeams(teams) {
    var teamInfoPromises = []
    var users = {}
    teams.forEach(teamID => { teamInfoPromises.push(fetchGroupData(teamID)) })
    Promise.all(teamInfoPromises).then((teamResults) => {

      teamResults.forEach(values => values.value.forEach(

        user => {
          if (!Object.keys(users).includes(user.id)) {

            users[user.id] = user.displayName
          }

        }

      ))
      setDeviceUsers(users)
    })

  }
  function handleCopyReqGroup() {
    const newGrpDashNum = Math.max(...props.reqGroupsRaw.filter(rG => rG._id.includes(sourceForModal.groupID.slice(0, 20))).map(id => Number(id._id.slice(21)))) + 1
    const newGroupID = sourceForModal.groupID.slice(0, 20) + "-" + newGrpDashNum
    if (sourceForModal.howToTreatAssociatedReqGroupTasks) {
      const associatedReqGroupTasks = props.tasks.find(task => task._id === "reqGroup-" + sourceForModal.groupID)
      if (associatedReqGroupTasks) {
        // TURN ON 
        dispatch(updateTask("reqGroup-" + newGroupID, associatedReqGroupTasks._source))
      }
      // 

    }
    if (sourceForModal.howToTreatAssociatedReqs) {
      var reqsToUpdate = {}
      var tilesToUpdate = {}
      const associatedReqs = props.reqs.filter(r => r._source.group === sourceForModal.groupID)
      associatedReqs.forEach(req => {
        const newReqDashNum = Math.max(...props.reqs.filter(r => r._id.includes(req._id.slice(0, 20))).map(id => Number(id._id.slice(21)))) + 1

        const newReqID = req._id.slice(0, 20) + "-" + newReqDashNum
        const source = req._source

        var reqchild = []
        var reqparent = []
        var child = []
        var parent = []
        if (sourceForModal.howToTreatAssociatedSpecification) {
          reqchild = source.reqchild
          reqparent = source.reqparent
          reqchild.forEach(childID => {
            var existingReq = Object.keys(reqsToUpdate).includes(childID)
            var reqSource
            // console.log("tiles", props.tiles, childID)
            const exReq = existingReq ? { _source: reqsToUpdate[childID] } : props.reqs.find(t => t._id === childID)
            console.log("EXREQ", exReq)
            if (exReq) {
              reqSource = { ...exReq._source, reqparent: !exReq._source.reqparent.includes(newReqID) ? [...exReq._source.reqparent, newReqID] : [...exReq._source.reqparent] }
              reqsToUpdate[childID] = reqSource
            }

          })
          reqparent.forEach(parentID => {
            var existingReq = Object.keys(reqsToUpdate).includes(parentID)
            var reqSource
            // console.log("tiles", props.tiles, parentID)
            const exReq = existingReq ? { _source: reqsToUpdate[parentID] } : props.reqs.find(t => t._id == parentID)
            if (exReq) {
              reqSource = { ...exReq._source, reqchild: !exReq._source.reqchild.includes(newReqID) ? [...exReq._source.reqchild, newReqID] : [...exReq._source.reqchild] }
              reqsToUpdate[parentID] = reqSource
            }

          })
          Object.keys(reqsToUpdate).forEach(reqID => {

            dispatch(updateReq(reqID, reqsToUpdate[reqID]))
          })
          //TODO: update other tiles here
        }
        if (sourceForModal.howToTreatAssociatedDesign) {
          child = source.child
          parent = source.parent
          child.forEach(childID => {
            var existingTile = Object.keys(tilesToUpdate).includes(childID)
            var tileSource
            // console.log("tiles", props.tiles, childID)
            const exTile = existingTile ? tilesToUpdate[childID] : props.tiles.find(t => t._id == childID)
            if (exTile && exTile._source) {
              tileSource = { ...exTile._source, parent: !exTile._source.parent.includes(newReqID) ? [...exTile._source.parent, newReqID] : [...exTile._source.parent] }
              tilesToUpdate[childID] = tileSource
            }

          })
          Object.keys(tilesToUpdate).forEach(tileID => {

            dispatch(updateTile(tileID, tilesToUpdate[tileID]))
          })

          //TODO: update other tiles here

        }

        if (sourceForModal.howToTreatAssociatedReqRecs) {
          const associatedRecords = getReqTestStatus(req._id)
          var newRecords = {}
          if (associatedRecords.records.length > 0) {
            // console.log("records:", associatedRecords)
            associatedRecords.records.forEach(record => {
              var existingRec = Object.keys(newRecords).includes(record._id)
              var recSource
              if (existingRec) {
                const exRec = newRecords[record._id]
                recSource = { ...exRec._source, idTiles: !exRec._source.idTiles.includes(newReqID) ? [...exRec._source.idTiles, newReqID] : [...exRec._source.idTiles] }
              } else {
                recSource = { ...record._source, idTiles: !record._source.idTiles.includes(newReqID) ? [...record._source.idTiles, newReqID] : [...record._source.idTiles] }

              }
              // console.log("RECORDS::", record._id, record._source.idTiles, recSource.idTiles)
              newRecords[record._id] = recSource


            })

            Object.keys(newRecords).forEach(recID => {
              dispatch(updateRecord(recID, newRecords[recID]))
            })

            //TODO: update other records here
          }


        }

        dispatch(createReq(
          // console.log(newReqID, "SOURCE",
          {
            ...source,
            group: newGroupID,
            groupRaw: newGroupID,
            reqchild: reqchild,
            reqparent: reqparent,
            child: child,
            parent: parent,
            ref: source.ref + "-1",
            riskReq: true

          }, newReqID
        )
        )
      })
    }
    // TURN ON
    dispatch(createGroup(props.device._id, { ...sourceForModal.groupSource, ref: sourceForModal.groupSource.ref + "-1", risk: true }, newGroupID))
    toggleCopyModal()
  }

  function handleCopyReq() {
    console.log("handleCopyReq", sourceForModal)
    const newReqDashNum = Math.max(...props.reqs.filter(r => r._id.includes(sourceForModal._id.slice(0, 20))).map(id => Number(id._id.slice(21)))) + 1
    // const newGroupID = sourceForModal.groupID.slice(0, 20) + "-" + newGrpDashNum


    // }

    var reqsToUpdate = {}
    var tilesToUpdate = {}
    const associatedReqs = props.reqs.filter(r => r._source.group === sourceForModal.groupID)


    const newReqID = sourceForModal._id.slice(0, 20) + "-" + newReqDashNum
    const source = sourceForModal._source

    var reqchild = []
    var reqparent = []
    var child = []
    var parent = []
    if (sourceForModal.howToTreatAssociatedSpecification) {
      reqchild = source.reqchild
      reqparent = source.reqparent
      reqchild.forEach(childID => {
        var existingReq = Object.keys(reqsToUpdate).includes(childID)
        var reqSource
        // console.log("tiles", props.tiles, childID)
        const exReq = existingReq ? { _source: reqsToUpdate[childID] } : props.reqs.find(t => t._id == childID)
        if (exReq) {
          reqSource = { ...exReq._source, reqparent: !exReq._source.reqparent.includes(newReqID) ? [...exReq._source.reqparent, newReqID] : [...exReq._source.reqparent] }
          reqsToUpdate[childID] = reqSource
        }

      })
      reqparent.forEach(parentID => {
        var existingReq = Object.keys(reqsToUpdate).includes(parentID)
        var reqSource
        // console.log("tiles", props.tiles, parentID)
        const exReq = existingReq ? { _source: reqsToUpdate[parentID] } : props.reqs.find(t => t._id == parentID)
        if (exReq) {
          reqSource = { ...exReq._source, reqchild: !exReq._source.reqchild.includes(newReqID) ? [...exReq._source.reqchild, newReqID] : [...exReq._source.reqchild] }
          reqsToUpdate[parentID] = reqSource
        }

      })
      Object.keys(reqsToUpdate).forEach(reqID => {

        dispatch(updateReq(reqID, reqsToUpdate[reqID]))
      })
      //TODO: update other tiles here
    }
    if (sourceForModal.howToTreatAssociatedDesign) {
      child = source.child
      parent = source.parent
      child.forEach(childID => {
        var existingTile = Object.keys(tilesToUpdate).includes(childID)
        var tileSource
        // console.log("tiles", props.tiles, childID)
        const exTile = existingTile ? tilesToUpdate[childID] : props.tiles.find(t => t._id == childID)
        if (exTile && exTile._source) {
          tileSource = { ...exTile._source, parent: !exTile._source.parent.includes(newReqID) ? [...exTile._source.parent, newReqID] : [...exTile._source.parent] }
          tilesToUpdate[childID] = tileSource
        }

      })
      Object.keys(tilesToUpdate).forEach(tileID => {

        dispatch(updateTile(tileID, tilesToUpdate[tileID]))
      })

      //TODO: update other tiles here

    }

    if (sourceForModal.howToTreatAssociatedReqRecs) {
      const associatedRecords = getReqTestStatus(sourceForModal._id)
      var newRecords = {}
      if (associatedRecords.records.length > 0) {
        // console.log("records:", associatedRecords)
        associatedRecords.records.forEach(record => {
          var existingRec = Object.keys(newRecords).includes(record._id)
          var recSource
          if (existingRec) {
            const exRec = newRecords[record._id]
            recSource = { ...exRec._source, idTiles: !exRec._source.idTiles.includes(newReqID) ? [...exRec._source.idTiles, newReqID] : [...exRec._source.idTiles] }
          } else {
            recSource = { ...record._source, idTiles: !record._source.idTiles.includes(newReqID) ? [...record._source.idTiles, newReqID] : [...record._source.idTiles] }

          }
          // console.log("RECORDS::", record._id, record._source.idTiles, recSource.idTiles)
          newRecords[record._id] = recSource


        })

        Object.keys(newRecords).forEach(recID => {
          dispatch(updateRecord(recID, newRecords[recID]))
        })

        //TODO: update other records here
      }


    }

    dispatch(createReq(
      // console.log(newReqID, "SOURCE",
      {
        ...source,
        reqchild: reqchild,
        reqparent: reqparent,
        child: child,
        parent: parent,
        ref: source.ref + "-1",
        riskReq: true
      }, newReqID
    )
    )
    toggleCopyReqModal()


    // // TURN ON
    // dispatch(createGroup(props.device._id, { ...sourceForModal.groupSource }, newGroupID))
  }
  function getDefaultTableTask(tasks) {
    if (!tasks) {
      return {}
    }
    else if (props.reqs.filter(e => e._source.level === "top").length > 0) {
      return props.reqs.filter(e => e._source.level === "top")[0]
    } else if (props.reqs.filter(e => e._source.level === "mid").length > 0) {
      return props.reqs.filter(e => e._source.level === "mid")[0]
    } else if (props.reqs.filter(e => e._source.level === "low").length > 0) {
      return props.reqs.filter(e => e._source.level === "low")[0]
    } else {
      return {}
    }

  }
  const account = useAccount(accounts[0] || {});
  const AUTHAPIURL = process.env.REACT_APP_STAGE == 'stage'
    ? "https://tentodevauth.azurewebsites.net/"
    : process.env.REACT_APP_STAGE == 'production' ? "https://tentoauth.azurewebsites.net/" : "http://localhost:5001/";


  const toggleGroupWarningModal = (parameters) => {
    setShowGroupWarningModal(!showGroupWarningModal)
    setdeleteType("group")
    setparametersForDetail(parameters)
  };





  const toggleWarningModal = (parameters, type) => {

    setshowWarningModal(!showWarningModal)
    setdeleteType(type)
    setparametersForDetail(parameters)

  };
  const handleDeleteWarning = () => {
    setselectedTask({ ...selectedTask, [parametersForDetail._source.level]: {} })
    switch (deleteType) {

      case "requirement": toggleWarningModal()
        dispatch(deleteReq(parametersForDetail._id, parametersForDetail._source))

        // selectTask(parametersForDetail._source.level,{})
        parametersForDetail.taskWindowSelectTask(parametersForDetail._source.level, {})
        break;
      case "record": toggleWarningModal()
        dispatch(deleteRecord(parametersForDetail._id, parametersForDetail._source))

        break;
      case "anom": toggleWarningModal()
        dispatch(deleteAnom(parametersForDetail._id, parametersForDetail._source))
        break;
      case "protocol": toggleWarningModal()
        dispatch(deleteProtocol(parametersForDetail._id, parametersForDetail._source))
        break;
      case "tile": toggleWarningModal()
        dispatch(deleteTile(parametersForDetail._id, parametersForDetail._source))
        break;
      case "testlog": toggleWarningModal()
        dispatch(deleteTestLog(parametersForDetail._id, parametersForDetail._source))
        break;
      case "run": toggleWarningModal()
        dispatch(deleteRun(parametersForDetail._id, parametersForDetail._source))
        break;
      case "tag": toggleWarningModal()
        dispatch(deleteTag(parametersForDetail._id, parametersForDetail._source))
        break;
      case "req":
        dispatch(deleteReq(parametersForDetail._id, parametersForDetail._source))
        selectTask(parametersForDetail._source.level, {})
        break;
      case "group":
        var reqs2update = []
        var tiles2update = []
        if (moveOrDeleteOption.value.type === "delete") {
          var newLevel = [...props.reqGroups[parametersForDetail._source.level]]
          newLevel = newLevel.filter(el => el._source.title !== parametersForDetail._source.title)
          //delete Group


          //delete requirements
          props.reqs.filter(e => e._source.group == parametersForDetail._id).forEach(ereq => reqs2update.push(ereq))

          reqs2update.forEach(req2u => dispatch(deleteReq(req2u._id, req2u._source)))


          //delete functions,units, and source
          props.tiles.filter(e => e._source.group === parametersForDetail._id).forEach(tile => tiles2update.push(tile))
          tiles2update.forEach(tile2u => dispatch(deleteTile(tile2u._id, tile2u._source)))
        } else {
          const new_group = moveOrDeleteOption.value.grp
          //update requirements

          props.reqs.filter(e => e._source.group === parametersForDetail._id).forEach(ereq => reqs2update.push(ereq))

          reqs2update.forEach(req2u => dispatch(updateReq(req2u._id, { ...req2u._source, group: new_group._id })))
          //update functions units and source

          props.tiles.filter(e => e._source.group === parametersForDetail._id).forEach(tile => tiles2update.push(tile))
          tiles2update.forEach(tile2u => dispatch(updateTile(tile2u._id, { ...tile2u._source, group: new_group._id })))


        }
        dispatch(deleteBoard(parametersForDetail._id, parametersForDetail._source))
        setShowGroupWarningModal(false)
        setMoveOrDeleteOption({ label: "Delete them", value: { type: "delete" } })
        // 
        // dispatch(deleteTag(parametersForDetail._id,parametersForDetail._source))
        break;
      default:
        return;
    }

    // reorderProtocolsForDelete(props.protocols.filter(p =>p._source.idTest === props.selectedRecordId), parametersForDetail._source.order)

  };





  async function fetchUserName(id) {
    let tokenResp
    if (account) {
      tokenResp = await instance.acquireTokenSilent({
        account: account
      }).then((response) => {
        if (response) {
          return response
        }
      }).catch((error) => {
        if (error instanceof InteractionRequiredAuthError) {
          instance.acquireTokenRedirect({
            account: account,
          });
        }
      }).then((response) => {
        if (response) {
          return response
        }
      });
    }

    return fetch(
      AUTHAPIURL + `getUserForID`,
      {
        method: "POST",
        body: JSON.stringify({ "id": id }),
        // mode: 'cors',
        headers: new Headers({
          Accept: "application/json",
          'Content-Type': 'application/json',
          Authorization: `Bearer ${tokenResp.idToken}`
        }
        )

      }
    )
      .then(res => res.json()).then(
        result => {

          return result
        })
  }





  const toggleBoardModal = () => {

    setshowBoardModal(!showBoardModal)

  };



  const toggleCopyingSelection = (parameters, type) => {

    var itemsToCopy = []
    switch (type) {
      case 'tile':
        itemsToCopy = ['Related Test Records', 'Tags']
        break;
      default:
        break;
    }

    setshowCopySelection(!showCopySelection)
    setparametersForDetail(parameters)
    setitemsToCopy(itemsToCopy)
    setcopyType(type)

  };
  async function fetchGroupData(id) {
    let tokenResp
    if (account) {

      tokenResp = await instance.acquireTokenSilent({
        account: account
      }).then((response) => {
        // if(response) {
        return response         //   }
      }).catch((error) => {
        if (error instanceof InteractionRequiredAuthError) {
          instance.acquireTokenRedirect({
            account: account,
          });
        }
      }).then((response) => {

        return response
      });

      return fetch(
        AUTHAPIURL + `getUsersForGroup`,
        {
          method: "POST",
          body: JSON.stringify({ "id": id }),
          // mode: 'cors',
          headers: new Headers({
            Accept: "application/json",
            'Content-Type': 'application/json',
            Authorization: `Bearer ${tokenResp.idToken}`
          }
          )

        }
      )
        .then(res => res.json()).then(result => {
          return result
        }

        )



    }
  }
  async function fetchUsersInSharedGroups(id) {
    let tokenResp
    if (account) {
      tokenResp = await instance.acquireTokenSilent({
        account: account
      }).then((response) => {
        if (response) {
          return response
        }
      }).catch((error) => {
        if (error instanceof InteractionRequiredAuthError) {
          instance.acquireTokenRedirect({
            account: account,
          });
        }
      }).then((response) => {
        if (response) {
          return response
        }
      });
    }
    return fetch(
      AUTHAPIURL + `getSharedUsers`,
      {
        method: "POST",
        body: JSON.stringify({ "id": id }),
        // mode: 'cors',
        headers: new Headers({
          Accept: "application/json",
          'Content-Type': 'application/json',
          Authorization: `Bearer ${tokenResp.idToken}`
        }
        )

      }
    )
      .then(res => res.json()).then(
        result => {
          setuserOptionIDs(result)
          var promises = []
          userOptionIDs.forEach(id => {
            promises.push(fetchUserName(id))
          })
          Promise.all(promises).then((values2) => {
            if (values2) { setuserOptionObjs(values2.reduce((obj, item) => ({ ...obj, [item.id]: item }), {})) }
          })

        })

  }
  const reorderProtocolsForDelete = (protocols, order) => {
    const reorderedProtocols = protocols.filter(t => t._source.order > order)
    reorderedProtocols.map(p => {
      p._source.order = p._source.order - 1;
      dispatch(updateProtocol(p._id, p._source))
      return null
    })
    return null
  };

  const actionsForDeleteModal = { toggleWarningModal, handleDeleteWarning }
  const checkAllGroups = (level) => {

    var lvlArr = [...cSelected[level]]

    // const index = lvlArr.indexOf(selected);
    if (props.reqGroups[level].length > lvlArr.length) {
      lvlArr = [...props.reqGroups[level].map(e => e._id)]
    } else {
      lvlArr = []
    }
    setCSelected({ ...cSelected, [level]: lvlArr });
  }
  useEffect(() => {
    // 
    //
    const configID = window.sessionStorage.getItem("configID")
    if (props.device && props.device._source) {
      getMembersForTeams(Object.keys(props.device._source.teams ? props.device._source.teams : []))
      dispatch(getAISteps("requirements"))
    }
    if (props.selectedRecordId || props.selectedTestLogId) {
      dispatch(selectRecord(""))
      dispatch(selectTestLog(""))
    }
    if (!props.tour) {
			dispatch(getTour(account.localAccountId))
		} else if ((!props.tour.visited || !props.tour.visited.includes("riskControls")) && window.innerWidth > 768) {
			const newVisitedArray= props.tour.visited?[...props.tour.visited,"riskControls"]:["riskControls"]
			dispatch(putTour(account.localAccountId, {visited:newVisitedArray}))
			setIsTourOpen(true)
		}


    if (!props.device || (Object.keys(props.device).length === 0 && !props.selectingDevice)) {
      if (props.location.state) {
        dispatch(selectDevice(props.location.state.id, props.location.state.idDevice, configID));
      } else {
        // console.log("return 1")
        return <Redirect to="/home" />;
      }
    }




    if (props.userTeams) {
      dispatch(getUsersTeamsDevices(account.localAccountId, props.userTeams));
    }
    if (props.update) {
      props.fetchTeamNames(account.localAccountId)
      // dispatch(getBoards(account.localAccountId))
      setTimeout(() => {
        dispatch(getUsersTeamsDevices(account.localAccountId, props.userTeams));
      }, 2000);

    }
    if (!props.reqs) {
      selectDevice(props.location.state.id, props.location.state.idDevice, configID)

    }
    selectDevice(props.location.state.id, props.location.state.idDevice, configID)



    // fetchGroups(account.localAccountId)
    // fetchUsersInSharedGroups(account.localAccountId)
    // dispatch(getBoards(account.localAccountId))

    // dispatch(getBoards(account.name +"-"+account.localAccountId))
    if (props.device !== undefined && props.device._id !== undefined) {
      // dispatch(getBoards(account.localAccountId))
      dispatch(getComments(props.device._id))
      dispatch(getDeviceGroups(props.device._id))
    }

    //setstopLoading(false);

    setTimeout(() => {
      if (!props.device || (Object.keys(props.device).length === 0 && !props.selectingDevice)) {
        if (props.location.state) {
          dispatch(selectDevice(props.location.state.id, props.location.state.idDevice, configID))
        } else {
          // console.log("return 2")

          return <Redirect to="/home" />
        }
      }
    }, 1500);


  }, [props.update, props.userTeams, props.teamsDevicesFetched, props.tour,]);




  const toggleEditParentChild = () => {
    seteditParentChild(!editParentChild)
    sethighlighting(true)

  };
  const handleSelectTile = (tileId) => {
    if (editParentChild && props.selectedTileId !== "") {
      // if editing parent-child, propagate the edit
      const idMap = mapById(props.reqs);
      const selectedTile = idMap[props.selectedTileId];
      const toggledTile = idMap[tileId];

      if (selectedTile && toggledTile) {
        const newSource = { ...selectedTile._source };
        const newToggledSource = { ...toggledTile._source };
        if (newSource.reqchild.indexOf(tileId) !== -1) {
          // remove child
          const newChild = newSource.reqchild.filter(id => id !== tileId);
          newSource.reqchild = newChild;
          dispatch(updateReq(props.selectedTileId, newSource))
          //also need to remove parent from child
          const newParent = newToggledSource.reqparent.filter(id => id !== selectedTile._id);
          newToggledSource.reqparent = newParent;
          dispatch(updateReq(toggledTile._id, newToggledSource))
        } else if (newSource.reqparent.indexOf(tileId) !== -1) {
          // remove parent
          const newParent = newSource.reqparent.filter(id => id !== tileId);
          newSource.reqparent = newParent;
          dispatch(updateReq(props.selectedTileId, newSource))

          //also need to remove child from parent
          const newChild = newToggledSource.reqchild.filter(id => id !== selectedTile._id);
          newToggledSource.reqchild = newChild;
          dispatch(updateReq(toggledTile._id, newToggledSource))

        } else {
          // add
          const selectedTileType = selectedTile._source.level;
          const toggledTileType = toggledTile._source.level;
          switch (selectedTileType + " " + toggledTileType) {
            case "mid top":
              const newParent1 = newSource.reqparent.slice();
              newParent1.push(tileId);
              newSource.reqparent = newParent1;
              dispatch(updateReq(props.selectedTileId, newSource))

              //need to add update of parent as well 

              const newChild1 = newToggledSource.reqchild.slice();
              newChild1.push(selectedTile._id);
              newToggledSource.reqchild = newChild1;
              dispatch(updateReq(toggledTile._id, newToggledSource))


              break;
            case "top mid":
              const newChild2 = newSource.reqchild.slice();
              newChild2.push(tileId);
              newSource.reqchild = newChild2;
              dispatch(updateReq(props.selectedTileId, newSource))

              //need to add update of parent as well 

              const newParent2 = newToggledSource.reqparent.slice();
              newParent2.push(selectedTile._id);
              newToggledSource.reqparent = newParent2;
              dispatch(updateReq(toggledTile._id, newToggledSource))

              break;
            case "mid low":
              const newChild3 = newSource.reqchild.slice();
              newChild3.push(tileId);
              newSource.reqchild = newChild3;
              dispatch(updateReq(props.selectedTileId, newSource))

              //need to add update of parent as well 

              const newParent3 = newToggledSource.reqparent.slice();
              newParent3.push(selectedTile._id);
              newToggledSource.reqparent = newParent3;
              dispatch(updateReq(toggledTile._id, newToggledSource))


              break;
            default:
          }

        }
      }
    } else {
      dispatch(selectTile(tileId))
    }
  }


  function handleNewGroup(values) {
    console.log("handleNewGroup", values)
    const configID = window.sessionStorage.getItem("configID")
    dispatch(createGroup("", { idDevice: props.device._id, configID, level: values.level, title: values.title, ref: values.ref, risk: true }))
    setshowGroupModal(false)
    setidForModal(null)
    setsourceForModal(null)


  }

  const editGroup = (group) => {

    setsourceForModal({ source: { ...group._source, level: group._source.level === "" ? "top" : group._source.level }, id: group._id })
    setshowGroupModal(true)
    setgroupModalMode('edit')

  };
  const copyGroup = (group) => {


    setsourceForModal({
      howToTreatAssociatedReqGroupTasks: true,
      howToTreatAssociatedReqs: true,
      howToTreatAssociatedReqRecs: true,
      howToTreatAssociatedSpecification: true,
      howToTreatAssociatedDesign: true,
      groupSource: {
        ...group._source,
        level: group._source.level === "" ? "top" : group._source.level
      },
      groupID: group._id
    })
    setshowCopyModal(true)

  };
  const copyReq = (req) => {

    setsourceForModal({
      _source: { ...req._source }, _id: req._id,
      howToTreatAssociatedReqRecs: true,
      howToTreatAssociatedSpecification: true,
      howToTreatAssociatedDesign: true,
    })
    setshowCopyReqModal(true)

  };

  function handleEditGroup(values) {
    dispatch(updateGroup(sourceForModal.id, { ...sourceForModal.source, ref: values.ref, title: values.title, level: values.level }))
    setshowGroupModal(false)
    setidForModal(null)
    setsourceForModal(null)

  }
  function handleEditAssign(id, taskSource) {

    dispatch(updateTask(id, taskSource))
    setshowAssignModal(false)
    setidForModal(null)
    setsourceForModal(null)


  }
  function handleEditTile(event, values) {
    const updatedSource = {
      ...sourceForModal.source,

      ...values,

    };
    dispatch(updateReq(idForModal, updatedSource))
    setidForModal(null)
    setshowTileModal(false)
    setsourceForModal(null)



  }

  function editTile(tile) {
    setidForModal(tile._id)
    setsourceForModal({ source: tile._source })
    setshowTileModal(true)
    settileModalMode('edit')

  };


  const newTile = (type, level) => {
    const newOrder = props.reqs.filter(t => t._source.level === level).length !== 0 ? Math.max(...props.reqs.map(t => t._source.order)) + 1 : 0;
    // co/nst newOrder = props.tiles.filter(t => t._source.level ===level).length+1;
    const configID = window.sessionStorage.getItem("configID")

    setidForModal(null)
    setsourceForModal({ source: { idDevice: props.device._id, configID, created: new Date(), createdBy: account.localAccountId, status: 1, level: level, group: groups[level].filter(e => !cSelected[level].includes(e._source.title))[0]._id, type: 1, child: [], parent: [], reqchild: [], required: "Mandatory", reqparent: [], order: newOrder } })
    setshowTileModal(true)
    settileModalMode('new')

  };
  const newBlankReq = (type, level, group) => {
    const newOrder = Math.max(...props.reqs.map(t => t._source.order)) + 1;
    // setsourceForModal({source:  })
    const refnum = props.reqs.filter(req => req._source.group === group).length + 1
    const configID = window.sessionStorage.getItem("configID")

    const newTileObj = {
      idDevice: props.device._id,
      created: new Date(),
      configID,
      title: "",
      ref: refnum.toString(),
      createdBy: account.localAccountId,
      status: 1,
      level: level,
      group: group,
      type: 1, child: [], parent: [], reqchild: [], required: "Mandatory", reqparent: [], order: newOrder,
      riskReq: true
    };
    dispatch(createReq(newTileObj))
  }



  const toggleTileModal = () => {

    setshowTileModal(!showTileModal)

  };
  const toggleGroupModal = () => {

    setshowGroupModal(!showGroupModal)

  };

  const [showCoverageModal, setCoverageModal] = useState(false)
  const toggleCoverageModal = () => {

    setCoverageModal(!showCoverageModal)

  }
  const listOfTiles = (groupid, type) => {
    var tileList = [...props.tiles.filter(t => t._source.type !== 4), ...props.reqs]

    if (type === "1") {
      tileList = [...props.reqs]
    }
    if (type === "2") {
      tileList = tileList.filter(t => t._source.type === 2)

    }
    if (type === "3") {
      tileList = tileList.filter(t => t._source.type === 3)

    }

    if (groupid) {
      tileList = tileList.filter(t => t._source.group === groupid._id)
    }

    return tileList

  }
  const editCoverage = (req) => {

    setidForModal(req._id)
    setsourceForModal({
      records: [...props.records.filter(r => props.testlogs.some(t => t._id === r._source.idTestLog))],

      source: req._source
    })
    setCoverageModal(true)
  };
  function handleEditTestRecordCrossRef(event, values) {

    var updateRecords = []
    if (sourceForModal.idRecords) {
      sourceForModal.idRecords.forEach(
        idR => {
          var updatingRecord = props.records.find(r => idR === r._id)
          if (!updatingRecord._source.idTiles.includes(idForModal)) {
            var source = {
              ...updatingRecord._source,
              idTiles: [...updatingRecord._source.idTiles, idForModal]
            }
            updateRecords.push({ ...updatingRecord, _source: source })
          }
        }
      )
    }
    var removeRecords = props.records.filter(r => r._source.idTiles.includes(idForModal) && sourceForModal.idRecords && !sourceForModal.idRecords.includes(r._id))
    removeRecords.forEach(remRec => updateRecords.push({
      ...remRec,
      _source: { ...remRec._source, idTiles: remRec._source.idTiles.filter(id => id !== idForModal) }
    }))
    updateRecords.forEach(uR => {
      dispatch(updateRecord(uR._id, uR._source))
    })
    // const updatedSource = {
    //   ...sourceForModal.source,
    //   ...values,
    // };
    // dispatch(props.updateRecord(idForModal, updatedSource))
    setCoverageModal(false)
    // // setshowRecordModal(false)
    setidForModal(null)
    setsourceForModal(null)
  };

  const handleTestlogFilterChange = (option) => {
    const sourceForModel = {
      ...sourceForModal, selectedTestLogs: option.value,
      records: props.records.filter(r => option.value === r._source.idTestLog).sort((a, b) => b._source.ref - a._source.ref)

      // tiles: listOfTiles(option.value, sourceForModal.selectType).sort((a, b) => b._source.ref - a._source.ref)
    }
    setsourceForModal(sourceForModel)

    return null
  }

  const handleListFilterChange = (option) => {
    const sourceForModel = { ...sourceForModal, selectedGroup: option.value, tiles: listOfTiles(option.value, sourceForModal.selectType).sort((a, b) => b._source.ref - a._source.ref) }
    setsourceForModal(sourceForModel)
    return null
  }

  const handleTestCoverageChange = (option) => {
    const idRecords = [];
    if (option !== null) {
      option.map(p => {
        idRecords.push(p.value)
        return null
      })
    }

    // const idRecords = { ...sourceForModal.source, idRecords }
    const sourceForModel = { ...sourceForModal, idRecords }

    setsourceForModal(sourceForModel)

    return null
  }
  const [showAssignModal, setshowAssignModal] = useState(false)


  const openAssignMember = (reqGroup) => {
    setidForModal(reqGroup._id)
    const task = props.tasks.find(task => task._id === "reqGroup-" + reqGroup._id)
    setsourceForModal({
      source: reqGroup._source,
      task: task ? task._source : {
        assigned: [],
        progress: "To Do",
        idDevice: props.device._id,
        type: "reqGroup",
        objectID: reqGroup._id
      }


    })
    setshowAssignModal(true)
    // setrecordModalMode('edit')


  };

  const toggleCopyModal = () => {
    setshowCopyModal(!showCopyModal)
  };
  const toggleCopyReqModal = () => {
    setshowCopyReqModal(!showCopyReqModal)
  };
  const newGroup = () => {
    const configID = window.sessionStorage.getItem("configID")

    setidForModal(props.device._id)
    setsourceForModal({ source: { configID, idDevice: props.device._id, level: "top", title: "", ref: "" } })
    setshowGroupModal(true)
    setgroupModalMode('new')

  };

  var groups = {}

  if (props.reqGroups && props.reqGroups.top) { groups = { ...props.reqGroups } }

  const toggleSpotAndHighlight = (parameters) => {
    setspotlighting(false)
    sethighlighting(!highlighting)
  };
  const pflatten = (id, idMap) => {
    var obj = idMap[id];

    if (obj == null || obj._source.reqparent.length === 0) {

      return [id];
    } else {

      var ancestors = obj._source.reqparent.map(id => pflatten(id, idMap)).flat();
      ancestors.push(id);
      return ancestors;
    }
  }

  const cflatten = (id, idMap) => {
    var obj = idMap[id];
    if (obj == null || obj._source.reqchild.length === 0) {
      return [id];
    } else {

      var descendants = obj._source.reqchild.map(id => cflatten(id, idMap)).flat();
      descendants.push(id);
      return descendants;
    }
  }

  const getConnectedTileIds = (id, idMap, recursive) => {
    if (recursive) {
      return [pflatten(id, idMap), cflatten(id, idMap)].flat();
    } else {
      const tile = idMap[id];
      if (tile) {
        return [tile._source.reqparent, tile._source.reqchild].flat();
      }
      else { return [] }
    }
  }

  const highlightedTileIds = (props.selectedTileId !== "" && highlighting) ? getConnectedTileIds(props.selectedTileId, mapById(props.reqs), !editParentChild) : [];



  const levels = {
    top: { title: "Tier 1 Requirement", desc: "These are high level requirements that describe the use case of the product" },
    mid: { title: "Tier 2 Requirement", desc: "These are Mid level requirements that describe what the system should be able to do functionally" },
    low: { title: "Tier 3 Requirement", desc: "These Low level requirements are the individual features and components of the system which are testable" }
  }


  const validateGroupName = (value, ctx) => {
    let reqGroupNames = Object.values(props.reqGroups[sourceForModal.level]).map(e => { return e._source.title })
    if (reqGroupNames && value && Object.values(reqGroupNames).includes(value)) {
      return "This Group already exists";
    }
    return true;
  }

  const validateGroupRef = (value, ctx) => {
    let reqGroupRefs = Object.values(props.reqGroups[sourceForModal.level]).map(e => { return e._source.ref })
    if (reqGroupRefs && value && Object.values(reqGroupRefs).includes(value) && groupModalMode === 'new') {
      return "This Reference already exists";
    }
    return true;
  }

  const validateTileName = (value, ctx) => {

    let reqNames = props.reqs.map(e => { return e._source.title })
    if (reqNames && value && reqNames.includes(value) && groupModalMode === 'new') {
      return "This Requirement already exists";
    }
    return true;
  }
  const validatTileRef = (value, ctx) => {
    let reqRefs = props.reqs.map(e => { return e._source.ref })
    if (reqRefs && value && reqRefs.includes(value) && groupModalMode === 'new') {
      return "This Reference already exists";
    }
    return true;
  }
  const toggleComments = () => setCommentsIsOpen(!commentsisOpen);

  const getReqTestStatus = (reqID) => {
    var records = props.records.filter(r => r._source.idTiles.length > 0)
    var filterRecs = records.filter(rec => rec._source.idTiles.includes(reqID))
    if (filterRecs.length === 0) { return { value: "To Test", runs: [], records: [] } }
    var reqsWithRecs = {}
    filterRecs.forEach(fRec => { fRec._source.idTiles.forEach(idTile => { if (reqsWithRecs[idTile]) { reqsWithRecs[idTile] = [...reqsWithRecs[idTile], fRec] } else { reqsWithRecs[idTile] = [fRec] } }) })

    var reqRecords = reqsWithRecs[reqID]
    var reqRecordsRuns = []
    reqRecords.forEach((reqRec) => {
      reqRecordsRuns = [...reqRecordsRuns, ...props.runs.filter((run) => run._source.star && run._source.idTest === reqRec._id)]
    })


    // if(!Object.keys(reqsWithRuns).includes(reqID) ){finalRequirementStatus.notTested.push(reqID)}
    // if(reqRecordsRuns.includes(undefined) ||reqRecordsRuns.some( run => run.result>1) ){return  {value:"Not Met",runs:reqRecordsRuns,records:reqRecords} }
    // else if(reqRecordsRuns.some( run => run.result===1) ){return {value:"Verified",runs:reqRecordsRuns,records:reqRecords}}
    // else {return {value:"To Test",runs:reqRecordsRuns,records:reqRecords}}  

    var runResults = []
    reqRecordsRuns.forEach(run => runResults = [...runResults, ...run._source.results])
    var reqAnoms = []
    runResults.forEach(res => typeof res.idAnom === "string" ? reqAnoms = [...reqAnoms, ...props.anoms.filter(anm => anm._id === res.idAnom)] : res.idAnom.forEach(anomID => reqAnoms = [...reqAnoms, ...props.anoms.filter(anm => anm._id === anomID)]))

    if (reqAnoms.some(anm => [2, 3].includes(anm._source.type))) {
      return { value: "Not Met", runs: reqRecordsRuns, records: reqRecords }
    } else if (reqAnoms.some(anm => [4].includes(anm._source.type)) || runResults.some(res => res.result === 1)) {
      return { value: "Verified", runs: reqRecordsRuns, records: reqRecords }
    } else {
      return { value: "To Test", runs: reqRecordsRuns, records: reqRecords }
    }

  }

  const genReqsCounters = (level) => {

    //This isn't the most efficient method, but was needed to get my head around the data structures
    //TODO: refactor to work more efficiently
    var lvlReqs = props.reqs.filter(req => req._source.level === level).map(req => req._id)
    if (lvlReqs.length === 0) { return { verified: [], notMet: [], notTested: [] } }

    var records = props.records.filter(r => r._source.idTiles.length > 0)

    var outputVal = {}

    var filterRecs = records.filter(rec => lvlReqs.filter(value => rec._source.idTiles.includes(value)).length > 0)
    var reqsWithRecs = {}
    filterRecs.forEach(fRec => { fRec._source.idTiles.forEach(idTile => { if (reqsWithRecs[idTile]) { reqsWithRecs[idTile] = [...reqsWithRecs[idTile], fRec] } else { reqsWithRecs[idTile] = [fRec] } }) })
    var reqsWithRuns = {}
    Object.keys(reqsWithRecs).forEach(reqId => {
      var reqRecords = reqsWithRecs[reqId]
      reqsWithRuns[reqId] = reqRecords.map(record => {
        return props.runs.filter(run => run._source.idTest === record._id)

          .map(run => {
            return {
              id: run._id,
              result: run._source.result,
              created: run._source.created,
            }

          }).sort(function (a, b) {
            // Turn your strings into dates, and then subtract them
            // to get a value that is either negative, positive, or zero.
            return new Date(b.created) - new Date(a.created);
          })[0]
      })
    })
    var finalRequirementStatus = { verified: [], notMet: [], notTested: [] }
    lvlReqs.forEach(reqID => {
      if (reqsWithRuns[reqID]) { }

      if (!Object.keys(reqsWithRuns).includes(reqID)) { finalRequirementStatus.notTested.push(reqID) }
      else if (reqsWithRuns[reqID].includes(undefined) || reqsWithRuns[reqID].some(run => run.result > 1)) { finalRequirementStatus.notMet.push(reqID) }
      else if (reqsWithRuns[reqID].some(run => run.result === 1)) { finalRequirementStatus.verified.push(reqID) }
      else { finalRequirementStatus.notTested.push(reqID) }
    })

    return finalRequirementStatus, reqsWithRuns

  }
  const configID = window.sessionStorage.getItem("configID")
  if (!props.device || (Object.keys(props.device).length === 0 && !props.selectingDevice)) {
    if (props.location.state) {
      dispatch(selectDevice(props.location.state.id, props.location.state.idDevice, configID))
    } else {
      // console.log("return 3")

      return <Redirect to="/home" />
    }
  }


  function selectTask(level, task) {
    setselectedTask({ ...selectedTask, [level]: task });
  }
  if (props.devices && props.device && props.teamsDevicesFetched && Object.keys(props.device).length > 0) {

    if (props.device && props.device._id) {

      if (!props.devices.find(dev => dev._id === props.device._id)) {
        // console.log("return 4")

        return <Redirect to="/home" />
      }
    }


  }


  return (
    <React.Fragment>
      {props.device && props.device._source && <div style={{ "minHeight": "100vh" }}>

        <Container style={{
          marginTop: "30px",
          float: "left",
          maxWidth: "1920px",
        }}>
          <Row

          ><CommentsAndHistory page={"requirements"} account={account} tentoUser={props.tentoUser} instance={instance} device={props.device} changes={props.changes}
            comments={props.comments} userOptionMentionObjs={userOptionMentionObjs} leaveComment={leaveComment} dispatch={dispatch}

            isTourOpen={isTourOpen} setIsTourOpen={setIsTourOpen} tourCompleted={tourCompleted} settourCompleted={settourCompleted}
            curStep={curStep} setCurStep={setCurStep}
          >

            </CommentsAndHistory>

            <Col>
            </Col>
          </Row>

        </Container>
        <Container>


          <Row style={{ marginTop: "5px" }}>
          </Row>
        </Container>

        {(!props.device || !props.device._source || !props.reqs || !props.reqGroups || !props.tasks || !deviceUsers) && <LoaderWidget></LoaderWidget>}
        {props.device && props.device._source && props.reqs && props.reqGroups && props.tasks && deviceUsers &&//tableView&&

          <TableView
            // key={props.reqGroupsRaw.length}
            reqGroupsState={reqGroupsState}
            reqGroups={props.reqGroups} reqGroupsRaw={props.reqGroupsRaw}
            {...{
              levelToInclude: levelToInclude, setLevelToInclude: setLevelToInclude, reqs: props.reqs, userOptionObjs, dispatch, account, instance, tour: props.tour,
              testlogs: props.testlogs, newTile, newBlankReq,
              selectTask, selectedTask, editTile, newGroup, cSelected, setCSelected, toggleGroupWarningModal, editGroup, copyGroup, copyReq,
              device: props.device, getReqTestStatus, anoms: props.anoms, toggleWarningModal, 
              isTourOpen: isTourOpen, setIsTourOpen: setIsTourOpen, tourCompleted: tourCompleted, settourCompleted: settourCompleted,
              curStep: curStep, setCurStep: setCurStep, runs: props.runs, fetchGroupData, deviceUsers, openAssignMember,
              tasks: props.tasks, editCoverage, aiSteps: props.aiSteps,
              userTeams: props.userTeams,
              isAIOpen: props.isAIOpen, setIsAIOpen,
              aiQuestions, setAiQuestions,
              aiChat, setAiChat,
              aiResult, setAiResult,
              added, setAdded,tiles:props.tiles,userModules:props.userModules,
               hasRiskSub :hasRiskSub

            }} ></TableView>
        }




        {showAssignModal && (
          <TentoModal
            title={"Assign Team Members"}
            isOpen={showAssignModal}

            toggle={() => { setshowAssignModal(!showAssignModal) }}
            size="lg"

            body={
              <div>
                <Row style={{ minHeight: "300px" }}>
                  <Col>
                    <Select
                      onChange={(target, event) => {
                        var task
                        if (target === null) {
                          task = { ...sourceForModal.task, assigned: [] }

                        }
                        else {
                          task = { ...sourceForModal.task, assigned: target.map(t => { return t.value }) }
                        }
                        const sourceForModel = { ...sourceForModal, task, }
                        setsourceForModal(sourceForModel)
                        // handleTeamChange(target,event,sourceForModal.source.teams)


                      }}
                      value={

                        sourceForModal.task.assigned ? sourceForModal.task.assigned.map(a => {

                          return { value: a, label: "" + deviceUsers[a] ? deviceUsers[a] : "Unknown" }
                        }) : []

                      }



                      // }
                      isMulti
                      //    options = {userGroups?Object.keys(userGroups).map(u=>({ label:""+userGroups[u],value:""+u})):[]}
                      options={Object.keys(deviceUsers).map(u => { return { label: deviceUsers[u], value: u } })}
                    />
                  </Col>



                </Row>


                <div className="text-right">
                  <Button
                    color="light"
                    type="button"
                    className="mr-1"
                    onClick={() => {
                      setsourceForModal(null)
                      setidForModal(null)
                      setshowAssignModal(false)
                    }}>
                    Cancel
                  </Button>
                  <Button color="primary" onClick={() => {
                    handleEditAssign("reqGroup-" + idForModal, sourceForModal.task)
                    setshowAssignModal(false)
                    // setshowAssignModal(false)
                  }}>
                    Save
                  </Button>
                </div>
              </div>
              // </Form>
            }
          ></TentoModal>)}

        {showGroupModal && (


          <TentoModal
            title={(groupModalMode === 'new' ? "New Group" : "Edit Group")}
            isOpen={showGroupModal}
            toggle={toggleGroupModal}
            size="md"
            body={
              <Form className="p-2" onSubmit={
                (event) => { event.preventDefault(); groupModalMode === 'new' ? handleNewGroup(sourceForModal.source) : handleEditGroup(sourceForModal.source) }}>
                <Row style={{ marginBottom: "10px" }}>
                  <Col>
                    <Label>{"Group Name: "}
                      <i className="uil uil-info-circle" id="grounametooltip" ></i>
                      <UncontrolledTooltip
                        target="grounametooltip"
                        placement="right"
                      >
                        Requirements group name such as 'Labelling' or 'User Interface' requirements
                      </UncontrolledTooltip></Label>
                    <Input name="title" type="text" value={sourceForModal.source.title}
                      onChange={(event) => { setsourceForModal({ ...sourceForModal, source: { ...sourceForModal.source, title: event.target.value } }) }}
                      required validate={{
                        required: { value: true, errorMessage: 'Please enter a name for your group' },
                        // checkExists: validateGroupName,
                        // maxLength: {value: 15, errorMessage: 'Your group name must be shorter than 15 characters'}
                      }} />
                  </Col>
                </Row>
                <Row style={{ marginTop: "10px", marginBottom: "10px" }}>
                  <Col>
                    <Label>{"Group Reference: "}
                      <i className="uil uil-info-circle" id="refametooltip" ></i>
                      <UncontrolledTooltip
                        target="refametooltip"
                        placement="right"
                      >
                        This reference will be used to index requirements added to the group.
                      </UncontrolledTooltip></Label>
                    <Input name="ref" type="text" value={sourceForModal.source.ref} required
                      onChange={(event) => { if (event.target.value.length < 11) { setsourceForModal({ ...sourceForModal, source: { ...sourceForModal.source, ref: event.target.value } }) } }}

                    />
                  </Col>

                </Row>
                {/* {
								// sourceForModal.source.level === "" && */}
                <Row style={{ marginTop: "10px", marginBottom: "10px" }}>
                  <Col>
                    <Label>{"Group Level: "}
                      <i className="uil uil-info-circle" id="leveltooltip" ></i>
                      <UncontrolledTooltip
                        target="leveltooltip"
                        placement="right"
                      >
                        You can specify whether requirements in this group will be high-level product, mid-level system, or low-level component requirements.
                      </UncontrolledTooltip></Label>
                    {groupModalMode !== 'new' && props.tiles.filter(tile => tile._source.group === sourceForModal.id).length > 0 &&
                      <Label style={{ color: "black" }}>This group has associated design outputs and as such you are not able to change the level of this group. Use the design traceability page to assign them to a new group first and then try again.  </Label>
                    }
                    <Input type="select" name="level"
                      onChange={(event) => { console.log("LEVEL:", event.target.value); setsourceForModal({ ...sourceForModal, source: { ...sourceForModal.source, level: event.target.value } }) }}

                      value={sourceForModal.source.level} disabled={groupModalMode !== 'new' &&
                        props.tiles.filter(tile => tile._source.group === sourceForModal.id).length > 0}>
                      <option value="top">Product</option>
                      <option value="mid">System</option>
                      <option value="low">Component</option>

                    </Input></Col>
                </Row>


                {/* // } */}

                <div className="text-right" style={{ marginTop: "20px" }}>
                  <Button
                    color="light"
                    type="button"
                    className="mr-1"
                    onClick={toggleGroupModal}>
                    Cancel
                  </Button>
                  <Button color="primary" type="submit">
                    {tileModalMode === 'new' ? 'Create' : 'Save'}
                  </Button>
                </div>
              </Form>}
          ></TentoModal>

        )}
        {showCopyModal && props.tasks && (


          <CopyReqGroupModal {...props} getReqTestStatus={getReqTestStatus} showCopyModal={showCopyModal} toggleCopyModal={toggleCopyModal} setsourceForModal={setsourceForModal} sourceForModal={sourceForModal} handleCopyReqGroup={handleCopyReqGroup}
          ></CopyReqGroupModal>

        )}
        {showCopyReqModal && props.tasks && (


          <CopyReqModal {...props} getReqTestStatus={getReqTestStatus} showCopyModal={showCopyReqModal} toggleCopyModal={toggleCopyReqModal} setsourceForModal={setsourceForModal} sourceForModal={sourceForModal} handleCopyReq={handleCopyReq}
          ></CopyReqModal>

        )}

        {showWarningModal && (<div> {deleteModal(showWarningModal, actionsForDeleteModal, deleteType)} </div>)}
        {showGroupWarningModal && (


          <TentoModal
            title={"Are you sure?"}
            isOpen={showGroupWarningModal}
            toggle={() => setShowGroupWarningModal(!showGroupWarningModal)}
            size="lg"
            body={
              <div >

                {"Deleting will permanently remove Requirement group " + parametersForDetail._source.title
                  //  + " as well as associated requirements. To save requirements close this popupup and move the following requirements to a new group before trying to delete this group again."
                }
                <p></p>

                <p>
                  <b> What would you like to do with this group's requirements{parametersForDetail._source.level === "low" && ", functions, units, and source entries"} :</b> <Select
                    value={moveOrDeleteOption}
                    onChange={setMoveOrDeleteOption}
                    options={
                      [{ label: "Delete them", value: { type: "delete" } }, ...props.reqGroups[parametersForDetail._source.level].filter(rq => rq._id !== parametersForDetail._id).map(
                        (r) => { return { label: "Move them to Group " + r._source.title, value: { type: "move", grp: r } } })
                      ]}
                  ></Select>
                </p>
                <p>This can NOT be undone. Are you sure?"</p>
              </div>
            }
            fullWidthButtons={
              [
                {
                  color: "danger",
                  onClick: handleDeleteWarning,
                  text: "Confirm"
                }
              ]}
          ></TentoModal>
        )}

        {showCoverageModal && (

          <TentoModal
            title={"Set your testing coverage."}
            isOpen={showCoverageModal}

            toggle={toggleCoverageModal}
            size="lg"
            bodyClassname={modals.modalBody_noScroll}
            body={<Form className="p-2" onSubmit={handleEditTestRecordCrossRef}>
              <Row style={{ minHeight: "500px" }}>
                <Col >
                  <Label>{"Filter by test log: "}
                    <i className="uil uil-info-circle" id="typetooltip" ></i>
                    <UncontrolledTooltip
                      target="typetooltip"
                      placement="right"
                    >
                      Filter the test coverage by requirements, functions or units.
                    </UncontrolledTooltip></Label>
                  <Select
                    onChange={handleTestlogFilterChange}
                    options=
                    {props.testlogs.map(
                      (r) => {
                        // const relateLog = props.testlogs.filter(g => g._id === r._source.idTestLog)[0];
                        return { label: r._source.ref + "-" + r._source.title, value: r._id }
                      })

                    } />

                  <Label>{"Select test coverage: "}
                    <i className="uil uil-info-circle" id="groupftooltip" ></i>
                    <UncontrolledTooltip
                      target="groupftooltip"
                    >
                      Select which requirements, functions, or units these record is testing.
                    </UncontrolledTooltip></Label>
                  <Select
                    isMulti="true"
                    // closeMenuOnSelect={false}
                    // name="tileCoverageSelect"
                    // className="react-select"
                    // classNamePrefix="react-select"
                    onChange={handleTestCoverageChange}
                    defaultValue={props.records.map(
                      (r) => {
                        if (r._source.idTiles.includes(idForModal)) {
                          const testLog = props.testlogs.find(t => t._id === r._source.idTestLog)

                          return { label: testLog._source.ref + "- " + r._source.title, value: r._id }
                        }

                      }
                    )}
                    options={
                      sourceForModal && [...sourceForModal.records].map(
                        (r) => {
                          const relateLog = props.testlogs.filter(g => g._id === r._source.idTestLog)[0];
                          // if (relateLog) {
                          return { label: relateLog._source.ref + "-" + r._source.ref + "- " + r._source.title, value: r._id }
                          // }
                        })
                    }
                  // options={sourceForModal && [
                  //   ...sourceForModal.tiles.filter(t => t._source.ref !== "").sort(function (a, b) {
                  //     return a._source.ref.localeCompare(b._source.ref)
                  //   }),
                  //   ...sourceForModal.tiles.filter(t => t._source.ref === "").sort(function (a, b) {
                  //     return a._source.ref.localeCompare(b._source.ref)
                  //   })].map(
                  //     (r) => { const relateGroup = props.reqGroupsRaw.filter(g => g._id === r._source.group)[0]; return { label: relateGroup._source.ref + "-" + r._source.ref + "- " + r._source.title, value: r._id } })} 
                  />
                </Col>

                <div className="text-right">
                  <Button
                    color="light"
                    type="button"
                    className="mr-1"
                    onClick={toggleCoverageModal}>
                    Cancel
                  </Button>
                  <Button color="primary" type="submit">
                    {'Save'}
                  </Button>
                </div>
              </Row>
            </Form>}
          ></TentoModal>
        )}
      </div>
      } </React.Fragment>
  );

}


const mapStateToProps = (state, ownProps) => {
  const { TestPlan } = state;
  return { ...TestPlan };
}



export default connect(
  mapStateToProps,
)(RiskControl);
