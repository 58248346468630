import React, { useState } from 'react';
import {
    Row,
    Col,
    Card,
    CardBody,
    Media,
    Button,
    Badge,
    Modal,
    ModalHeader,
    ModalBody,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    UncontrolledDropdown, UncontrolledTooltip,
} from 'reactstrap';
import classNames from 'classnames';

import { Link } from 'react-router-dom';
import { connect, useDispatch } from 'react-redux';
import styles from './styles.module.css'
import  card from './Styles/card.module.css';
import  devices from './Styles/deviceInfo.module.css';
import  modals from './Styles/modal.module.css';

export default function TentoModal (props){
    const {
        isOpen,
        toggle,
        size,
        style,
        bodyStyle,
        title,
        body,
        fullWidthButtons,
        extraClassname,
        bodyClassname,
        seperateButtons } = props

    return (
        <Modal className={extraClassname +modals.modal } style={style} isOpen={isOpen} toggle={toggle} size={size}>
            <ModalHeader className={modals.modalHeader} toggle={toggle}>{title}</ModalHeader>

            <ModalBody style={bodyStyle} className={bodyClassname?bodyClassname:modals.modalBody}>
                {body}

            </ModalBody>
            {fullWidthButtons && fullWidthButtons.map(but => {
                return (
                    <Button className={modals.modalButton} color={but.color} onClick={() => { but.onClick() }}> {but.text} </Button>
                )
            })}
           {seperateButtons && <div className="text-right">

           {seperateButtons.map( but => {
            console.log("BUTTON", but);
            return(
                <Button
                    color={but.color}
                    type={but.type}
                    className={but.className}
                    onClick={() => { but.onClick() }}
                >
                    {but.text}
                </Button>
            )
           })}
                

                
            </div>}
        </Modal>
    );
};

