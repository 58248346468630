import React from 'react';
import { Card, CardHeader, NavLink, UncontrolledCollapse, DropdownMenu, DropdownItem, UncontrolledButtonDropdown,DropdownToggle,CardBody } from 'reactstrap';
import classNames from 'classnames';

import BootstrapTable from 'react-bootstrap-table-next';
import cellEditFactory, { Type } from 'react-bootstrap-table2-editor';
import styles from './style.module.css'

import {EvidenceItemStatusLabels, EvidenceItemStatusClasses,  } from './constants.js'
import { updateDevice, updateEvidence } from '../../redux/actions';

//import Table from './EvidenceTable';


/*
Functions for the formating of the section titles 

*/



const colourForIndicator =source =>
{
    var colourForIndicator = "success"; //default value is set to completed.     
    source.items.map(i => {
    if (i.status == 1 || i.status == 2 ) // if any of the items within the evidence element are in progress or not yet started, change colour. 
    { colourForIndicator = "primary"}
    })
    return colourForIndicator
}

//Creates the text for the label on the ribbon
const labelForIndicator =source =>
{
    var labelForIndicator = "Completed"; //default value is set to completed.     
    source.items.map(i => {
        if (i.status == 1 || i.status == 2 ) // if any of the items within the evidence element are in progress or not yet started, change text. 
        { labelForIndicator = "In Progress"}
    })
    return labelForIndicator
}




/*
Functions for the formating of the table 

*/

//Called when changing the status of an evidence item. 
function handleStatusChange(row, newResultValue,user)
{
    const {evidence,title,desc,ref, updateEvidence,dispatch} = row;
    const itemIndex = evidence._source.items.findIndex((e) => e.title === title) //Find the item to replace based on the title. This may need changing. 
    evidence._source.items[itemIndex] = {title:title, desc:desc, ref:ref, status:newResultValue} //Replace item with updated status value. 
    const newEvidence = {...evidence}
    dispatch(updateEvidence(newEvidence._id, newEvidence._source,user)) //Update through props evidence with source 
}

//This funciton is not handled well as it does not allow for the changing of other elements in the graph. It relies on the title of the element to be static. 
//Called when changing the reference of an evidence item. 
const   handleEvidenceItemChange = (oldValue,newValue,row,user) => {
    
    const {evidence,title,desc,ref,status, updateEvidence,dispatch} = row;
    const itemIndex = evidence._source.items.findIndex((e) => e.title === title) //Find the item to replace based on the title. This may need changing. 
    evidence._source.items[itemIndex] = {title:title, desc:desc, ref:newValue, status:status} //Replace item with updated status value. 
    const newEvidence = {...evidence}
    dispatch(updateEvidence(newEvidence._id, newEvidence._source,user)) //Update through props evidence with source 
}


/*
Returns a collapse elements for each evidence block.  
*/
const EvidenceItem = (props)=> {

    var { evidence, number } = props;
    var source = evidence._source || {}; // grabs source of evidence item. 
    var evidenceitems = source.items //unpacks the evidence items into a seperate array. 
    var toggle = "toggle"+number //creates a unique identifier for that element to be toggled. 
    return (
            <Card className="mb-0">
                <CardHeader>
                    <h5 className="m-0 ribbon-box">
                    <div className={classNames('ribbon', 'ribbon-' + colourForIndicator(source), 'float-right')}>
                        <i className="float-right mdi mdi-check-bold mr-1"></i>  {labelForIndicator(source)}
                        </div>
                        <NavLink className="custom-accordion-title d-block pt-2 pb-2" id={toggle} href="#">
                            {source.name}
                            <span className="float-left">
                                <i className="mdi mdi-chevron-right font-18 accordion-arrow"></i>
                            </span>
                        </NavLink>
                    </h5>
                </CardHeader>
                <UncontrolledCollapse toggler={toggle}>
                    <CardBody className={styles.cardBody}>
                        <Table {...props}  evidence={evidence}/>              
                    </CardBody>
                </UncontrolledCollapse>
            </Card>
    )
};

/*
Returns a the table to be included in the body of the collapsable item  
*/
const Table = (props) => {
    var { evidence, updateAnom } = props
    const evidenceitems = evidence._source.items;
    const data = dataForRuns(props, evidenceitems,evidence);
    const cols = columnsForRuns(evidenceitems,props);

       return (
        <React.Fragment>
         {data.length === 0 ? "None" :
          <BootstrapTable
            bootstrap4
            keyField="id"
            data={data}
            columns={cols}
            cellEdit={ cellEditFactory({
            mode: 'click',
            afterSaveCell: ( newValue, oldValue, row ) => {handleEvidenceItemChange(newValue, oldValue, row,props.user)},
            blurToSave : true,
            autoSelectText : false
        })
        }
        />}
        {/*wrapperClasses="table-responsive - put scroll bars on*/}
        </React.Fragment>
    );
};

const columnsForRuns = (anoms, props) => {
    var c = [
         {
            dataField: 'title',
            text: 'Title',
            editable: false,
            sort: true,
        },
        {
            dataField: 'desc',
            text: 'Description',
            sort: false,
            editable: false,
            editor: {
              type: Type.TEXTAREA
          }
        },
       {
            dataField: 'ref',
            text: 'Reference/Link',
            sort: false,
            editor: {
              type: Type.TEXTAREA
          }
        },            
          {
            dataField: 'status',
            text: 'Status',
            sort: false,
            editable: false,
            formatter: statusFormatter,
            formatExtraData:props.user
        },
    ];
    return c;
  };
  
  const dataForRuns = (props, evidenceitems,evidence) => evidenceitems.map(a => {
        var row = {
           title: a.title,
          ref: a.ref,
          status: a.status,
          desc: a.desc,
          evidence: evidence,
          updateEvidence: props.updateEvidence,
          updateSection:props.updateSection,
          dispatch:props.dispatch
        }
        return row;
      });

      const statusFormatter = (value, row,rowIndex,formatExtraData) => {
        return (<div>
            <UncontrolledButtonDropdown className="ml-5">
                    <DropdownToggle className={EvidenceItemStatusClasses[value]}>
                        {EvidenceItemStatusLabels[value]}
                    </DropdownToggle>
                    <DropdownMenu className="dropdown-menu-animated">
                        <DropdownItem onClick={() => handleStatusChange(row,1,formatExtraData)}>{EvidenceItemStatusLabels[1]}</DropdownItem>
                        <DropdownItem onClick={() => handleStatusChange(row,2,formatExtraData)}>{EvidenceItemStatusLabels[2]}</DropdownItem>
                        <DropdownItem onClick={() => handleStatusChange(row,3,formatExtraData)}>{EvidenceItemStatusLabels[3]}</DropdownItem>
                        <DropdownItem divider />
                        <DropdownItem onClick={() => handleStatusChange(row,0,formatExtraData)}>{EvidenceItemStatusLabels[0]}</DropdownItem>
                    </DropdownMenu>
                </UncontrolledButtonDropdown>
          </div>
        );
};




export default EvidenceItem;
