// @flow
import * as constants from './constants';

const INIT_STATE = {
   'idBoard': "",'idDevice': "", 'server':'ok', "news":[], 'projects':  [], deviceEvidence: [], 'boards':[], 'tiles':[], 'records':[], 'protocols':[], "tags": [], 'testlogs':[], 'anoms':[], 'runs':[],
   'selectedTileId':"", 'selectedTestLogId':"",'selectedProjectId': "", 'selectedRecordId':"", 'unackdSeqNo':[]
};

const updateArray = (array, payload) => {
  console.log("UPDATEARRAY",array,payload)
    const newArray = array.filter(t => t._id !== payload._id);
    newArray.push(payload);
    return newArray;
};

type DataAction = { type: string, payload: {} };
function filterObjectForArraysWithConfigID(state,returnState,configIDs){
  Object.keys(state).forEach(dataKey =>{

    switch (dataKey){
      case "comments":
      return
      case "reqGroups":
        returnState[dataKey] =filterObjectForArraysWithConfigID(state[dataKey],{},configIDs)
        return
      case "reqGroupsRaw":
      default:
        if(!dataKey.includes("ignoreConfig-") &&Array.isArray(state[dataKey])&&state[dataKey].some( data => Object.keys(data).includes("_source")&& Object.keys(data._source).includes("idDevice") )){
          const included = state[dataKey].filter( dat => configIDs.includes( dat._source.configID))

          // const notIncluded =state[dataKey].filter( dat => !configIDs.includes( dat._source.configID))
          returnState[dataKey] = included
                    // returnState["NonConfig"]= {...returnState["NonConfig"],[dataKey]:[...notIncluded]}
        }else{
          returnState[dataKey] = state[dataKey]
        }
        return
    } 
  })
  return returnState
}
const Data = (state: State = INIT_STATE, action: DataAction) => {
  if (process.env.REACT_APP_STAGE === "production") {
    console.log = () => { };
}

    const {type, payload, seqNo} = action;
    const configID = window.sessionStorage.getItem("configID") 
    console.log("REDUCER:",type,payload,state,action);
    const unackdSeqNo = state.unackdSeqNo.slice();
    if(seqNo >= 0 && unackdSeqNo.indexOf(seqNo) === -1) {
    //  console.log('Adding new seqNo',seqNo);
      unackdSeqNo.push(seqNo);
    //  console.log('UnackdSeqNos:', unackdSeqNo);
    }
    
    switch(type) {
      case "PUT_TOUR":
      //  console.log("UPDATE_REQ::",payload)
        return { ...state, tour:payload._source  };
      case "UPDATE_REQ":
      //  console.log("UPDATE_REQ::",payload)
        return { ...state, unackdSeqNo, reqs: updateArray(state.reqs,payload)  };
      case "REORDER_REQS":
     //   console.log("reducer REORDER_REQS::",payload._sources.map(r => r._source.order))
        return { ...state, unackdSeqNo, reqs:payload._sources  };
      case "REORDER_REQ_GRPS":
     // console.log("reducer REORDER_REQS_GRPS::",{...state.reqGroups,[payload._sources[0]._source.level]:payload._sources})
      return { ...state, unackdSeqNo, reqGroups:{...state.reqGroups,[payload._sources[0]._source.level]:payload._sources},   };
      case constants.LEAVE_COMMENT:
      //  console.log("LEAVING  REDUCER:",state.comments,payload,"comments" in state)
        if ("comments" in state){
          var new_comments = {...state.comments.comments}
          if (payload._topLevelComment  !==""){
            // var commentsThread = Array.isArray(new_comments[payload._topLevelComment])?[...new_comments[payload._topLevelComment]]:[new_comments[payload._topLevelComment]]
            // commentsThread.push(payload._source) 
            
            new_comments[payload._topLevelComment].push(payload._source)
            
          }else{new_comments[payload._source.userId +"|"+payload._source.dateTime]=[payload._source]}
        //  console.log("comments leaving",new_comments)
          return { ...state, comments:{"comments":new_comments} }
        }else{
          return  { ...state, comments:{"comments":{[payload._source.userId +""+payload._source.dateTime]:[payload._source]} }}
        }

      case constants.UPDATE_TILE:
      //  console.log("REDUCER TILES UPDATE:",state,payload)
        return { ...state, tiles:updateArray(state.tiles,payload)  };
      case constants.UPDATE_RUN:
       // console.log("REDUCER RUN UPDATE:",state,payload)
        return { ...state, runs:updateArray(state.runs,payload)  };
      
      case constants.UPDATE_ANOM:
      //  console.log("REDUCER UPDATE_ANOM:",state,payload)
        return { ...state, anoms:updateArray(state.anoms,payload)  };
      case constants.UPDATE_BOARD:
        const boards =updateArray(state.boards,payload)
        // var boards = updateArray(state.boards,payload)
      //  console.log("UPDATE_BOARD::","PAYLOAD:",payload,"STATE.BOARDS:",state.boards,"updateARRAY:",updateArray(state.boards,payload),"END")
        return {...state, reqGroupsRaw:boards,reqGroups: {
          top: boards.filter(e => e._source.level === "top"),
          mid: boards.filter(e => e._source.level === "mid"),
          low: boards.filter(e => e._source.level === "low")
        }};
      case constants.UPDATE_DEVICE:
        
        if (Object.keys(state).includes("device") && state.device._id){
          window.sessionStorage.setItem("device", JSON.stringify(payload._id))
          window.sessionStorage.setItem("deviceSource", JSON.stringify(payload._source))
        }
                    return { ...state, unackdSeqNo, device: Object.keys(state).includes("device")?{_id:payload._id,_source:payload._source}:undefined , devices: updateArray(state.devices?state.devices:[],payload)  };
      case constants.DELETE_RISK_DETAILS:
        console.log("REDUCER DELETE_RISK_DETAILS:",state,payload,state.riskDetails.filter( i => i._id===payload._id))
        return { ...state, unackdSeqNo, riskDetails: state.riskDetails.filter( i => i._id!==payload._id)  };
      case constants.DELETE_RISK_SEVERITY:
        console.log("REDUCER DELETE_RISK_SEVERITY:",state,payload,state.riskSeverity.filter( i => i._id===payload._id))
        return { ...state, unackdSeqNo, riskSeverity: state.riskSeverity.filter( i => i._id!==payload._id) };
      case constants.DELETE_RISK_PROBABILITY:
        console.log("REDUCER DELETE_RISK_PROBABILITY:",state,payload,state.riskProbability.filter( i => i._id===payload._id))
        return { ...state, unackdSeqNo, riskProbability: state.riskProbability.filter( i => i._id!==payload._id) };
      case constants.DELETE_RISK_MATRIX:
        console.log("REDUCER DELETE_RISK_MATRIX:",state,payload,state.riskMatrix.filter( i => i._id===payload._id))
        return { ...state, unackdSeqNo, riskMatrix: state.riskMatrix.filter( i => i._id!==payload._id) };
      case constants.DELETE_PROBABILITY_MATRIX:
        console.log("REDUCER DELETE_PROBABILITY_MATRIX:",state,payload,state.probMatrix.filter( i => i._id===payload._id))
        return { ...state, unackdSeqNo, probMatrix: state.probMatrix.filter( i => i._id!==payload._id) };
      case constants.UPDATE_RISK_CATEGORY:
        return {...state,riskCategories:updateArray(state.riskCategories,payload)} ;

      case constants.CREATE_BOARD:
      case constants.CREATE_PROJECT:
      case constants.DELETE_PROJECT:
      case constants.UPDATE_PROJECT:
      case constants.CREATE_RUN:
      case constants.DELETE_RUN:
      case constants.CREATE_RECORD:
      case constants.UPDATE_RECORD:
      case constants.CREATE_TESTLOG:
      case constants.UPDATE_TESTLOG:
      case constants.DELETE_TESTLOG:
      case constants.CREATE_TILE:
      case constants.CREATE_ANOM:
      case constants.DELETE_ANOM:
      case constants.CREATE_TAG:
      case constants.UPDATE_TAG:
      case constants.DELETE_TAG:
      case constants.CREATE_PROTOCOL:
      case constants.UPDATE_PROTOCOL:
      case constants.DELETE_PROTOCOL:
      case constants.CREATE_EVIDENCE_SECTION:
      case constants.CREATE_RISK_DETAILS:
      case constants.CREATE_RISK_SEVERITY:
      case constants.CREATE_RISK_PROBABILITY:
      case constants.CREATE_RISK_MATRIX:
      case constants.CREATE_PROBABILITY_MATRIX:
        return {...state};
      case constants.UPDATE_RISK_DETAILS:
        return { ...state, unackdSeqNo, riskDetails: updateArray(state.riskDetails,payload) };

      case constants.UPDATE_RISK_SEVERITY:
        return { ...state, unackdSeqNo, riskSeverity: updateArray(state.riskSeverity,payload) };
      case constants.UPDATE_RISK_PROBABILITY:    
        return { ...state, unackdSeqNo, riskProbability: updateArray(state.riskProbability,payload) };

      case constants.UPDATE_RISK_MATRIX:
        return { ...state, unackdSeqNo, riskMatrix: updateArray(state.riskMatrix,payload) };
      case constants.UPDATE_PROBABILITY_MATRIX:
        return { ...state, unackdSeqNo, probMatrix: updateArray(state.probMatrix,payload) };
      //  console.log("CREATE_EVIDENCE_SECTION PAYLOAD",payload)
        // console.log(`REDUCER: ${type} PAYLOAD`);
        // return {...state};
      case constants.JOIN_USER_ROOM:
      //    console.log("JOIN_USER_ROOM PAYLOAD",payload)
          return {...state};
      case constants.DEVICE_CHANGE:
      //  console.log("REDUCER dev:",payload.change)
        return {...state,"changes":[...state.changes,{"_source":payload.change}]};
      case constants.UPDATE_CURRENT_SECTION:
      //  console.log("UPDATE_CURRENT_SECTION PAYLOAD",{...state, device:payload.value})
        return {...state, device:payload.value};
      case constants.UPDATE_SECTION:
      //  console.log("UPDATE_SECTION PAYLOAD",payload.value)
        return {...state, ...payload.value};
      case constants.UPDATE_EVIDENCE:
      //  console.log("UPDATING EVIDENCE", payload, state.deviceEvidence)
        return { ...state, unackdSeqNo, deviceEvidence: updateArray(state.deviceEvidence,payload) };
        case constants.UPDATE_TASK:
      //  console.log("UPDATING EVIDENCE", payload, state.deviceEvidence)
        return { ...state, unackdSeqNo, tasks: updateArray(state.tasks,payload) };
      case constants.DELETE_DEVICE:
      //  console.log("DELETE DEVICE:",payload._id,state.boards.map(u=>u._id))

            return { ...state,devices:state.devices.filter(dev=>dev._id!==payload._id)};
      case constants.SELECT_DEVICE:
      //  console.log("SELECT DEVICE:",payload)
           return { ...state,device:{},deviceProperties:{},deviceAnalysis:{},deviceEvidence:[],selectingDevice:true};
      
        case constants.CSV_DOWLOAD:
      //  console.log("CSV:",payload)
            return { ...state,};
      case constants.POPULATE_SECTION:
      //  console.log("REDUCER POPULATE_SECTION DEVICE:",payload,)
           return { ...state,loadingAnalysis:"false"};
      case constants.CLEAR_NOTIFS:
      //  console.log("REDUCER CLEAR NOTIFS::",state.userNotifs.filter(ntf => !payload.notifs.some(payNtf => payNtf.messageId ===ntf.messageId)))
            return{ ...state,userNotifs:state.userNotifs.filter(ntf => !payload.notifs.some(payNtf => payNtf.messageId ===ntf.messageId))};
      case constants.GET_BOARDS:
          return {...state, unackdSeqNo,teamsDevicesChange:undefined};
          // case constants.GET_NOTIFS:
          //   console.log("TOP GET NOTIFS")
          // return {...state, unackdSeqNo,gettingNotifs:true};
      case constants.SELECT_BOARD:
          return { ...state, idBoard:payload._id,idDevice:payload._deviceId, selectedTestLogId: "", selectedTileId:"", selectedRecordId:""};
      case constants.SELECT_TILE:
          return { ...state, selectedTileId:payload._id};
      case constants.SELECT_RECORD:
      //  console.log("SELECT_RECORD:",payload)
          return { ...state, selectedRecordId:payload._id};
      case constants.SELECT_TESTLOG:
      //  console.log("SELECT_TESTLOG:",payload)
          return { ...state, selectedTestLogId:payload._id};
      case constants.SELECT_PROJECT:
            return { ...state, selectedProjectId:payload._id};
      case constants.DELETE_BOARD:
          return { ...state, idBoard:"" };
      case constants.GET_COMMENTS:
        return { ...state, tasks:undefined};
      case constants.DELETE_TILE:
          return { ...state, selectedTileId:""};
      case constants.DELETE_RECORD:
          return { ...state, selectedRecordId:"" };
      case constants.SELECT_CONFIG:
        return { ...state, configLoading:true};
        case constants.CONFIG_FOUND:
        return { ...state, configLoading:false};
      case constants.SERVER_CONNECTION_UPDATE:

        return {...state, server: payload.server};
        case constants.GET_USER_TEAM_DEVICES:


        return {...state, update:undefined,
          // teamsDevicesFetched:false
        };
      case constants.SERVER_UPDATE:
        const reducedUnackdSeqNo = state.unackdSeqNo.filter(n => n !== seqNo);
        var returnState = { ...state, ...payload, unackdSeqNo: reducedUnackdSeqNo}
        if (Object.keys(returnState).includes("device") && returnState.device._id){
          window.sessionStorage.setItem("device", JSON.stringify(returnState.device._id))
          window.sessionStorage.setItem("deviceSource", JSON.stringify(returnState.device._source))
        }
       
   
        if(configID!==null&&configID!=="Base Device"){
          returnState=filterObjectForArraysWithConfigID(returnState,returnState,[configID])
        }

        Object.keys(payload).forEach(dataKey =>{
          if( !dataKey.includes("ignoreConfig-") &&Array.isArray(payload[dataKey])&&payload[dataKey].some( data => Object.keys(data).includes("_source")&& Object.keys(data._source).includes("idDevice") )){
            returnState["ignoreConfig-"+dataKey] = payload[dataKey]
          window.sessionStorage.setItem("ignoreConfig-"+dataKey,JSON.stringify(payload[dataKey]))
          }
        })
         if(Object.keys(payload).includes("testlogs")){
        }
          return { ...returnState};
        case constants.CLEAR_BOARDS: 
        console.log("CLEAR_BOARDS ")
            return {...state,idBoard: "",  boards:[], tiles:[], records:[], protocols:[], 'anoms':[], runs:[],    selectedTileId:"", selectedRecordId:"", unackdSeqNo:[]}
  //  case constants.SELECT_CONFIG:
  //   console.log("config payload","SELECT_CONFIG")

  //   var returnState ={...state}
  //       //  console.log('Reducer Server UPDATE',payload);
  //       if (Object.keys(state).includes("device")){
  //         window.sessionStorage.setItem("device", JSON.stringify(state.device._id))
  //         window.sessionStorage.setItem("deviceSource", JSON.stringify(state.device._source))
  //       }
        
  //       console.log("config",configID)
  //       if(configID!==null&&configID!=="Base Device"){
  //         console.log("config filter")
  //         returnState=filterObjectForArraysWithConfigID(state,returnState,configID)
  //     }
  //   console.log("config returnState",returnState)

  //   return { ...returnState};
   case "AI_MENU":
          return { ...state, isAIOpen:payload.isAIOpen };
          case constants.DELETE_REPORT:
            return { ...state, unackdSeqNo, reports: state.reports.filter( i => i._id!==payload._id) };
      default:
          return { ...state };
    }
};

export default Data;
// if(constants.APPLY_DOCUMENT_CHANGES_LOCALLY) {
    //   // apply document changes
    //   switch (type) {
    //   //boards
    //       case constants.DELETE_BOARD:
    //         return { ...state, unackdSeqNo, boards:state.boards.filter(t => t._id !== payload._id), idBoard:"" };
    //       case constants.DELETE_PROJECT:
    //           return { ...state, unackdSeqNo, projects:state.projects.filter(t => t._id !== payload._id), selectedProjectId:"" };
    //       case constants.UPDATE_BOARD:
    //         return { ...state, unackdSeqNo, boards: updateArray(state.boards,payload)  };
    //   //device
    //       case constants.UPDATE_DEVICE:
    //           return { ...state, unackdSeqNo, devices: updateArray(state.devices,payload)  };
    //   // runs
    //       case constants.UPDATE_RUN:
    //         return { ...state, unackdSeqNo, runs: updateArray(state.runs,payload) };
    //       case constants.DELETE_RUN:
    //         return { ...state, unackdSeqNo, runs:state.runs.filter(t => t._id !== payload._id) };
    //   // records
    //       case constants.UPDATE_RECORD:
    //         return { ...state, unackdSeqNo, records: updateArray(state.records,payload)};
    //       case constants.DELETE_RECORD:
    //         return { ...state, unackdSeqNo, records:state.records.filter(t => t._id !== payload._id), selectedRecordId:"" };
    //   // records
    //       case constants.UPDATE_TESTLOG:
    //         return { ...state, unackdSeqNo, testlogs: updateArray(state.testlogs,payload)};
    //       case constants.DELETE_TESTLOG:
    //         return { ...state, unackdSeqNo, testlogs:state.testlogs.filter(t => t._id !== payload._id), selectedTestLogId:"" };
    //   //tiles
    //       case constants.UPDATE_TILE:
    //         return { ...state, unackdSeqNo, tiles: updateArray(state.tiles,payload)};
    //       case constants.DELETE_TILE:
    //         return { ...state, unackdSeqNo, tiles:state.tiles.filter(t => t._id !== payload._id), selectedTileId:""};
    //   // anoms
    //       case constants.UPDATE_ANOM:
    //         return { ...state, unackdSeqNo, anoms: updateArray(state.anoms,payload) };
    //       case constants.DELETE_ANOM:
    //         return { ...state, unackdSeqNo, anoms:state.anoms.filter(t => t._id !== payload._id) };
    //   // tags
    //         case constants.UPDATE_TAG:
    //           return { ...state, unackdSeqNo, tags: updateArray(state.tags,payload) };
    //         case constants.DELETE_TAG:
    //             return { ...state, unackdSeqNo, tags:state.tags.filter(t => t._id !== payload._id)};
    //         // project
    //         case constants.UPDATE_PROJECT:
    //           return { ...state, unackdSeqNo, projects: updateArray(state.projects,payload) };

    //   // protocol
    //       case constants.UPDATE_PROTOCOL:
    //         return { ...state, unackdSeqNo, protocols: updateArray(state.protocols,payload) };
    //       case constants.DELETE_PROTOCOL:
    //         return { ...state, unackdSeqNo, protocols:state.protocols.filter(t => t._id !== payload._id) };
    //   // protocol
    //       case constants.UPDATE_EVIDENCE:
    //         console.log("UPDATING EVIDENCE", payload, state.deviceEvidence)
    //         return { ...state, unackdSeqNo, deviceEvidence: updateArray(state.deviceEvidence,payload) };
    //   // OTHER
    //       case constants.SET_OBJECT:
    //         return { ...state };
          
    //   }
    // }