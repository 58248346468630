import React, {
  Component, useState, useEffect, MouseEvent,
  ReactElement,
  useCallback,
  useMemo,
  useRef,
} from 'react';
import reactStringReplace from 'react-string-replace';
import CustomMentionEditor from './CustomMentionEditor.tsx';
import styles from './style.module.css';
import { Redirect } from 'react-router-dom';
import Select from 'react-select';
import editorStyles from './editorStyles.module.css'
import {
  InteractionRequiredAuthError,
  InteractionStatus,
} from "@azure/msal-browser";
import {
  Card,
  CardBody,
  CardTitle,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  Button,
  Collapse,
  DropdownItem,
  Modal, ModalHeader, ModalBody, UncontrolledCollapse,
  Col,
  Container, Table,
  TabContent, TabPane, Nav, NavItem, NavLink, CardText, Row, CardHeader,
} from 'reactstrap';
import { Editor, RichUtils, convertToRaw, Modifier, EditorState } from 'draft-js';
import 'draft-js/dist/Draft.css';


import { MsalAuthenticationTemplate, useMsal, useAccount } from "@azure/msal-react";

import { connect, useSelector, useDispatch } from 'react-redux';
import CsvDownload from 'react-json-to-csv'

import EvidenceBlock from './EvidenceBlock';





import { Link } from 'react-router-dom';
import LoaderWidget from '../Loader';

import {
 leaveComment} from '../../redux/actions';

import mentionsStyles from './MentionsStyles.module.css';
import createMentionPlugin, {
  defaultSuggestionsFilter,
  MentionData,
  MentionPluginTheme,
} from '@draft-js-plugins/mention';
import { EvidenceItemStatusLabels, EvidenceItemStatusClasses, } from './constants.js'

const CommentSection = (props) => {
  const AUTHAPIURL = process.env.REACT_APP_STAGE == 'stage'
    ? "https://tentodevauth.azurewebsites.net/"
    : process.env.REACT_APP_STAGE == 'production' ? "https://tentoauth.azurewebsites.net/" : "http://localhost:5001/";

  const ref = useRef()
  const [showComments, setShowComments] = useState(true)
  const [deviceHistory, setDeviceHistory] = useState(<div></div>)
  const [deviceChanges, setDeviceChanges] = useState([])
  var { userOptionMentionObjs } = props
  const [topLevelComment, setTopLevelComment] = useState("")
  const [editorState, setEditorState] = React.useState(
    () => EditorState.createEmpty(),
  );
  const removeSelectedBlocksStyle = (editorState) => {
    const newContentState = RichUtils.tryToRemoveBlockStyle(editorState);
    if (newContentState) {
      return EditorState.push(editorState, newContentState, 'change-block-type');
    }
    return editorState;
  }

  const getResetEditorState = (editorState) => {
    const blocks = editorState
      .getCurrentContent()
      .getBlockMap()
      .toList();
    const updatedSelection = editorState.getSelection().merge({
      anchorKey: blocks.first().get('key'),
      anchorOffset: 0,
      focusKey: blocks.last().get('key'),
      focusOffset: blocks.last().getLength(),
    });
    const newContentState = Modifier.removeRange(
      editorState.getCurrentContent(),
      updatedSelection,
      'forward'
    );

    const newState = EditorState.push(editorState, newContentState, 'remove-range');
    return removeSelectedBlocksStyle(newState)
  }
  const handleSendComment = () => {
    var mentionedUsers = []
    const contentState = editorState.getCurrentContent()
    const raw = convertToRaw(contentState)
    var newComment = ""
    raw.blocks.forEach(block => { newComment = newComment + "\n" + block.text })
    for (let key in raw.entityMap) {
      const ent = raw.entityMap[key]

      if (ent.type === 'mention') {
        mentionedUsers.push(ent.data.mention)
      }
    }
    if (newComment.slice(1) !== "") {
      var commentSource = { mentions: mentionedUsers, name: props.tentoUser.displayName, userId: props.account.localAccountId, dateTime: new Date().toISOString(), text: newComment.slice(1), topLevelComment: topLevelComment }
      props.dispatch(leaveComment(props.device._id, commentSource, topLevelComment))

      setEditorState(getResetEditorState(editorState))
      setTopLevelComment("")
    }
  }
  var CommentsBlock = <div></div>
  // var changesBlock = <div></div>
  // // console.log("changes", props.changes)
  // if (props.changes){
  //   changesBlock =<div style={{maxHeight:"65vh",overflowY:"scroll"}}>{props.changes.sort(function (a, b) {
  //     return Date.parse(a._source.timestamp) - Date.parse(b._source.timestamp);
  //   }).map((changeRaw) => {
  //     const change = changeRaw._source
  //     console.log("CHANGE::",change)
  //     if (change.section==="input"){
  //       return <p style ={{paddingLeft:'10px'}}> {change.user} changed the device description to {change.description}</p>

  //     }else if(change.section==="properties"){
  //       return <p style ={{paddingLeft:'10px'}}> {change.output}</p>


  //     }else if (change.section==="evidence"&&change.source){
  //       console.log("CHANGE:",change)
  //       return <p style ={{paddingLeft:'10px'}}> {change.user} changed the {change.source.element==="status"?"status of ":""} {change.source.element==="ref"?"reference for ":""}
  //        {change.source.title} to "{change.source.element==="ref"?change.source.change:EvidenceItemStatusLabels[change.source.change]}"</p>
  //     }else if (change.section==="evidence"&&change.changeObj){
  //       console.log("CHANGE::!",change)
  //       return <p style ={{paddingLeft:'10px'}}> {change.user} {change.changeObj.change} the {change.changeObj.element} {change.changeObj.title}</p>
  //     }
  //   })}</div>
  // }


  //backgroundColour ={props.comments.comments.indexOf(comment)%2===0?"#ededed":"white"}
  const [activeTab, setActiveTab] = useState('1')
  function toggleTab(tab) {
    if (activeTab !== tab) {

      setActiveTab(tab)

    }
  }
  const [suggestions, setSuggestions] = useState([]);
  if (props.device._source) {

    if (suggestions === "") {
      fetchUsersInSharedGroups(props.account.localAccountId, "")
    }
  }

  async function fetchUsersInSharedGroups(id, value) {
    if ( !props.device._source.teams||props.device._source.teams.length===0){
      return

    }else{
    let tokenResp
    if (props.account) {
      tokenResp = await props.instance.acquireTokenSilent({
        account: props.account
      }).then((response) => {
        if (response) {
          return response
        }
      }).catch((error) => {
        if (error instanceof InteractionRequiredAuthError) {
          props.instance.acquireTokenRedirect({
            account: props.account,
          });
        }
      }).then((response) => {
        if (response) {
          return response
        }
      })


        ;
    }
    return fetch(
      AUTHAPIURL + `getMentionUsers`,
      {
        method: "POST",
        body: JSON.stringify({ "device_teams": props.device._source.teams, "id": props.account.localAccountId }),
        // mode: 'cors',
        headers: new Headers({
          Accept: "application/json",
          'Content-Type': 'application/json',
          Authorization: `Bearer ${tokenResp.idToken}`

        }
        )

      }
    )
      .then(res => res.json()).then(
        result => {
          result = result.filter((u) => props.account.localAccountId !== u.id)

          if (value !== "") {
            const regex = new RegExp('^' + value,);
            const matchedVals = result.filter((res) => res.name.match(regex));
            setSuggestions(matchedVals)
          } else {
            setSuggestions(result)

          }

        })}
  }

  useEffect(() => {
    if (props.device._source) {
      fetchUsersInSharedGroups(props.account.localAccountId, "")
    }
    var changesBlock = <div></div>
    if (props.changes) {
      changesBlock = <div style={{ maxHeight: "65vh", overflowY: "scroll", padding: "0 10px" }}>{props.changes.sort(function (a, b) {
        return Date.parse(a._source.timestamp) - Date.parse(b._source.timestamp);
      }).map((changeRaw) => {
        const change = changeRaw._source
        if (change.section === "input") {
          return <p style={{ paddingLeft: '10px' }}> {change.user} changed the device description to {change.description}</p>

        } else if (change.section === "properties" && change.output) {
          return <p style={{ paddingLeft: '10px' }}> {change.output}</p>


        } else if (change.section === "evidence" && change.source) {
          if (change.source.change === 99) {
            return <p style={{ paddingLeft: '10px' }}>{change.user} created the document {change.source.title}</p>
          } else {
            return <p style={{ paddingLeft: '10px' }}> {change.user} changed the {change.source.element === "ref" ? "reference for " : "Status for "}
              {change.source.title} to "{change.source.element === "ref" ? change.source.change : EvidenceItemStatusLabels[change.source.change]}"</p>
          }
        } else if (change.section === "evidence" && change.changeObj) {
          return <p style={{ paddingLeft: '10px' }}> {change.user} {change.changeObj.change} the {change.changeObj.element} {change.changeObj.title}</p>
        } else {
          var changeUserName = suggestions.some(usr => usr.id === change.userID) ? suggestions.filter(usr => usr.id === change.userID)[0].name : change.user
          return <p style={{ paddingLeft: '10px' }}> {change.changeText.replace("CHANGE_USER_NAME", changeUserName)}</p>
        }
      })}</div>
    }
    setDeviceHistory(changesBlock)
    setDeviceChanges(props.changes)

  }, [props.changes])


  if (props.comments) {
    CommentsBlock = Object.keys(props.comments.comments).map((commentID) => {

      if (props.comments.comments[commentID].length > 1) {
        var thread = props.comments.comments[commentID]

        return <div>
          <Comment className="Comment" suggestions={suggestions} tentoUser={props.tentoUser} account={props.account} comment={thread[0]} key={thread[0].userId + "|" + thread[0].dateTime}>
          </Comment>
          {thread.slice(1).map(threadComment => {
            var inset = "25px"
            return <div style={{ paddingLeft: inset }}><Comment account={props.account} tentoUser={props.tentoUser} suggestions={suggestions} className="Comment" comment={threadComment} key={threadComment.userId + "|" + threadComment.dateTime}>

            </Comment>
            </div>
          })}
          <a className="btn " onClick={() => { setTopLevelComment(commentID) }}> <b>Reply to Thread</b></a>
        </div>
        // return <div>{comment.map( theadComment => {
        //   var inset = comment.indexOf(theadComment) ===0?"0px":"25px"
        //   return <div style ={{paddingLeft:inset}}>
        //     <Comment className="Comment"  comment={theadComment} key={theadComment.name+"|"+theadComment.dateTime}>
        //       </Comment>
        //       </div>})}<span onClick={()=>{console.log("index:",topLevelIndex);setTopLevelComment(topLevelIndex)}}>Reply to Thread</span></div>
      } else {
        let comment = props.comments.comments[commentID][0]
        return <div className={styles.Comment}> <Comment tentoUser={props.tentoUser} account={props.account} suggestions={suggestions}
          comment={comment} key={commentID}></Comment><a className="btn " onClick={() => { setTopLevelComment(commentID) }}><b>Reply</b></a></div>
      }
    }

    )
  }

  return (
    <div style={{}} >
      <div>
        {/* <Nav tabs>
          <NavItem className={styles.navItme}>
            <NavLink 
               className={styles.navLink + " " + activeTab === '1'?"active":"" }
              onClick={() => { toggleTab('1'); }}
            >
              Comments
            </NavLink>
          </NavItem>
          <NavItem className={styles.navItem}>
            <NavLink
               className={ activeTab === '2'?"active":"" }
              onClick={() => { toggleTab('2'); }}
            >
              History
            </NavLink>
          </NavItem>
        </Nav> */}
        <TabContent activeTab={activeTab}>
          <TabPane tabId="1">
            {/* <div style={{display:showComments?"block":"none"}}> */}
            <div className={styles.commentSection} style={{ overflowY: "scroll", width: "100%", height: "40vh" }}>



              {/* {props.comments &&
    //backgroundColour ={props.comments.comments.indexOf(comment)%2===0?"#ededed":"white"}
    }  */}
              {CommentsBlock}
            </div>
            <p style={{marginLeft: "10px"}}>
              {topLevelComment && topLevelComment !== "" && <span > { " You are replying to: " + props.comments.comments[topLevelComment][props.comments.comments[topLevelComment].length - 1].text}
                {/* {props.comments.comments[topLevelComment].length>1?props.comments.comments[topLevelComment][props.comments.comments[topLevelComment].length -1].text:props.comments.comments[topLevelComment].text} */}
                {" "}<i onClick={() => { setTopLevelComment("") }} className='mdi mdi-close-circle-outline'></i>
              </span>}
            </p>

            <CustomMentionEditor fetchUsersInSharedGroups={fetchUsersInSharedGroups} handleSendComment={handleSendComment} account={props.account} device={props.device} suggestions={suggestions} instance={props.instance} mentions={userOptionMentionObjs} editorState={editorState} setEditorState={setEditorState}></CustomMentionEditor>
            <p style={{  fontSize:"11px", "color": "#626262", marginLeft: "10px" }}>Enter to send, Ctrl + Enter for new line</p>




            <Button style={{ marginLeft: "15px", marginBottom: "10px", backgroundColor:"#35A8E0", border:"none" }} onClick={handleSendComment}>Post</Button>
            {/* </div> */}
          </TabPane>
          {/* <TabPane tabId="2" >
            {console.log("DEVICE CHANGES:",deviceChanges)}
          {deviceHistory}
          </TabPane> */}
        </TabContent>
      </div>

      {/* <span style={{fontSize:showComments?"36px":"15px"}} onClick={()=>{setShowComments(true)}}>Comments</span> <span style={{fontSize:!showComments?"36px":"15px"}} onClick={()=>{setShowComments(false)}}>History</span> */}


    </div>

  )
}

const Comment = (props) => {
  // console.log("TPLVEL:",props.topLevelIndex)
  var text = props.comment.text
  if (props.comment.mentions && props.comment.mentions.length > 0) {
    props.comment.mentions.forEach(user => {
      // var splitText = text.split
      var mentionName = props.suggestions.some(usr => usr.id === user.id) ? props.suggestions.filter(usr => usr.id === user.id)[0].name : user.id === props.account.localAccountId ? props.tentoUser.displayName : user.name
      text = reactStringReplace(text, "@" + user.name, (match, i) => (
        <span style={{ color: '#4a85bb' }}>{"@" + mentionName}</span>
      ));
      //  text.replace("@"+user.name, "<span style={{ color: '#4a85bb'>@"+user.name+"</span>")
    })

  }
  let options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
  const dateVal = new Date(Date.parse(props.comment.dateTime))
  var commenterName = props.suggestions.some(usr => usr.id === props.comment.userID) ? props.suggestions.filter(usr => usr.id === props.comment.userID)[0].name : props.comment.userId === props.account.localAccountId ? props.tentoUser.displayName : props.comment.name
  return (
    <article className="media" >
      {/* <figure className="media-left">
          <p className="image is-64x64">
            <img src="https://bulma.io/images/placeholders/128x128.png" alt="Avatar" />
          </p>
        </figure> */}
      <div className="media-content" >
        <div className="content">
          <Row>
            <span className={styles.userName}>{commenterName}</span>
            <span className={styles.messageDate}>{new Intl.DateTimeFormat('en-UK', options).format(dateVal)}</span>
          </Row>
          <Row>
            <span>{text}</span>
          </Row>

        </div>
      </div>
    </article>
  )
}

export default CommentSection