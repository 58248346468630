import { useState } from 'react';
import { Input, Table } from 'reactstrap';
import Select from "react-select";

import styles from './style.module.css';
import tables from '../../../../components/Styles/table.module.css';

const Matrix = (props) => {
    const [editing, setEditing] = useState("");
    const [toSave, setToSave] = useState([]);

    const handleRowChange = (newVal, sevVal, probVal) => {
        console.log("CHANGED", newVal, sevVal, sevVal)
        // props.data[rIndex][cIndex].text = newVal
        setToSave([
            newVal,
            sevVal,
            probVal
        ])
    }

    const onSave = () => {
        try {
            console.log("onSAVE", props.type, toSave, props.data)
            props.onChange(toSave);
            if (props.type === "select") {
                props.data.find(rows => rows.some(dat => dat.probability1.id === toSave[1] && dat.probability2.id === toSave[2])).find(dat => dat.probability1.id === toSave[1] && dat.probability2.id === toSave[2]).text = toSave[0]

            } else if (props.type === "text") {
                props.data.find(rows => rows.some(dat => dat.severity.id === toSave[1] && dat.probability.id === toSave[2])).find(dat => dat.severity.id === toSave[1] && dat.probability.id === toSave[2]).text = toSave[0]

            }
            setEditing("");
            setToSave([]);
        } catch (e) {
            setEditing("");
            setToSave([]);
            console.error("ERROR", e);
        }
    }

    const onEditCell = (cellId, cellIds) => {
        console.log("SET EDITING", cellId, cellIds);
        setEditing(cellId);
    }
    function handleSelect(e) {
        console.log("ONCHANGE", e)
    }
    return (
        <Table className={tables.table}>
            <thead className={tables.tableHead}>
                <tr>
                    <th className={props.data.length > 0 ? tables.tableTitle : ""}>{props.title1}/{props.title2 || props.title1}</th>
                    {props.columns1.map((head) => (
                        <th>{props.title1} - {head.text}</th>
                    ))}
                    {props.canAdd && <th><i className="mdi mdi-plus" /> Add Column</th>}
                </tr>
            </thead>
            <tbody>
                {props.data.length > 0 ? (
                    props.data.map((row, rIndex) => (
                        <tr style={{ "height": "60px", "maxheight": "65px" }}>
                            <td style={{ "width": "20%", "maxWidth": "20%" }} className={tables.tableTVH}>{props.title2 || props.title1} - {props.columns2[rIndex].text}</td>
                            {row.map((cell, cIndex) => (
                                <td style={{ "width": 80 / row.length + "%", "maxWidth": 80 / row.length + "%" }}
                                    className={tables.tableTD} key={`row_${rIndex}_cell_${cIndex}`}
                                    onClick={() => { onEditCell(`row_${rIndex}_cell_${cIndex}`, cell) }}
                                >
                                    <span>
                                        {editing !== `row_${rIndex}_cell_${cIndex}` || !props.hasRiskSub ? (
                                            props.type === "text" ? cell.text : props.riskProbability.find(p => p._id === cell.text) ? props.riskProbability.find(p => p._id === cell.text)._source.probability : ""
                                        ) : props.type === "text" ? (
                                            <Input

                                                autoFocus
                                                type="text"
                                                defaultValue={cell.text}
                                                onInput={(e) => { handleRowChange(e.target.value, cell.severity.id, cell.probability.id) }}
                                                onBlur={onSave}
                                            // className={tables.tableTD}
                                            />
                                        ) : (
                                            
                                            <select
                                                autoFocus
                                                className={styles.matrixDropdown}
                                                onBlur={onSave}
                                                onChange={
                                                    (e) => {
                                                    console.log("ONCHANGE", cell); handleRowChange(e.target.value,
                                                        props.type === "text" ? cell.severity.id : cell.probability1.id,
                                                        props.type === "text" ? cell.probability.id : cell.probability2.id)
                                                }
                                            }


                                                defaultValue={{ label: cell.text, value: cell.text }}
                                            >
                                                <option value="">Select option</option>
                                                {props.options.map((option, index) => (
                                                    <option 
                                                    //  onClick={() => {
                                                    //     console.log("ONCLICK", cell); handleRowChange(option.value,
                                                    //         props.type === "text" ? cell.severity.id : cell.probability1.id,
                                                    //         props.type === "text" ? cell.probability.id : cell.probability2.id)
                                                    // }} 
                                                    value={option.value}>{option.label}</option>
                                                ))}
                                            </select>
                                        )}
                                    </span>
                                </td>
                            ))}
                            {props.canAdd && <td className={tables.tableTD}></td>}
                        </tr>
                    ))
                ) : (
                    <tr>
                        <td className={styles.matrixPlaceholder}>You need to define the scales first.</td>
                    </tr>
                )}
                {props.canAdd &&
                    <tr>
                        <td className={tables.tableTVH}><i className="mdi mdi-plus" /> Add Row</td>
                        {/* {props.columns1.map((row) => (
                            <td className={tables.tableTD}></td>
                        ))} */}
                    </tr>
                }
            </tbody>
        </Table>
    )
}

export default Matrix