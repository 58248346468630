import React, { useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import {
    Row, Col, Card, CardBody, CardHeader, Collapse, UncontrolledTooltip,
    Button, ListGroup, ListGroupItem, DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown, Table
} from 'reactstrap';
import classNames from 'classnames';
import styles from './styles.module.css'
import card from '../../../../components/Styles/card.module.css';
import tables from '../../../../components/Styles/table.module.css';
import TaskWindow from './TaskWindow';

const Task = props => {

    const { task, selectTask, selectedTask, getReqTestStatus, reqGroups, group, testlogs } = props;
    const [completed, setCompleted] = useState(task.stage === 'Done');
    const markCompleted = e => {
        setCompleted(e.target.checked);
        selectTask(task);
    };
    var taskStatus = getReqTestStatus(task._id)
    var taskStatusOutput = taskStatus.value
    // if (taskStatus.value === "Not Met") {

    //     if (taskStatus.runs.includes(undefined)) {
    //         var recordIndex = taskStatus.runs.indexOf(undefined)
    //         taskStatusOutput = taskStatus.records[recordIndex]._source.ref + "-" + taskStatus.records[recordIndex]._source.title
    //     } else {
    //         //     var runAnomalyIDs = []
    //         //     taskStatus.runs.forEach(rn => {
    //         //         rn._source.results.forEach(res => {
    //         //             typeof res.idAnom === "string" ? runAnomalyIDs = [...runAnomalyIDs, res.idAnom] : runAnomalyIDs = [...runAnomalyIDs, ...res.idAnom]
    //         //         })
    //         //     });

    //         //     const runAnomalies = props.anoms.filter( anm => runAnomalyIDs.includes(anm._id))
    //         //    var failingRun = taskStatus.runs.filter(run =>{
    //         //     run._source.results.some( res => {
    //         //         var runAnoms = runAnomalies.filter( anm => res.idAnom.includes(anm._id))
    //         //         return runAnoms.some( anm => [2, 3].includes(anm._source.type) )
    //         //     })
    //         //    })
    //         //     // var failingRun = taskStatus.runs.filter(run =>{
    //         //     //     console.log("FAILING",run,props.anoms.filter(an => [2, 3].includes(an._source.type) && an._source.result === 1 && run._source.results.some( res => res.idAnom.includes(an._id))))
    //         //     //     return props.anoms.filter(an => [2, 3].includes(an._source.type) && an._source.result === 1 && run._source.results.some( res => res.idAnom.includes(an._id))).length>0
    //         //     // })[0]
    //         //     console.log("FAILING RUN:", taskStatus.runs, failingRun, taskStatus.records)
    //         var failingRecord = taskStatus.records.filter(rec => rec._id === taskStatus.runs[0]._source.idTest)[0]
    //         var failingLog = testlogs.filter(log => log._id === failingRecord._source.idTestLog)[0]
    //         taskStatusOutput = failingLog._source.ref + "-" + failingRecord._source.ref

    //     }
    // }
    // else if (taskStatus.value === "To Test") {
    //     taskStatusOutput = "TO TEST"
    // }
    // else if (taskStatus.value === "Verified") {

    //     var passingRun = taskStatus.runs[0]//.filter(run => run.result === 1)[0]
    //     var passingRecord = taskStatus.records.filter(rec => rec._id === passingRun._source.idTest)[0]
    //     var passLog = testlogs.filter(log => log._id === passingRecord._source.idTestLog)[0]
    //     taskStatusOutput = passLog._source.ref + "-" + passingRecord._source.ref
    // }
    return ( //justify-content-sm-between mt-2 tableRowReq
        <tr
            onClick={() => {
                if (selectedTask && task._id === selectedTask._id) {
                    selectTask(task._source.level, {})
                } else {
                    selectTask(task._source.level, task)
                }
            }}>
            <td className={tables.tableTD} style={{ backgroundColor: task._id === selectedTask._id ? "#35A8E033" : "" }} sm={3}>
                <div style={{ float: "left", textAlign: "left" }} //className="custom-control custom-checkbox"
                >
                    <span style={{ marginLeft: "20px" }} className={styles.ref}>{task._source.ref ? group._source.ref + "-" + task._source.ref : "-"}</span>
                </div>
            </td>

            <td sm={6} className={tables.tableTD} style={{ padding: "0", height: "100%", backgroundColor: task._id === selectedTask._id ? "#35A8E033" : "" }}>
                <div style={{ float: "left", textAlign: "left", maxWidth: "400px" }} className="custom-control custom-checkbox">
                    <span className={styles.title}>{task._source.title}</span>
                </div>
            </td>



            <td className={tables.tableTD} style={{ backgroundColor: task._id === selectedTask._id ? "#35A8E033" : "" }} sm={3}>
                <span className={taskStatus.value === "To Test" ? styles.greyCounter : taskStatus.value === "Verified" ? styles.greenCounter : styles.redCounter} style={{ padding: "0" }}>
                    {taskStatusOutput.toUpperCase()}
                </span>
            </td>

        </tr>
    );
};

const TaskSection = ({
    title, tasks, device, dispatch, selectBoard, deleteWarning, level, type, group, newTile, reqGroups, newGroup,
    cSelected, setCSelected, toggleGroupWarningModal, editGroup, copyGroup, testlogs, getReqTestStatus, account, instance,
    userOptionObjs, reqGroupsRaw, anoms, runs, hasRiskSub, deviceUsers, openAssignMember, toDoTasks, editCoverage,userModules }) => {
    const [collapse, setCollapse] = useState(false);
    const [selectedTask, setselectedTask] = useState({});

    useEffect(() => {


    }, [reqGroups, reqGroupsRaw])

    var upToDateGroup = reqGroupsRaw.find(i => i._id === group._id)
    function selectTask(level, task) {
        setselectedTask(task);
    }
    const toggle = () => {
        if (collapse && selectedTask._source && level === selectedTask._source.level) {

            selectTask(level, {})
        }
        setCollapse(!collapse);
    }

    const leveltitle = level === 'top' ? "product level" : level === 'mid' ? "system level" : level === 'low' ? "component level" : ""

    tasks = tasks.filter((cell) => cell._source.riskReq)

    var testStatuses = tasks.map(tsk => { return getReqTestStatus(tsk._id).value })
    var assignText = ""
    // if (upToDateGroup._source && upToDateGroup._source.assigned) {
    //     const filteredAssingnees = upToDateGroup._source.assigned.filter(as => Object.keys(deviceUsers).includes(as.value))

    //     assignText = filteredAssingnees.length === 0 ? "To Assign" :
    //         "assigned to: " + deviceUsers[filteredAssingnees[0].value]

    //     if (filteredAssingnees.length > 1) { assignText += ` + ${filteredAssingnees.length - 1} more` }
    // }

    var assignText
    var statusText = "In Progress"
    const task = toDoTasks.find(task => task._id === "reqGroup-" + upToDateGroup._id)
    if (task && task._source && deviceUsers) {
        statusText = task._source.progress
        const filteredAssingnees = task._source.assigned.filter(as => Object.keys(deviceUsers).includes(as))

        assignText = filteredAssingnees.length === 0 ? "" :
            "assigned to: " + deviceUsers[filteredAssingnees[0]]

        if (filteredAssingnees.length > 1) { assignText += ` + ${filteredAssingnees.length - 1} more` }
    } else {
        assignText = ""
    }
    return (
        <React.Fragment>
            <Row className={styles.requirementRow} onClick={toggle} style={{ cursor: 'pointer' }}>
                <Col xs={3}>
                    {title}<span className="text-muted"> {"(" + tasks.length + ")"}</span> <br /> <span className="text-muted"> {leveltitle}</span>
                </Col>
                <Col xs={1} style={{ marginBottom: "auto", marginTop: "auto", display: "grid", justifyContent: "center" }}>
                    <span>
                        {testStatuses.filter(res => res === "Verified").length}
                    </span>
                </Col>
                <Col xs={1} style={{ marginBottom: "auto", marginTop: "auto", display: "grid", justifyContent: "center" }}>
                    <span>
                        {testStatuses.filter(res => res === "Not Met").length}
                    </span>
                </Col>
                <Col xs={1} style={{ marginBottom: "auto", marginTop: "auto", display: "grid", justifyContent: "center" }}>
                    <span>
                        {testStatuses.filter(res => res === "To Test").length}
                    </span>
                </Col>
                <Col xs={4} style={{ marginBottom: "auto", marginTop: "auto", display: "grid", justifyContent: "left" }}>
                    {/*<span>
                        status: {statusText}
                    </span>
                    */}
                    <span className="text-muted">{assignText} </span>
                </Col>
                <Col style={{}} >
                    <div style={{ float: "right" }}>
                        <UncontrolledDropdown style={{ display: "inline", gap: "20px" }} setActiveFromChild onClick={(e) => { e.stopPropagation() }}>
                            <DropdownToggle
                                tag="a"
                            >
                                {' '}<i style={{ float: "right", cursor: "pointer", color: "#6C757D", float: "right" }} className="mdi mdi-dots-horizontal font-18"></i>
                            </DropdownToggle>


                            <DropdownMenu>
                                {(device._id === "js1wBogB1L2gw3Qicv2Q" || !hasRiskSub) &&
                                    <DropdownItem disabled >
                                        <i>viewing mode only</i>
                                    </DropdownItem>
                                }
                                <DropdownItem onClick={() => { if (device._id !== "js1wBogB1L2gw3Qicv2Q" && hasRiskSub) { editGroup(upToDateGroup) } }}>
                                    <i className="mdi mdi-pencil mr-1" ></i>Edit
                                </DropdownItem>
                                <DropdownItem onClick={() => { if (device._id !== "js1wBogB1L2gw3Qicv2Q" && hasRiskSub) { openAssignMember(upToDateGroup) } }}>
                                    <i className="mdi mdi-account-multiple-plus mr-1" onClick={() => { if (device._id !== "js1wBogB1L2gw3Qicv2Q" && hasRiskSub) { openAssignMember(upToDateGroup) } }} ></i>Assign
                                </DropdownItem>
                                <DropdownItem onClick={() => { if (device._id !== "js1wBogB1L2gw3Qicv2Q" && hasRiskSub) { copyGroup(group) } }}>
                                    <i className="mdi mdi-content-copy mr-1" ></i>Copy
                                </DropdownItem>
                                <DropdownItem onClick={() => { if (device._id !== "js1wBogB1L2gw3Qicv2Q" && hasRiskSub) { toggleGroupWarningModal(upToDateGroup) } }}>
                                    <i className="mdi mdi-delete-forever"></i>Delete
                                </DropdownItem>
                            </DropdownMenu>


                        </UncontrolledDropdown>
                        <i style={{ float: "right" }} className={classNames(
                            'uil',
                            { 'uil-angle-up': collapse, 'uil-angle-down': !collapse },
                            'font-18'
                        )}>
                        </i>
                    </div>
                </Col>
            </Row>


            <Collapse className={styles.requirementContainer} isOpen={collapse}>
                <Row style={{ marginBottom: "1vh" }} className={styles.buttonCollection}>

                    <Col //className={styles.secondaryButtons}
                    >
                        {reqGroups && reqGroups[level] && reqGroups[level].length !== 0 && device._id !== "js1wBogB1L2gw3Qicv2Q" && hasRiskSub &&
                            <Button
                                className="btn btn-link p-0 text-secondary px-0 py-2"
                                onClick={() => (newTile(type, level, group._id))}
                                style={{ background: "none", border: "none", boxShadow: "none", paddingTop: "0px", paddingBottom: "0px", marginLeft: "5px" }}
                            >
                                <span style={{ paddingLeft: "15px" }}><i style={{ paddingLeft: "5px" }} className="mdi mdi-plus"></i>Add {title} Requirement</span>
                            </Button>}
                    </Col>
                </Row>

                <Card className={styles.card + " mb-0"}>
                    <CardBody data-tut="reactour__table" style={{ "minHeight": 0 }} className={styles.cardBody + " pb-1 pt-2"}>

                        <Row>
                            <Col xs={7} style={{ padding: "20px", backgroundColor: "white" }}>
                                <div style={{ maxHeight: "100vh", overflowY: "scroll" }}>
                                    <Table className={tables.table}>
                                        <thead className={tables.tableHead}>
                                            <tr>
                                                <th className={tables.tableTH} style={{ width: "10%" }}>Ref</th>
                                                <th className={tables.tableTH} style={{ width: "55%" }}>Description</th>

                                                <th className={tables.tableTH} style={{ width: "10%" }}>Status</th>
                                            </tr>
                                        </thead>
                                        {
                                            [...tasks.filter(tile => //!cSelected[level].includes(tile._source.group) && 
                                                tile._source.ref !== "").sort((a, b) => a._source.ref.localeCompare(b._source.ref, 'en', { numeric: true })),
                                            ...tasks.filter(tile => //!cSelected[level].includes(tile._source.group) &&
                                                tile._source.ref === "")]
                                                .map((task, idx) => (
                                                    <Task anoms={anoms} group={group} selectTask={selectTask} testlogs={testlogs} selectedTask={selectedTask} task={task} key={task._source.ref + ":" + task._source.title} getReqTestStatus={getReqTestStatus} reqGroups={reqGroups} />
                                                ))}
                                        <tfoot >
                                            {device._id !== "js1wBogB1L2gw3Qicv2Q" && hasRiskSub &&
                                                <div id={"Add" + level} style={{ width: "150%" }}>
                                                    <button

                                                        className="btn btn-link p-0 text-secondary  px-0 py-2"
                                                        // disabled={reqGroups[level].length === 0}
                                                        // style={{ pointerEvents: 'none'}}
                                                        onClick={() => newTile(type, level, group._id)}>
                                                        <i className="mdi mdi-plus"></i>Add {title} Requirement
                                                    </button>
                                                </div>}
                                            {reqGroups && reqGroups[level] && reqGroups[level].length === 0 &&
                                                <UncontrolledTooltip
                                                    placement="right"
                                                    target={"Add" + level}
                                                >
                                                    Create a group before adding requirements
                                                </UncontrolledTooltip>}
                                        </tfoot>

                                    </Table>
                                </div>
                            </Col>
                            <Col data-tut="reactour__window" xs={5}>

                                {

                                    selectedTask && selectedTask._source &&

                                    <TaskWindow // {Object.keys(props.selectedTask)!==0?props.selectedTask:props.reqs.filter(e =>  e._source.level ==="top")[0]}
                                        task={selectedTask}
                                        device={device}
                                        account={account} instance={instance}
                                        userOptionObjs={userOptionObjs}
                                        dispatch={dispatch}
                                        reqGroups={reqGroups}
                                        level={level}
                                        group={group}
                                        reqGroupsRaw={reqGroupsRaw}
                                        deleteWarning={deleteWarning}
                                        selectTask={selectTask}
                                        hasRiskSub={hasRiskSub}
                                        userModules={userModules}
                                        allTasks={tasks}
                                        getReqTestStatus={getReqTestStatus}
                                        testlogs={testlogs}
                                        anoms={anoms}
                                        runs={runs}
                                        editCoverage={editCoverage}
                                    />}
                            </Col>
                        </Row>


                    </CardBody>
                </Card>
            </Collapse>
        </React.Fragment>
    );
};

export default TaskSection;
