import React, { useEffect, useState } from 'react';
import { Redirect } from 'react-router-dom';
import { Route, useHistory } from 'react-router-dom';
import { MsalAuthenticationTemplate, useMsal, useAccount } from "@azure/msal-react";
import { changeActiveMenuFromLocation } from '../redux/actions';
import LoaderWidget from '../components/Loader';

import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import RiskMatrix from '../pages/apps/RiskManagement/RiskMatrix';
import RiskConfig from '../pages/apps/RiskManagement/RiskConfig';
import RiskManagement from '../pages/apps/RiskManagement/RiskManagement';
import RiskControls from '../pages/apps/RiskManagement/RiskControls';

// lazy load all the views

// auth
const Login = React.lazy(() => import('../pages/auth/Login'));
const Logout = React.lazy(() => import('../pages/auth/Logout'));
const ServerError = React.lazy(() => import('../pages/error/ServerError'));

const TestingLandingPage = React.lazy(() => import('../pages/apps/TestPlans/TestingLandingPage'));
const TestRecordPage = React.lazy(() => import('../pages/apps/TestPlans/TestLogList/RecordPage'));

const Dashboard = React.lazy(() => import('../pages/apps/Dashboard/index'))
const UserHub = React.lazy(() => import('../pages/apps/TentoPlus/UserHub'));

const Support = React.lazy(() => import('../pages/apps/Meta/Support'));
const Contact = React.lazy(() => import('../pages/apps/Meta/Contact'));
const Privacy = React.lazy(() => import('../pages/apps/Meta/Privacy'));
const TCs = React.lazy(() => import('../pages/apps/Meta/TCs'));
const TeamsViewer = React.lazy(() => import('../pages/apps/TeamViewer'));
const Prices = React.lazy(() => import('../pages/apps/Prices'));
const Trial = React.lazy(() => import('../pages/apps/Trial'));

const Profile = React.lazy(() => import('../pages/apps/Profile'));
const DeviceHub = React.lazy(() => import('../pages/apps/TentoPlus/DeviceRequirements/index'));
const DeviceStart = React.lazy(() => import('../pages/apps/TentoPlus/DeviceStart/index'));
const DeviceOverview = React.lazy(() => import('../pages/apps/TentoPlus/DeviceOverview/index'));
const DeviceRegulation = React.lazy(() => import('../pages/apps/TentoPlus/DeviceAnalyse'));
const DeviceDocumentation = React.lazy(() => import('../pages/apps/TentoPlus/DeviceDocumentation'));

const DeviceTraceabilityRequirementXref = React.lazy(() => import('../pages/apps/TentoPlus/DeviceSpecificationTrace'))
const DeviceTraceabilitySourceXref = React.lazy(() => import('../pages/apps/TentoPlus/DeviceDesignTrace'))
const DeviceAnoms = React.lazy(() => import('../pages/apps/TentoPlus/DeviceAnoms'));
const ReportBuilder = React.lazy(() => import('../pages/apps/TentoPlus/ReportBuilder'));



// handle auth and authorization
const STRIPEAPIURL = process.env.REACT_APP_STAGE == 'stage'
    ? "https://tentostripedev.azurewebsites.net/"
    : process.env.REACT_APP_STAGE == 'production' ? "https://tentostripe.azurewebsites.net/" : "http://localhost:4242/";

const PrivateRoute = ({ component: Component, roles, ...rest }) => {
    const { instance, accounts, inProgress } = useMsal();
    const account = useAccount(accounts[0] || {});
    const [stripeUser, setStripeUser] = useState()
    // console.log("ROUTE ACCOUNT:", account, rest)
    
    
    return <Route
        {...rest}
        render={props => {

            if (!account) {
                console.log('In the PrivateRoute route, and user not authenticated')
                // not logged in so redirect to login page with the return url
                return <Redirect to={{ pathname: '/account/login', state: { from: window.location.pathname } }} />;
            }
            if (rest.serverError.status) {
                return <Redirect to={{ pathname: '/error', state: { from: window.location.pathname } }} />;

            }
            // authorised so return component
            if (rest.tentoUser) {
                console.log('In the PrivateRoute route, and user authenticated')
                return <Component {...props} {...rest} tentoUser={rest.tentoUser} 
                // modules={modules} 
                userTeams={rest.userTeams} stripeUser={rest.stripeUser} />;
            }

        }}
    />

}
const PremiumRoute = ({ component: Component, roles, ...rest }) => {
    const [stripeUser, setStripeUser] = useState()

    const { instance, accounts, inProgress } = useMsal();
    const account = useAccount(accounts[0] || {});

    useEffect(() => {
    }, []);

    return <Route
        {...rest}
        render={props => {

            if (!account) {
                console.log('In the PremiumRoute route, and user not authenticated')
                // not logged in so redirect to login page with the return url
                return <Redirect to={{ pathname: '/account/login', state: { from: props.location } }} />;
            }
            // const loggedInUser = getLoggedInUser();
            // // check if route is restricted by role
            // if (roles && roles.indexOf(loggedInUser.role) === -1) {
            //     // role not authorised so redirect to home page
            //     return <Redirect to={{ pathname: '/' }} />;
            // }
            if (rest.serverError.status) {
                return <Redirect to={{ pathname: '/error', state: { from: props.location } }} />;

            }
            if (!rest.subs) {
                return <LoaderWidget></LoaderWidget>
            } else {
                console.log('In the PremiumRoute route, and user authenticated with correct subscription')
                var hasCorrectSub = false
                if (rest.subs.subscriptions.length > 0) {
                    rest.subs.subscriptions.forEach(el => {
                        if (el.active && ["active", "trialing"].includes(el.status) && ["prod_LsLTHdyq5x5Yy2", "prod_Lw2sxyg6duJ2zx"
                            , "prod_NsIfNCmeBfEJuT", "prod_NsIf2szynGis1L", "prod_NsIGaEaB6nEVcE"].includes(el.product))
                            hasCorrectSub = true
                    })
                }

                if (hasCorrectSub) {
                    return <Component {...props} />;
                } else {
                    return <Redirect to={{ pathname: '/prices', state: { from: props.location } }} />;
                }
            }
            // // authorised so return component
            // return <Component {...props} />;
            // if (!isUserAuthenticated()) {
            //     // not logged in so redirect to login page with the return url
            //     console.log('In the private route, and user not authenticated')
            //     return <Redirect to={{ pathname: '/account/login', state: { from: props.location } }} />;
            // }
            // console.log('In the private route, and user authenticated')
            // const loggedInUser = getLoggedInUser();
            // // check if route is restricted by role
            // if (roles && roles.indexOf(loggedInUser.role) === -1) {
            //     // role not authorised so redirect to home page
            //     return <Redirect to={{ pathname: '/' }} />;
            // }

            // authorised so return component

        }}
    />

}
const ErrorRoute = ({ component: Component, roles, ...rest }) => {
    const { instance, accounts, inProgress } = useMsal();
    const account = useAccount(accounts[0] || {});
    // console.log("ROUTE ACCOUNT:", account, rest)


    return <Route
        {...rest}
        render={props => {

            if (!account) {
                // not logged in so redirect to login page with the return url
                console.log('In the private route, and user not authenticated')
                return <Redirect to={{ pathname: '/account/login', state: { from: props.location } }} />;
            }
            // if(!rest.error){
            //     return <Redirect to={{ pathname: '/home', state: { from: props.location } }} />;

            // }
            // authorised so return component
            console.log('In the private route, and user authenticated')
            return <Component {...props} {...rest} tentoUser={rest.tentoUser} userTeams={rest.userTeams} stripeUser={rest.stripeUser} />;


        }}
    />

}

const TestingRoute = ({ component: Component, roles, ...rest }) => {
    const [stripeUser, setStripeUser] = useState()

    const { instance, accounts, inProgress } = useMsal();
    const account = useAccount(accounts[0] || {});
    // console.log("ROUTE ACCOUNT:",account)

    useEffect(() => {
    }, []);

    return <Route
        {...rest}
        render={props => {

            if (!account) {
                // not logged in so redirect to login page with the return url
                // console.log('In the private route, and user not authenticated')
                return <Redirect to={{ pathname: '/account/login', state: { from: props.location } }} />;
            }
            if (rest.serverError.status) {
                return <Redirect to={{ pathname: '/error', state: { from: props.location } }} />;

            }
            // console.log('In the private route, and user authenticated')
            // const loggedInUser = getLoggedInUser();
            // // check if route is restricted by role
            // if (roles && roles.indexOf(loggedInUser.role) === -1) {
            //     // role not authorised so redirect to home page
            //     return <Redirect to={{ pathname: '/' }} />;
            // }
            if (!rest.subs) {
                return <LoaderWidget></LoaderWidget>
            } else {
                var hasCorrectSub = false
                if (rest.subs.subscriptions.length > 0) {
                    rest.subs.subscriptions.forEach(el => {
                        if (el.active && ["active", "trialing"].includes(el.status) && ["prod_LsLTHdyq5x5Yy2", "prod_Lw2sxyg6duJ2zx"
                            , "prod_NsIfNCmeBfEJuT", "prod_NsIf2szynGis1L", "prod_NsIGaEaB6nEVcE"].includes(el.product))
                            hasCorrectSub = true
                    })
                }

                if (hasCorrectSub) {
                    return <Component {...props} />;
                } else {
                    return <Redirect to={{ pathname: '/prices', state: { from: props.location } }} />;
                }
            }
            // // authorised so return component
            // return <Component {...props} />;
            // if (!isUserAuthenticated()) {
            //     // not logged in so redirect to login page with the return url
            //     console.log('In the private route, and user not authenticated')
            //     return <Redirect to={{ pathname: '/account/login', state: { from: props.location } }} />;
            // }
            // console.log('In the private route, and user authenticated')
            // const loggedInUser = getLoggedInUser();
            // // check if route is restricted by role
            // if (roles && roles.indexOf(loggedInUser.role) === -1) {
            //     // role not authorised so redirect to home page
            //     return <Redirect to={{ pathname: '/' }} />;
            // }

            // authorised so return component

        }}
    />

}


const StripeRoute = ({ component: Component, roles, ...rest }) => {
    const { instance, accounts, inProgress } = useMsal();
    const account = useAccount(accounts[0] || {});
    // console.log("ROUTE ACCOUNT:",account)
    const [data, setData] = useState()
    const getStripeConfig = async () => {
        var tempSubs = await rest.fetchWithTimeout(

            STRIPEAPIURL + 'config',
            {
                method: "GET",
                // mode: 'cors',
                headers: new Headers({
                    Accept: "application/json",
                    'Content-Type': 'application/json',
                    // Authorization: `Bearer ${tokenResp.idToken}`
                }
                )

            }).then(res => res.json()).then(
                result => {
                    setData(result)
                })
    }
    useEffect(() => {
        getStripeConfig()
    }, []);

    return <Route
        {...rest}
        render={props => {

            if (!account) {
                // not logged in so redirect to login page with the return url
                return <Redirect to={{ pathname: '/account/login', state: { from: props.location } }} />;
            }
            // const loggedInUser = getLoggedInUser();
            // // check if route is restricted by role
            // if (roles && roles.indexOf(loggedInUser.role) === -1) {
            //     // role not authorised so redirect to home page
            //     return <Redirect to={{ pathname: '/' }} />;
            // }
            if (!data) {
                return <h4>LOADING</h4>
            } else {

                return <Elements stripe={loadStripe(data.publishableKey)}><Component {...props} /></Elements>;

            }


        }}
    />

}

// root routes
const rootRoute = {
    path: '/',
    exact: true,
    component: () => <Redirect to="/home" />,
    route: PrivateRoute,
};
const designHubRoute = {
    path: '/designRequirements',
    name: 'Design Hub',
    route: PrivateRoute,
    roles: ['Admin'],
    icon: 'uil uil-file-check',
    component: DeviceHub,
    exact: true,
}
const managementDashRoute = {
    path: '/managementDashboard',
    name: 'Management Dashboard',
    route: PrivateRoute,
    roles: ['Admin'],
    icon: 'uil uil-file-check',
    component: Dashboard,
    exact: true,
}
const deviceStartRoute = {
    path: '/deviceStart',
    name: 'Device Start',
    route: PrivateRoute,
    roles: ['Admin'],
    icon: 'uil uil-file-check',
    component: DeviceStart,
    exact: true,
}
const designOverviewRoute = {
    path: '/designOverview',
    name: 'Device Overview',
    route: PrivateRoute,
    roles: ['Admin'],
    icon: 'uil uil-file-check',
    component: DeviceOverview,
    exact: true,
}
const deviceRoutes = {
    path: '/home',
    name: 'Devices',
    exact: true,
    route: PrivateRoute,
    roles: ['Admin'],
    icon: 'uil uil-tablet',
    component: UserHub,
    exact: true,
    children: [
        {
            path: '/home',
            name: 'All Devices',
            type: "all",
            component: UserHub,
            route: PrivateRoute,
        }, {
            path: '/home',
            name: 'In Progress',
            type: "inProgress",
            component: UserHub,
            route: PrivateRoute,
        }
    ],
};
const profileRoutes = {
    path: '/profile',
    name: 'Profile',
    exact: true,
    route: PrivateRoute,
    roles: ['Admin'],
    icon: 'uil uil-tablet',
    component: Profile,
    exact: true
    // children: [
    //  {
    //      path: '/home',
    ////     name: 'Device Hub',
    //   component: UserHub,
    //      route: PrivateRoute,
    // },{
    //     path: '/device',
    //     name: 'Current Device info screen',
    //     component: Device,
    //     route: PrivateRoute,
    // }
    //],
};
const testingRoutes = {
    path: '/designTesting',

    name: 'Testing Manager',
    route: PrivateRoute,
    roles: ['Admin'],
    icon: 'uil uil-file-check',
    component: TestingLandingPage,
    exact: true,

    // children: [
    //   {
    //      path: '/designTesting/Record',
    //      name: 'Test Record',
    //      component: TestRecordPage,
    //      route: PrivateRoute,
    //   } 
    // ]
};
const testRecordRoute = {
    path: '/testRecord',

    name: 'TestRecord',
    route: PrivateRoute,
    roles: ['Admin'],
    icon: 'uil uil-file-check',
    component: TestRecordPage,
    exact: true,

    // children: [
    //   {
    //      path: '/designTesting/Record',
    //      name: 'Test Record',
    //      component: TestRecordPage,
    //      route: PrivateRoute,
    //   } 
    // ]
};
const teamViewerRoutes = {
    path: '/team',
    name: 'Team',
    route: PrivateRoute,
    roles: ['Admin'],
    icon: 'uil uil-users-alt',
    component: TeamsViewer,
    exact: true,

};
const priceRoutes = {
    path: '/prices',
    name: 'Prices page',
    route: PrivateRoute,
    roles: ['Admin'],
    icon: 'uil uil-pound-circle',
    component: Prices,
    exact: true,
    // children: [
    //   {
    //      path: '/apps/plans/board',
    //      name: 'Current Board',
    //      component: TestPlans,
    //      route: PrivateRoute,
    //   } 
    // ],
};
const trialRoutes = {
    path: '/freeTrial',
    name: 'Free Trial',
    route: PrivateRoute,
    roles: ['Admin'],
    icon: 'uil uil-pound-circle',
    component: Trial,
    exact: false,
    // children: [
    //   {
    //      path: '/apps/plans/board',
    //      name: 'Current Board',
    //      component: TestPlans,
    //      route: PrivateRoute,
    //   } 
    // ],
};
// const premiumRoutes = {
//     path: '/premium',
//     name: 'Premium page',
//     route: PremiumRoute,
//     roles: ['Admin'],
//     icon: 'uil uil-pound-circle',
//     component: PremiumPage,
//     exact: true,
//     children: [
//         {
//             path: '/apps/plans/board',
//             name: 'Current Board',
//             component: TestPlans,
//             route: PrivateRoute,
//         }
//     ],
// };
// const boardRoutes = {
//     path: '/apps/plans/board',
//     name: 'Bpard',
//     route: PrivateRoute,
//     roles: ['Admin'],
//     icon: 'uil uil-file-check',
//     component: TestPlans,
//     exact: true,
// }

const deviceRegulationRoute = {
    path: '/deviceRegulation',
    name: 'Device',
    route: PrivateRoute,
    roles: ['Admin'],
    icon: 'uil uil-file-check',
    component: DeviceRegulation,
    exact: true,
}

const deviceDocumentationRoute = {
    path: '/deviceDocumentation',
    name: 'Device',
    route: PrivateRoute,
    roles: ['Admin'],
    icon: 'uil uil-file-check',
    component: DeviceDocumentation,
    exact: true,
}
// const deviceTraceabilityRoute = {
//     path: '/deviceTraceability',
//     name: 'Device Traceabiity',
//     route: PrivateRoute,
//     roles: ['Admin'],
//     icon: 'uil uil-file-check',
//     component: DeviceTraceability,
//     exact: true,
// }
// const deviceTraceabilityRequirementStructureRoute = {
//     path: '/deviceTraceabilityRequirementStructure',
//     name: 'Device Requirement Structure',
//     route: PrivateRoute,
//     roles: ['Admin'],
//     icon: 'uil uil-file-check',
//     component: DeviceTraceabilityRequirementStructure,
//     exact: true,
// }
const designTraceabilityRequirementXrefRoute = {
    path: '/designTraceabilityRequirementXref',
    name: 'Device Requirement X-Ref',
    route: PrivateRoute,
    roles: ['Admin'],
    icon: 'uil uil-file-check',
    component: DeviceTraceabilityRequirementXref,
    exact: true,
}

const designTraceabilitySourceXrefRoute = {
    path: '/designTraceabilitySourceXref',
    name: 'Device Source X-Ref',
    route: PrivateRoute,
    roles: ['Admin'],
    icon: 'uil uil-file-check',
    component: DeviceTraceabilitySourceXref,
    exact: true,
}
const traceRoutes = [designTraceabilitySourceXrefRoute, designTraceabilityRequirementXrefRoute]

const deviceAnomsRoute = {
    path: '/designAnomalies',
    name: 'Anomalies',
    route: PrivateRoute,
    roles: ['Admin'],
    icon: 'uil uil-file-check',
    component: DeviceAnoms,
    exact: true,
}

const riskManagementRoute = {
    path: '/riskManagement',
    name: 'Risk Management',
    route: PrivateRoute,
    roles: ['Admin'],
    icon: 'uil uil-file-check',
    component: RiskManagement,
    exact: true,
}

const riskMatrixRoute = {
    path: '/riskMatrix',
    name: 'Risk Matrix',
    route: PrivateRoute,
    roles: ['Admin'],
    icon: 'uil uil-file-check',
    component: RiskMatrix,
    exact: true,
}

const riskConfigRoute = {
    path: '/riskConfig',
    name: 'Risk Configuration',
    route: PrivateRoute,
    roles: ['Admin'],
    icon: 'uil uil-file-check',
    component: RiskConfig,
    exact: true,
}

const riskControlsRoute = {
    path: '/riskControls',
    name: 'Risk Controls',
    route: PrivateRoute,
    roles: ['Admin'],
    icon: 'uil uil-file-check',
    component: RiskControls,
    exact: true,
}



const ReportBuilderRoute = {
    path: '/reportBuilder',
    name: 'Export',
    route: PrivateRoute,
    roles: ['Admin'],
    icon: 'uil uil-file-check',
    component: ReportBuilder,
    exact: true,
}

const supportRoute = {
    path: '/support',
    name: 'Support',
    route: PrivateRoute,
    roles: ['Admin'],
    icon: 'uil uil-file-check',
    component: Support,
    exact: true,
}

const privacyRoute = {
    path: '/privacy',
    name: 'Privacy',
    route: PrivateRoute,
    roles: ['Admin'],
    icon: 'uil uil-file-check',
    component: Privacy,
    exact: true,
}

const contactRoute = {
    path: '/contact',
    name: 'Contact',
    route: PrivateRoute,
    roles: ['Admin'],
    icon: 'uil uil-file-check',
    component: Contact,
    exact: true,
}

const TCsRoute = {
    path: '/TCs',
    name: 'TCs',
    route: PrivateRoute,
    roles: ['Admin'],
    icon: 'uil uil-file-check',
    component: TCs,
    exact: true,
}

const appRoutes = [
    deviceRoutes,

    teamViewerRoutes
    // testingRoutes,

]

// auth

const authRoutes = {
    path: '/account',
    name: 'Auth',
    children: [
        {
            path: '/account/login',
            name: 'Login',
            component: Login,
            route: Route,
        },
        {
            path: '/account/logout',
            name: 'Logout',
            component: Logout,
            route: Route,
        },


    ],
};

const errorRoutes = {
    path: '/error',
    name: 'Error',
    route: ErrorRoute,
    component: ServerError,

};

// flatten the list of all nested routes
const flattenRoutes = routes => {
    let flatRoutes = [];

    routes = routes || [];
    routes.forEach(item => {
        flatRoutes.push(item);

        if (typeof item.children !== 'undefined') {
            flatRoutes = [...flatRoutes, ...flattenRoutes(item.children)];
        }
    });
    return flatRoutes;
};

// All routes
const allRoutes = [rootRoute, ...appRoutes, supportRoute, errorRoutes, TCsRoute, privacyRoute, contactRoute, testingRoutes,
    // boardRoutes,
    ReportBuilderRoute,
    deviceRegulationRoute, deviceDocumentationRoute, deviceAnomsRoute, riskManagementRoute, riskMatrixRoute, riskConfigRoute, riskControlsRoute, ...traceRoutes, managementDashRoute, deviceStartRoute, designOverviewRoute,
    designHubRoute, authRoutes,testRecordRoute,
    //   premiumRoutes,
    trialRoutes, priceRoutes, profileRoutes]; //, uiRoutes, dashboardRoutes, pageRoutes];

const authProtectedRoutes = [...appRoutes];//uiRoutes

const allFlattenRoutes = flattenRoutes(allRoutes);

export { allRoutes, authProtectedRoutes, allFlattenRoutes };
