const StatusLabels = ["","ACTIVE","INACTIVE","ARCHIVED"];
const StatusBadgeClasses = ['','badge-success-lighten', 'badge-danger-lighten', 'badge-info-lighten'];
const TileTypeLabels = ["","Requirement","Function","Unit","Source Code"];
const RecordTypeLabels = ["","System","Functions","Unit"]
const ResultLabels = ["NONE", "PASS", "MAJOR", "MINOR", "OBSERVATION"]
const ResultBadgeClasses = ['badge-light','badge-success','badge-danger','badge-danger', 'badge-success'];
const AnomBadgeClasses = ['badge-info','badge-danger','badge-success','badge-info','badge-warning',];
const AnomResultLabels = ["None",'OPEN','CLOSED','NO ACTION','REVIEW'];
const EvidenceItemStatusClasses = ['badge-info','badge-warning','badge-primary','badge-success',];
const EvidenceItemStatusLabels = ['Not required',"Yet to start",'In Progress','Completed',];
export { EvidenceItemStatusClasses, EvidenceItemStatusLabels, AnomBadgeClasses, AnomResultLabels, StatusLabels, StatusBadgeClasses, TileTypeLabels, RecordTypeLabels, ResultLabels, ResultBadgeClasses };
